import React, { useState } from 'react';
import styled, { css } from 'styled-components';

const HButton_MainBox = styled.div`
  .Primary {
    outline: none;
    border-radius: 4px;
    border: solid 1px #e7e9ea;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    min-width: 89px;
    color: #ffffff;
    background-color: ${(props) => props.theme.main_color};
  }

  .Secondary {
    outline: none;
    border-radius: 4px;
    border: ${(props) => (props.unSelect ? '0' : 'solid 1px #e7e9ea')};
    font-weight: ${(props) => (props.unSelect ? '700' : '600')};
    font-stretch: normal;
    font-style: normal;
    min-width: 89px;
    color: ${(props) => (props.unSelect ? '#969BA1' : props.theme.main_color)};
    background-color: ${(props) => (props.unSelect ? '#E7E9EA' : '#ffffff')};
  }

  .Tertiary {
    outline: none;
    border-radius: 4px;
    border: solid 1px #e7e9ea;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    color: #59616a;
    background-color: #ffffff;
  }

  .Primary:hover {
    background-color: ${(props) => props.theme.main_color_hover};
    cursor: ${(props) => (props.mouseWait ? 'wait' : 'default')};
  }

  .Secondary:hover {
    border: solid 1px #e7e9ea;
    cursor: ${(props) => (props.mouseWait ? 'wait' : 'default')};

    ${(props) =>
      props.tab
        ? css`
            color: ${(props) => (props.unSelect ? '#59616a' : '#e5621f')};
          `
        : css`
            background-color: #eeeff0;
          `};
  }

  .Tertiary:hover {
    background-color: #eeeff0;
    cursor: ${(props) => (props.mouseWait ? 'wait' : 'default')};
  }

  .Primary:active {
    border: solid 1px ${(props) => props.theme.main_color};
    background-color: ${(props) => props.theme.main_color_focus};
    color: ${(props) => props.theme.main_color};
  }

  .Secondary:active {
    ${(props) =>
      props.tab
        ? css``
        : css`
            border: solid 1px ${(props) => props.theme.main_color};
            background-color: ${(props) => props.theme.main_color_focus};
            color: ${(props) => props.theme.main_color};
          `};
  }

  .Tertiary:active {
    border: solid 1px ${(props) => props.theme.main_color};
    background-color: ${(props) => props.theme.main_color_focus};
    color: ${(props) => props.theme.main_color};
  }

  .Primary:disabled {
    background-color: #f5f6f6;
    color: #c3c6c9;
  }

  .Secondary:disabled {
    background-color: #f5f6f6;
    color: #c3c6c9;
  }

  .Tertiary:disabled {
    background-color: #f5f6f6;
    color: #c3c6c9;
  }

  .hbuttonIcon {
    width: 25px;
    height: 25px;
    margin-left: -4px;
    float: left;
  }

  .hbuttonIconText {
    font-family: Source Sans Pro;
    float: left;
    margin-left: 12px;
    margin-top: 5px;
    vertical-align: center;
  }

  .hbuttonText {
    font-family: Source Sans Pro;
    text-align: center;
  }
`;

const HButton = ({
  type = 'button',
  tab = false,
  unSelect = false,
  category,
  size,
  text,
  width,
  margin,
  float,
  disabled = false,
  icon = '',
  onClick,
  mouseWait,
  ...rest
}) => {
  return (
    <HButton_MainBox mouseWait={mouseWait} tab={tab} unSelect={unSelect}>
      <button
        {...rest}
        type={type}
        className={
          category === 'Primary'
            ? 'Primary'
            : category === 'Secondary'
            ? 'Secondary'
            : 'Tertiary'
        }
        style={{
          width: width,
          paddingLeft: '16px',
          paddingRight: '16px',
          height: size === 'Large' ? '46px' : size === 'Medium' ? '40px' : '34px',
          fontSize: size === 'Large' ? '16px' : '14px',
          lineHeight: size === 'Large' ? 1 : 1.14,
          letterSpacing: size === 'Large' ? '0.1px' : '0.2px',
          margin: margin,
          float: float,
        }}
        // onMouseEnter={() => {
        onClick={onClick}
        disabled={disabled}
      >
        {icon !== '' ? (
          <div>
            {/*
                        <img src={process.env.PUBLIC_URL + icon} className={'hbuttonIcon'} />
                        */}
            <div className={'hbuttonIcon'}>{icon}</div>
            <div className={'hbuttonIconText'}>{text}</div>
          </div>
        ) : (
          <div>
            <div className={'hbuttonText'}>{text}</div>
          </div>
        )}
      </button>
    </HButton_MainBox>
  );
};

export default HButton;
