import React, { useState, useEffect, useRef, useContext, useImperativeHandle } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
// import { NavLink, withRouter } from 'react-router-dom';

const useDetectOutsideClick = (el, initialState, openList) => {
  const [isActive, setIsActive] = useState(initialState);

  useEffect(() => {
    const onClick = (e) => {
      // If the active element exists and is clicked outside of
      if (el.current !== null && !el.current.contains(e.target)) {
        if (openList === false) return;
        setIsActive(!isActive);
      }
    };

    // If the item is active (ie open) then listen for clicks outside
    if (isActive) {
      window.addEventListener('click', onClick);
    }

    return () => {
      window.removeEventListener('click', onClick);
    };
  }, [isActive, el, openList]);

  return [isActive, setIsActive];
};

const Container = styled.div`
  .menu-container {
    position: relative;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
  }

  .menu-trigger {
    display: flex;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    margin: ${(props) => props.margin || '0px'};
    background-color: #ffffff;
    padding: 8px 0 9px 16px;
    border: solid 1px #e7e9ea;
    border-radius: 4px;
    outline: none;
    /* cursor: pointer; */

    align-items: center;
    vertical-align: middle;
    /* justify-content: space-between; */
    /* transition: box-shadow 0.4s ease; */
    margin-left: auto;
    /* Strictly for positioning */

    &:hover {
      border: solid 1px #eeeff0;
      background-color: #f5f6f6;
    }

    &:active {
      border: solid 1px ${(props) => props.theme.main_color};
      background-color: #ffffff;
    }

    &:disabled {
      border: solid 1px #e7e9ea;
      background-color: #f5f6f6;
    }

    .searchInput {
      width: 100%;
      height: 19px;
      outline: none;
      border: 0;
      padding: 0;
      margin: 0 30px 0 0;
      background-color: transparent;
      /* background-color: red; */
      color: ${(props) => props.valid || '#969ba1'};
      font-family: 'Source Sans Pro';
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;

      &::placeholder {
        color: #969ba1;
        font-family: 'Source Sans Pro';
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }
      &::-webkit-input-placeholder {
        color: #969ba1;
        font-family: 'Source Sans Pro';
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }
      &::-ms-input-placeholder {
        color: #969ba1;
        font-family: 'Source Sans Pro';
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }
    }

    span {
      width: auto;
      height: 19px;
      color: ${(props) => props.valid || '#969ba1'};
      font-family: 'Source Sans Pro';
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      /* vertical-align: middle; */
      /* &:hover {
        color: #c3c6c9; 
        //span이 아닌 button의 hover이벤트를 받아야하는데 어떻게 할지...
      } */
    }

    img {
      position: absolute;
      right: 0;
      margin: ${(props) => props.img_margin || '6px 6px 6px 0px'};
      width: 24px;
      height: 24px;
    }
  }

  .menu {
    position: absolute;
    top: ${(props) => parseInt(props.margin.split(/(\s)/)[0]) + parseInt(props.height) + 4 + 'px'};
    width: ${(props) => props.width};
    border: solid 1px #e7e9ea;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    //scrollbar
    overflow-x: hidden;
    overflow-y: auto;
    /* .myScrollbar {
      height: 627px;
      overflow-y: auto;
      //background: #ffffff;
    } */

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      /* box-shadow: inset 0px 0px 5px white; */
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c0c3c7;
      border-radius: 4px;
      background-clip: padding-box;
      border: 1px solid transparent;
    }
    &::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }

    /* Firefox scrollbar */
    scrollbar-width: thin;
    scrollbar-color: #c0c3c7 transparent;

    max-height: ${(props) => props.maxHeight || 'auto'};
    /* -ms-overflow-style: none; // IE and Edge
    scrollbar-width: none; //Firefox
    &::-webkit-scrollbar {
      display: none; // Chrome, Safari, Opera
    } */

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      &:first-child {
        margin-top: 8px;
      }
      &:last-child {
        margin-bottom: 8px;
      }
    }
    li {
      height: 40px;

      &:hover {
        background-color: #eeeff0;
      }
    }
    li div {
      font-family: 'Source Sans Pro';
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #59616a;

      text-decoration: none;
      height: 20px;
      padding: 10px 0 10px 15px;
      display: block;
    }
  }

  .active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
`;

const Dropdown = React.forwardRef(
  (
    {
      pardeValue,
      name,
      className,
      width,
      height,
      maxHeight,
      margin,
      img_margin,
      search,
      placeholder,
      defaultValue,
      items,
      onChange,
      onSelect,
      zIndex,
      tabIndex,
      required,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const [selectedItem, setSelectedItem] = useState(defaultValue);
    const [openList, setOpenList] = useState(true);
    const dropdownRef = useRef(null);
    const textInput = useRef(null);
    const buttonBox = useRef(null);
    const [Focus, setFocus] = useState('false');
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false, openList);
    const [isCheckOnce, setIsCheckOnce] = useState(false);
    const node = document.getElementsByName(name + 'Validate')[0];
    const themeContext = useContext(ThemeContext);

    useImperativeHandle(ref, (value) => ({
      setValue(value) {
        setSelectedItem(value);
      },
    }));

    const onRemoveValidate = () => {
      if (name) {
        if (node === undefined) return;
        if (node.childNodes) {
          if (node.childNodes.length > 0) {
            node.removeChild(node.childNodes[0]);
            node.classList.remove('validateCss');
          }
        }
      }
    };
    const onClick = () => {
      const searchInput = textInput.current;
      if (search) {
        searchInput.focus();
      }
      if (openList === false) return;
      setIsActive(!isActive);
    };
    const filterFunction = (e) => {
      setSelectedItem(e.target.value);
      let filter, li, i;
      let tagCount = 0;
      filter = e.target.value.toUpperCase();
      li = dropdownRef.current.getElementsByTagName('li');

      for (i = 0; i < li.length; i++) {
        let txtValue = li[i].textContent || li[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = '';
          // li[i].getElementsByTagName('div')[0].style.color = 'red';
        } else {
          li[i].style.display = 'none';
          tagCount++;
        }
      }

      if (tagCount === li.length) {
        for (i = 0; i < li.length; i++) {
          li[i].style.display = '';
        }
        // setIsActive(false);
        // setOpenList(false);
      } else {
        setIsActive(true);
        setOpenList(true);
      }
    };
    const onFocusInput = () => {
      setFocus(true);
      buttonBox.current.style.border = `solid 1px ${themeContext.main_color}`;
      buttonBox.current.style.backgroundColor = '#ffffff';
    };
    const onBlurOutput = () => {
      setFocus(false);

      //initialization
      if (!defaultValue) {
        //if there is no validate tag, return
        if (node === undefined) return;

        //ex) sign up form
        if (!pardeValue) {
          //No selected item
          setSelectedItem('');
          let small = document.createElement('small');
          small.innerHTML = "<font color='red'>" + t('This field may not be blank') + '</font>';

          if (node.childNodes.length === 0) node.appendChild(small);
          else node.replaceChild(small, node.childNodes[0]);
          node.classList.add('validateCss');
        } else {
          onRemoveValidate();
        }
        if (selectedItem !== pardeValue) {
          if (!isCheckOnce) setSelectedItem('');
          else setSelectedItem(pardeValue);
        }
      } else {
        //ex) edit profile form
        if (!pardeValue) setSelectedItem(defaultValue);
        if (selectedItem !== pardeValue) setSelectedItem(defaultValue);
      }

      buttonBox.current.style.border = 'solid 1px #e7e9ea';
      buttonBox.current.style.backgroundColor = '#ffffff';
    };
    const onMouseOver = () => {
      if (Focus === false) {
        buttonBox.current.style.border = 'solid 1px #eeeff0';
        buttonBox.current.style.backgroundColor = '#f5f6f6';
      }
    };
    const onMouseOut = (e) => {
      if (Focus === false) {
        buttonBox.current.style.border = 'solid 1px #e7e9ea';
        buttonBox.current.style.backgroundColor = '#ffffff';
      }
    };
    // const onKeyUp = (e) => {
    //   const li = dropdownRef.current.getElementsByTagName('li');
    //   switch (e.keyCode) {
    //     case 38:
    //       console.log('up');
    //       li[1].tabIndex = -1;
    //       li[1].focus();
    //       li[1].getElementsByTagName('div')[0].style.color = 'red';
    //       break;
    //     case 40:
    //       console.log('down');
    //       li[0].tabIndex = -1;
    //       li[0].focus();
    //       li[0].style.outline = 'none';
    //       li[0].getElementsByTagName('div')[0].style.color = 'red';
    //       break;
    //     default:
    //       break;
    //   }
    // };

    return (
      <Container
        className={className}
        width={width}
        height={height}
        maxHeight={maxHeight}
        margin={margin}
        img_margin={img_margin}
        // valid={placeholder && selectedItem === defaultValue ? false : '#59616a'}
        valid={placeholder && !defaultValue && !selectedItem ? false : '#59616a'}
        zIndex={zIndex}
      >
        <div className="menu-container">
          <button
            type="button"
            className="menu-trigger"
            ref={buttonBox}
            onClick={onClick}
            // onFocus={onFocusInput}
            // onBlur={onBlurOutput}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
          >
            {search && (
              <input
                className="searchInput"
                ref={textInput}
                value={t(selectedItem)}
                onChange={filterFunction}
                // onKeyDown={onKeyUp}
                placeholder={placeholder}
                tabIndex={tabIndex}
                onFocus={onFocusInput}
                onBlur={onBlurOutput}
                required={required}
              />
            )}
            {!search && <span>{selectedItem}</span>}
            <img
              src={process.env.PUBLIC_URL + '/images/login/icon-24-arrow-down-active.svg'}
              alt="Arrow-down"
            />
          </button>
          <nav ref={dropdownRef} className={`menu ${isActive ? 'active' : 'inactive'}`}>
            {items && Array.isArray(items) ? (
              <ul>
                {items.map((item, index) => (
                  <li key={index}>
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedItem(t(item));
                        onChange(item);
                        onSelect(item, name);
                        onClick();
                        onRemoveValidate();
                        setIsCheckOnce(true);
                      }}
                    >
                      {t(item)}
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <ul>
                {items &&
                  Object.entries(items).map(([key, value], index) => (
                    <li key={index}>
                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedItem(t(value));
                          onChange(value);
                          onSelect(key, name);
                          onClick();
                          onRemoveValidate();
                          setIsCheckOnce(true);
                        }}
                      >
                        {t(value)}
                      </div>
                    </li>
                  ))}
              </ul>
            )}
          </nav>
        </div>
      </Container>
    );
  },
);

const WrapperDropDownList = styled.div`
  float: left;
  position: relative;

  .select-wrapper {
    position: relative;
    z-index: ${(props) => props.zIndex};
  }

  .input-required {
    z-index: 0;
    position: absolute;
    border: 0;
    padding: 0;
    outline: 0;
    top: 0;
    left: 0;
    color: transparent;
    background-color: transparent;
    opacity: 0;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    margin: ${(props) => props.margin};
  }
`;

const noop = () => {
  // no operation (do nothing real quick)
};

const DropdownList = React.forwardRef((props, ref) => {
  const { width, height, margin, defaultValue, required, onSelect, zIndex } = props;
  const [pardeValue, setPardeValue] = useState(defaultValue);
  const { t } = useTranslation();

  if (Array.isArray(defaultValue) || typeof value === 'object')
    setPardeValue(Object.keys(defaultValue).length > 0 ? defaultValue : '');

  const onChange = (value) => {
    setPardeValue(t(value));
  };

  return (
    <WrapperDropDownList width={width} height={height} margin={margin} zIndex={zIndex}>
      <Dropdown
        {...props}
        pardeValue={pardeValue}
        className={`select-wrapper`}
        onChange={onChange}
        onSelect={onSelect}
        ref={ref}
        required={required}
      />
      {/* {required && (
        <input
          type="text"
          tabIndex={-1}
          autoComplete="off"
          className="input-required"
          value={pardeValue && JSON.stringify(pardeValue)}
          onChange={noop}
          required={required}
          // onInvalid={(e) =>
          //   e.target.setCustomValidity(
          //     t('Please select an item from the list.'),
          //   )
          // }
          // onBlur={(e) => {
          //   //Fired when a pointer is no longer active.
          //   console.log('onBlur');
          //   e.target.setCustomValidity('');
          // }}
        />
      )} */}
    </WrapperDropDownList>
  );
});

DropdownList.defaultProps = {
  search: false,
  margin: '0px 0px 0px 0px',
  defaultValue: '',
  zIndex: 1,
};

export default React.memo(DropdownList);
