import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { getLocaleDate, getLocaleTime } from '../common/Util';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import InputSelect from '../common/InputSelect';
import { listAssignees, changeAssignee } from '../../lib/api/case';
import { caseContent } from '../../modules/case';
import SVG from '../common/svg';
import { TabletOrDesktop } from '../../lib/responsive';

const CaseDetailInfoBox = styled.div`
  .caseInfo {
    width: calc(100% - 40px);
    height: 100%;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 13px;
    display: grid;
    grid-template-columns: ${(props) => (props.isTabletOrDesktop ? '50% 50%' : '100%')};
    grid-template-rows: auto;
    gap: 0px;
    font-family: Source Sans Pro;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #59646a;
  }

  .caseInfo_From,
  .caseInfo_Patient,
  .caseInfo_DentalClinic {
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .caseInfo_LeftTitle {
    float: left;
    font-weight: 600;
  }

  .caseInfo_LeftContent {
    float: left;
    font-weight: normal;

    /* text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; */
  }

  .caseInfo_LeftContent::after {
    content: '';
    display: grid;
    clear: both;
  }

  .caseInfo_LeftContent2 {
    float: left;
    font-weight: normal;

    /* text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; */
  }

  .editSmallIconDiv {
    height: 20px;
    float: none;
  }

  .editSmallIcon {
    width: 16px;
    height: 16px;
  }

  .caseInfo_RightTitle {
    ${(props) =>
      props.isTabletOrDesktop
        ? css`
            float: none;
          `
        : css`
            float: left;
          `}

    font-weight: 600;
    /* text-overflow: ellipsis; */
    white-space: nowrap;
    /* overflow: hidden; */
  }

  .caseInfo_RightContent {
    ${(props) =>
      props.isTabletOrDesktop
        ? css`
            float: right;
          `
        : css`
            float: left;
          `}

    font-weight: normal;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .caseInfo_From {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    text-align: left;
  }

  .caseInfo_Patient {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    text-align: left;
  }

  .caseInfo_DentalClinic {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
    text-align: left;
  }

  .caseInfo_Assignee {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 5;
    text-align: left;

    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .caseInfo_OrderID {
    ${(props) =>
      props.isTabletOrDesktop
        ? css`
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 2;
            display: flex;
            justify-content: flex-end;
          `
        : css`
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 5;
            grid-row-end: 6;
            text-align: left;
            clear: both;
          `}
  }

  .caseInfo_OrderSent {
    ${(props) =>
      props.isTabletOrDesktop
        ? css`
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 2;
            grid-row-end: 3;
            /* text-align: right; */
            display: flex;
            justify-content: flex-end;
          `
        : css`
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 6;
            grid-row-end: 7;
            text-align: left;
          `}
  }

  .caseInfo_CaseCreated {
    ${(props) =>
      props.isTabletOrDesktop
        ? css`
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 3;
            grid-row-end: 4;
            /* text-align: right; */
            display: flex;
            justify-content: flex-end;
          `
        : css`
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 7;
            grid-row-end: 8;
            text-align: left;
          `}
  }

  .caseInfo_DeliveryDate {
    ${(props) =>
      props.isTabletOrDesktop
        ? css`
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 4;
            grid-row-end: 5;
            /* text-align: right; */
            display: flex;
            justify-content: flex-end;
          `
        : css`
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 8;
            grid-row-end: 9;
            text-align: left;
          `}
  }

  .caseInfo_AvailableUntil {
    ${(props) =>
      props.isTabletOrDesktop
        ? css`
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 5;
            grid-row-end: 6;
            /* text-align: right; */
            display: flex;
            justify-content: flex-end;
          `
        : css`
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 9;
            grid-row-end: 10;
            text-align: left;
          `}
  }
`;

const useDetectOutsideClick = (el, initialState) => {
  const [isActive, setIsActive] = useState(initialState);

  useEffect(() => {
    const onClick = (e) => {
      // If the active element exists and is clicked outside of
      if (el.current !== null && !el.current.contains(e.target)) {
        setIsActive(!isActive);
      }
    };

    // If the item is active (ie open) then listen for clicks outside
    if (isActive) {
      window.addEventListener('click', onClick);
    }

    return () => {
      window.removeEventListener('click', onClick);
    };
  }, [isActive, el]);

  return [isActive, setIsActive];
};

const CaseDetailInfo = ({ content, anyUserDeleted }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { odm } = useSelector(({ app }) => ({
    odm: app.odm,
  }));

  const [assigneeVisible, setAssigneeVisible] = useState(true);
  const [assignees, setAssignees] = useState([]);
  const [assigneeId, setAssigneeId] = useState(null);
  const [sgUsers, setSgUsers] = useState([]);
  const [assigneeName, setAssigneeName] = useState(null);
  const [assigneeIconVisible, setAssigneeIconVisible] = useState(false);

  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

  const isTabletOrDesktop = TabletOrDesktop();

  useEffect(() => {
    if (!isActive) {
      setAssigneeVisible(true);
    }
  });

  useEffect(() => {
    if (content && content.to.is) setAssigneeName(content.to.username);
  }, [content]);

  const GetPatientName = (patient) => {
    if (patient === null || patient === undefined) {
      return t('not specified');
    }

    if (patient.birdt === '0001-01-01' || patient.birdt === null)
      return patient.firstname + ' ' + patient.lastname + ' / ' + patient.sex;
    else
      return (
        patient.firstname +
        ' ' +
        patient.lastname +
        ' / ' +
        patient.sex +
        ' / ' +
        getLocaleDate(patient.birdt, i18n.language)
      );
  };

  const getDetnalClinic = () => {
    if (content.usertype) {
      if (content.usertype === 'AU' || content.usertype === 'SG')
        return content.author.institution;
      else return content.author.disp_name;
    } else return '';
  };

  const getDeliveryDate = () => {
    let str = t('not specified');
    if (content.case) {
      if (content.case.deliverydt !== null) {
        return getLocaleDate(content.case.deliverydt, i18n.language);
      }
    }
    return str;
  };

  const getCaseCreatedDate = () => {
    let str = t('not specified');
    if (content.case) {
      if (content.case.case_date !== null) {
        return getLocaleDate(content.case.case_date, i18n.language);
      }
    }
    return str;
  };

  const getAvailableUntil = () => {
    let str = t('not specified');
    if (content) {
      if (content.updated_date !== null) {
        const currentDate = new Date(content.updated_date);
        currentDate.setMonth(currentDate.getMonth() + 3);
        return getLocaleDate(currentDate.toISOString(), i18n.language);
      }
    }
    return str;
  };

  const isAssigne = () => {
    if (
      'OT' === content.usertype &&
      content.status.code !== 'CLSD' &&
      !anyUserDeleted &&
      content.user.has_sharegroup
    )
      return true;
    else return false;
  };

  const onClickAssignee = async () => {
    if (
      'OT' === content.usertype &&
      content.status.code !== 'CLSD' &&
      !anyUserDeleted &&
      content.user.has_sharegroup
    ) {
      setAssigneeVisible(false);
      setAssigneeIconVisible(false);
      setIsActive(!isActive);
      try {
        const res = await listAssignees(content.id);
        setAssigneeId(res.data.assignee.id);
        setSgUsers(res.data.sg_users);
        const assinees = res.data.sg_users.map((item) => item.name);
        setAssignees(assinees);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const onAssigneeSelect = async (selAssignee) => {
    const selSgUsers = sgUsers.filter((item) => item.name === selAssignee);
    if (selSgUsers.length === 1 && selSgUsers[0].user_id !== assigneeId) {
      try {
        const res = await changeAssignee({
          case_id: content.id,
          assignee_id: selSgUsers[0].user_id,
        });
        setAssigneeName(selSgUsers[0].name);
        dispatch(caseContent(content.id));
      } catch (e) {
        console.log(e);
      }
    }
    setAssigneeVisible(true);
  };

  const onAssigneeCancel = () => {
    setAssigneeVisible(true);
    setIsActive(!isActive);
  };

  const onAssigneeMouseEnter = () => {
    if (isAssigne() === false) return;
    if (!assigneeVisible) return;
    setAssigneeIconVisible(true);
  };

  const onAssigneeMouseLeave = () => {
    if (isAssigne() === false) return;
    setAssigneeIconVisible(false);
  };

  return (
    <CaseDetailInfoBox isTabletOrDesktop={isTabletOrDesktop}>
      <div
        className={'caseInfo'}
        onMouseEnter={() => onAssigneeMouseEnter()}
        onMouseLeave={() => onAssigneeMouseLeave()}
      >
        <div>
          {/* From */}
          <div className={'caseInfo_From'}>
            <div className={'caseInfo_LeftTitle'}>
              {t('From')}:<span>&nbsp;&nbsp;</span>
            </div>
            <div className={'caseInfo_LeftContent'}>{content.author.username}</div>
          </div>

          {/* Patient */}
          <div className={'caseInfo_Patient'}>
            <div className={'caseInfo_LeftTitle'}>
              {t('Patient')}:<span>&nbsp;&nbsp;</span>
            </div>
            <div className={'caseInfo_LeftContent'}>
              {GetPatientName(content.patient)}
            </div>
          </div>

          {/* Dental Clinic */}
          <div className={'caseInfo_DentalClinic'}>
            <div className={'caseInfo_LeftTitle'}>
              {t('Dental Clinic')}:<span>&nbsp;&nbsp;</span>
            </div>
            <div className={'caseInfo_LeftContent'}>{getDetnalClinic()}</div>
          </div>

          {/* Assignee */}
          <div className={'caseInfo_Assignee'}>
            <div className={'caseInfo_LeftTitle'}>
              {t('Assignee')}:<span>&nbsp;&nbsp;</span>
            </div>
            {assigneeVisible && (
              <div className={'caseInfo_LeftContent2'} onClick={onClickAssignee}>
                {assigneeName}
                <span>&nbsp;&nbsp;</span>
              </div>
            )}
            {!assigneeVisible && (
              <div className={'caseInfo_LeftContent2'} ref={dropdownRef}>
                <InputSelect
                  placeholder={t('Change Assignee')}
                  fontSize="14"
                  width="250px"
                  height="40px"
                  items={assignees}
                  onSelect={onAssigneeSelect}
                  onCancel={onAssigneeCancel}
                />
              </div>
            )}
            {isTabletOrDesktop && (
              <div
                className={'editSmallIconDiv'}
                onClick={onClickAssignee}
                title={t('Edit')}
              >
                {assigneeIconVisible && (
                  <SVG
                    className={'editSmallIcon'}
                    name="editSmall"
                    color="#878D93"
                    colorOver={odm.main_color_hover}
                    colorOut="#878D93"
                    colorDown={odm.main_color}
                  />
                )}
              </div>
            )}
          </div>

          {!isTabletOrDesktop && (
            <>
              {/* Order ID */}
              <div className={'caseInfo_OrderID'}>
                <div className={'caseInfo_RightTitle'}>
                  {t('Order ID')}:<span>&nbsp;&nbsp;</span>
                </div>
                <div className={'caseInfo_RightContent'}>{content.no}</div>
              </div>
              <br />

              {/* Order sent */}
              <div className={'caseInfo_OrderSent'}>
                <div className={'caseInfo_RightTitle'}>
                  {t('Order Sent')}:<span>&nbsp;&nbsp;</span>
                </div>
                <div className={'caseInfo_RightContent'}>
                  {getLocaleDate(content.published_date, i18n.language)}
                  <span>&nbsp;&nbsp;</span>
                  {getLocaleTime(content.published_date, i18n.language)}
                </div>
              </div>
              <br />

              {/* Case Created */}
              <div className={'caseInfo_CaseCreated'}>
                <div className={'caseInfo_RightTitle'}>
                  {t('Case Created')}:<span>&nbsp;&nbsp;</span>
                </div>
                <div className={'caseInfo_RightContent'}>{getCaseCreatedDate()}</div>
              </div>
              <br />

              {/* Delivery Date */}
              <div className={'caseInfo_DeliveryDate'}>
                <div className={'caseInfo_RightTitle'}>
                  {t('Requested Delivery Date')}:<span>&nbsp;&nbsp;</span>
                </div>
                <div className={'caseInfo_RightContent'}>{getDeliveryDate()}</div>
              </div>
            </>
          )}
        </div>
        <div>
          {isTabletOrDesktop && (
            <>
              {/* Order ID */}
              <div className={'caseInfo_OrderID'}>
                <div className={'caseInfo_RightTitle'}>
                  {t('Order ID')}:<span>&nbsp;&nbsp;</span>
                </div>
                <div className={'caseInfo_RightContent'}>{content.no}</div>
              </div>

              {/* Order sent */}
              <div className={'caseInfo_OrderSent'}>
                <div className={'caseInfo_RightTitle'}>
                  {t('Order Sent')}:<span>&nbsp;&nbsp;</span>
                </div>
                <div className={'caseInfo_RightContent'}>
                  {getLocaleDate(content.published_date, i18n.language)}
                  <span>&nbsp;&nbsp;</span>
                  {getLocaleTime(content.published_date, i18n.language)}
                </div>
              </div>

              {/* Case Created */}
              <div className={'caseInfo_CaseCreated'}>
                <div className={'caseInfo_RightTitle'}>
                  {t('Case Created')}:<span>&nbsp;&nbsp;</span>
                </div>
                <div className={'caseInfo_RightContent'}>{getCaseCreatedDate()}</div>
              </div>

              {/* Delivery Date */}
              <div className={'caseInfo_DeliveryDate'}>
                <div className={'caseInfo_RightTitle'}>
                  {t('Requested Delivery Date')}:<span>&nbsp;&nbsp;</span>
                </div>
                <div className={'caseInfo_RightContent'}>{getDeliveryDate()}</div>
              </div>

              {/* Expiration Date */}
              <div className={'caseInfo_AvailableUntil'}>
                <div className={'caseInfo_RightTitle'}>
                  {t('Expiration Date')}:<span>&nbsp;&nbsp;</span>
                </div>
                <div className={'caseInfo_RightContent'}>{getAvailableUntil()}</div>
              </div>
              <br />
              {/* Expiration Date End */}
            </>
          )}
        </div>
      </div>
    </CaseDetailInfoBox>
  );
};

export default CaseDetailInfo;
