import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled, { keyframes, css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import SVG from '../common/svg';
import { theme } from '../../lib/odm';

const Arrow = styled.div`
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  transform: scaleX(${(props) => (props.dir === 'left' ? 1 : -1)});

  /* .LeftArrow {
    transform: scaleX(1);
  }

  .RightArrow {
    transform: scaleX(-1);
  } */
`;

const ArrowButton = ({ direction, tooltip, onClicked }) => {
  const active =
    process.env.PUBLIC_URL + '/images/common/icon-24-arrow-left-active.svg';
  const hover =
    process.env.PUBLIC_URL + '/images/common/icon-24-arrow-left-hover.svg';
  const selected =
    process.env.PUBLIC_URL + '/images/common/icon-24-arrow-left-selected.svg';
  const disabled =
    process.env.PUBLIC_URL + '/images/common/icon-24-arrow-left-disabled.svg';

  const [mouseState, setMouseState] = useState('leave');

  useEffect(() => {});

  function getImage() {
    if (mouseState === 'hover') return hover;
    else if (mouseState === 'pressed') return selected;

    return active;
  }

  return (
    <Arrow
      // onMouseEnter={() => setMouseState('hover')}
      // onMouseLeave={() => setMouseState('leavel')}
      // onMouseDown={() => setMouseState('pressed')}
      // onMouseUp={() => setMouseState('hover')}
      onClick={() => onClicked(direction)}
      dir={direction}
      title={tooltip}
    >
      <SVG
        name="arrow"
        color="#878D93"
        colorOver={theme.main_color_hover}
        colorOut="#878D93"
        colorDown={theme.main_color}
      />
    </Arrow>
  );
};

export default ArrowButton;
