import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  /* margin: ${(props) => props.margin | '60px 0 0 277px'}; */
  /* margin: 60px 0 0 277px; */
  position: relative;

  .InputSearch_Icon {
    position: absolute;
    width: 24px;
    height: 24px;
    margin-top: 8px;
    margin-left: 12px;
    background-color: transparent;
  }

  .myInput {
    width: 255px;
    height: 19px;
    padding: 9px 50px 10px 48px;
    border-radius: 4px;
    border: solid 1px #e7e9ea;
    background-color: #ffffff;
    outline: none;

    font-family: 'Source Sans Pro';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;

    ::placeholder {
      color: #969ba1;
    }
  }

  .searchclear {
    position: absolute;
    right: 0;
    display: inline;
    width: 24px;
    height: 24px;
    margin-top: 8px;
    margin-right: 12px;
    margin-left: 12px;
    background-color: transparent;
    cursor: pointer;
  }

  .searchclearHidden {
    display: none;
  }

  input[type='text']:-ms-clear {
    display: none;
  }
`;

const SearchInput = ({ src, placeholder, settingRef, settingRef2 }) => {
  const settingItemDiv = settingRef.current;
  const settingItemDiv2 = settingRef2.current;

  const clearActive =
    process.env.PUBLIC_URL + '/images/common/icon-24-delete-input-active.svg';
  const clearHover =
    process.env.PUBLIC_URL + '/images/common/icon-24-delete-input-hover.svg';
  const clearSelect =
    process.env.PUBLIC_URL + '/images/common/icon-24-delete-input-selected.svg';
  const [text, setText] = useState('');
  const [Focus, setFocus] = useState('false');
  const [isActive, setActive] = useState('false');
  const inputRef = useRef(null);
  const input = inputRef.current;
  const onChange = (e) => {
    setText(e.target.value);
    setFocus(true);
  };
  const onFocusInput = () => {
    setFocus(true);
    input.style.border = 'solid 1px #ff6d22';
    input.style.backgroundColor = '#ffffff';
  };
  const onBlurInput = () => {
    setFocus(false);

    input.style.border = 'solid 1px #e7e9ea';
    input.style.backgroundColor = '#ffffff';
  };
  const onMouseOver = () => {
    if (Focus === false) {
      input.style.border = 'solid 1px #eeeff0';
      input.style.backgroundColor = '#f5f6f6';
    }
  };
  const onMouseOut = () => {
    if (Focus === false) {
      input.style.border = 'solid 1px #e7e9ea';
      input.style.backgroundColor = '#ffffff';
    }
  };
  const onKeyUp = (e) => {
    setActive(!Boolean(e.target.value));

    var txtValue;
    const filter = inputRef.current.value.toUpperCase();
    const wDiv = settingItemDiv.getElementsByClassName('whiteBox');
    const wDiv2 = settingItemDiv2.getElementsByClassName('whiteBox');

    for (let i = 0; i < wDiv.length; i++) {
      txtValue = wDiv[i].textContent || wDiv[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        wDiv[i].style.display = '';
      } else {
        wDiv[i].style.display = 'none';
      }
    }
    for (let i = 0; i < wDiv2.length; i++) {
      txtValue = wDiv2[i].textContent || wDiv2[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        wDiv2[i].style.display = '';
      } else {
        wDiv2[i].style.display = 'none';
      }
    }
  };
  const onClear = () => {
    setText('');
    input.focus();
    setActive(!isActive);
    // const li = settingItemDiv.getElementsByTagName('li');
    const wDiv = settingItemDiv.getElementsByClassName('whiteBox');
    const wDiv2 = settingItemDiv2.getElementsByClassName('whiteBox');
    for (let i = 0; i < wDiv.length; i++) {
      wDiv[i].style.display = '';
    }
    for (let i = 0; i < wDiv2.length; i++) {
      wDiv2[i].style.display = '';
    }
  };

  return (
    <Container>
      <img
        src={src}
        // src={process.env.PUBLIC_URL + '/images/common/icon-24-search-active.svg'}
        className="InputSearch_Icon"
        alt="search"
      />
      <input
        className="myInput"
        type="text"
        ref={inputRef}
        value={text}
        autoComplete="off"
        placeholder={placeholder}
        onKeyUp={onKeyUp}
        onChange={onChange}
        onFocus={onFocusInput}
        onBlur={onBlurInput}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      />
      <img
        className={isActive ? 'searchclearHidden' : 'searchclear'}
        alt="ClearImage"
        src={
          process.env.PUBLIC_URL +
          '/images/common/icon-24-delete-input-active.svg'
        }
        onMouseOver={(e) => (e.currentTarget.src = clearHover)}
        onMouseOut={(e) => (e.currentTarget.src = clearActive)}
        onMouseDown={(e) => (e.currentTarget.src = clearSelect)}
        onClick={onClear}
      />
    </Container>
  );
};

SearchInput.defaultProps = {
  src: process.env.PUBLIC_URL + '/images/common/icon-24-search-active.svg',
  // margin: '32px 0 0 32px', //up right botoom left,
};

export default SearchInput;
