import React from 'react';
import { useSelector } from 'react-redux';

const Eu = ({ darkMode }) => {
  const { app } = useSelector(({ app }) => ({
    app: app.app,
  }));

  return (
    <>
      {app && app.server_loc === 'EU' && (
        <span
          style={{
            color: darkMode ? 'white' : '#6A6A6A',
            fontWeight: '400',
            fontSize: '1rem',
            verticalAlign: 'baseline',
            fontFamily: 'Source  Sans Pro',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
          }}
        >
          .eu
        </span>
      )}
    </>
  );
};

export default Eu;
