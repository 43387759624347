import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import Logo from './styled/Logo';
import Input from './styled/Input';
import Button from './styled/Button';
import ErrorList from './styled/ErrorList';
import ValidateDiv from '../../components/auth/styled/ValidateText';
import MobileTop from './styled/MobileTop';
import { TabletOrDesktop } from '../../lib/responsive';

const Container = styled.div`
  display: flex;
  min-height: 798px;
  height: 100%;
  background: #eeeff0;
  justify-content: center;
  /* user-select: none; */

  .FormContainer {
    margin-top: ${(props) => (props.mobile ? (props.error ? '0px' : '80px') : '225px')};
  }
`;

const Text = styled.div`
  display: flex;
  margin-left: ${(props) => (props.mobile ? 'auto' : '16px')};
  margin-right: ${(props) => (props.mobile ? 'auto' : '0px')};
  margin-top: ${(props) => props.marginTop};
  width: ${(props) => (props.mobile ? '288px' : '328px')};
  height: ${(props) => props.height};
  background: transparent;
  flex-direction: row;

  font-family: 'Source Sans Pro';
  font-size: ${(props) => props.fontSize};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: ${(props) => props.lineHeight || 'normal'};
  letter-spacing: ${(props) => props.letterSpacing || 'normal'};
  text-align: ${(props) => (props.mobile ? 'center' : 'left')};
  justify-content: ${(props) => (props.mobile ? 'center' : 'left')};
  color: ${(props) => props.color || '#ff6d22'};
`;

export const PasswordAssistanceForm2 = ({
  form,
  onChange,
  onSubmit,
  error,
  onFocusOut,
  isLoadingRef,
}) => {
  const { t } = useTranslation();
  const isTabletOrDesktop = TabletOrDesktop();

  return (
    <div>
      {isTabletOrDesktop && <Logo />}
      {!isTabletOrDesktop && <MobileTop></MobileTop>}
      <Container error={error} mobile={!isTabletOrDesktop}>
        <div className="FormContainer">
          <ErrorList error={error} width={isTabletOrDesktop ? 'auto' : '288px'} />
          <Text
            // width="328px"
            mobile={!isTabletOrDesktop}
            // height="32px"
            color="#59616a"
            fontSize="25px"
            marginLeft="16px"
          >
            {t('Create New Password')}
          </Text>
          <form onSubmit={onSubmit}>
            <div>
              <Input
                width={isTabletOrDesktop ? '416px' : '288px'}
                margin="24px 0 0 0"
                name="password"
                type="password"
                placeholder={t('Enter New Password')}
                value={form.password}
                onChange={onChange}
                onBlur={onFocusOut}
                required
              />
              <ValidateDiv name="new_passwordValidate"></ValidateDiv>
              <Input
                width={isTabletOrDesktop ? '416px' : '288px'}
                margin="24px 0 0 0"
                name="passwordConfirm"
                type="password"
                placeholder={t('Confirm New Password')}
                value={form.passwordConfirm}
                onChange={onChange}
                onBlur={onFocusOut}
                required
              />
              <ValidateDiv name="new_password_confirmValidate"></ValidateDiv>
            </div>
            <div>
              <Button
                disabled={isLoadingRef.current}
                width={isTabletOrDesktop ? '448px' : '320px'}
                margin="16px 0 0 0"
              >
                {t('Change Password').toUpperCase()}
              </Button>
            </div>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default React.memo(PasswordAssistanceForm2);
