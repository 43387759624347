import React from 'react';
import styled, { css } from 'styled-components';
import Logo from './Logo';
import DropdownList from './DropdownList';
import LoggedUser from '../../leftpanel/LoggedUser';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from '../../../modules/cases';
import { useHistory } from 'react-router-dom';
import { initializeForm } from '../../../modules/case';
import { logout } from '../../../modules/user';
import { selectedMenu } from '../../../modules/leftpanel';

const Rectangle = styled.div`
  display: flex;
  width: 100%;
  height: 55px;
  background-color: #ffffff;

  .top-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    margin-right: 20px;
  }
`;

const Contour = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e7e9ea;
`;

const MobileTop = ({ language, lngs, onSelect, isLogined }) => {
  const { leftpanel, user, user_checking } = useSelector(
    ({ app, leftpanel, user, loading, cases }) => ({
      odm: app.odm,
      leftpanel,
      user: user.user,
      user_checking: loading['user/CHECK'],
    }),
  );
  const dispatch = useDispatch();
  let history = useHistory();

  const logoutUser = () => {
    if (window.es) {
      window.es.close();
      delete window.es;
    }
    localStorage.removeItem('user');
    localStorage.removeItem('settings');
    localStorage.removeItem('caseFilters');

    dispatch(initializeForm('content'));
    dispatch(logout());
  };

  const onPersonalSelect = (id) => {
    if (id === 'Sign out') {
      history.push('/');

      dispatch(
        setFilters({
          status: [],
          unreadCommentsOnly: false,
          createDateFrom: null,
          createDateTo: null,
          institution: 'All',
          person: 'All',
          searchString: '',
          orderBy: '',
        }),
      );
      // dispatch(logout());
      logoutUser();
    } else {
      dispatch(selectedMenu(id));
    }
  };
  return (
    <>
      <Rectangle>
        <Logo margin="14px 0px 0px 20px" width="174px" height="24px" />
        {language && (
          <div className="top-right">
            <DropdownList
              width="108px"
              height="36px"
              // defaultValue={languageName}
              // items={{
              //   de: 'Deutsch',
              //   en: 'English',
              //   es: 'español',
              //   fr: 'français',
              //   ja: '日本語',
              // }}
              defaultValue={language}
              items={lngs}
              onSelect={onSelect}
            />
          </div>
        )}
        {isLogined && !user_checking && (
          <LoggedUser
            img={user.picture}
            name={(user.first_name + ' ' + user.last_name).trim()}
            width="254px"
            height="55px"
            collapsed={leftpanel.collapsed || leftpanel.collapsedAuto}
            onSelect={onPersonalSelect}
          />
        )}
      </Rectangle>
      <Contour></Contour>
    </>
  );
};

export default React.memo(MobileTop);
