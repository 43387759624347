import qs from 'qs';
import client from './client';
import { newActionId } from '../../index';

const sort_dic = {
  'Last Updated': 'updated desc',
  'Order Sent': 'created desc',
  Status: 'status asc',
};

// cases
export const listCases = ({ filters, page }) => {
  // truncate time
  const conds = {};
  if (filters.createDateFrom) conds.createDateFrom = filters.createDateFrom;
  if (filters.createDateTo) conds.createDateTo = filters.createDateTo;
  if (filters.status.length) conds.status = filters.status;
  // if (filters.institution !== 'All') conds.institution = filters.institution;
  // if (filters.person !== 'All') conds.person = filters.person;
  if (filters.userIds !== '') conds.uid = filters.userIds;
  if (filters.searchString) conds.search = filters.searchString;
  if (filters.orderBy) {
    conds.sort = sort_dic[filters.orderBy];
  }
  if (page) conds.page = page;
  const queryString = qs.stringify(conds, { indices: false });
  return client.get('v1/cases/' + (queryString ? `?${queryString}` : ''));
};

export const deleteCases = ({ ids }) => {
  const isOne = ids.length === 1;
  const pk = isOne ? ids[0] : 0;
  let params = { headers: { 'X-Action-ID-For-Event': newActionId() } };
  if (!isOne) params['data'] = { ids };

  return client.delete(`/v1/cases/${pk}/remove/`, params);
};

// filters
export const userFilters = () => client.get('/v1/user/filters/');
