import { createAction, handleActions } from 'redux-actions';
import { produce } from 'immer';
// import { takeLatest, put, delay, select } from 'redux-saga/effects';
// import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
// import * as helpDeskAPI from '../lib/api/helpdesk';

const CHANGE_FIELD = 'helpdesk/CHANGE_FIELD';
const INITIALIZE_FORM = 'helpdesk/INITIALIZE_FORM';

// const [LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE] = createRequestActionTypes('helpdesk/LOGIN');

export const changeField = createAction(CHANGE_FIELD, ({ form, key, value }) => ({
  form,
  key,
  value,
}));
export const initializeForm = createAction(INITIALIZE_FORM, (form) => form);
// export const login = createAction(LOGIN, ({ username, password }) => ({
//   username,
//   password,
// }));

// const loginSaga = createRequestSaga(LOGIN, helpDeskAPI.gethelpDeskSession);

// export function* helpdeskSaga() {
//   yield takeLatest(LOGIN, loginSaga);
// }

const initialState = {
  // login: {
  //   username: '',
  //   password: '',
  // },
  ticket: {
    recipient: 'support@3disc.com',
    title: '',
    description: '',
  },
};

const helpdesk = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: { form, key, value } }) =>
      produce(state, (draft) => {
        draft[form][key] = value;
      }),
    [INITIALIZE_FORM]: (state, { payload: form }) => ({
      ...state,
      [form]: initialState[form],
      helpdesk: null,
      helpdeskError: null,
    }),
    // [LOGIN_SUCCESS]: (state, { payload: helpdesk }) => ({
    //   ...state,
    //   helpdeskError: null,
    //   helpdesk,
    // }),
    // [LOGIN_FAILURE]: (state, { payload: error }) => ({
    //   ...state,
    //   helpdeskError: error,
    // }),
  },
  initialState,
);

export default helpdesk;
