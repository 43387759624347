import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';

export const TabletOrDesktop = () => useMediaQuery({ query: '(min-width: 992px)' });

export const GotoCases = () => {
  const history = useHistory();
  const isTabletOrDesktop = TabletOrDesktop();
  if (!isTabletOrDesktop) {
    history.push('/cases');
  }
};
