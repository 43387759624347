import React, { useState } from 'react';
import HButton from '../common/HButton';
import styled from 'styled-components';
import { getInitial } from '../common/Util';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MessageBox from '../common/MessageBox';
import ConfirmBox from '../common/ConfirmBox';
import { listGroupUsers } from '../../modules/groups';
import {
  acceptGroupUser,
  deleteGroupUser,
  rejectGroupUser,
  requestGroupUser,
} from '../../lib/api/groups';

const MainBlock = styled.div`
  .groupBox {
    width: calc(100% - 80px);
    height: 72px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 8px;
    display: grid;
    grid-template-columns: 222px 36px 300px auto auto 20px;
    grid-template-rows: 100%;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #e7e9ea;
    outline: none;
    gap: 0px;
  }

  .groupName {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-left: 20px;
    margin-top: auto;
    margin-bottom: auto;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59646a;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.5em;
    max-height: 3em;
  }

  .picture {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;

    width: 32px;
    height: 32px;
    line-height: 32px;
    margin-top: 20px;
    border-radius: 18px;
    background-color: #9f9f9f;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
  }

  .nameBox {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-left: 10px;
    margin-top: 26px;
  }

  .name {
    font-size: 15px;
    font-weight: 600;
    color: #59646a;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowarp;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    float: left;
  }

  .email {
    font-size: 15px;
    font-weight: normal;
    color: #a5a9ae;
    float: left;
  }

  .status {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;

    height: 19px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 26px;
    font-size: 15px;
    font-weight: 600;
    color: #59646a;
  }

  .buttonBox {
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .button1 {
    margin-top: 16px;
    float: right;
  }

  .button2 {
    margin-top: 16px;
    margin-left: 20px;
    float: right;
  }
`;

const StyledImage = styled.img`
  width: ${(props) => props.width || '32px'};
  height: ${(props) => props.height || '32px'};
  margin: 0;
  border: 0;
  padding: 0;
  object-fit: cover; /*contain*/
  border-radius: 60%;
`;

const GroupItem = ({ member, showMessage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const memberName = member.is_unknown ? `(${t('Unknown')})` : member.name;

  const { groups } = useSelector(({ groups }) => ({
    groups: groups.groups,
  }));

  const getTitle = () => {
    if (member.status === 'REQD') {
      if (member.is_owner) return t('Owner');
      else if (!member.is_owner)
        return groups.sharegroup.name ? groups.sharegroup.name : member.sg_name;
    } else if (member.status === 'ACCD') {
      if (member.is_owner) return t('Owner');
      else return t('Member');
    } else if (member.status === 'REJD') {
      return t('Member');
    }
    return '';
  };

  const getName = () => {
    if (member.status === 'REQD') {
      if (member.is_owner) return member.name;
      if (member.Im_invited) return member.requester.username;
      else if (member.Im_requester) return member.name;
    } else if (member.status === 'ACCD') {
      return memberName;
      // return member.name;
    } else if (member.status === 'REJD') {
      return member.name;
    }
    return '';
  };

  const getEmail = () => {
    let res = '';
    if (member.status === 'REQD') {
      if (member.is_owner) res = member.email;
      else if (member.Im_invited) res = member.requester.email;
      else if (member.Im_requester) res = member.email;
    } else if (member.status === 'ACCD') {
      res = member.email;
    } else if (member.status === 'REJD') {
      res = member.email;
    }

    if (getName() !== '') res = '\u00a0' + '/' + '\u00a0' + res;
    return res;
  };

  const getStatus = () => {
    if (member.status === 'REJD') {
      return t('Rejected');
    }
    return '';
  };

  // Message Box
  const [messageTitle, setMessageTitle] = useState('');
  const [messageContent, setMessageContent] = useState('');
  const [messageVisible, setMessageVisible] = useState(false);

  const [confirmBoxContent, setConfirmBoxContent] = useState('');
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);

  const onMessageBoxOk = async () => {
    try {
      await deleteGroupUser({ id: member.id });
      dispatch(listGroupUsers());
    } catch (e) {
      console.log(e);
    }
    setMessageVisible(false);
  };

  const onMessageBoxCancel = () => {
    setMessageVisible(false);
  };

  // Remove
  const onRemoveAcceptedMember = () => {
    setMessageTitle(t('Warning'));
    setMessageContent(t('Remove member?'));
    setMessageVisible(true);
  };

  const onRemoveInvitation = () => {
    setMessageTitle(t('Warning'));
    setMessageContent(t('Remove Invitation?'));
    setMessageVisible(true);
  };

  // Invidate Again
  const onInviteAgain = async () => {
    try {
      await requestGroupUser({ sg_id: member.sg_id, id: member.id, email: member.email });
      setConfirmBoxContent(t('An Invitation has been sent'));
      setConfirmBoxVisible(true);
    } catch (e) {
      // alert(e);
      if (e.response && e.response.data) showMessage(e.response.data.detail);
    }
  };

  const onConfirmBoxOk = () => {
    setConfirmBoxVisible(false);
  };

  // Accept
  const onAccept = async () => {
    try {
      const res = await acceptGroupUser({
        sguidb64: member.idb64,
        token: member.accept_token,
      });
      dispatch(listGroupUsers());
    } catch (e) {
      if (e.response) {
        if (e.response.data) {
          if (e.response.data.detail) {
            showMessageAccept(e.response.data.detail);
          }
        }
      }
    }
  };

  // Reject
  const onReject = async () => {
    try {
      await rejectGroupUser({ id: member.id });
      dispatch(listGroupUsers());
    } catch (e) {
      console.log(e);
    }
  };

  // Leave
  const onLeaveGroup = () => {
    setMessageTitle(t('Warning'));
    setMessageContent(t('Leave Group?'));
    setMessageVisible(true);
  };

  // Picture
  const getPicture = () => {
    if (member.requester) {
      if (member.requester.picture) {
        return (
          <div class="picture">
            <StyledImage src={member.requester.picture} />
          </div>
        );
      } else {
        return <div class="picture">{getInitial(member.requester.username)}</div>;
      }
    } else {
      if (member.picture) {
        return (
          <div class="picture">
            <StyledImage src={member.picture} />
          </div>
        );
      } else {
        // return <div class="picture">{getInitial(member.name)}</div>;
        return <div class="picture">{getInitial(memberName.replace('(', ''))}</div>;
      }
    }
  };

  const showMessageAccept = (detail) => {
    setMessageTitle(t('Info'));

    if (detail === 'joined group') setMessageContent(t('Joined group'));
    else if (detail === 'request already accepted')
      setMessageContent(t('Request is already accepted'));
    if (detail === 'request needed') setMessageContent(t('Request is needed'));
    else if (detail === 'different role')
      setMessageContent(t("The user's role is different from that of the group"));
    else if (detail === 'already in connections') setMessageContent(t('Already in connections'));
    else if (detail === 'already joined another group')
      setMessageContent(t('Already joined another group'));

    setMessageVisible(true);
  };

  return (
    <MainBlock>
      <div className="groupBox">
        {/* Title */}
        <div class="groupName">{getTitle()}</div>

        {/* Picture */}
        {getPicture()}

        {/* Name */}
        <div className="nameBox">
          <div className="name">{getName()}</div>
          <div className="email">{getEmail()}</div>
        </div>

        {/* Email */}

        {/* Status */}
        <div className={'status'}>{getStatus()}</div>

        {member.status === 'REQD' && member.Im_requester && (
          <div className="buttonBox">
            <div className="button2">
              {/* Invite Again */}
              <HButton
                category="Secondary"
                size="Medium"
                text={t('Invite again').toUpperCase()}
                onClick={() => onInviteAgain()}
              />
            </div>
            <div className="button1">
              {/* Remove */}
              <HButton
                category="Secondary"
                size="Medium"
                text={t('Remove').toUpperCase()}
                onClick={() => onRemoveInvitation()}
              />
            </div>
          </div>
        )}

        {member.status === 'REQD' && member.Im_invited && (
          <div className="buttonBox">
            <div className="button2">
              {/* Accept */}
              <HButton
                category="Secondary"
                size="Medium"
                text={t('Accept').toUpperCase()}
                onClick={() => onAccept()}
              />
            </div>
            <div className="button1">
              {/* Reject */}
              <HButton
                category="Secondary"
                size="Medium"
                text={t('Reject').toUpperCase()}
                onClick={() => onReject()}
              />
            </div>
          </div>
        )}

        {member.status === 'REJD' && (
          <div className="buttonBox">
            <div className="button2">
              {/* Remove */}
              <HButton
                category="Secondary"
                size="Medium"
                text={t('Remove').toUpperCase()}
                onClick={() => onRemoveInvitation()}
              />
            </div>
            <div className="button1">
              {/* Invite Again */}
              <HButton
                category="Secondary"
                size="Medium"
                text={t('Invite again').toUpperCase()}
                onClick={() => onInviteAgain()}
              />
            </div>
          </div>
        )}

        {member.status === 'ACCD' && !member.is_owner && !member.Im_requester && (
          <div className="button2">
            {/* Leave Group */}
            <HButton
              category="Secondary"
              size="Medium"
              text={t('Leave Group').toUpperCase()}
              onClick={() => onLeaveGroup()}
            />
          </div>
        )}

        {member.status === 'ACCD' && !member.is_owner && member.Im_requester && (
          <div className="buttonBox">
            <div className="button2">
              {/* Remove */}
              <HButton
                category="Secondary"
                size="Medium"
                text={t('Remove').toUpperCase()}
                onClick={() => onRemoveAcceptedMember()}
              />
            </div>
          </div>
        )}

        <MessageBox
          visible={messageVisible}
          type="okcancel"
          title={messageTitle}
          content={messageContent}
          onOk={onMessageBoxOk}
          onCancel={onMessageBoxCancel}
        />
        <ConfirmBox visible={confirmBoxVisible} content={confirmBoxContent} onOk={onConfirmBoxOk} />
      </div>
    </MainBlock>
  );
};

export default GroupItem;
