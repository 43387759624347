import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled, { keyframes, css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import ArrowButton from './ArrowButton.js';

const DateText = styled.div`
  float: left;
  width: 200px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.2px;
  text-align: center;
  color: #59616a;
  user-select: none;
`;

const monName = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const CalendarNavigator = ({ date, onChanged }) => {
  const { t } = useTranslation();

  useEffect(() => {});

  function onClicked(dir) {
    //date.setMonth(date.getMonth() + (dir === 'left' ? -1 : 1));
    var d = new Date();
    d.setFullYear(date.getFullYear());
    d.setMonth(date.getMonth() + (dir === 'left' ? -1 : 1));
    d.setDate(date.getDate());
    onChanged(d);
  }

  return (
    <div
      style={{
        marginLeft: '21px',
        marginTop: '20px',
        width: '280px',
        height: '40px',
      }}
    >
      <div
        style={{
          float: 'left',
          width: '40px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ArrowButton
          direction="left"
          tooltip={t('Previous')}
          onClicked={onClicked}
        />
      </div>

      <DateText>
        {t(monName[date.getMonth()]).toUpperCase()} {date.getFullYear()}
      </DateText>

      <div style={{ float: 'left', width: '40px' }}>
        <ArrowButton
          direction="right"
          tooltip={t('Next')}
          onClicked={onClicked}
        />
      </div>
    </div>
  );
};

export default CalendarNavigator;
