export var loadMarginPoints = (function () {
  var loadBinaryMargin = function (buffer) {
    var uint8array = new Uint8Array(buffer);

    var enc = new TextDecoder('utf-8');
    var splitBuffer = enc.decode(uint8array).split(/\r\n/);
    var points = [];

    // start i=1, because have to start second line
    for (var i = 1; i < splitBuffer.length; i++) {
      var vectorBuf = splitBuffer[i].split(',');
      // points.push(new Vector3(Number(vectorBuf[0]), Number(vectorBuf[1]), Number(vectorBuf[2])));
      points.push(Number(vectorBuf[0]), Number(vectorBuf[1]), Number(vectorBuf[2]));
    }

    return points;
  };

  return function (buffer) {
    try {
      console.log('load as binary margin');
      return loadBinaryMargin(buffer);
    } catch (e) {
      console.log(e);
    }
  };
})();
