import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled, { keyframes, css } from 'styled-components';
import HButton from '../common/HButton';
import { useTranslation } from 'react-i18next';
import SVG from './svg';
import { TabletOrDesktop } from '../../lib/responsive';
import DropdownList from '../auth/styled/DropdownList';
import countries from 'i18n-iso-countries';
import CheckboxText from './CheckBoxText';
import Cookies from 'universal-cookie';
const MB_fadeIn = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

const MB_fadeOut = keyframes`
  from {
    opacity: 1
  }
  to {
    opacity: 0
  }
`;

const MB_slideDown = keyframes`
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0px);
  }
`;

const MB_slideUp = keyframes`
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-100px);
  }
`;

const MB_Fullscreen = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;

  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${MB_fadeIn};
  animation-fill-mode: forwards;

  ${(props) =>
    props.disappear &&
    css`
      animation-name: ${MB_fadeOut};
    `}
`;

const MB_ModalBlock = styled.div`
  width: 392px;
  width: ${(props) => (props.mobile ? '320px' : '392px')};
  /* height: 252px; */
  height: ${(props) => (props.migration ? '320px' : '252px')};
  background: white;
  border-radius: 4px;
  border: solid 1px #eeeff0;

  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${MB_slideDown};
  animation-fill-mode: forwards;

  ${(props) =>
    props.disappear &&
    css`
      animation-name: ${MB_slideUp};
    `}
`;

const MB_TitleBlock = styled.div`
  .titleBox {
    width: ${(props) => (props.mobile ? '320px' : '392px')};
    height: 56px;
    border-radius: 4px 4px 0 0;
    margin: 0 0 0 0;
    background: #eeeff0;
  }

  .titleText {
    /* width: 300px; */
    width: ${(props) => (props.mobile ? '228px' : '300px')};
    height: 25px;
    margin: 15px 0 0 19px;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
    text-align: left;
    float: left;
  }

  .closeIcon {
    width: 24px;
    height: 24px;
    margin: 16px 19px 0 0;
    float: right;
  }
`;

const MB_ContentBlock = styled.div`
  .contentBox {
    /* width: 352px; */
    width: ${(props) => (props.mobile ? '280px' : '352px')};
    /* height: 80px; */
    margin: 24px 20px 32px 20px;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #59616a;
  }
`;

const MBCountry = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const MB_ButtonBlock = styled.div`
  width: 100%;
  position: absolute;
  bottom: 20px;
`;

const MessageBox = ({
  form,
  language,
  onSelect,
  messageConfirmCheck,
  visible,
  type,
  title,
  content,
  onOk,
  onCancel,
  expire,
}) => {
  const [animate, setAnimate] = useState(false);
  const [localVisible, setLocalVisible] = useState(visible);
  const { t } = useTranslation();
  const isTabletOrDesktop = TabletOrDesktop();
  const migration = messageConfirmCheck === 'migration';
  // State to store the "Do not show again" checkbox value
  const [hide, setHide] = useState(false);

  const { odm } = useSelector(({ app }) => ({
    odm: app.odm,
  }));

  useEffect(() => {
    if (localVisible && !visible) {
      setAnimate(true);
      setTimeout(() => setAnimate(false), 250);
    }
    setLocalVisible(visible);
  }, [localVisible, visible]);

  const setCookie = (name, value, exp) => {
    const cookies = new Cookies();
    cookies.set(name, value, { path: '/', expires: exp });
  };

  const callbackFunction = () => {
    // If the "Do not show again" checkbox is checked, set the cookie
    if (hide) {
      const expires = new Date();

      expires.setFullYear(expires.getFullYear() + 10);
      setCookie('hide', true, expires);
    }
  };

  /*
  const closeActive =
    process.env.PUBLIC_URL + '/images/common/icon-24-close-active.svg';
  const closeHover =
    process.env.PUBLIC_URL + '/images/common/icon-24-close-hover.svg';
  const closeDisabled =
    process.env.PUBLIC_URL + '/images/common/icon-24-close-disabled.svg';
  const closeSelected =
    process.env.PUBLIC_URL + '/images/common/icon-24-close-selected.svg';
*/
  if (!animate && !localVisible) return null;

  return (
    <MB_Fullscreen disappear={!visible} mobile={!isTabletOrDesktop}>
      <MB_ModalBlock disappear={!visible} mobile={!isTabletOrDesktop} migration={migration}>
        <MB_TitleBlock mobile={!isTabletOrDesktop}>
          <div className="titleBox">
            <div class="titleText">{title}</div>
            {/*
            <img
              className={'closeIcon'}
              src={closeActive}
              onMouseOver={(e) => (e.currentTarget.src = closeHover)}
              onMouseOut={(e) => (e.currentTarget.src = closeActive)}
              onMouseDown={(e) => (e.currentTarget.src = closeSelected)}
              onClick={onCancel}
            />
            */}
            <div className={'closeIcon'} onClick={onCancel}>
              <SVG
                name="close"
                color="#878D93"
                colorOver={odm.main_color_hover}
                colorOut="#878D93"
                colorDown={odm.main_color}
              />
            </div>
          </div>
        </MB_TitleBlock>
        <MB_ContentBlock mobile={!isTabletOrDesktop}>
          <div className={'contentBox'}>{content}</div>
        </MB_ContentBlock>

        {migration && (
          <MBCountry>
            <DropdownList
              name="country"
              width="352px"
              height="48px"
              maxHeight="216px"
              defaultValue={countries.getName(form.country, language)}
              margin="8px 0 0 0"
              img_margin="12px 12px 12px 0"
              search={true}
              items={Object.fromEntries(
                Object.entries(countries.getNames(language, { select: 'official' })).sort(function (
                  [, a],
                  [, b],
                ) {
                  return a.localeCompare(b);
                }),
              )}
              onSelect={onSelect}
              zIndex={1}
              required
            />
          </MBCountry>
        )}
        <MB_ButtonBlock>
          <HButton
            category="Primary"
            size="Medium"
            text={migration ? t('Select Now').toUpperCase() : t('OK').toUpperCase()}
            width={migration ? (language === 'fr' ? '160px' : 'auto') : 'auto'}
            margin="0 20px 0 0"
            float="right"
            onClick={(e) => {
              onOk(e, callbackFunction);
            }}
          />
          {type !== 'ok' ? (
            <div>
              <HButton
                category="Secondary"
                size="Medium"
                text={migration ? t('Select Later').toUpperCase() : t('Cancel').toUpperCase()}
                width={migration ? (language === 'fr' ? '160px' : 'auto') : 'auto'}
                margin="0 16px 0 0"
                float="right"
                onClick={onCancel}
              />
            </div>
          ) : (
            <div></div>
          )}
          {expire && (
            <CheckboxText
              text={"Don't show this message again"}
              height={'40px'}
              fontSize={'15px'}
              margin={'0 0 0 20px'}
              id="hide-checkbox"
              checked={hide}
              onChange={(e) => setHide(e.target.checked)}
            ></CheckboxText>
          )}
        </MB_ButtonBlock>
      </MB_ModalBlock>
    </MB_Fullscreen>
  );
};

export default MessageBox;
