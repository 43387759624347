import React from 'react';
import { useSelector } from 'react-redux';
import Pagination from '../../components/cases/Pagination';

const PaginationContainer = () => {
  const { cases, page, filters, loading } = useSelector(
    ({ cases, loading }) => ({
      cases: cases.cases,
      page: cases.page,
      filters: cases.filters,
      loading: loading['posts/LIST_POSTS'],
    }),
  );

  if (!cases || loading) return null;
  return <Pagination page={page} filters={filters} />;
};

export default PaginationContainer;
