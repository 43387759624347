import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectedCaseStatus } from '../../modules/leftpanel';
import { useTranslation } from 'react-i18next';
import './TextBox.css';

const TextBox = React.memo((props) => {
    const { t } = useTranslation();

    if (props.selectedStatus === undefined) return null;

    return (
        <button
            type="button"
            className={props.fat === true ? "TextBoxFatRect" : "TextBoxRect"}
            style={{
                backgroundColor: props.selectedStatus.indexOf(props.id) > -1 ? props.sel_color : '#ffffff',
                cursor: props.cursor,
                userSelect: 'none',
                margin: props.margin,
            }}
            onClick={() => props.onClick(props.id)}
        >
            <div className="TextBoxFont" style={{ color: props.color, cursor: props.cursor, opacity: '1.0' }}>{t(props.name)}</div>
        </button>
    );
});

export default TextBox;