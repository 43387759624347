import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import LeftPanel from '../../containers/leftpanel/LeftPanelContainer';
import Button from './styled/Button';
import Input from './styled/Input';
import ErrorList from './styled/ErrorList';
import MessageBox from '../common/MessageBox';
import ValidateDiv from '../../components/auth/styled/ValidateText';
import HButton from '../common/HButton';

const MainBlock = styled.div`
  /* user-select: none; */
  .validateCss {
    margin-top: 4px;
    margin-bottom: 8px;
  }
  .Normal {
    width: 100%;
    min-width: 991px;
    height: 100%;
    margin: auto;
    background: #eeeff0;
    display: grid;
    grid-template-columns: 256px auto;
    gap: 0px;
  }

  .Collapsed {
    width: 100%;
    height: 100%;
    background: #eeeff0;
    margin: auto;
    display: grid;
    grid-template-columns: 64px auto;
    gap: 0px;
  }

  div {
    font-family: 'Source Sans Pro';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
`;

const RightDiv = styled.div`
  display: flex;
  flex-direction: column; /* vertical */
  width: 100%;
  height: 100%;

  form {
    display: flex;
    flex-direction: column;
    width: 542px;
    margin-top: ${(props) => props.marginTop || '93px'};
    margin-left: 40px;
  }
`;

const Top = styled.div`
  display: flex;
  flex-direction: row; /* horizontal */
  width: 100%;
  height: 40px;
  margin-top: 60px;
  align-items: center;

  .title {
    flex: 1 1 135px; /* flex-grow, flex-shrink, flex-basis */
    height: 32px;
    margin-left: 48px;
    font-size: 25px;
    font-weight: 600;
    color: #59616a;
  }
  .buttons {
    display: flex;
    /* width: 196px; */
    /* justify-content: space-between; */
    align-items: center;
    margin-right: 40px;
  }
`;

const ChangePassword = ({
  form,
  onChange,
  onSubmit,
  error,
  messageVisible,
  messageTitle,
  messageType,
  messageContent,
  onConfirmBoxOk,
  onCancel,
  onFocusOut,
}) => {
  const { t, i18n } = useTranslation();
  const leftpanel = useSelector((state) => state.leftpanel);

  return (
    <MainBlock>
      <div className={leftpanel.collapsed || leftpanel.collapsedAuto ? 'Collapsed' : 'Normal'}>
        <LeftPanel />
        <RightDiv marginTop={error ? '16px' : null}>
          <Top>
            <div className="title">{t('Change Password')}</div>
            <div className="buttons">
              <HButton
                form="submit"
                category="Primary"
                size="Medium"
                text={t('Save New Password').toUpperCase()}
                type=""
              ></HButton>
            </div>
          </Top>
          <ErrorList error={error} width="540px" margin="41px 0 0 40px" />
          <form id="submit" onSubmit={onSubmit}>
            <div>
              <Input
                name="old_password"
                type="password"
                margin="0"
                width="509px"
                placeholder={t('Enter Old Password')}
                value={form.old_password}
                onChange={onChange}
                onBlur={onFocusOut}
                tabIndex={1}
                required
              />
              {/* <ValidateDiv name="old_passwordValidate"></ValidateDiv> */}
              <Input
                name="password"
                type="password"
                margin="0"
                width="509px"
                placeholder={t('Enter New Password')}
                value={form.password}
                onChange={onChange}
                onBlur={onFocusOut}
                tabIndex={2}
                required
              />
              <ValidateDiv name="new_passwordValidate"></ValidateDiv>
              <Input
                name="passwordConfirm"
                type="password"
                margin="0"
                width="509px"
                placeholder={t('Confirm New Password')}
                value={form.passwordConfirm}
                onChange={onChange}
                onBlur={onFocusOut}
                tabIndex={3}
                required
              />
              <ValidateDiv name="new_password_confirmValidate"></ValidateDiv>
            </div>
          </form>
          <MessageBox
            visible={messageVisible}
            type={messageType}
            title={messageTitle}
            content={messageContent}
            onOk={onConfirmBoxOk}
            onCancel={onCancel}
          />
        </RightDiv>
      </div>
    </MainBlock>
  );
};

export default React.memo(ChangePassword);
