import axios from 'axios';
import qs from 'qs';
import client from './client';
import { newActionId } from '../../index';

const CancelToken = axios.CancelToken;

// case
export const caseDetail = (_case) => {
  if (_case.id) return client.get(`/v1/cases/${_case.id}/all/`);
  else return client.get(`/v1/cases/${_case.no}/`);
};
export const caseDetailContent = (id) => client.get(`/v1/cases/${id}/content/`);

export const casePatCommDetail = (data) => client.get(`/v1/share/case/?data=${data}`);

// export const editCase = (id) =>
//   client.post(`/v1/cases/${id}/edit/`, null, {
//     headers: { 'X-Action-ID-For-Event': newActionId() },
//   });

export const caseStatus = ({ id, action }) =>
  client.put(
    `/v1/cases/${id}/status/`,
    { action },
    {
      headers: { 'X-Action-ID-For-Event': newActionId() },
    },
  );

// export const deleteCase = (id) =>
//   client.delete(`/v1/cases/${id}/remove/`, {
//     headers: { 'X-Action-ID-For-Event': newActionId() },
//   });

export const listAssignees = (id) => client.get(`/v1/cases/${id}/assignee/`);

export const changeAssignee = ({ case_id, assignee_id }) =>
  client.put(
    `/v1/cases/${case_id}/assignee/`,
    { assignee_id },
    {
      headers: { 'X-Action-ID-For-Event': newActionId() },
    },
  );

// export const newCase = () => client.post('/v1/cases/new/');

// files
export const listFiles = (id) => client.get(`/v1/cases/${id}/files/`);

export let uploadCancel = [];
export const uploadFile = async (caseId, formData, onUploadProgress) =>
  client.post(`/v1/cases/${caseId}/file/`, formData, {
    cancelToken: new CancelToken(function executor(c) {
      uploadCancel.push(c);
    }),
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });

export const uploadFilesDone = (id) =>
  client.put(`/v1/cases/${id}/files/upload-done/`, null, {
    headers: { 'X-Action-ID-For-Event': newActionId() },
  });

// export const downloadFilesAll = ({ id, files_ids }) =>
//   client.post(
//     `/v1/cases/${id}/files/download-all/`,
//     { files_ids },
//     { responseType: 'blob' },
//   );

export const getFileUri = (ids) => {
  const queryString = qs.stringify(ids, { indices: false });
  return client.get('/v1/files/uri/' + (queryString ? `?${queryString}` : ''));
};

export const getShareCaseFileUri = (ids) => {
  const queryString = qs.stringify(ids, { indices: false });
  return client.get('/v1/share/files/uri/' + (queryString ? `?${queryString}` : ''));
};

export const downloadFile = (id) =>
  client.get(`/v1/files/${id}/download/`, {
    responseType: 'arraybuffer',
  });

// export const download2DFile = (url) =>
//   axios.get(url, {
//     responseType: 'arraybuffer',
//   });

export let downloadFileList = {};
export const setDownloadFileList = (value) => {
  downloadFileList = value;
};

export let downloadCancel = [];
export const download3DFile = async (id, count, patCommPath = '') => {
  return client
    .get(`/v1${patCommPath}/files/${id}/download/`, {
      responseType: 'arraybuffer',
      cancelToken: new CancelToken(function executor(c) {
        downloadCancel.push(c);
      }),
      onDownloadProgress: (progressEvent) => {
        var sum = 0;
        const progressBar = document.querySelector('#progress');
        const loadingOverlay = document.querySelector('#loading-overlay');
        if (loadingOverlay != null) {
          loadingOverlay.style.display = 'flex';
        }

        let percentCompleted = Math.floor(
          (progressEvent.loaded / progressEvent.total) * 100,
        );
        downloadFileList[progressEvent.currentTarget.responseURL] = percentCompleted;

        for (var key in downloadFileList) {
          sum += downloadFileList[key];
        }
        var progress = sum / Object.keys(downloadFileList).length;
        if (count === Object.keys(downloadFileList).length) {
          if (progressBar != null) {
            progressBar.style.width = progress + '%';
          }

          if (progress === 100) {
            downloadFileList = {};
          }
        }
      },
    })
    .catch(function (thrown) {
      if (axios.isCancel(thrown)) {
        console.log(thrown.message);
      } else {
        console.log('3D download cancel error!!');
      }
    });
};

export const deleteFile = (id) =>
  client.delete(`/v1/files/${id}/remove/`, {
    headers: { 'X-Action-ID-For-Event': newActionId() },
  });

// export const deleteTempFiles = ({ ids }) =>
//   client.delete('/v1/files/remove/temp/', {
//     data: { ids },
//   });

// comments
export const listComments = (id) => client.get(`/v1/cases/${id}/comments/`);

export const addComment = ({ id, comment }) =>
  client.post(
    `/v1/cases/${id}/comment/`,
    { comment },
    {
      headers: { 'X-Action-ID-For-Event': newActionId() },
    },
  );

export const editComment = ({ id, comment }) =>
  client.put(
    `/v1/comments/${id}/edit/`,
    { comment },
    {
      headers: { 'X-Action-ID-For-Event': newActionId() },
    },
  );

export const deleteComment = (id) =>
  client.delete(`/v1/comments/${id}/remove/`, {
    headers: { 'X-Action-ID-For-Event': newActionId() },
  });
