import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HButton from '../../components/common/HButton';
import GroupItem from './GroupItem';
import CreateGroup from './CreateGroup';
import EditGroupMembers from './EditGroupMembers';
import styled from 'styled-components';
import MessageBox from '../common/MessageBox';
import { listGroupUsers } from '../../modules/groups';
import { requestGroupUser, newGroup, deleteGroup, editGroup } from '../../lib/api/groups';
import { useTranslation } from 'react-i18next';
import Spinner from '../common/Spinner';
import InputBox from '../common/InputBox';

const MainBlock = styled.div`
  font-family: Source Sans Pro;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  .GroupNormal {
    width: 100%;
    height: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 64px 32px auto 60px;
    gap: 0px;
  }

  .GroupCollapsed {
    width: 100%;
    height: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 64px 32px auto 60px;
    gap: 0px;
  }

  .GroupTitle {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    margin-left: 48px;
    font-size: 25px;
    font-weight: 600;
    color: #59616a;
  }

  .GroupButton {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    text-align: right;
    margin-right: 40px;
  }

  .GroupContent {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
  }

  .GroupBottom {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
  }

  .pendingTitle {
    width: 100%;
    height: auto;
    margin: 56px 0 16px 48px;
    font-size: 20px;
    font-weight: normal;
    color: #969ba1;
  }

  .acceptedTitle {
    width: 100%;
    height: auto;
    margin: 56px 0 16px 48px;
    font-size: 20px;
    font-weight: normal;
    color: #969ba1;
  }

  .loading {
    margin-top: 100px;
    text-align: center;
  }
`;

const Groups = ({ match, history, accept }) => {
  const { token } = match;
  const leftpanel = useSelector((state) => state.leftpanel);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { groups, error, loading } = useSelector(({ groups, loading }) => ({
    groups: groups.groups,
    error: groups.error,
    loading: loading['groups/LIST_GROUPUSERS'],
  }));

  const [modalGroup, setModalGroup] = useState(false);
  const [modalEditGroupMembers, setModalEditMembers] = useState(false);

  const onCancel = () => {
    setModalGroup(false);
    setModalEditMembers(false);
  };

  // Message Box
  const [messageTitle, setMessageTitle] = useState('');
  const [messageContent, setMessageContent] = useState('');
  const [messageVisible, setMessageVisible] = useState(false);
  const [messageType, setMessageType] = useState('ok');
  const [messageID, setMessageID] = useState('');

  // Input Box
  const [inputBoxVisible, setInputBoxVisible] = useState(false);

  // Delete Group
  const onMessageBoxOk = async () => {
    if (messageID === 'DeleteGroup') {
      try {
        await deleteGroup({ id: groups.sharegroup.id });
        dispatch(listGroupUsers());
      } catch (e) {
        console.log(e);
      }
    }
    setMessageVisible(false);
    if (token) history.push('/groups');
  };

  const onMessageBoxCancel = () => {
    setMessageVisible(false);
  };

  // Create
  const onCreateGroup = async (n) => {
    setModalGroup(true);
  };

  const onConfirmCreateGroup = async (n) => {
    setModalGroup(false);
    try {
      await newGroup({ name: n });
      dispatch(listGroupUsers());
    } catch (e) {
      if (e.response && e.response.data) showMessage(e.response.data.detail);
    }
  };

  // Delete
  const onDeleteGroup = () => {
    setMessageTitle(t('Warning'));
    setMessageContent(t('Delete Group?'));
    setMessageType('okcancel');
    setMessageID('DeleteGroup');
    setMessageVisible(true);
  };

  // Edit Group
  const onEditGroup = () => {
    setInputBoxVisible(true);
  };

  // Update Group Name
  const onInputBoxOk = async (inputText) => {
    setInputBoxVisible(false);
    try {
      await editGroup({ id: groups.sharegroup.id, name: inputText });
      dispatch(listGroupUsers());
    } catch (e) {
      showMessage(e);
    }
  };

  const onInputBoxCancel = () => {
    setInputBoxVisible(false);
  };

  // Edit
  /*
  const onEditGroupMembers = () => {
    setModalEditMembers(true);
  };

  const onCencelEditGroupMembers = () => {
    setModalEditMembers(false);
  };

  const onConfirmEditGroupMembers = () => {
    setModalEditMembers(false);
  };
  */

  // Invite
  const onInviteMember = () => {
    setModalEditMembers(true);
  };

  const onSendInvitation = async (email) => {
    setModalEditMembers(false);

    // check email is not on the connection list

    try {
      var res = await requestGroupUser({
        sg_id: groups.sharegroup.id,
        id: 0,
        email: email,
      });
      setMessageTitle(t('Info'));
      setMessageContent(t('An Invitation has been sent'));
      setMessageType('ok');
      setMessageVisible(true);
      setMessageID('RequestGroupUser');
      dispatch(listGroupUsers());
    } catch (e) {
      if (e.response && e.response.data) showMessage(e.response.data.detail);
    }
  };

  const showMessage = (e) => {
    // tested
    if (e === "email same as member's") setMessageContent(t('Requested user is already a member'));
    // not tested
    else if (e === 'email already added')
      setMessageContent(t('You already have a connection to this email address.'));
    else if (e === 'request email sent') setMessageContent(t('Request mail has been sent'));
    else if (e === 'user already member') setMessageContent(t('User is already a member'));
    else if (e === 'joined group') setMessageContent(t('Joined group'));
    else if (e === 'request already accepted') setMessageContent(t('Request is already accepted'));
    else if (e === 'invalid token') setMessageContent(t('Invalid token'));
    else if (e === 'Invalid state') setMessageContent(t('Invalid state'));
    else if (e === 'already joined another group')
      setMessageContent(t('Already joined another group'));
    else if (e === 'request rejected') setMessageContent(t('Request rejected'));
    else if (e === 'Invalid state') setMessageContent(t('Invalid state'));

    setMessageTitle(t('Error'));
    setMessageVisible(true);
  };

  const showMessageAccept = (result, detail) => {
    if (result) {
      if (detail === 'joined group') setMessageContent(t('Joined group'));
      else if (detail === 'request already accepted')
        setMessageContent(t('Request is already accepted'));
      setMessageTitle(t('Info'));
    } else {
      setMessageTitle(t('Warning'));
      if (detail === 'request needed') setMessageContent(t('Request is needed'));
      else if (detail === 'different role')
        setMessageContent(t("The user's role is different from that of the group"));
      else if (detail === 'already in connections')
        setMessageContent(t('This email address already exists in connections'));
      else if (detail === 'already joined another group')
        setMessageContent(t('Already joined another group'));
      else if (detail === 'no email')
        setMessageContent(
          t(
            'Unable to verify if you are the requested user since your account ' +
            'does not have an email. If you have not verified your email ' +
            'on social media, please first complete the verification and ' +
            'then try again.',
          ),
        );
      else if (detail === 'no permission')
        setMessageContent(
          t(
            'Access denied because you are not the user with the requested email. Are you logged in with another account?',
          ),
        );
      else if (detail === 'no server location') setMessageContent(t('Invalid data'));
      else if (detail === 'server is wrong')
        setMessageContent(t('The users are from different regions: EU and non-EU.'));
      else if (detail === 403) setMessageContent(t('Access denied'));
      else {
        // error
        setMessageTitle(t('Error'));
        if (detail === 'invalid token') setMessageContent(t('Invalid token'));
        else setMessageContent(detail);
      }
    }
    setMessageVisible(true);
  };

  useEffect(() => {
    if (accept)
      showMessageAccept(
        accept.result,
        accept.result
          ? accept.detail
          : accept.error.response.data
            ? accept.error.response.data.detail
            : accept.error.response.status,
      );
  }, [accept]);

  //
  if (error) {
    return (
      <div>
        {error.message} {error.detail}
      </div>
    );
  }

  return (
    <MainBlock>
      <div className={leftpanel.collapsed ? 'GroupCollapsed' : 'GroupNormal'}>
        {/* Titile */}
        <div className={'GroupTitle'}>{t('Group')}</div>

        {/* Button - Empty */}
        {/* {(groups === null || groups.sharegroup === null) && ( */}
        {!loading &&
          (groups === null ||
            (groups.sharegroup.is_owner === false && groups.sg_users.accepted.length == 0)) && (
            <div className={'GroupButton'}>
              {/* Create New Group */}
              <HButton
                category="Primary"
                size="Medium"
                text={t('Create New Group').toUpperCase()}
                onClick={onCreateGroup}
              />
            </div>
          )}

        {groups !== null && groups.sharegroup !== null && (
          <>
            {/* Button - Owner */}
            {groups.sharegroup.is_owner && (
              <div className={'GroupButton'}>
                {/* Edit Group */}
                <HButton
                  category="Primary"
                  size="Medium"
                  text={t('Edit group').toUpperCase()}
                  float="right"
                  onClick={onEditGroup}
                />
                {/* Delete Group */}
                <HButton
                  category="Primary"
                  size="Medium"
                  text={t('Delete group').toUpperCase()}
                  float="right"
                  margin="0 16px 0 0"
                  onClick={onDeleteGroup}
                />
                {/* Invite Member */}
                <HButton
                  category="Primary"
                  size="Medium"
                  text={t('Invite member').toUpperCase()}
                  float="right"
                  margin="0 16px 0 0"
                  onClick={onInviteMember}
                />
              </div>
            )}

            {/* Button - Member */}
            <div className={'GroupContent'}>
              {/* Spinner */}
              {loading && (
                <div className={'loading'}>
                  <Spinner />
                </div>
              )}

              {!loading && (
                <div>
                  {/* Group Owner */}
                  {groups.sharegroup.is_owner && (
                    <>
                      {/* Pending */}
                      {groups.sg_users.pending.filter((x) => x.Im_requester === true).length >
                        0 && (
                          <div>
                            <div className={'pendingTitle'}>{t('Pending Invitations')}</div>
                            <div>
                              {groups.sg_users.pending.map((item, index) => (
                                <div key={index}>
                                  {item.Im_requester && (
                                    <GroupItem member={item} showMessage={showMessage} />
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}

                      {/* Accepted */}
                      {groups.sg_users.accepted.length !== 0 && (
                        <div>
                          <div className={'acceptedTitle'}>{groups.sharegroup.name}</div>
                          <div>
                            {groups.sg_users.accepted
                              .sort((a, b) => b.is_owner - a.is_owner)
                              .map((item, index) => (
                                <div key={index}>
                                  <GroupItem member={item} />
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {/* Group Member */}
                  {!groups.sharegroup.is_owner && (
                    <>
                      {/* Pending */}
                      {groups.sg_users.pending.filter((x) => x.Im_invited === true).length > 0 && (
                        <div>
                          <div className={'pendingTitle'}>{t('Pending Invitations')}</div>
                          <div>
                            {groups.sg_users.pending.map((item, index) => (
                              <div key={index}>
                                {item.Im_invited && (
                                  <GroupItem member={item} showMessage={showMessage} />
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {/* Accepted */}
                      {groups.sg_users.accepted.length !== 0 && (
                        <div>
                          <div className={'acceptedTitle'}>{groups.sharegroup.name}</div>
                          <div>
                            {groups.sg_users.accepted
                              .sort((a, b) => b.is_owner - a.is_owner)
                              .map((item, index) => (
                                <div key={index}>
                                  <GroupItem member={item} />
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>

      {/* Bottom Margin */}
      <div className={'GroupBottom'}></div>

      <InputBox
        visible={inputBoxVisible}
        type="okcancel"
        title={t('Edit Group')}
        content={!groups ? null : groups.sharegroup.name}
        onOk={onInputBoxOk}
        onCancel={onInputBoxCancel}
      />
      <EditGroupMembers
        visible={modalEditGroupMembers}
        title={t('Members')}
        onSendInvitation={onSendInvitation}
        onCancel={onCancel}
      />
      <CreateGroup
        visible={modalGroup}
        title={t('Create new group')}
        onConfirm={onConfirmCreateGroup}
        onCancel={onCancel}
      />
      <MessageBox
        visible={messageVisible}
        type={messageType}
        title={messageTitle}
        content={messageContent}
        onOk={onMessageBoxOk}
        onCancel={onMessageBoxCancel}
      />
    </MainBlock>
  );
};

export default Groups;
