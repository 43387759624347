import React, { useLayoutEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import styled from 'styled-components';

const CardContainer = styled.div`
  border: 1px solid #e7e9ea;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 8px;
  padding: 20px 20px 12px 20px;
  /* margin: 16px; */
  /* width: 300px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */

  .card-content {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 264px auto;
    grid-template-rows: 40px 12px auto auto auto auto auto;
    border-radius: 4px;
    outline: none;
    gap: 0px;

    font-family: 'Source Sans Pro';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
  }

  .card-companyName {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    /* overflow: hidden;
    overflow-wrap: break-word; //display next line */

    width: 264px;
    /* max-width: 264px; */
    height: 100%;
    /* height: 25px; */
    font-style: normal;
    font-weight: 600;
  }
  .card-logo {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 7;

    padding: 8px;
    display: flex;
    justify-content: right;
    align-items: flex-start; //baseline;
    object-fit: contain;
    max-width: 176px;
  }
  .card-contactEmail {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;

    margin: 2px 0;
  }
  .card-contactName {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 5;

    margin: 2px 0;
  }
  .card-country {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 5;
    grid-row-end: 6;

    margin: 2px 0;
  }
  .card-contactPhone {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 6;
    grid-row-end: 7;

    margin: 2px 0;
  }
  .card-contactAddres {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 7;
    grid-row-end: 8;

    margin: 2px 0;
  }
`;

const StyledLogo = styled.img`
  object-fit: contain;
  max-width: 176px;
  /* height: 40px; */
`;

const StyledParagraph = styled.div`
  width: 100%;
  color: #666;

  overflow: hidden;
  overflow-wrap: break-word; //display next line
`;

const Card = (props) => {
  const divRef = useRef(null);
  const [fontSize, setFontSize] = useState(20);

  useLayoutEffect(() => {
    if (divRef.current) {
      if (props.companyName.length > 24) {
        setFontSize(15);
      } else {
        setFontSize(20);
      }
    }
  }, [props.companyName]);

  return (
    <CardContainer>
      <div className="card-content">
        <div className="card-companyName" ref={divRef} style={{ fontSize }}>
          {props.companyName}
        </div>
        <div className="card-logo">
          {props.logo && <StyledLogo alt="Logo" src={props.logo}></StyledLogo>}
        </div>

        <div className="card-contactEmail">
          {props.contactEmail && (
            <StyledParagraph>{props.contactEmail}</StyledParagraph>
          )}
        </div>
        <div className="card-contactName">
          {props.contactName && <StyledParagraph>{props.contactName}</StyledParagraph>}
        </div>
        <div className="card-country">
          {props.country && <StyledParagraph>{props.country}</StyledParagraph>}
        </div>
        <div className="card-contactPhone">
          {props.contactPhone && (
            <StyledParagraph>{props.contactPhone}</StyledParagraph>
          )}
        </div>
        <div className="card-contactAddres">
          {props.contactAddress && (
            <StyledParagraph fullWidth={true}>{props.contactAddress}</StyledParagraph>
          )}
        </div>
      </div>
    </CardContainer>
  );
};

export default Card;
