import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import HButton from '../common/HButton';
// import SearchInput from '../auth/styled/SearchInput';
import InputSearch from '../common/InputSearch';
import { getLocaleDate, GetLocale } from '../common/Util';
import Spinner from '../common/Spinner';
import DevicePagination from '../registration/DevicePagination';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;

  img {
    vertical-align: middle;
  }

  .loading {
    display: flex;
    height: 200px;
    align-items: center; //vertical center
    justify-content: center; //horizontal center
  }

  table.device {
    border-collapse: collapse;
    text-align: center;
    line-height: 1.5;
    /* border: 1px solid #E7E9EA; */

    width: 100%;
    margin-bottom: 32px;
    color: #212529;
  }

  table.device thead {
    /* background-color: #f0f1f2; */
  }
  table.device thead th {
    /* border-bottom: 2px solid #dee2e6; */
  }
  table.device th {
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-size: 15px;
    color: #59616a;
    vertical-align: middle !important;

    height: 56px;
    padding: 0 0 0 20px;
    margin: 0;
    text-align: left;
    /* border: 1px solid #e7e9ea; */
    background-color: #e7e9ea;
  }
  table.device tr {
    background-color: white;
  }
  table.device td {
    height: 56px;
    padding: 0 0 0 20px;
    text-align: left;
    border-bottom: 1px solid #e7e9ea;
    background-color: transparent;

    font-family: 'Source Sans Pro';
    font-size: 15px;
    color: rgba(48, 57, 69, 0.8);
  }

  table.device td:first-child {
    padding-left: 20px;
  }
  table.device td:last-child {
    padding-right: 20px;
  }
  table.device th:first-child {
    padding-left: 20px;
  }
  table.device th:last-child {
    padding-right: 20px;
  }

  table.device tbody {
    vertical-align: middle;
  }

  .checkboxDiv {
    display: flex;
    width: 100%;
    flex-basis: 24px;
    /* margin: 16px 20px; */
    align-items: center; //vertical center
    justify-content: right; //horizontal center
  }

  .checkbox {
    background-image: url('${process.env
      .PUBLIC_URL}/images/settings/icon-24-checkbox-active.svg');
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 24px;
    height: 24px;
    margin: 0;
    outline: none;

    &:checked {
      background-image: url('${process.env.PUBLIC_URL}/${(props) =>
        props.theme.images}/settings/icon-24-checkbox-selected.svg');
    }
  }

  .whiteCheckbox {
    background-image: url('${process.env
      .PUBLIC_URL}/images/settings/icon-24-checkbox-active-white.svg');
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 24px;
    height: 24px;
    margin: 0;
    outline: none;

    &:checked {
      background-image: url('${process.env.PUBLIC_URL}/${(props) =>
        props.theme.images}/settings/icon-24-checkbox-selected.svg');
    }
  }
`;

const Content = styled.div`
  margin: 0;
  /* width: 960px; */
  /* max-width: 960px; */
  /* min-width: 960px; */
  padding: 64px 64px 64px 64px;

  /* .underLine {
    text-decoration: underline;
  } */
`;

const TableTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-size: 25px;
  color: #59616a;
`;

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 88px;
`;

const Devices = ({
  loading,
  devices,
  pageInfo,
  paginate,
  user,
  odm,
  onSearchEnter,
  onReset,
  onSort,
  onEditLockConnections,
}) => {
  /* console.log(devices); */
  const { t, i18n } = useTranslation();
  const [deviceLockList, setDeviceLockList] = useState([]);
  const [sortQuery, setSortQuery] = useState('udi desc');
  const [sortOrder, setSortOrder] = useState({});
  const [sortImg, setSortImg] = useState({});
  const imgUp = process.env.PUBLIC_URL + '/images/device/ic_arrow_up.svg';
  const imgDown = process.env.PUBLIC_URL + '/images/device/ic_arrow_down.svg';
  const is3DISC = odm.company_name === '3DISC';

  useEffect(() => {
    let lockList = [];
    if (devices) {
      devices.devices.forEach((device) => {
        if (device.state === 'locked') lockList.push({ id: device.id, locked: true });
        else lockList.push({ id: device.id, locked: false });
      });
      setDeviceLockList(lockList);
    }
  }, [devices]);

  useEffect(() => {
    sortOrder.udi = 'desc';
    sortOrder.name = 'desc';
    sortOrder.regdevEmail = 'desc';
    sortOrder.country = 'desc';
    sortOrder.state = 'desc';
    setSortOrder(sortOrder);

    sortImg.udi = '';
    sortImg.name = '';
    sortImg.regdevEmail = '';
    sortImg.country = '';
    sortImg.state = '';
    setSortImg(sortImg);
  }, []);

  useEffect(() => {
    const imgs = {
      udi: '',
      name: '',
      regdevEmail: '',
      country: '',
      state: '',
    };

    if (sortQuery) {
      const sort_params = sortQuery.split(' ');

      if (
        sort_params[0] in imgs &&
        (sort_params[1] === 'asc' || sort_params[1] === 'desc')
      ) {
        imgs[sort_params[0]] = sort_params[1] === 'asc' ? imgUp : imgDown;
      }
      setSortImg(imgs);
    }
  }, [sortQuery]);

  const onAllCheck = useCallback(
    (checked) => {
      /* const checkedListArray = []; */

      let lockList = [];
      if (devices) {
        devices.devices.forEach((device) => {
          if (checked === true) lockList.push({ id: device.id, locked: true });
          else lockList.push({ id: device.id, locked: false });
        });
        setDeviceLockList(lockList);
      }
    },
    [devices],
  );

  const onCheck = (checked, id) => {
    let objIndex = deviceLockList.findIndex((obj) => obj.id === id),
      deviceLockListCopy = [...deviceLockList];
    deviceLockListCopy[objIndex].locked = checked;
    setDeviceLockList(deviceLockListCopy);
  };

  const onClickSort = (colName) => {
    const order = sortOrder[colName] === 'desc' ? 'asc' : 'desc';
    sortOrder[colName] = order;
    setSortOrder(sortOrder);
    const query = `${colName} ${order}`;
    setSortQuery(query);

    onSort(query);
  };

  const onHighlightOn = (e) => {
    var tr = e.target.closest('tr');
    if (tr) tr.style.background = '#F5F6F6';
  };

  const onHighlightOff = (e) => {
    var tr = e.target.closest('tr');
    if (tr) tr.style.background = 'white';
  };

  return (
    <Container>
      <Content>
        <Wrap>
          <TableTitle>{t('Device Management')}</TableTitle>
          <InputSearch
            size="Medium"
            placeholder={t('Filter by search')}
            width="355px"
            float="left"
            value=""
            onSearchEnterPress={onSearchEnter}
            // inputText={filters.searchString !== '' ? filters.searchString : ''}
            // margin="16px 20px 16px 20px"
          />

          {/* Lock Save Button*/}
          <HButton
            category="Primary"
            size="Medium"
            text={t('Save').toUpperCase()}
            onClick={() => {
              onEditLockConnections(deviceLockList);
            }}
          />
        </Wrap>

        {loading && (
          <div className={'loading'}>
            <Spinner />
          </div>
        )}

        {!loading && (
          <>
            <table className="device">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th
                    scope="col"
                    style={{ width: '500px' }}
                    onClick={(e) => onClickSort('udi')}
                  >
                    {'UDI'}
                    {sortImg.udi && (
                      <img src={sortImg.udi} alt={sortQuery.split(' ')[1]} />
                    )}
                  </th>
                  <th scope="col" onClick={(e) => onClickSort('name')}>
                    {t('Customer Name')}
                    {sortImg.name && (
                      <img src={sortImg.name} alt={sortQuery.split(' ')[1]} />
                    )}
                  </th>
                  <th scope="col" onClick={(e) => onClickSort('regdevEmail')}>
                    {t('Customer Email')}
                    {sortImg.regdevEmail && (
                      <img src={sortImg.regdevEmail} alt={sortQuery.split(' ')[1]} />
                    )}
                  </th>
                  <th scope="col" onClick={(e) => onClickSort('country')}>
                    {t('Country')}
                    {sortImg.country && (
                      <img src={sortImg.country} alt={sortQuery.split(' ')[1]} />
                    )}
                  </th>
                  {
                    <th
                      scope="col"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'right',
                      }}
                    >
                      <div onClick={(e) => onClickSort('state')}>
                        {t('Lock')}
                        {sortImg.state && (
                          <img src={sortImg.state} alt={sortQuery.split(' ')[1]} />
                        )}
                      </div>
                      &nbsp;
                      <div className="checkboxDiv">
                        <input
                          className="whiteCheckbox"
                          name="selectAll"
                          type="checkbox"
                          checked={
                            deviceLockList.find((e) => e.locked === false)
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            onAllCheck(e.target.checked);
                          }}
                        />
                      </div>
                    </th>
                  }
                </tr>
              </thead>
              {devices && (
                <tbody>
                  {devices.devices.map((device) => (
                    <tr
                      key={device.id}
                      onMouseOver={(e) => {
                        onHighlightOn(e);
                      }}
                      onMouseOut={(e) => {
                        onHighlightOff(e);
                      }}
                    >
                      <td>{device.udi}</td>
                      {device.reg_customer && <td>{device.reg_customer.name}</td>}
                      {!device.reg_customer && <td></td>}
                      {device.reg_customer && <td>{device.reg_customer.email}</td>}
                      {!device.reg_customer && <td></td>}

                      {device.reg_customer && <td>{device.reg_customer.country}</td>}
                      {!device.reg_customer && <td></td>}
                      <td>
                        <div className="checkboxDiv">
                          <input
                            className="checkbox"
                            type="checkbox"
                            checked={
                              deviceLockList.find(
                                (e) =>
                                  JSON.stringify(e) ===
                                  JSON.stringify({
                                    id: device.id,
                                    locked: 'locked' ? true : false,
                                  }),
                              )
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              onCheck(e.currentTarget.checked, device.id);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>

            <DevicePagination
              pageInfo={pageInfo}
              paginate={paginate}
              odm={odm}
            ></DevicePagination>
          </>
        )}
      </Content>
    </Container>
  );
};

export default React.memo(Devices);
