import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import HButton from '../common/HButton';
import { getFileUri, deleteFile, getShareCaseFileUri } from '../../lib/api/case';
import {
  listFiles,
  initializeForm,
  caseContent,
  uploadFilesDone,
} from '../../modules/case';
import MessageBox from '../common/MessageBox';
import { useTranslation } from 'react-i18next';
import DownloadBox from '../common/DownloadBox';
import SVG from '../common/svg';
import { TabletOrDesktop } from '../../lib/responsive';
import { startCursorLoading } from '../../modules/cursorLoading';

const CaseDetailAttachmentsBox = styled.div`
  font-family: Source Sans Pro;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  .attachTitle {
    width: 250px;
    height: 32px;
    margin: 57px 0 31px 22px;
    font-size: 25px;
    font-weight: 600;
    color: #59616a;
  }

  .attachButtonBox {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .attachFormButton {
    display: flex;
    justify-content: center;
  }

  .attachDownloadButton {
    display: flex;
    justify-content: center;
    margin-left: 16px;
  }

  .attachButtonBoxMobile {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .attachFormButtonMobile {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }

  .attachDownloadButtonMobile {
    display: flex;
    justify-content: center;
  }
`;

const CaseDetailAttachmentBox = styled.div`
  font-family: Source Sans Pro;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  .attachmentBox {
    width: calc(100% - 39px);
    height: 40px;
    margin: 8px 20px 0 19px;
    border-radius: 4px;
    border: solid 1px #e7e9ea;
    background: #eeeff0;

    display: grid;
    grid-template-columns: auto 24px 12px 24px 12px;
    grid-template-rows: 100%;
  }

  .attachmentName {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;

    width: auto;
    height: 20px;
    margin: 10px 20px 0 19px;
    font-size: 15px;
    color: #59616a;

    /* display: flex;
    justify-content: Left;
    align-items: center; */
    word-wrap: break-word;
    word-break: break-all;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .deleteIcon {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;

    width: 24px;
    height: 24px;
    margin: 10px 0 0 0;
    font-size: 15px;
    color: #59616a;
  }

  .downloadIcon {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;

    width: 24px;
    height: 24px;
    margin: 10px 0 0 0;
    font-size: 15px;
    color: #59616a;
  }
`;

const AttachmentItem = ({ content, file, onDownloadFile }) => {
  const { files } = useSelector(({ caseDetail }) => ({
    files: caseDetail.files,
  }));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { odm } = useSelector(({ app }) => ({
    odm: app.odm,
  }));

  const [messageTitle, setMessageTitle] = useState('');
  const [messageContent, setMessageContent] = useState('');
  const [messageVisible, setMessageVisible] = useState(false);

  const onMessageBoxOk = async () => {
    setMessageVisible(false);
    try {
      const res = await deleteFile(file.id);
      dispatch(listFiles(files.id));
      dispatch(caseContent(files.id));
      dispatch(initializeForm('deleteFile'));
    } catch (e) {
      console.log(e);
    }
  };

  const onMessageBoxCancel = () => {
    setMessageVisible(false);
  };

  const onClickDelete = () => {
    setMessageTitle(t('Warning'));
    setMessageContent(t('Delete file?'));
    setMessageVisible(true);
  };

  const getFileSize = () => {
    if (file.size > 1024 * 1024 * 1024) {
      return (file.size / (1024 * 1024 * 1024)).toFixed(1) + ' GB';
    } else if (file.size > 1024 * 1024) {
      return (file.size / (1024 * 1024)).toFixed(1) + ' MB';
    } else if (file.size > 1024) {
      return (file.size / 1024).toFixed(0) + ' kB';
    } else return file.size + ' B';
  };

  return (
    <CaseDetailAttachmentBox>
      <div className={'attachmentBox'}>
        {/* file name */}
        <div className={'attachmentName'}>
          {file.filename} ({getFileSize()})
        </div>

        {/* Delete Icon*/}
        {file.uploader && content.status.code !== 'CLSD' && (
          <div className={'deleteIcon'} onClick={onClickDelete} title={t('Delete')}>
            <SVG
              name="delete"
              color="#878D93"
              colorOver={odm.main_color_hover}
              colorOut="#878D93"
              colorDown={odm.main_color}
            />
          </div>
        )}

        {/* Download Icon */}
        <div
          className={'downloadIcon'}
          onClick={(e) => onDownloadFile(file)}
          title={t('Download')}
        >
          <SVG
            name="forward"
            color="#878D93"
            colorOver={odm.main_color_hover}
            colorOut="#878D93"
            colorDown={odm.main_color}
          />
        </div>

        <MessageBox
          visible={messageVisible}
          type="okcancel"
          title={messageTitle}
          content={messageContent}
          onOk={onMessageBoxOk}
          onCancel={onMessageBoxCancel}
        />
      </div>
    </CaseDetailAttachmentBox>
  );
};

const CaseDetailAttachments = ({
  content,
  files,
  user,
  downloadFilesAll,
  onlyPDF,
  cursorLoading,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [downloadBoxVisible, setDownloadBoxVisible] = useState(false);
  const [downloadBoxUri, setDownloadBoxUri] = useState('');
  const [downloadBoxFileName, setDownloadBoxFileName] = useState('');

  const [lockMessageTitle, setLockMessageTitle] = useState('');
  const [lockMessageContent, setLockMessageContent] = useState('');
  const [lockMessageVisible, setLockMessageVisible] = useState(false);

  const onLockMessageBoxOk = async () => {
    setLockMessageVisible(false);
  };

  const isTabletOrDesktop = TabletOrDesktop();

  {
    /* Comment by Arthur 2021-09-28 */
  }
  // const { upload, uploadDone } = useSelector(({ caseDetail }) => ({
  //   error: caseDetail.error,
  //   upload: caseDetail.upload,
  //   uploadDone: caseDetail.uploadDone,
  // }));

  // useEffect(() => {
  //   if (upload) {
  //     dispatch(uploadFilesDone(content.id));
  //     dispatch(initializeForm('upload'));
  //   }
  // }, [dispatch, upload]);

  // useEffect(() => {
  //   if (uploadDone) {
  //     dispatch(caseContent(content.id));
  //     dispatch(initializeForm('uploadDone'));
  //   }
  // }, [dispatch, uploadDone]);
  // End Comment by Arthur 2021-09-28

  const onOrderForm = () => {
    var pdf = '';
    files.files.map((file, key) => {
      if (file.filename.includes('.qcProject')) {
        var str = file.filename;
        var caseId = str.replace('.qcProject', '');
        pdf = caseId + '.pdf';
      }
    });

    files.files.map((file, key) => {
      if (file.filename === pdf) {
        onDownloadFile(file);
      }
    });
  };

  const onDownloadBoxOk = () => {
    setDownloadBoxVisible(false);
  };

  const onDownloadFile = async (file) => {
    if (content.usertype != 'OT' && content.from_locked_device == true) {
      {
        if (
          file.from_locked_device == true &&
          (file.filename.toUpperCase().includes('.PLY') ||
            file.filename.toUpperCase().includes('.STL') ||
            file.filename.toUpperCase().includes('.OBJ') ||
            file.filename.toUpperCase().includes('.XORDER') ||
            file.filename.toUpperCase().includes('.3OXZ'))
        ) {
          setLockMessageTitle(t('Info'));
          setLockMessageContent(
            t('Download mesh data is not allowed for export management device'),
          );
          setLockMessageVisible(true);

          return;
        }
      }
    }

    try {
      const res = await getFileUri({ id: file.id });
      const uri = res.data[file.id];
      console.log(uri);

      if (
        file.filename.toUpperCase().includes('.PNG') ||
        file.filename.toUpperCase().includes('.JPG') ||
        file.filename.toUpperCase().includes('.PDF')
      ) {
        setDownloadBoxVisible(true);
        setDownloadBoxUri(uri);
        setDownloadBoxFileName(file.filename);
      } else {
        window.location.href = uri;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onShareCaseDownloadFile = async (file) => {
    try {
      const res = await getShareCaseFileUri({ id: file.id });
      const uri = res.data[file.id];
      console.log(uri);

      if (
        file.filename.toUpperCase().includes('.PNG') ||
        file.filename.toUpperCase().includes('.JPG') ||
        file.filename.toUpperCase().includes('.PDF')
      ) {
        setDownloadBoxVisible(true);
        setDownloadBoxUri(uri);
        setDownloadBoxFileName(file.filename);
      } else {
        window.location.href = uri;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onDownloadAll = () => {
    // dispatch(
    //   downloadFilesAll({
    //     id: files.id,
    //     files_ids: files.files.map(({id}) => ({id})),
    //   }),
    // );
    // const uri = `/heclore/v1/cases/${files.id}/files/download-all/`;
    // const events_token = cookies.get('events');
    // if (events_token) {
    //   const uidb64 = user.idb64;
    //   const uri = `/heclore/v1/cases/${files.id}/files/download/${uidb64}/${events_token}/`;
    //   window.location.href = uri;
    // } else {
    //   console.log("The Cookie 'events' not exist. Please refresh.");
    // }
    dispatch(startCursorLoading('caseDownLoading'));
    downloadFilesAll(files.id);
  };

  return (
    <CaseDetailAttachmentsBox>
      <div className={'attachTitle'}>{t('Attachments')}</div>
      {files && (
        <div>
          {files.files.map((file, key) => {
            if (onlyPDF) {
              if (file.filename.search('.pdf') > 0) {
                return (
                  <AttachmentItem
                    key={key}
                    content={content}
                    file={file}
                    onDownloadFile={onShareCaseDownloadFile}
                  />
                );
              }
            } else {
              return (
                <AttachmentItem
                  key={key}
                  content={content}
                  file={file}
                  onDownloadFile={onDownloadFile}
                />
              );
            }
          })}
        </div>
      )}

      {isTabletOrDesktop && !onlyPDF && (
        <div className={'attachButtonBox'}>
          <div className={'attachFormButton'}>
            <HButton
              // margin="0 0 16px 0"
              category="Secondary"
              size="Medium"
              text={t('Printable Order Form').toUpperCase()}
              onClick={onOrderForm}
            />
          </div>
          <div className={'attachDownloadButton'}>
            <HButton
              category="Primary"
              size="Medium"
              text={t('Download All Files').toUpperCase()}
              onClick={onDownloadAll}
              disabled={cursorLoading}
              mouseWait={cursorLoading}
            />
          </div>
        </div>
      )}
      {!isTabletOrDesktop && !onlyPDF && (
        <div className={'attachButtonBoxMobile'}>
          <div className={'attachDownloadButtonMobile'}>
            <HButton
              category="Primary"
              size="Medium"
              text={t('Download All Files').toUpperCase()}
              onClick={onDownloadAll}
              disabled={cursorLoading}
              mouseWait={cursorLoading}
            />
          </div>
          <div className={'attachFormButtonMobile'}>
            <HButton
              category="Secondary"
              size="Medium"
              text={t('Printable Order Form').toUpperCase()}
              onClick={onOrderForm}
            />
          </div>
        </div>
      )}

      <DownloadBox
        visible={downloadBoxVisible}
        uri={downloadBoxUri}
        filename={downloadBoxFileName}
        onOk={onDownloadBoxOk}
      />
      <MessageBox
        visible={lockMessageVisible}
        type="ok"
        title={lockMessageTitle}
        content={lockMessageContent}
        onOk={onLockMessageBoxOk}
      />
    </CaseDetailAttachmentsBox>
  );
};

export default CaseDetailAttachments;
