import qs from 'qs';
import client from './client';

// export const activate = ({ rdidb64, token }) =>
//   client.post('/v1/device/activate/', { rdidb64, token });

// export const requestActivate = ({ rdid }) =>
//   client.post('/v1/device/request-activate/', { rdid });

export const listDevices = ({ filters, page }) => {
  const conds = {};
  if (filters && filters.searchString) conds.search = filters.searchString;
  if (filters && filters.orderBy) conds.sort = filters.orderBy;
  if (page) conds.page = page;
  conds['user-registeredDevices'] = true;
  const queryString = qs.stringify(conds, { indices: false });
  return client.get('v1/devices/' + (queryString ? `?${queryString}` : ''));
};

export const addLockConnections = ({ dev_conns }) =>
  client.post('v1/devices/connections/', { dev_conns });

export const deviceConfirmEmail = ({ rdidb64, token, srv_loc }) =>
  client.post('v1/devices/confirm-email/', { rdidb64, token, srv_loc });
