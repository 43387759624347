import qs from 'qs';
import client from './client';

export const signup = ({ formData }) =>
  client.post('/v1/auth/signup/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

// export const socialSignup = (formData) =>
//   client.post('/v1/auth/signup/social/', formData, {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//   });

export const validate = ({ formData, form, field }) => {
  const queryString = qs.stringify({ form, field });
  return client.post(`v1/auth/validate/?${queryString}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const activate = ({ uidb64, token }) =>
  client.post('/v1/auth/activate/', { uidb64, token });

export const requestActivate = ({ uid }) =>
  client.post(
    '/v1/auth/request-activate/',
    { uid },
    { headers: { 'X-API-KEY': client.apiKey_en } },
  );

export const login = ({ username, password, callFrom }) => {
  return client.post('/v1/auth/login/', {
    username,
    password,
    callfrom: callFrom,
  });
};

export const setToken = ({ access }) => {
  client.defaults.headers.common['Authorization'] = `Bearer ${access}`;
};

export const hasAccessToken = () => {
  return !!client.defaults.headers.common['Authorization'];
};

export const check = ({ callFrom }) => {
  const path = '/v1/auth/check/' + (callFrom ? '?callfrom=adm' : '');
  return client.get(path);
};

export const refresh = () => client.post('/v1/auth/refresh/');

// export const refreshEvents = () => client.post('/v1/auth/refresh-events/');

export const resetAccessToken = () => {
  client.defaults.headers.common['Authorization'] = null;
};

export const logout = () => {
  const response = client.post('/v1/auth/logout/');
  // don't move up. Access token is needed to logout
  resetAccessToken();
  return response;
};

export const socialLoginOtp = ({ code, codeVerifier }) =>
  client.post('/v1/auth/social/otp/', { code, codeVerifier });

export const changePassword = ({ formData }) =>
  client.put('/v1/auth/change-password/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const resetPassword = ({ email }) =>
  client.post(
    '/v1/reset-password/',
    { email },
    { headers: { 'X-API-KEY': client.apiKey_en } },
  );

export const checkResetPassToken = ({ token }) =>
  client.post('/reset_password/validate_token/', { token });

export const resetPasswordConfirm = ({ token, password }) =>
  client.post('/reset_password/confirm/', { token, password });

export const getUserProfile = () => client.get('/v1/user/edit/');

export const setUserProfile = ({ formData }) =>
  client.put('/v1/user/edit/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const getEventsToken = ({ callFrom, postId }) =>
  client.post('/v1/auth/otp/', { callfrom: callFrom, postId });

// export const getSocialLoginUrl = ({ socialMedia }) => {
//   if (socialMedia === 'facebook') return client.get('/v1/auth/login/social/facebook/');
//   else if (socialMedia === 'google') return client.get('/v1/auth/login/social/google-oauth2/');
// };

// qc
export const socialLoginValidateState = ({ state }) => {
  const queryString = qs.stringify({ state });
  return client.get(`/v1/qc/social-login/validate-state/?${queryString}`);
};
