import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import HButton from '../common/HButton';
import { useTranslation } from 'react-i18next';

const CB_fadeIn = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

const CB_fadeOut = keyframes`
  from {
    opacity: 1
  }
  to {
    opacity: 0
  }
`;

const CB_slideDown = keyframes`
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0px);
  }
`;

const CB_slideUp = keyframes`
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-100px);
  }
`;

const CB_Fullscreen = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;

  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${CB_fadeIn};
  animation-fill-mode: forwards;

  ${(props) =>
    props.disappear &&
    css`
      animation-name: ${CB_fadeOut};
    `}
`;

const CB_ModalBlock = styled.div`
  width: 392px;
  height: 194px;
  background: white;
  border-radius: 4px;
  border: solid 1px #eeeff0;

  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${CB_slideDown};
  animation-fill-mode: forwards;

  ${(props) =>
    props.disappear &&
    css`
      animation-name: ${CB_slideUp};
    `}
`;

const CB_ContentBlock = styled.div`
  .contentBox {
    width: 352px;
    height: 80px;
    margin: 24px 20px 32px 20px;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #59616a;
  }
`;

const CB_ButtonBlock = styled.div``;

const ConfirmBox = ({ visible, content, onOk }) => {
  const [animate, setAnimate] = useState(false);
  const [localVisible, setLocalVisible] = useState(visible);
  const { t } = useTranslation();

  useEffect(() => {
    if (localVisible && !visible) {
      setAnimate(true);
      setTimeout(() => setAnimate(false), 250);
    }
    setLocalVisible(visible);
  }, [localVisible, visible]);

  if (!animate && !localVisible) return null;

  return (
    <CB_Fullscreen disappear={!visible}>
      <CB_ModalBlock disappear={!visible}>
        <CB_ContentBlock>
          <div className={'contentBox'}>{content}</div>
        </CB_ContentBlock>
        <CB_ButtonBlock>
          <HButton
            category="Secondary"
            size="Medium"
            text={t('OK').toUpperCase()}
            width="auto"
            margin="0 20px 0 0"
            float="right"
            onClick={onOk}
          />
        </CB_ButtonBlock>
      </CB_ModalBlock>
    </CB_Fullscreen>
  );
};

export default ConfirmBox;
