import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
//import TextBox from '../../components/leftpanel/TextBox';
//import CheckBox from '../../components/common/CheckBox';
import { getLocaleDate, getLocaleTime } from '../common/Util';
import { getInitial, getStatusString } from '../common/Util';
import { useTranslation } from 'react-i18next';

const MainBlock = styled.div`

div {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.container {
    height: 80px; 
    display:grid;
    grid-template-columns: 72px 149px auto; 
    grid-template-rows: 8px 20px 20px 24px 7px;
    grid-template-areas:
    padding: 18px 0 0 20px;
    gap:0;
    background-color:#eeeff0;
}

.container:select {
    background-color:#ff0000;
}

.container:hover {
    background-color:#ffffff;
}

.container:active {
    background-color:#ffffff;
}

.caseItem_Picture { 
    grid-column: 1;
    grid-row: 2/ span 2;
}

.Icon_checkbox {
    grid-column:1;
    grid-row:4;
    margin:0px;
    align-self:start;
    justify-self:center;
  }

.caseItem_DentistName {
    grid-column: 2;
    grid-row: 2;
}

.caseItem_PatientName {
    grid-column: 2/ span 2;
    grid-row: 3;
}

.caseItem_CaseID {
    grid-column: 2 / span 2;
    grid-row: 4;
    align-self:center;
}

.caseCreatedDate {
    grid-column: 2 / span 2;
    grid-row: 2;
    padding-right: 20px;
    float: right;
}

.caseItem_CaseStatus {
    grid-column: 3;
    grid-row: 4;
    justify-self: end;
    margin-right:20px;
    align-self:center;
}

.caseItem_CaseName {
    grid-column: 2;
    grid-row: 3;
}

.caseItem_AttachedFiles {
    grid-column: 2;
    grid-row: 4;
    align-self:center;
}

.picture {    
    width: 32px;
    height: 32px;
    line-height: 32px;
    margin-left: 18px;
    margin-top: 0px;
    border-radius: 18px;
    background-color: #787f86;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    float: left;
  }

.avatar {
    margin-left:18px; 
    width: 32px;
    height: 32px;
    border-radius: 18px;
//    background-color: #787F86;
}

.checkbox {
}

.dentistName {
    font-size: 15px;
    font-family: Source Sans Pro;
    font-weight: 600;
    font-stretch: normal;
    color: #59646a;  
    float: none;  
}

.patientName {
    font-size: 15px;
    font-family: Source Sans Pro;
    font-stretch: normal;
    color: #969ba1;        
    float: none;   
    width: 350px;    
}

.createdDate {
    height: 17px;
    font-family: Source Sans Pro;
    font-size: 13px;
    line-height: 20px;
    color: #969ba1;
    float: right;
    text-align: right;

}

.caseName {
    margin-left:100px;
    font-size: 15px;
    font-family: Source Sans Pro;
    font-stretch: normal;
    font-style: normal;
    color: #969ba1;   
}

.caseID {
    font-family: Source Sans Pro;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #969ba1;
    text-align: left;
    float: left;
    align-items:center;
}

.attachedFileNumber {
    margin-left: 100px;
    font-family: Source Sans Pro;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #969ba1;
    text-align: left;
    float: left;
}

.caseStatus {
    width:auto;
    margin-right:20px;
    font-family: Source Sans Pro;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2776e9;
    text-align: left;
    float: right;
}

.caseBottomLine {
    grid-column: 1/4;
    grid-row: 5; 
    margin-bottom: 0px;
    margin-left: 20px;    
    margin-right: 20px; 
    height: 100%;
    border-bottom: 1px solid #e7e9ea;
}

.selectedCaseBottomLine {
    grid-column: 1/4;
    grid-row: 5; 
    margin-bottom: 0px;
    margin-left: 20px;    
    margin-right: 20px; 
    height: 100%;
    border-bottom: 1px solid #ffffff;
}

.hr.caseDivLine {
//    border:none;
//    border:1px;    
}

.button {
    width: 162;
    margin-top: 16px;
    margin-right: 64px;
    outline: none;
    float: right;
}

.button:hover {
    background-color: #ff6d22;
    border: solid 1px #ff6d22;
    color: #ffffff;
}

.button:active {
    background-color: #ff6d22;
    border: solid 1px #ff6d22;
    color: #ffffff;
}

.caseDetailNumberBox {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background: #787f86;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
}

.CounterRect {   
    display: inline-block;
    height: 16px;
    width: 16px;
    border-radius: 4px;        
    background: #787f86;
    float : right;
    color: #ffffff;
    text-align: center;        
    margin: 2px 2px 2px 20px;
    padding: 0px;

    font-family: Source Sans Pro;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.CounterFont {
    font-family: Source Sans Pro;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.checkbox {
    display: flex;
    width: 100%;
    flex-basis: 24px;
    margin: 16px 20px;
    align-items: center; //vertical center
    /* background: red; */
  }

  input[type='checkbox'] {
    background-image: url('${process.env.PUBLIC_URL}/images/settings/icon-24-checkbox-active.svg');
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 24px;
    height: 24px;
    margin: 0;
    outline: none;

    &:checked {
      background-image: url('${process.env.PUBLIC_URL}/images/settings/icon-24-checkbox-selected.svg');
    }

    .TextBoxRect {
        height: 18px;
        border-radius: 4px;
        border: solid 1px #e7e9ea;
        background-color: #ffffff;
        outline: none;
      }

      .TextBoxFont {
        font-family: Source Sans Pro;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        vertical-align: text-center;
      }
  }
`;

const StyledImage = styled.img`
  width: ${(props) => props.width || '32px'};
  height: ${(props) => props.height || '32px'};
  margin: 0;
  border: 0;
  padding: 0;
  object-fit: cover; /*contain*/
  border-radius: 60%;
`;

const statusColor = {
  OPEN: '#2776e9',
  READ: '#2776e9',
  PROC: '#a78120',
  COMP: '#50b019',
  REOP: '#2776e9',
  CLSD: '#a0a0a0',
};

const CaseItem = ({
  _case,
  content,
  checked,
  selected,
  isCheckVisible,
  onSelectCase,
  onChange,
}) => {
  //  const dispatch = useDispatch();
  //  const [bChecked, setChecked] = useState(checked);
  //    const checkIcon = process.env.PUBLIC_URL + '/images/common/icon-24-checkbox-active.svg';
  //  function onChecked(state) { }
  const { t, i18n } = useTranslation();

  const { odm } = useSelector(({ app }) => ({
    //    leftpanel: leftpanel,
    odm: app.odm,
  }));

  const onCheckClick = (e) => {
    e.stopPropagation();
  };

  const getStatus = () => {
    return t(getStatusString(_case.status.code));
  };

  const getPatientName = () => {
    return _case.case.pat_firstname + ' ' + _case.case.pat_lastname;
  };

  return (
    <MainBlock>
      <div
        className={'container'}
        style={{
          backgroundColor: selected
            ? '#ffffff'
            : checked
            ? odm.main_color_focus
            : '#eeeff0',
        }}
        onClick={() => onSelectCase(_case)}
      >
        <div class="caseItem_Picture">
          {_case.author.picture !== null && (
            <div className="avatar">
              <StyledImage src={_case.author.picture} />
            </div>
          )}
          {_case.author.picture === null && (
            <div className="picture">{getInitial(_case.author.username)}</div>
          )}
        </div>
        {_case.del_enabled && isCheckVisible && (
          <div className="Icon_checkbox">
            <input
              type="checkbox"
              name="checkbox"
              id={_case.id}
              checked={checked}
              onChange={(e) => onChange(e.currentTarget.checked, _case.id)}
              onClick={(e) => onCheckClick(e)}
            />
          </div>
        )}
        <div className={'caseItem_DentistName'}>
          <div className={'dentistName'}>{_case.author.username}</div>
        </div>
        <div className={'caseItem_PatientName'}>
          <div className={'patientName'}>
            {/* {_case.to.username} / {_case.title} */}
            {_case.to.is_unknown ? `(${t('Unknown')})` : _case.to.username} /{' '}
            {getPatientName()}
          </div>
        </div>

        <div className={'caseCreatedDate'}>
          {_case.comment_count > 0 ? (
            <div className="CounterRect" title={t('Number of comment(s)')}>
              {_case.comment_count}
            </div>
          ) : (
            <div />
          )}
          <div class="createdDate">
            {getLocaleDate(_case.published_date, i18n.language)}{' '}
            {getLocaleTime(_case.published_date, i18n.language)}
          </div>
        </div>

        <div className={'caseItem_CaseID'}>
          <div class="caseID">
            {t('Case')}
            <span>&nbsp;&nbsp;</span>
            {_case.no}
            <span>&nbsp;&nbsp;</span>
            {_case.files_count === '0' ? '' : '/ '}
            {_case.files_count !== '0' && (
              <>
                {_case.files_count}
                <span>&nbsp;&nbsp;</span>
                {t('file(s) attached')}
              </>
            )}
          </div>
        </div>

        <div className={'caseItem_CaseStatus'}>
          <div className={'TextBoxRect'}>
            <div
              className="TextBoxFont"
              style={{
                // color: _case.status.color,
                color: statusColor[_case.status.code],
                backgroundColor: 'white',
                opacity: '1.0',
              }}
            >
              {getStatus()}
            </div>
          </div>
        </div>
        <div className={selected ? 'selectedCaseBottomLine' : 'caseBottomLine'} />
      </div>
    </MainBlock>
  );
};

export default React.memo(CaseItem);
