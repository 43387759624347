import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ActivityItem from '../../components/dashboard/ActivityItem';
import moment from 'moment';

import 'moment/locale/ko';
import 'moment/locale/ja';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/es';

import i18n from '../../i18n';
//import styled from 'styled-components';

// const ActivityLayout=styled.div`
//   display: grid;
//   grid-template-columns: 1fr;
//   grid-template-rows: 40px 8px 1fr;
//   gap: 0px 0px;
//   grid-auto-flow: row;
//   grid-template-areas:
//     "ActivityTitle-DayFilter"
//     "Space4"
//     "ActivityList";

//   .ActivityTitle-DayFilter {
//     display: grid;
//     grid-template-columns: 1fr 167px;
//     grid-template-rows: 1fr;
//     gap: 0px 0px;
//     grid-auto-flow: row;
//     grid-template-areas:
//       "ActivityTitle Filter";
//     grid-area: ActivityTitle-DayFilter;
//   }

//   .ActivityTitle { grid-area: ActivityTitle; }
//   .Filter { grid-area: Filter; }

//   .Space4 { grid-area: Space4; }

//   .ActivityList { grid-area: ActivityList; }
// `;

const ActivityList = ({ list, users }) => {
  const leftpanel = useSelector((state) => state.leftpanel);
  const { t } = useTranslation();

  function getUser(user_id) {
    var ret = users.find((x) => {
      return x.id === user_id;
    });

    if (ret === undefined) return '';
    return ret.username;
  }

  function getUserImg(user_id) {
    var ret = users.find((x) => {
      return x.id === user_id;
    });

    if (ret === undefined) return null;
    return ret.pictures;
  }

  function getTime(time) {
    var d = new Date(time);
    var today = new Date();
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // today
    if (
      today.getFullYear() === d.getFullYear() &&
      today.getMonth() === d.getMonth() &&
      today.getDate() === d.getDate()
    ) {
      // var h = d.getHours();
      // var m = d.getMinutes();

      // if (h < 10) h = '0' + h;
      // if (m < 10) m = '0' + m;

      // return h + ':' + m + ' ' + (h < 12 ? 'AM' : 'PM');

      return moment(d.toString()).locale(i18n.language).format('LT');
    } else if (
      yesterday.getFullYear() === d.getFullYear() &&
      yesterday.getMonth() === d.getMonth() &&
      yesterday.getDate() === d.getDate()
    ) {
      return t('Yesterday');
    } else {
      return moment(d.toString()).locale(i18n.language).format('ll');
      // var mon = [
      //   'Jan',
      //   'Feb',
      //   'Mar',
      //   'Apr',
      //   'May',
      //   'Jun',
      //   'Jul',
      //   'Aug',
      //   'Sep',
      //   'Oct',
      //   'Nov',
      //   'Dec',
      // ];
      // return mon[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear();
    }
  }

  function getStatusString(st) {
    if (st === 'STATUS_CHANGE') return t('Changed Status');
    else if (st === 'POST_CREATE') return t('Added New Case');
    else if (st === 'POST_EDIT') return t('Edited Case');
    else if (st === 'ASSIGNEE_CHANGE') return t('Changed Assignee');
    else if (st === 'POST_UPLOAD') return t('Uploaded Case');
    else if (st === 'FILE_UPLOAD') return t('Added New File');
    else if (st === 'COMMENT_ADD') return t('Added a New Comment');
    else if (st === 'COMMENT_EDIT') return t('Edited Comment');
    else if (st === 'REGION_CONFIRM') return t('Confirmed Region');
  }

  return (
    <>
      {list.length == 0 ? (
        <div
          style={{
            width: '100%',
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* show No Data if necessary */}
          {/* {t('No Data')} */}
        </div>
      ) : (
        <>
          {list.map((x, idx) => (
            <div key={idx}>
              <ActivityItem
                case_date={getTime(x.action_date)}
                dent_name={getUser(x.user_id)}
                img={getUserImg(x.user_id)}
                type={getStatusString(x.action)}
                case_id={x.post_id}
                case_no={x.post_no}
              />
            </div>
          ))}
        </>
      )}

      {/* <ActivityItem 
        case_date="12:58:00"
        protrait={null}
        dent_name="nam soo"
        type="Open"
        case_id="12"
      /> */}
    </>
  );
};

export default ActivityList;
