import React, { useRef, useEffect, useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';

const sizeStyles = css`
  ${({ width, height, padding }) => css`
    width: ${width};
    height: ${height};
    padding: ${padding};
  `}
`;

const StyledInput = styled.input`
  margin: ${(props) => props.margin};
  border-radius: 4px;
  border: solid 1px #e7e9ea;
  background-color: ${(props) => props.bgkColor || '#ffffff'};
  outline: none;

  color: ${(props) => props.color || '#59616a'};
  font-family: 'Source Sans Pro';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  /* Size */
  ${sizeStyles}

  ::placeholder {
    color: #969ba1;
  }

  &:not(:first-child) {
    margin-top: ${(props) => props.marginTop || '8px'};
  }
`;

const Input = ({
  children,
  width,
  height,
  margin,
  padding,
  readOnly,
  maxLength,
  color,
  bgkColor,
  marginTop,
  ...rest
}) => {
  const inputRef = useRef(null);
  let focus = false;
  const themeContext = useContext(ThemeContext);

  useEffect(() => {
    if (inputRef.current && readOnly) {
      inputRef.current.disabled = true;
      inputRef.current.removeEventListener('focus', onFocusInput);
      inputRef.current.removeEventListener('blur', onBlurInput);
      inputRef.current.removeEventListener('mouseover', onMouseOver);
      inputRef.current.removeEventListener('mouseout', onMouseOut);
      // inputRef.current.style.backgroundColor = 'transparent';
      // inputRef.current.style.border = '0';
    } else {
      inputRef.current.addEventListener('focus', onFocusInput);
      inputRef.current.addEventListener('blur', onBlurInput);
      inputRef.current.addEventListener('mouseover', onMouseOver);
      inputRef.current.addEventListener('mouseout', onMouseOut);
    }
  }, []);

  const onFocusInput = () => {
    focus = true;
    if (inputRef.current) {
      inputRef.current.style.border = `solid 1px ${themeContext.main_color}`;
      inputRef.current.style.backgroundColor = '#ffffff';
    }
  };
  const onBlurInput = () => {
    focus = false;
    if (inputRef.current) {
      inputRef.current.style.border = 'solid 1px #e7e9ea';
      inputRef.current.style.backgroundColor = '#ffffff';
    }
  };
  const onMouseOver = () => {
    if (focus === false) {
      if (inputRef.current) {
        inputRef.current.style.border = 'solid 1px #eeeff0';
        inputRef.current.style.backgroundColor = '#f5f6f6';
      }
    }
  };
  const onMouseOut = () => {
    if (focus === false) {
      if (inputRef.current) {
        inputRef.current.style.border = 'solid 1px #e7e9ea';
        inputRef.current.style.backgroundColor = '#ffffff';
      }
    }
  };

  return (
    <StyledInput
      ref={inputRef}
      width={width}
      height={height}
      margin={margin}
      padding={padding}
      autoComplete="off"
      maxLength={maxLength}
      color={color}
      bgkColor={bgkColor}
      marginTop={marginTop}
      readOnly={readOnly}
      // onFocus={onFocusInput}
      // onBlur={onBlurInput}
      // onMouseOver={onMouseOver}
      // onMouseOut={onMouseOut}
      {...rest}
    ></StyledInput>
  );
};

Input.defaultProps = {
  width: '416px',
  height: '19px',
  padding: '14px 15px 13px',
  margin: '24px 496px 0px 496px',
};

export default React.memo(Input);
