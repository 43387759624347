import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import HButton from '../common/HButton';
import { useTranslation } from 'react-i18next';
import SVG from '../common/svg';
import { getLocaleDate, GetLocale } from '../common/Util';
import { deviceRegistrationInfo } from '../../lib/api/cs';
import Spinner from '../common/Spinner';

const RegInfoFullscreen = styled.div`
  width: 100%;
  height: 100%;
`;

const ReginfoTitleBlock = styled.div`
  .titleBox {
    width: 100%;
    height: 56px;
    margin: 0 auto 0 auto;
    background: #eeeff0;
    display: flex;
    justify-content: center;
  }

  .titleText {
    /* width: 600px; */
    height: 25px;
    margin: 15px 0 0 0px;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
    text-align: left;
    float: left;
  }
`;

const RegInfoModalBlock = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  /* height: 100%; */
  background: white;
`;

const RegInfoContentBlock = styled.div`
  .contentBox {
    width: 560px;
    height: 80px;
    margin: 24px 20px 32px 20px;

    display: grid;
    grid-template-columns: 152px 16px 392px;
    grid-template-rows: 40px 40px 40px 40px 40px 40px 40px 80px;
    gap: 0px;

    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #59616a;
  }

  .RegInfo_NameT {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    text-align: right;
    color: #969ba1;
  }

  .RegInfo_NameC {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    text-align: left;
  }

  .RegInfo_EmailT {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    text-align: right;
    color: #969ba1;
  }

  .RegInfo_EmailC {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
    text-align: left;
  }

  .RegInfo_CustomerNumberT {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 5;
    text-align: right;
    color: #969ba1;
  }

  .RegInfo_CustomerNumberC {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end: 5;
    text-align: left;
  }

  .RegInfo_SNT {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
    text-align: right;
    color: #969ba1;
  }

  .RegInfo_SNC {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 4;
    text-align: left;
  }

  .RegInfo_InstallT {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 5;
    grid-row-end: 6;
    text-align: right;
    color: #969ba1;
  }

  .RegInfo_InstallC {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 5;
    grid-row-end: 6;
    text-align: left;
  }

  .RegInfo_PhoneT {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 6;
    grid-row-end: 7;
    text-align: right;
    color: #969ba1;
  }
  .RegInfo_PhoneC {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 6;
    grid-row-end: 7;
    text-align: left;
  }

  .RegInfo_CountryT {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 7;
    grid-row-end: 8;
    text-align: right;
    color: #969ba1;
  }

  .RegInfo_CountryC {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 7;
    grid-row-end: 8;
    text-align: left;
  }

  .RegInfo_AddressT {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 8;
    grid-row-end: 9;
    text-align: right;
    color: #969ba1;
  }

  .RegInfo_AddressC {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 8;
    grid-row-end: 9;
    text-align: left;
  }
`;

const RegInfo = ({ match }) => {
  const { t } = useTranslation();
  const { deviceId } = match.params;

  const { odm, userChecking } = useSelector(({ app, loading }) => ({
    odm: app.odm,
    userChecking: loading['user/CHECK'],
  }));
  const [device, setDevice] = useState(null);
  const [loading, setLoading] = useState(true);

  const reqRegInfo = async () => {
    try {
      setLoading(true);
      await deviceRegistrationInfo(deviceId).then((res) => {
        setDevice(res.data);
        console.log(res.data);
        setLoading(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!userChecking && deviceId) reqRegInfo();
  }, [userChecking, deviceId]);

  return (
    <>
      {loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Spinner />
        </div>
      )}

      {!loading && (
        <RegInfoFullscreen>
          <ReginfoTitleBlock>
            <div className="titleBox">
              <div class="titleText">{'Device Detail'}</div>
            </div>
          </ReginfoTitleBlock>
          <RegInfoModalBlock>
            <RegInfoContentBlock>
              {device.reg_customer && (
                <div className="contentBox">
                  {/* Name */}
                  <div className={'RegInfo_NameT'}>{'Name'} :</div>
                  <div className={'RegInfo_NameC'}>
                    {device.reg_customer.name}
                  </div>
                  {/* Email */}
                  <div className={'RegInfo_EmailT'}>{'Email'} :</div>
                  <div className={'RegInfo_EmailC'}>
                    {device.reg_customer.email}
                  </div>
                  {/* SN */}
                  <div className={'RegInfo_SNT'}>{'Device S/N'} :</div>
                  <div className={'RegInfo_SNC'}> {device.serial_no}</div>
                  {/* Product Date */}
                  <div className={'RegInfo_CustomerNumberT'}>
                    {'Manufacturing Date'} :
                  </div>
                  <div className={'RegInfo_CustomerNumberC'}>
                    {getLocaleDate(device.prod_date, 'en')}
                  </div>
                  {/* Install Date */}
                  <div className={'RegInfo_InstallT'}>
                    {'Installation Date'} :
                  </div>
                  <div className={'RegInfo_InstallC'}>
                    {getLocaleDate(device.reg_customer.installed_date, 'en')}
                  </div>
                  {/* Phone */}
                  <div className={'RegInfo_PhoneT'}>{'Phone'} :</div>
                  <div className={'RegInfo_PhoneC'}>
                    {device.reg_customer.phone}
                  </div>
                  {/* Country */}
                  <div className={'RegInfo_CountryT'}>{'Country'} :</div>
                  <div className={'RegInfo_CountryC'}>
                    {device.reg_customer.country}
                  </div>
                  {/* Address */}
                  <div className={'RegInfo_AddressT'}>{'Address'} :</div>
                  <div className={'RegInfo_AddressC'}>
                    {device.reg_customer.address}
                    {device.reg_customer.address && ','}
                    <span>&nbsp;&nbsp;</span>
                    {device.reg_customer.city}
                    {device.reg_customer.city && ','}
                    <span>&nbsp;&nbsp;</span>
                    {device.reg_customer.state}
                    {device.reg_customer.state && ','}
                    <span>&nbsp;&nbsp;</span>
                    {device.reg_customer.zip_code}
                  </div>
                </div>
              )}
              {!device.reg_customer && <p>This page does not exist.</p>}
            </RegInfoContentBlock>
          </RegInfoModalBlock>
        </RegInfoFullscreen>
      )}
    </>
  );
};

export default RegInfo;
