import { myCache } from './create-nonce';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { CacheProvider } from '@emotion/react';
import './index.css';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from 'history';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import createRootReducer, { rootSaga } from './modules';
import { tempSetUser, check } from './modules/user';
import { appInfo, setODM } from './modules/app';
import { getODM, theme } from './lib/odm';
import { ThemeProvider } from 'styled-components';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

window.es = null;
window.actionIDs = new Set();

function uid_short() {
  return String(1e7).replace(/[01]/g, (c) =>
    (
      c ^
      (window.crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );
}
export const newActionId = () => {
  const actionId = uid_short();
  if (window.actionIDs) {
    window.actionIDs.add(actionId);
    // delete action ID after 5 minutes
    setTimeout(() => {
      if (window.actionIDs.has(actionId)) window.actionIDs.delete(actionId);
    }, 5 * 60 * 1000);
  }
  return actionId;
};

export const appConfig = process.env.REACT_APP_URL
  ? {
      appUrl: process.env.REACT_APP_URL,
      serverUrl: process.env.REACT_APP_SERVER_URL,
    }
  : window.globalConfig;
export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware({
  context: { history: history },
});
const store = createStore(
  createRootReducer(history),
  composeWithDevTools(applyMiddleware(routerMiddleware(history), sagaMiddleware)),
);

function loadUser() {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    const settings = JSON.parse(localStorage.getItem('settings'));
    const odm = getODM();
    const redirect_region = cookies.get('redirectRegion') === 'true';

    store.dispatch(setODM(odm));
    store.dispatch(appInfo());
    if (redirect_region) {
      const isDeviceLogin = /^\/adm.*/i.test(window.location.pathname);
      store.dispatch(check({ callFrom: isDeviceLogin ? 'adm' : null }));

      const domain =
        window.location.hostname === 'localhost'
          ? 'localhost'
          : window.location.hostname.match(/\w*\.\w*$/gi)[0];
      cookies.remove('redirectRegion', { domain: domain, samesite: 'Strict' });
    } else if (user) {
      store.dispatch(tempSetUser({ user, settings }));
      store.dispatch(
        check({
          callFrom:
            user.is_device_manager ||
            user.is_sales_manager ||
            user.is_staff ||
            user.is_device_user_viewer
              ? 'adm'
              : null,
        }),
      );
    }
  } catch (e) {
    console.log('localStorage is not working');
  }
}

sagaMiddleware.run(rootSaga);
loadUser();

ReactDOM.render(
  <Provider store={store}>
    <CacheProvider value={myCache}>
      <ConnectedRouter history={history}>
        <Suspense fallback="">
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </Suspense>
      </ConnectedRouter>
    </CacheProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
