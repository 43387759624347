import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import SVG from '../common/svg';

const InputSearch_MainBox = styled.div`
  .InputSearch_MainBox_Main {
    display: grid;
    grid-template-columns: 48px auto 48px;    
    outline: none;
    border-radius: 4px;
    border: solid 1px #e7e9ea;
    color: #969ba1;
    background-color: #ffffff;
    width: -webkit-fill-available;
  }

  .InputSearch_Icon {
    grid-column: 1;
    grid-row: 1;     
    width: 24px;
    height: 24px;
    margin-top: 8px;
    margin-left: 12px;
    margin-right: 12px;
    background-color: transparent;
  }

  .InputSearch_ClearButton {
    grid-column: 3;
    grid-row: 1;       
    width: 24px;
    height: 24px;
    margin-top: 8px;
    margin-right: 12px;
    margin-left: 12px;
    visibility: visible;
  }
  
  .InputSearch_InputField {
    grid-column: 2;
    grid-row: 1;       
//    width: calc(100% - 48px - 10px);
    margin-top: 0px;
    outline: none;
    background-color: transparent;
    border: none;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
  }

  .InputSearch_InputField::-webkit-input-placeholder {
    color: #969ba1;
  }

  .InputSearch_InputField:focus::placeholder {
    color: transparent;
  }
`;

const InputSearch = (props) => {
  const clearActive =
    process.env.PUBLIC_URL + '/images/common/icon-24-delete-input-active.svg';
  const clearHover =
    process.env.PUBLIC_URL + '/images/common/icon-24-delete-input-hover.svg';
  const clearSelect =
    process.env.PUBLIC_URL + '/images/common/icon-24-delete-input-selected.svg';

  const [inputText, setInputText] = useState(props.value);
  const [inputFocus, setInputFocus] = useState(false);

  const { odm } = useSelector(({ app }) => ({
    odm: app.odm,
  }));

  const onChangeInput = (e) => {
    setInputText(e.target.value);
    setInputFocus(true);
  };

  const onReset = () => {
    setInputText('');
    props.onSearchEnterPress('');
  };

  const onFocusInput = () => {
    setInputFocus(true);
    document.getElementById('mainDiv').style.border =
      'solid 1px ' + odm.main_color;
    document.getElementById('mainDiv').style.backgroundColor = '#ffffff';
  };

  const onBlurInput = () => {
    setInputFocus(false);

    document.getElementById('mainDiv').style.border = 'solid 1px #e7e9ea';
    document.getElementById('mainDiv').style.backgroundColor = '#ffffff';
  };
  const onMouseOver = () => {
    if (inputFocus === false) {
      document.getElementById('mainDiv').style.border = 'solid 1px #eeeff0';
      document.getElementById('mainDiv').style.backgroundColor = '#f5f6f6';
    }
  };
  const onMouseOut = () => {
    if (inputFocus === false) {
      document.getElementById('mainDiv').style.border = 'solid 1px #e7e9ea';
      document.getElementById('mainDiv').style.backgroundColor = '#ffffff';
    }
  };

  /*
  const GetInputWidth = () => {
    return parseInt(props.width, 10) - 48 - 48 - 10 + 'px';
  };
  */

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      props.onSearchEnterPress(inputText);
    }
  };

  useEffect(() => {
    // if (!props.value && inputText) {
    setInputText(props.value);
    onBlurInput();
    // }
  }, [props.value]);

  return (
    <InputSearch_MainBox>
      <div
        id="mainDiv"
        className={'InputSearch_MainBox_Main'}
        style={{
          width: props.width,
          height:
            props.size === 'Large'
              ? '48px'
              : props.size === 'Medium'
                ? '40px'
                : '36px',
          margin: props.margin,
          float: props.float,
        }}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        <img
          src={
            process.env.PUBLIC_URL + '/images/common/icon-24-search-active.svg'
          }
          class="InputSearch_Icon"
        />

        <input
          class="InputSearch_InputField"
          type="text"
          // value={props.value !== '' ? props.value : inputText}
          value={inputText}
          style={{
            // width:
            //   inputText === ''
            //     ? "calc(100% - 48px - 10px)"
            //     : "calc(100% - 48px - 48px - 10px)",
            height:
              props.size === 'Large'
                ? '46px'
                : props.size === 'Medium'
                  ? '38px'
                  : '34px',
            fontSize: props.size === 'Large' ? 16 : 14,
            lineHeight: props.size === 'Large' ? 1 : 1.14,
            letterSpacing: props.size === 'Large' ? '0.1px' : '0.2px',
          }}
          onChange={onChangeInput}
          onFocus={onFocusInput}
          onBlur={onBlurInput}
          placeholder={props.placeholder}
          onKeyPress={onKeyPress}
        />

        {inputText !== '' && (
          <div className={'InputSearch_ClearButton'} onClick={onReset} >
            <SVG
              name="delete_input"
              color="#878D93"
              colorOver={odm.main_color_hover}
              colorOut="#878D93"
              colorDown={odm.main_color}
            />
          </div>
        )}

      </div >
    </InputSearch_MainBox >
  );
};

export default InputSearch;
