import React from 'react';
import LoginContainer from '../containers/auth/LoginContainer';
import styled from 'styled-components';

const Body = styled('div')`
  min-width: 360px;
  min-height: 100vh;
  height: 100vh;
  margin: auto;
  background: #eeeff0;
  /* overflow-y: auto; */
  /* overflow-x: hidden;
  overflow-y: auto; */
`;

const LoginPage = ({ history }) => {
  return (
    <Body>
      <LoginContainer />
    </Body>
  );
};

export default LoginPage;
