import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectedMenu } from '../../modules/leftpanel';
import { useTranslation } from 'react-i18next';
import TextBox from './TextBox';
import SVG from '../common/svg';

import './MenuItem.css';
import { theme } from '../../lib/odm';

const MenuItem = (props) => {
  const [mouseState, setMouseState] = useState('leave');

  const leftpanel = useSelector((state) => state.leftpanel);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  // function getImage() {
  //   if (leftpanel.menu === props.id) return props.img_selected;

  //   if (mouseState === 'enter') return props.img_hover;

  //   return props.img_active;
  // }

  function getColor() {
    var ret = '';
    if (leftpanel.menu === props.id) ret = theme.main_color;
    if (mouseState === 'enter') ret = theme.main_color_hover;
    if (mouseState === 'leave') ret = leftpanel.menu === props.id ? theme.main_color : '#A5A9A3';
    if (mouseState === 'down') ret = theme.main_color;

    return ret;
  }

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    // <Link to={props.link} onClick={() => dispatch(selectedMenu(props.id))}>
    <Link
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      to={props.link}
      onClick={(e) => {
        if (leftpanel.menu !== props.id) dispatch(selectedMenu(props.id));
        else e.preventDefault();
      }}
    >
      <div
        onMouseEnter={() => setMouseState('enter')}
        onMouseLeave={() => setMouseState('leave')}
        onMouseDown={() => setMouseState('down')}
        className={
          leftpanel.collapsed === false && leftpanel.collapsedAuto == false
            ? 'Rectangle'
            : 'SmallRect'
        }
      >
        <div className="column">
          {/* <img alt="" src={getImage()} className="icon-menu" /> */}
          <div
            className="icon-menu"
            title={leftpanel.collapsed === true ? t(capitalize(props.id)) : ''}
          >
            <SVG
              name={props.id}
              color={getColor()}
              // colorOver={theme.main_color_hover}
              // colorOut={
              //   leftpanel.menu === props.id ? theme.main_color : '#A5A9AE'
              // }
              // colorDown={theme.main_color}
            />
          </div>
        </div>

        <div className="column">
          <label
            className={leftpanel.menu === props.id ? 'selected_menu_text' : 'menu_text'}
            style={{
              cursor: 'pointer',
              display:
                leftpanel.collapsed === false && leftpanel.collapsedAuto === false
                  ? 'block'
                  : 'none',
            }}
          >
            {' '}
            {t(props.name)}
          </label>
        </div>

        {props.count > 0 && leftpanel.collapsed === false && leftpanel.collapsedAuto === false && (
          <div
            className="column"
            style={{
              marginLeft: '10px',
              marginTop: '6.5px',
              textAlign: 'right',
            }}
          >
            <button className="CounterRect" style={{ userSelect: 'none' }}>
              <div className="CounterFont">{props.count}</div>
            </button>
          </div>
        )}
      </div>
    </Link>
  );
};

export default MenuItem;
