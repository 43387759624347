import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
// import { getInitial } from '../../common/Util';

const colorStyles = css`
  ${({ color }) => {
    const selected = color;
    return css`
      background-color: ${selected};
      &:hover {
        background-color: #e5621f;
      }
      /* &:active {
        background-color: #ff6d22;
      }
      &:focus {
        background-color: #ff6d22;
      }
      &:disabled {
        background-color: #f5f6f6;
      } */
    `;
  }}
`;

const sizeStyles = css`
  ${({ width, height }) => css`
    width: ${width};
    height: ${height};
  `}
`;

const StyledButton = styled.button`
  background-color: transparent;
  white-space: nowrap;
  width: ${(props) => props.width || '64px'};
  height: ${(props) => props.height || '64px'};
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  user-select: none; /*Prevent drag*/
  overflow: hidden;

  .imageGroup {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 60%;
  }

  /* display: inline; */
  vertical-align: top;

  /* Size */
  /* ${sizeStyles} */

  /* Color */
  /* ${colorStyles} */
`;

const StyledImage = styled.img`
  width: ${(props) => props.width || '64px'};
  height: ${(props) => props.height || '64px'};
  margin: 0;
  border: 0;
  padding: 0;
  object-fit: cover; /*contain*/
  border-radius: 60%;
`;

const CoveredStyledImage = styled.img.attrs(props => ({
  src: process.env.PUBLIC_URL + "/" + props.theme.images + '/common/icon-24-close-selected.svg',
}))`
  display: none;
  position: absolute;
  width: ${(props) => props.width || '25px'};
  height: ${(props) => props.height || '25px'};
  margin: 0;
  border: 0;
  padding: 15px;
`;

// const Avatar = styled.div`
//   display:flex;
//   justify-content: center;
//   align-items: center;
//   width: ${(props) => props.width || '64px'};
//   height: ${(props) => props.height || '64px'};
//   margin: 0;
//   border: 0;
//   padding: 0;
//   border-radius: 60%;
//   background-color: #787F86;

//   font-family: Source Sans Pro;
//   font-size: ${(props) => props.fontSize || '24px'};;
//   font-weight: 600;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: 0.09px;
//   text-align: center;
//   color: #ffffff;
// `;

function ImageButton({
  social_auth,
  children,
  color,
  width,
  height,
  fontSize,
  src,
  onChangeImage,
  disabled,
  imgRef,
  coverImgRef,
  onMouseOver,
  onMouseOut,
  onDeleteClick,
  ...rest
}) {
  // const closeSelected =
  //   process.env.PUBLIC_URL + '/images/common/icon-24-close-selected.svg';

  const { user, form } = useSelector(({ user, auth }) => ({
    user: user.user,
    form: auth.signup,
  }));

  useEffect(() => {
    if (user && !social_auth) {
      if (form) {
        if (form.picture || user.picture) {
          if (imgRef) {
            imgRef.current.addEventListener('mouseover', onMouseOver);
            imgRef.current.addEventListener('mouseout', onMouseOut);
          }
          if (coverImgRef) {
            coverImgRef.current.addEventListener('mouseover', onMouseOver);
            coverImgRef.current.addEventListener('mouseout', onMouseOut);
          }
        }
      }
    }
  }, []);

  return (
    <StyledButton type="button" color={color} {...rest}>
      {social_auth && (
        <label>
          <StyledImage src={src} alt="avatar" />
        </label>
      )}
      {!social_auth && (
        <div>
          <label htmlFor="file-input" className="imageGroup">
            <StyledImage ref={imgRef} src={src} alt="avatar" />
            <CoveredStyledImage
              ref={coverImgRef}
              // src={closeSelected}
              alt="delete"
              onClick={onDeleteClick}
            />
          </label>
          <input
            name="picture"
            disabled={disabled}
            id="file-input"
            type="file"
            style={{ display: 'none' }}
            onChange={onChangeImage}
            accept="image/png, image/jpeg"
          />
        </div>
      )}
    </StyledButton>
  );
}

export default ImageButton;
