import React from 'react';
import styled from 'styled-components';

function CheckboxText({ margin, height, text, fontSize, checked, onChange }) {
  return (
    <StyledLabel htmlFor={text} margin={margin} height={height}>
      <StyledInput type="checkbox" onChange={onChange} id={text} name={text} checked={checked} />
      <StyledP fontSize={fontSize}>{text}</StyledP>
    </StyledLabel>
  );
}

export default CheckboxText;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  user-select: none;
  margin: ${(props) => props.margin};
  height: ${(props) => props.height};
`;

const StyledInput = styled.input`
  /* appearance는 IE에서 지원하지 않음 */
  appearance: none;
  background-image: url('${process.env.PUBLIC_URL}/images/settings/icon-24-checkbox-active.svg');
  width: 24px;
  height: 24px;
  margin: 0;
  outline: none;

  &:checked {
    background-image: url('${process.env.PUBLIC_URL}/${(props) =>
      props.theme.images}/settings/icon-24-checkbox-selected.svg');
  }
`;

const StyledP = styled.p`
  margin-left: 0.25rem;
  font-size: ${(props) => props.fontSize};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #59616a;
`;
