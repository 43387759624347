import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import HButton from '../common/HButton';
import { useDispatch, useSelector } from 'react-redux';
import InputBox from '../common/InputBox';
import {
  removeConnection,
  rejectConnection,
  acceptConnection,
  listConnections,
} from '../../modules/connections';
import { editConnection } from '../../lib/api/connections';

import { getInitial } from '../common/Util';
import { listAlarms } from '../../modules/leftpanel';
import MessageBox from '../common/MessageBox';
import ConfirmBox from '../common/ConfirmBox';
import { useTranslation } from 'react-i18next';
import SVG from '../common/svg';
import ConnectionInfo from './ConnectionInfo';

const MainBlock = styled.div`
  font-family: Source Sans Pro;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  .connectionBox {
    width: calc(100% - 80px);
    height: 72px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 8px;
    display: grid;
    grid-template-columns: 222px 36px 320px auto auto 24px 4px 20px 24px 20px;
    grid-template-rows: 100%;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #e7e9ea;
    outline: none;
    gap: 0px;
  }

  .connectionName {
    grid-column-start: 1;
    grid-column-end: 2;

    width: 192px;
    margin-left: 20px;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 15px;
    font-weight: 600;
    color: #59646a;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.5em;
    max-height: 3em;
  }

  .picture {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;

    width: 32px;
    height: 32px;
    line-height: 32px;
    margin-top: 20px;
    border-radius: 18px;
    background-color: #787f86;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
  }

  .nameBox {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-left: 10px;
    margin-top: 26px;
  }

  .name {
    font-size: 15px;
    font-weight: 600;
    color: #59646a;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowarp;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    float: left;
  }

  .email {
    font-size: 15px;
    font-weight: normal;
    color: #a5a9ae;
    float: left;
  }

  .institution {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;

    min-width: 100px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: auto;
    margin-bottom: auto;

    font-size: 15px;
    font-weight: 600;
    color: #59646a;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.5em;
    max-height: 3em;
  }

  .buttonBox {
    grid-column-start: 5;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .button1 {
    margin-top: 16px;
    float: right;
  }

  .button2 {
    margin-top: 16px;
    margin-left: 20px;
    float: right;
  }

  .shared {
    grid-column-start: 6;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 2;

    height: 24px;
    margin-top: 24px;

    color: #59616a;
  }

  .moreButton {
    grid-column-start: 9;
    grid-column-end: 10;
    grid-row-start: 1;
    grid-row-end: 2;

    width: 24px;
    height: 24px;
    margin-top: 24px;
  }

  .menu {
    position: absolute;
    right: 25;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #e7e9ea;
    opacity: 0;
    visibility: hidden;
    //transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    display: block;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      &:first-child {
        margin-top: 8px;
      }
      &:last-child {
        margin-bottom: 8px;
      }
    }
    li {
      height: 40px;

      &:hover {
        background: #eeeff0;
      }
    }
    li div {
      font-family: 'Source Sans Pro';
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #59616a;
      height: 100%;

      padding: 0px 0px 0px 15px;
      margin-right: 20px;
      display: block;
    }

    li span {
    }
  }

  .active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
`;

const StyledImage = styled.img`
  width: ${(props) => props.width || '32px'};
  height: ${(props) => props.height || '32px'};
  margin: 0;
  border: 0;
  padding: 0;
  object-fit: cover; /*contain*/
  border-radius: 60%;
`;

const useDetectOutsideClick = (el, initialState) => {
  const [isActive, setIsActive] = useState(initialState);

  useEffect(() => {
    const onClick = (e) => {
      // If the active element exists and is clicked outside of
      if (el.current !== null && !el.current.contains(e.target)) {
        setIsActive(!isActive);
      }
    };

    // If the item is active (ie open) then listen for clicks outside
    if (isActive) {
      window.addEventListener('click', onClick);
    }

    return () => {
      window.removeEventListener('click', onClick);
    };
  }, [isActive, el]);

  return [isActive, setIsActive];
};

const ConnectionItem = ({
  connection,
  onClickRequestAgain,
  searchFilter,
  type,
  odm,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  /*
  const moreActive =
    process.env.PUBLIC_URL + '/images/common/icon-24-more-active.svg';
  const moreHover =
    process.env.PUBLIC_URL + '/images/common/icon-24-more-hover.svg';
  const moreSelected =
    process.env.PUBLIC_URL + '/images/common/icon-24-more-selected.svg';
    */

  const acceptListItems = [t('Info'), t('Edit'), t('Remove')];
  const acceptListShareItems = [t('Info')];
  const pendingOnwerListItems = [t('Edit'), t('Remove')];
  const pendingGuestListItems = [t('Reject')];
  const lockedListItem = [t('Edit'), t('Remove')];

  const [inputBoxVisible, setInputBoxVisible] = useState(false);
  const [infoVisible, setInfoVisible] = useState(false);

  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const connName = connection.is_unknown ? `(${t('Unknown')})` : connection.name;

  const onClick = () => {
    setIsActive(!isActive);
  };

  const onClickButton = () => {
    if (connection.Im_invited) {
      dispatch(
        acceptConnection({
          cidb64: connection.idb64,
          token: connection.accept_token,
        }),
      );
    } else {
      onClickRequestAgain(
        connection.email,
        connection.alias,
        connection.text,
        connection.id,
      );
    }
  };

  const onClickReject = async () => {
    alert('dd');
  };

  const showMessage = (e) => {
    setConfirmBoxContent('API Error');
    setConfirmBoxVisible(true);
  };

  // Update Alias
  const onInputBoxOk = async (inputText) => {
    setInputBoxVisible(false);
    try {
      const res = await editConnection({ id: connection.id, alias: inputText });
      dispatch(listConnections(searchFilter));
    } catch (e) {
      showMessage(e);
    }
  };

  const onInputBoxCancel = () => {
    setInputBoxVisible(false);
  };

  const onInfoCancel = () => {
    setInfoVisible(false);
  };

  const [messageTitle, setMessageTitle] = useState('');
  const [messageContent, setMessageContent] = useState('');
  const [messageVisible, setMessageVisible] = useState(false);

  const [confirmBoxContent, setConfirmBoxContent] = useState('');
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);

  const onMessageBoxOk = () => {
    dispatch(removeConnection(connection.id));
    setMessageVisible(false);
  };

  const onMessageBoxCancel = () => {
    setMessageVisible(false);
  };

  const onConfirmBoxOk = () => {
    setConfirmBoxVisible(false);
  };

  const onSelect = (item) => {
    setIsActive(false);
    if (connection.status === 'ACCD') {
      if (item === t('Remove')) {
        setMessageTitle(t('Warning'));
        setMessageContent(t('Remove Connection?'));
        setMessageVisible(true);
      } else if (item === t('Edit')) {
        // Edit Alias
        setInputBoxVisible(true);
      } else if (item === t('Info')) {
        setInfoVisible(true);
      }
    } else {
      if (item === t('Remove')) {
        setMessageTitle(t('Warning'));
        setMessageContent(t('Remove Connection?'));
        setMessageVisible(true);
      } else if (item === t('Reject')) {
        dispatch(rejectConnection(connection.id));
        dispatch(listAlarms());
      } else if (item === t('Edit')) {
        // Edit Alias
        setInputBoxVisible(true);
      }
    }
  };

  const getMoreRect = (id) => {
    const moreid = 'more' + id;
    var moreButton = document.getElementById(moreid);
    var moreRect = {};
    if (!moreButton) return moreRect;
    moreRect = moreButton.getBoundingClientRect();

    const boxid = 'box' + id;
    var box = document.getElementById(boxid);
    var boxRect = {};
    boxRect = box.getBoundingClientRect();

    var rect = {};
    rect.left = moreRect.left - boxRect.left;
    rect.top = moreRect.top;
    rect.right = moreRect.right;
    rect.bottom = moreRect.bottom;
    return rect;
  };

  const sharedActive =
    process.env.PUBLIC_URL + '/images/common/icon-24-share-disabled.svg';

  const getButtonString = () => {
    if (connection.Im_invited) return t('Accept Invitation').toUpperCase();
    else return t('Request Again').toUpperCase();
  };

  const getAliasString = () => {
    if (connection.status === 'REJD') {
      return connection.alias + ' (' + 'Rejected' + ')';
    } else if (connection.status === 'REQD' && !connection.is_owner) {
      return '';
    } else {
      return connection.alias;
    }
  };

  const getMoreItems = () => {
    if (connection.status === 'ACCD') {
      if (type === 'accepted' && !IsShared()) return acceptListItems;
      else if (type === 'accepted' && IsShared()) return acceptListShareItems;
      else return lockedListItem;
    } else {
      if (connection.Im_invited) return pendingGuestListItems;
      else return pendingOnwerListItems;
    }
  };

  const getName = () => {
    if (connection.status === 'ACCD') {
      // return connection.name;
      return connName;
    } else if (connection.status === 'REQD' && connection.Im_invited) {
      // return connection.owner.username;
      return connection.owner.is_unknown
        ? `(${t('Unknown')})`
        : connection.owner.username;
    } else {
      if (connection.is_owner) {
        // return connection.name;
        return connName;
      } else {
        // return connection.name;
        return connName;
      }
    }
  };

  const getEmail = () => {
    let res = '';
    if (connection.status === 'ACCD') {
      res = connection.email;
    } else if (connection.status === 'REQD' && connection.Im_invited) {
      res = connection.owner.email;
    } else {
      if (connection.is_owner) {
        res = connection.email;
      } else {
        res = connection.email;
      }
    }
    if (getName() !== '') res = '\u00a0' + '/' + '\u00a0' + res;
    return res;
  };

  const getInstitution = () => {
    if (connection.status === 'REQD' && !connection.is_owner) {
      return connection.text;
    }
    return connection.institution;
  };

  const getPicture = () => {
    if (connection.status === 'REQD' && connection.Im_invited) {
      if (connection.owner === null || connection.owner.picture === null) {
        return <div class="picture">{getInitial(getName())}</div>;
      } else {
        return (
          <div class="picture">
            <StyledImage src={connection.owner.picture} />
          </div>
        );
      }
    } else {
      if (connection.picture === null) {
        return <div class="picture">{getInitial(getName().replace('(', ''))}</div>;
      } else {
        return (
          <div class="picture">
            <StyledImage src={connection.picture} />
          </div>
        );
      }
    }
  };

  const IsShared = () => {
    if (type != 'pending' && !connection.is_owner) return true;
    else return false;
  };

  return (
    <MainBlock>
      <div class="connectionBox" id={'box' + connection.id}>
        {/* Title */}
        <div class="connectionName" title={t('Alias')}>
          {getAliasString()}
        </div>

        {/* Picture */}
        {getPicture()}

        {/* Name */}
        <div className="nameBox">
          <div className="name">{getName()}</div>
          <div className="email">{getEmail()}</div>
        </div>

        {/* Institution */}
        <div
          className="institution"
          title={`${connection.phone}\n${connection.address}`}
        >
          {getInstitution()}
        </div>

        {/* button */}
        {type === 'pending' && (
          <div className="buttonBox">
            <div className="button2">
              {/* Invite Again */}
              <HButton
                category="Secondary"
                size="Medium"
                text={getButtonString()}
                onClick={() => onClickButton(connection)}
              />
            </div>
          </div>
        )}

        {/* Share Button */}
        {IsShared() && (
          <div className={'shared'}>
            <img src={sharedActive} />
          </div>
        )}

        {/* more button */}
        {/* {!IsShared() && type != 'pending' && ( */}
        <>
          <div
            className={'moreButton'}
            onClick={onClick}
            id={'more' + connection.id}
            title={t('More')}
          >
            <SVG
              name="more"
              color="#878D93"
              colorOver={odm.main_color_hover}
              colorOut="#878D93"
              colorDown={odm.main_color}
            />
          </div>

          <nav
            ref={dropdownRef}
            className={`menu ${isActive ? 'active' : 'inactive'}`}
            style={{
              width: 160,
              left: getMoreRect(connection.id).left - 80,
              top: getMoreRect(connection.id).bottom + 8 + window.scrollY,
            }}
          >
            <ul>
              {getMoreItems().map((item, index) => (
                <li key={index}>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      onSelect(item);
                      onClick();
                    }}
                  >
                    <table
                      style={{
                        tableLayout: 'fixed',
                        height: '100%',
                        width: '100%',
                      }}
                    >
                      <tbody>
                        <tr>
                          <td>{item}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </li>
              ))}
            </ul>
          </nav>
        </>
      </div>

      <InputBox
        visible={inputBoxVisible}
        type="okcancel"
        title={t('Edit Alias')}
        content={connection.alias}
        onOk={onInputBoxOk}
        onCancel={onInputBoxCancel}
      />
      <ConnectionInfo
        connection={connection}
        visible={infoVisible}
        title={t('Information')}
        onCancel={onInfoCancel}
      />
      <MessageBox
        visible={messageVisible}
        type="okcancel"
        title={messageTitle}
        content={messageContent}
        onOk={onMessageBoxOk}
        onCancel={onMessageBoxCancel}
      />
      <ConfirmBox
        visible={confirmBoxVisible}
        content={confirmBoxContent}
        onOk={onConfirmBoxOk}
      />
    </MainBlock>
  );
};

export default ConnectionItem;
