import React, { useEffect, useState, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import SVG from '../common/svg';

const SliderContainer = styled.div`
  user-select: none;
  .slider {
    position: absolute;
    width: 0px;
    height: 0px;
    margin-top: 16px;
    margin-left: 16px;
  }

  .hide {
    display: none;
  }

  /* .slider-container {
        position: absolute;
        right: 0;
        top: 15px;
        left: 20px;
        height: 0px;
        width: 0px;
        background-color: red;
    } */
  .upper1,
  .upper2,
  .upper3 {
    position: relative;
  }
  .upper-left {
    position: absolute;
    top: 0px;
    width: 56px;
    height: 56px;
    background-color: transparent;
    cursor: pointer;
  }
  .upper-right1,
  .upper-right2,
  .upper-right3 {
    position: absolute;
    top: 10px;
    left: 60px;
    width: 104px;
    height: 56px;
    opacity: 1;
    padding-top: 2px;
  }

  .lower1,
  .lower2,
  .lower3 {
    position: relative;
    /* display: none; */
  }
  .lower-left {
    position: absolute;
    /* top: -2px; */
    width: 56px;
    height: 56px;
    background-color: transparent;
    cursor: pointer;
  }
  .lower-right1,
  .lower-right2,
  .lower-right3 {
    position: absolute;
    top: 10px;
    left: 60px;
    width: 104px;
    height: 56px;
    opacity: 1;
    padding-top: 2px;
  }

  .btn-toggle {
    outline: 0;
    position: absolute;
    /* top: 127px; */
    background-color: transparent;
    border: none;
    width: 56px;
    height: 56px;
    padding: 0;
    cursor: pointer;
    focus: disabled;
    margin: 56px 0 0 0;
  }

  /* Range Slider */
  input[type='range'] {
    box-sizing: border-box;
    /* font-size: 18px; */
    /* line-height: 1; */
    height: 26px;
    background-color: transparent;
    cursor: pointer;
    -webkit-appearance: none;
    width: 100%;
  }
  input[type='range']:focus {
    outline: none;
  }
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    margin-top: -10px;
    background-color: rgba(113, 120, 128, 1);
    border-radius: 50%;
    /* border: 2px solid rgba(48, 57, 69, 0.15); */
    border: 1px solid rgba(113, 120, 128, 1);
    /* cursor: pointer; */
  }
  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    /* cursor: pointer; */
    height: 2px;
    border-top: 2px solid rgba(48, 57, 69, 0.15);
    border-bottom: 2px solid rgba(48, 57, 69, 0.15);
    border-radius: 0.25em;
    background-color: transparent;
  }
  input[type='range']::-moz-range-thumb {
    -moz-appearance: none;
    width: 18px;
    height: 18px;
    margin-top: -10px;
    background-color: #717880;
    border-radius: 50%;
    /* border: 2px solid rgba(48, 57, 69, 0.15); */
    border: 0px solid #717880;
    cursor: pointer;
  }
  input[type='range']::-moz-range-track {
    width: 100%;
    cursor: pointer;
    height: 2px;
    border-top: 1px solid rgba(48, 57, 69, 0);
    border-bottom: 1px solid rgba(48, 57, 69, 0);
    border-radius: 0.25em;
    background-color: rgba(48, 57, 69, 0.15);
  }

  /* Common
  font-size: ${(props) => props.fontSize || '16px'}; */
`;

function SliderBox({
  arrObj,
  marginObj,
  loading_order,
  loading_order_margin,
  transControl,
  fileType,
  scene,
  ambi_light,
  light,
  geoLen,
  colors,
  temp_colors,
}) {
  const themeContext = useContext(ThemeContext);
  const [isJawClicked1, setIsJawClicked1] = useState(true);
  const [isJawClicked2, setIsJawClicked2] = useState(true);
  const [isJawClicked3, setIsJawClicked3] = useState(true);
  const [isJawClicked4, setIsJawClicked4] = useState(true);
  const [isJawClicked5, setIsJawClicked5] = useState(true);
  const [isJawClicked6, setIsJawClicked6] = useState(true);
  const [isSolidClicked, setIsSolidClicked] = useState(false);
  const [sliderValue1, setSliderValue1] = useState(100);
  const [sliderValue2, setSliderValue2] = useState(100);
  const [sliderValue3, setSliderValue3] = useState(100);
  const [sliderValue4, setSliderValue4] = useState(100);
  const [sliderValue5, setSliderValue5] = useState(100);
  const [sliderValue6, setSliderValue6] = useState(100);

  useEffect(() => {
    document.getElementsByClassName('upper1')[0].style.display = 'none';
    document.getElementsByClassName('upper2')[0].style.display = 'none';
    document.getElementsByClassName('upper3')[0].style.display = 'none';
    document.getElementsByClassName('lower1')[0].style.display = 'none';
    document.getElementsByClassName('lower2')[0].style.display = 'none';
    document.getElementsByClassName('lower3')[0].style.display = 'none';
    if (fileType === 'stl') document.getElementsByClassName('btn-toggle')[0].style.display = 'none';

    let margin = 0;
    let loop1 = true,
      loop2 = true,
      loop3 = true,
      loop4 = true,
      loop5 = true,
      loop6 = true;
    let upper1,
      upper2 = false,
      upper3 = false,
      lower1 = false,
      lower2 = false,
      lower3 = false;

    loading_order.forEach((name) => {
      if (name.search('preop') > 0) {
        //Pre-Op
        if (name.search('mandibular') > 0 || name.search('lowerjaw') > 0) {
          document.getElementsByClassName('lower1')[0].style.display = 'block';
          lower1 = true;
        } else if (name.search('maxillary') > 0 || name.search('upperjaw') > 0) {
          document.getElementsByClassName('upper1')[0].style.display = 'block';
          upper1 = true;
        }
      } else if (name.search('scanbody') > 0 || name.search('marker') > 0) {
        //scanbody
        if (name.search('mandibular') > 0 || name.search('lowerjaw') > 0) {
          document.getElementsByClassName('lower3')[0].style.display = 'block';
          lower3 = true;
        } else if (name.search('maxillary') > 0 || name.search('upperjaw') > 0) {
          document.getElementsByClassName('upper3')[0].style.display = 'block';
          upper3 = true;
        }
      } else {
        //nomal
        if (name.search('mandibular') > 0 || name.search('lowerjaw') > 0) {
          document.getElementsByClassName('lower2')[0].style.display = 'block';
          lower2 = true;
        } else if (name.search('maxillary') > 0 || name.search('upperjaw') > 0) {
          document.getElementsByClassName('upper2')[0].style.display = 'block';
          upper2 = true;
        }
      }
    });

    //Check Buttons UI
    for (var i in loading_order) {
      if (upper1 && loop1) {
        document.getElementsByClassName('upper1')[0].style.margin = margin + 'px 0px 0px 0px';
        margin += 56;
        loop1 = false;
      } else if (upper2 && loop2) {
        document.getElementsByClassName('upper2')[0].style.margin = margin + 'px 0px 0px 0px';
        margin += 56;
        loop2 = false;
      } else if (upper3 && loop3) {
        document.getElementsByClassName('upper3')[0].style.margin = margin + 'px 0px 0px 0px';
        margin += 56;
        loop3 = false;
      } else if (lower1 && loop4) {
        document.getElementsByClassName('lower1')[0].style.margin = margin + 'px 0px 0px 0px';
        margin += 56;
        loop4 = false;
      } else if (lower2 && loop5) {
        document.getElementsByClassName('lower2')[0].style.margin = margin + 'px 0px 0px 0px';
        margin += 56;
        loop5 = false;
      } else if (lower3 && loop6) {
        document.getElementsByClassName('lower3')[0].style.margin = margin + 'px 0px 0px 0px';
        loop6 = false;
        margin += 56;
        break;
      }
    }
  }, []);

  const UpperJaw1 = (sumMESH) => {
    setIsJawClicked1(!isJawClicked1);
    for (var i = 0; i < loading_order.length; i++) {
      if (
        loading_order[i].search('maxillary-preop') > 0 ||
        loading_order[i].search('upperjaw-preop') > 0
      ) {
        sumMESH[i].visible = !sumMESH[i].visible;
        if (!sumMESH[i].visible) {
          document.getElementsByClassName('slider1')[0].disabled = true;
          document.getElementsByClassName('slider1')[0].style.cursor = 'default';
          document.getElementsByClassName('upper-right3')[0].style.opacity = '0.45';
          setSliderValue1(0);
          setTransparent(arrObj[i], 0);
        } else {
          document.getElementsByClassName('slider1')[0].disabled = false;
          document.getElementsByClassName('slider1')[0].style.cursor = 'pointer';
          document.getElementsByClassName('upper-right3')[0].style.opacity = '1';
          setSliderValue1(100);
          setTransparent(arrObj[i], 100);
        }
      }
    }
  };
  const UpperJaw2 = (sumMESH) => {
    setIsJawClicked2(!isJawClicked2);

    // show/hide margin line
    for (var index = 0; index < loading_order_margin.length; index++) {
      var fileName = loading_order_margin[index];
      var filenameRegex = new RegExp('(.?[^?])-margin', 'gm');
      var matches = filenameRegex.exec(fileName); //ex) result: 17-margin
      if (matches != null && matches[0]) {
        var margin_number = matches[0].split(/-/); //result: [17, margin]
      }
      if (
        (Number(margin_number[0]) >= 11 && Number(margin_number[0]) <= 18) ||
        (Number(margin_number[0]) >= 21 && Number(margin_number[0]) <= 28)
      ) {
        marginObj[index].visible = !marginObj[index].visible;
      }
    }

    for (var i = 0; i < loading_order.length; i++) {
      //mainscan
      if (
        loading_order[i].search('marker') < 0 &&
        loading_order[i].search('scanbody') < 0 &&
        loading_order[i].search('preop') < 0
      ) {
        if (loading_order[i].search('maxillary') > 0 || loading_order[i].search('upperjaw') > 0) {
          sumMESH[i].visible = !sumMESH[i].visible;
          if (!sumMESH[i].visible) {
            document.getElementsByClassName('slider2')[0].disabled = true;
            document.getElementsByClassName('slider2')[0].style.cursor = 'default';
            document.getElementsByClassName('upper-right1')[0].style.opacity = '0.45';
            setSliderValue2(0);
            setTransparent(arrObj[i], 0);
          } else {
            document.getElementsByClassName('slider2')[0].disabled = false;
            document.getElementsByClassName('slider2')[0].style.cursor = 'pointer';
            document.getElementsByClassName('upper-right1')[0].style.opacity = '1';
            setSliderValue2(100);
            setTransparent(arrObj[i], 100);
          }
        }
      }
    }
  };
  const UpperJaw3 = (sumMESH) => {
    setIsJawClicked3(!isJawClicked3);
    for (var i = 0; i < loading_order.length; i++) {
      if (loading_order[i].search('marker') > 0 || loading_order[i].search('scanbody') > 0) {
        if (loading_order[i].search('maxillary') > 0 || loading_order[i].search('upperjaw') > 0) {
          sumMESH[i].visible = !sumMESH[i].visible;
          if (!sumMESH[i].visible) {
            document.getElementsByClassName('slider3')[0].disabled = true;
            document.getElementsByClassName('slider3')[0].style.cursor = 'default';
            document.getElementsByClassName('upper-right2')[0].style.opacity = '0.45';
            setSliderValue3(0);
            setTransparent(arrObj[i], 0);
          } else {
            document.getElementsByClassName('slider3')[0].disabled = false;
            document.getElementsByClassName('slider3')[0].style.cursor = 'pointer';
            document.getElementsByClassName('upper-right2')[0].style.opacity = '1';
            setSliderValue3(100);
            setTransparent(arrObj[i], 100);
          }
        }
      }
    }
  };

  const LowerJaw1 = (sumMESH) => {
    setIsJawClicked4(!isJawClicked4);
    for (var i = 0; i < loading_order.length; i++) {
      if (
        loading_order[i].search('mandibular-preop') > 0 ||
        loading_order[i].search('lowerjaw-preop') > 0
      ) {
        sumMESH[i].visible = !sumMESH[i].visible;
        if (!sumMESH[i].visible) {
          document.getElementsByClassName('slider4')[0].disabled = true;
          document.getElementsByClassName('slider4')[0].style.cursor = 'default';
          document.getElementsByClassName('lower-right3')[0].style.opacity = '0.45';
          setSliderValue4(0);
          setTransparent(arrObj[i], 0);
        } else {
          document.getElementsByClassName('slider4')[0].disabled = false;
          document.getElementsByClassName('slider4')[0].style.cursor = 'pointer';
          document.getElementsByClassName('lower-right3')[0].style.opacity = '1';
          setSliderValue4(100);
          setTransparent(arrObj[i], 100);
        }
      }
    }
  };
  const LowerJaw2 = (sumMESH) => {
    setIsJawClicked5(!isJawClicked5);

    // show/hide margin line
    for (var index = 0; index < loading_order_margin.length; index++) {
      var fileName = loading_order_margin[index];
      var filenameRegex = new RegExp('(.?[^?])-margin', 'gm');
      var matches = filenameRegex.exec(fileName); //ex) result: 17-margin
      if (matches != null && matches[0]) {
        var margin_number = matches[0].split(/-/); //result: [17, margin]
      }
      if (
        (Number(margin_number[0]) >= 31 && Number(margin_number[0]) <= 38) ||
        (Number(margin_number[0]) >= 41 && Number(margin_number[0]) <= 48)
      ) {
        marginObj[index].visible = !marginObj[index].visible;
      }
    }

    for (var i = 0; i < loading_order.length; i++) {
      //mainscan
      if (
        loading_order[i].search('marker') < 0 &&
        loading_order[i].search('scanbody') < 0 &&
        loading_order[i].search('preop') < 0
      ) {
        if (loading_order[i].search('mandibular') > 0 || loading_order[i].search('lowerjaw') > 0) {
          sumMESH[i].visible = !sumMESH[i].visible;
          if (!sumMESH[i].visible) {
            document.getElementsByClassName('slider5')[0].disabled = true;
            document.getElementsByClassName('slider5')[0].style.cursor = 'default';
            document.getElementsByClassName('lower-right1')[0].style.opacity = '0.45';
            setSliderValue5(0);
            setTransparent(arrObj[i], 0);
          } else {
            document.getElementsByClassName('slider5')[0].disabled = false;
            document.getElementsByClassName('slider5')[0].style.cursor = 'pointer';
            document.getElementsByClassName('lower-right1')[0].style.opacity = '1';
            setSliderValue5(100);
            setTransparent(arrObj[i], 100);
          }
        }
      }
    }
  };
  const LowerJaw3 = (sumMESH) => {
    setIsJawClicked6(!isJawClicked6);
    for (var i = 0; i < loading_order.length; i++) {
      if (loading_order[i].search('marker') > 0 || loading_order[i].search('scanbody') > 0) {
        if (loading_order[i].search('mandibular') > 0 || loading_order[i].search('lowerjaw') > 0) {
          sumMESH[i].visible = !sumMESH[i].visible;
          if (!sumMESH[i].visible) {
            document.getElementsByClassName('slider6')[0].disabled = true;
            document.getElementsByClassName('slider6')[0].style.cursor = 'default';
            document.getElementsByClassName('lower-right2')[0].style.opacity = '0.45';
            setSliderValue6(0);
            setTransparent(arrObj[i], 0);
          } else {
            document.getElementsByClassName('slider6')[0].disabled = false;
            document.getElementsByClassName('slider6')[0].style.cursor = 'pointer';
            document.getElementsByClassName('lower-right2')[0].style.opacity = '1';
            setSliderValue6(100);
            setTransparent(arrObj[i], 100);
          }
        }
      }
    }
  };

  const onChange = (e) => {
    const targetClassName = e.target.className;
    const value = e.target.value;
    for (var i = 0; i < loading_order.length; i++) {
      if (targetClassName === 'slider1') {
        if (
          loading_order[i].search('maxillary-preop') > 0 ||
          loading_order[i].search('upperjaw-preop') > 0
        ) {
          setSliderValue1(value);
          setTransparent(arrObj[i], value);
        }
      } else if (targetClassName === 'slider2') {
        if (loading_order[i].search('marker') < 0 && loading_order[i].search('scanbody') < 0) {
          if (loading_order[i].search('maxillary') > 0 || loading_order[i].search('upperjaw') > 0) {
            setSliderValue2(value);
            setTransparent(arrObj[i], value);
          }
        }
      } else if (targetClassName === 'slider3') {
        if (
          loading_order[i].search('maxillary-scanbody') > 0 ||
          loading_order[i].search('upperjaw-marker') > 0
        ) {
          setSliderValue3(value);
          setTransparent(arrObj[i], value);
        }
      } else if (targetClassName === 'slider4') {
        if (
          loading_order[i].search('mandibular-preop') > 0 ||
          loading_order[i].search('lowerjaw-preop') > 0
        ) {
          setSliderValue4(value);
          setTransparent(arrObj[i], value);
        }
      } else if (targetClassName === 'slider5') {
        if (loading_order[i].search('marker') < 0 && loading_order[i].search('scanbody') < 0) {
          if (
            loading_order[i].search('mandibular') > 0 ||
            loading_order[i].search('lowerjaw') > 0
          ) {
            setSliderValue5(value);
            setTransparent(arrObj[i], value);
          }
        }
      } else if (targetClassName === 'slider6') {
        if (
          loading_order[i].search('mandibular-scanbody') > 0 ||
          loading_order[i].search('mandibular-marker') > 0
        ) {
          setSliderValue6(value);
          setTransparent(arrObj[i], value);
        }
      }
    }
  };

  const setTransparent = (mesh, value) => {
    // console.log(mesh);
    mesh.material.transparent = true;
    mesh.material.opacity = value / 100;

    // mesh.renderOrder = 2;
    mesh.material.depthWrite = false;

    if (value / 100 === 1) {
      //opacity
      mesh.material.transparent = false;
      mesh.material.depthWrite = true;
    }
    // mesh.onBeforeRender = function(renderer){renderer.clearDepth();};
  };

  const slideIn = () => {
    transControl.enabled = false;
    // transControl.slider_flag = true;
  };
  const slideOut = () => {
    transControl.enabled = true;
    // transControl.slider_flag = false;
  };

  const Solid = (sumMESH, temp_colors, geoLen, colors) => {
    setIsSolidClicked(!isSolidClicked);

    if (fileType === 'ply' || fileType === 'obj') {
      if (!isSolidClicked) {
        //Solid
        ambi_light.color.setHex(0xeda942);
        for (var i = 0; i < arrObj.length; i++) {
          for (var j = 0; j < geoLen[i]; j++) {
            colors[i][j] = 255 / 255.0;
            colors[i][j + 1] = 255 / 255.0;
            colors[i][j + 2] = 255 / 255.0;
          }
        }

        for (var i = 0; i < arrObj.length; i++) {
          sumMESH[i].geometry.attributes.color.needsUpdate = true;
        }
      } else {
        //VertexColors
        ambi_light.color.setHex(0xffffff);
        for (var i = 0; i < arrObj.length; i++) {
          for (var j = 0; j < geoLen[i]; j++) {
            colors[i][j] = temp_colors[i][j];
            colors[i][j + 1] = temp_colors[i][j + 1];
            colors[i][j + 2] = temp_colors[i][j + 2];
          }
        }

        for (var i = 0; i < arrObj.length; i++) {
          sumMESH[i].geometry.attributes.color.needsUpdate = true;
        }
      }
    }
  };

  return (
    <SliderContainer>
      <div class="slider hide">
        {/* Pre-Op */}
        <div class="upper1">
          <div class="upper-left" onClick={() => UpperJaw1(arrObj)}>
            <SVG
              name="UpperPreop"
              color={isJawClicked1 ? themeContext.main_color : '#303945'}
              opacity={isJawClicked1 ? '1' : '0.66'}
            />
          </div>
          <div class="upper-right3">
            <input
              type="range"
              min="0"
              max="100"
              value={sliderValue1}
              onChange={onChange}
              class="slider1"
              onMouseOver={slideIn}
              onMouseOut={slideOut}
            />
          </div>
        </div>

        <div class="upper2">
          <div class="upper-left" onClick={() => UpperJaw2(arrObj)}>
            <SVG
              name="Upper"
              color={isJawClicked2 ? themeContext.main_color : '#303945'}
              opacity={isJawClicked2 ? '1' : '0.66'}
            />
          </div>
          <div class="upper-right1">
            <input
              type="range"
              min="0"
              max="100"
              value={sliderValue2}
              onChange={onChange}
              class="slider2"
              onMouseOver={slideIn}
              onMouseOut={slideOut}
            />
          </div>
        </div>

        <div class="upper3">
          <div class="upper-left" onClick={() => UpperJaw3(arrObj)}>
            <SVG
              name="UpperBody"
              color={isJawClicked3 ? themeContext.main_color : '#303945'}
              opacity={isJawClicked3 ? '1' : '0.66'}
            />
          </div>
          <div class="upper-right2">
            <input
              type="range"
              min="0"
              max="100"
              value={sliderValue3}
              onChange={onChange}
              class="slider3"
              onMouseOver={slideIn}
              onMouseOut={slideOut}
            />
          </div>
        </div>

        <div class="lower1">
          <div class="lower-left" onClick={() => LowerJaw1(arrObj)}>
            <SVG
              name="LowerPreop"
              color={isJawClicked4 ? themeContext.main_color : '#303945'}
              opacity={isJawClicked4 ? '1' : '0.66'}
            />
          </div>
          <div class="lower-right3">
            <input
              type="range"
              min="0"
              max="100"
              value={sliderValue4}
              onChange={onChange}
              class="slider4"
              onMouseOver={slideIn}
              onMouseOut={slideOut}
            />
          </div>
        </div>

        <div class="lower2">
          <div class="lower-left" onClick={() => LowerJaw2(arrObj)}>
            <SVG
              name="Lower"
              color={isJawClicked5 ? themeContext.main_color : '#303945'}
              opacity={isJawClicked5 ? '1' : '0.66'}
            />
          </div>
          <div class="lower-right1">
            <input
              type="range"
              min="0"
              max="100"
              value={sliderValue5}
              onChange={onChange}
              class="slider5"
              onMouseOver={slideIn}
              onMouseOut={slideOut}
            />
          </div>
        </div>

        <div class="lower3">
          <div class="lower-left" onClick={() => LowerJaw3(arrObj)}>
            <SVG
              name="LowerBody"
              color={isJawClicked6 ? themeContext.main_color : '#303945'}
              opacity={isJawClicked6 ? '1' : '0.66'}
            />
          </div>
          <div class="lower-right2">
            <input
              type="range"
              min="0"
              max="100"
              value={sliderValue6}
              onChange={onChange}
              class="slider6"
              onMouseOver={slideIn}
              onMouseOut={slideOut}
            />
          </div>
        </div>

        <button
          type="button"
          aria-pressed="true"
          class="btn-toggle"
          onClick={() => Solid(arrObj, temp_colors, geoLen, colors)}
        >
          <SVG
            name="Solid"
            color={isSolidClicked ? themeContext.main_color : '#303945'}
            opacity={isSolidClicked ? '1' : '0.66'}
          />
        </button>
      </div>
    </SliderContainer>
  );
}

export default React.memo(SliderBox);
