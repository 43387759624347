import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../lib/createRequestSaga';
import * as devicesAPI from '../lib/api/devices';
import { takeLatest } from '@redux-saga/core/effects';

const [LIST_DEVICES, LIST_DEVICES_SUCCESS, LIST_DEVICES_FAILURE] =
  createRequestActionTypes('devices/LIST_DEVICES');

export const listDevices = createAction(LIST_DEVICES, ({ filters, page }) => ({
  filters,
  page,
}));

const listDevicesSaga = createRequestSaga(LIST_DEVICES, devicesAPI.listDevices);

export function* devicesSaga() {
  yield takeLatest(LIST_DEVICES, listDevicesSaga);
}

const initialState = {
  devices: null,
  error: null,
};

const devices = handleActions(
  {
    [LIST_DEVICES_SUCCESS]: (state, { payload: devices }) => ({
      ...state,
      devices,
      error: null,
    }),
    [LIST_DEVICES_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
  },
  initialState,
);

export default devices;
