import React, { useState } from 'react';
import styled from 'styled-components';
import DropdownList from '../auth/styled/DropdownList';
import { useTranslation } from 'react-i18next';

const MainBlock = styled.div`
  .whiteBox {
    display: flex;
    width: auto;
    height: 56px;
    margin: 0 40px 0px 40px;
    outline: none;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 0 4px 0 #f2f3f3;
  }
  &:not(:first-child) {
    margin-top: 8px;
  }

  .text {
    display: flex;
    width: 100%;
    flex-basis: 1;
    height: 19px;
    margin: 19px 60px 18px 20px;

    font-family: 'Source Sans Pro';
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
    /* background: blue; */
  }

  .checkbox {
    display: flex;
    width: 100%;
    flex-basis: 24px;
    margin: 16px 20px;
    align-items: center; //vertical center
    /* background: red; */
  }

  input[type='checkbox'] {
    background-image: url('${process.env
      .PUBLIC_URL}/images/settings/icon-24-checkbox-active.svg');
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 24px;
    height: 24px;
    margin: 0;
    outline: none;

    &:checked {
      background-image: url('${process.env.PUBLIC_URL}/${(props) =>
        props.theme.images}/settings/icon-24-checkbox-selected.svg');
    }
  }
`;

const SettingItem = ({
  name,
  text,
  checked,
  onToggle,
  isDropdownList,
  onSelect,
  defaultValue,
}) => {
  const [bChecked, setChecked] = useState(checked);
  const { t } = useTranslation();

  return (
    <MainBlock>
      <div className="whiteBox">
        <div className="text">{text}</div>
        {isDropdownList && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '20px',
            }}
          >
            <DropdownList
              width="138px"
              height="40px"
              defaultValue={
                defaultValue === 'perspective' ? t('Perspective') : t('Parallel')
              }
              items={{ perspective: t('Perspective'), parallel: t('Parallel') }}
              onSelect={onSelect}
            />
          </div>
        )}
        {!isDropdownList && (
          <div className="checkbox">
            <input
              type="checkbox"
              name={name}
              checked={bChecked}
              onChange={(e) => {
                setChecked(!bChecked);
                onToggle(e.target.name);
              }}
            />
          </div>
        )}
      </div>
    </MainBlock>
  );
};

export const SettingItemSelect = ({ name, text, value, onSelect }) => {
  const [selected, setSelected] = useState(value);
  const items = ['US', 'EU'];

  return (
    <MainBlock>
      <div className="whiteBox">
        <div className="text">{text}</div>
        <div className="checkbox">
          <select
            name={name}
            value={selected}
            onChange={(e) => {
              setSelected(e.target.value);
              onSelect(name, e.target.value);
            }}
          >
            {items.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select>
        </div>
      </div>
    </MainBlock>
  );
};

export const SettingItemText = ({ name, text, value }) => {
  return (
    <MainBlock>
      <div className="whiteBox">
        <div className="text">{text}</div>
        <div className="checkbox">{value}</div>
      </div>
    </MainBlock>
  );
};

export default SettingItem;
