import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import HButton from '../common/HButton';
import { initializeForm } from '../../modules/case';
import BasicScene from './CaseDetail3DScene';
import { useTranslation } from 'react-i18next';
import Spinner from '../common/Spinner';
import { getFileUri, download3DFile } from '../../lib/api/case';
import { TabletOrDesktop } from '../../lib/responsive';
// dynamic import
// const BasicScene = React.lazy(() => import('./CaseDetail3DScene'));

const CaseDetailSelectBox = styled.div`
  width: 100%;
  height: 72px; /* margin 16px */
  background: transparent;
  /* margin-top: 25px; */
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;
const CaseDetail2DPreviewBox = styled.div`
  background: transparent;
  display: flex;
  justify-content: center;

  .paintBackground {
    width: 100%;
    min-width: 380px;
    height: 519px;
    margin: 0 20px 0 20px;
    background-color: #ebeced;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }

  .image2D {
    object-fit: cover;
  }

  .loading {
    width: 100%;
    height: 150px;
    margin-top: 100px;
    text-align: center;
  }
`;

//3D Container Box
const CaseDetail3DBox = styled.div`
  display: flex;
  justify-content: center;
  width: auto;
  /* min-width: 694px; */
  height: 519px;
  margin: 0 20px 0 20px;
  background: transparent;
  overflow: hidden;

  .FullScreen {
    position: fixed;
    z-index: 30;
    top: ${(props) => (props.isTabletOrDesktop ? '0' : '55px')};
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.25); */
    background: #f0f1f2;
  }

  .cornerRound {
    border-radius: 4px;
  }
`;

const LoadingBox = styled.div`
  /* position: absolute; */
  display: flex;
  width: 100%;
  height: 519px;
  /* margin: 0 20px 0 20px; */
  background: transparent;
  justify-content: center;
  align-items: center;
  background-color: #ebeced;
  border-radius: 4px;

  #loading-bar {
    background-color: #d3d3d3;
    width: 60.792%;
    height: 0.25em;
    border-radius: 0.25em;
    display: inline-flex;
  }

  #progress {
    background-color: #787f86;
    height: inherit;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;

    width: 0%;
  }
`;

const CaseDetail3D = () => {
  const dispatch = useDispatch();
  const { files } = useSelector(({ caseDetail }) => ({
    files: caseDetail.files,
    error: caseDetail.error,
  }));

  const { t } = useTranslation();
  const [fileCount, setFileCount] = useState(0);
  const [show2D, setShow2D] = useState(true);
  const [show3D, setShow3D] = useState(false);
  const [is3DFile, setIs3DFile] = useState(false);
  const [fileType, setFileType] = useState(null);
  const img2D = useRef(null);
  const [file2D, setFile2D] = useState(null);
  const [file3D, setFile3D] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [loading3D, setLoading3D] = useState(null);
  const isTabletOrDesktop = TabletOrDesktop();
  // const [uris, setUris] = useState(null);

  useEffect(() => {
    dispatch(initializeForm('file3D'));
    dispatch(initializeForm('file3DName'));
    setShow2D(true);
    setShow3D(false);
    setIs3DFile(false);
  }, []);

  useEffect(() => {
    if (show2D && file2D != null && img2D !== null) {
      img2D.current.src = file2D;
    }
  }, [file2D]);

  // Count 3D file number
  useEffect(() => {
    let count3D = 0;

    files &&
      files.files.forEach((file) => {
        if (
          file.filename.search('.stl') > 0 ||
          file.filename.search('.ply') > 0 ||
          file.filename.search('.obj') > 0
        ) {
          if (file.filename.search('bite') > 0) return;
          if (file.filename.search('-') < 0) return;
          count3D++;
        }
      });

    if (count3D === 0) {
      setIs3DFile(false);
      setShow3D(false);
    } else {
      setIs3DFile(true);
    }
  }, [files]);

  //Get the most recently uploaded file type.
  useEffect(() => {
    let filetype = { stl: 0, ply: 0, obj: 0, margin: 0 };
    let filedate = { name: null, date: null };

    files &&
      files.files.forEach((file) => {
        if (
          file.filename.search('.stl') > 0 ||
          file.filename.search('.ply') > 0 ||
          file.filename.search('.obj') > 0
        ) {
          // If I hadn't found it, return -1
          if (file.filename.search('bite') > 0) return;

          if (file.filename.search('-') < 0) return;

          // sort by file updated_date
          if (filedate.date === null) {
            filedate.name = file.filename;
            filedate.date = new Date(file.updated_date);
          }
          if (filedate.date < file.updated_date) {
            filedate.name = file.filename;
            filedate.date = new Date(file.updated_date);
          }

          //
          if (file.filename.search('.stl') > 0) {
            filetype.stl = filetype.stl + 1;
          } else if (file.filename.search('.ply') > 0) {
            filetype.ply = filetype.ply + 1;
          } else if (file.filename.search('.obj') > 0) {
            filetype.obj = filetype.obj + 1;
          }
        }
      });

    files &&
      files.files.forEach((file) => {
        if (file.filename.search('margin') > 0) {
          if (file.filename.search('margin') > 0) {
            filetype.margin = filetype.margin + 1;
          }
        }
      });

    if (is3DFile && filedate.name != null) {
      if (filedate.name.search('.stl') > 0) {
        setFileType('stl');
        setFileCount(filetype.stl + filetype.margin);
      } else if (filedate.name.search('.ply') > 0) {
        setFileType('ply');
        setFileCount(filetype.ply + filetype.margin);
      } else if (filedate.name.search('.obj') > 0) {
        setFileType('obj');
        setFileCount(filetype.obj + filetype.margin);
      }
    }
  }, [files, is3DFile]);

  //Download 2D file from s3
  useEffect(() => {
    let count2D = 0;
    if (show3D === false) {
      files &&
        files.files.forEach((file) => {
          if (file.filename === 'Preview3D.png') {
            setFile2D(file.url);
            count2D++;
          }
        });
    }
    if (count2D) setShow2D(true);
    else setShow2D(false);
  }, [files, show3D]);

  //Download 3D file from Django server
  const on3DDownload = async ({ id, count }, e) => {
    try {
      await download3DFile(id, count).then((res) => {
        let filename = '';
        if (res) {
          const url = res.headers['content-disposition'];
          if (url) {
            var filenameRegex = new RegExp("''(.?[^?]*)", 'gm');
            var matches = filenameRegex.exec(url);
            if (matches != null && matches[1]) {
              filename = matches[1];
            }
            // console.log(filename);
          }

          setFileName(filename);
          setFile3D(res.data);
        }
      });
    } catch (e) {
      console.log(e);
    }
    setLoading3D(false);
  };

  useEffect(() => {
    // const ids = [];
    if (show3D === true) {
      setLoading3D(true);
      files &&
        files.files.forEach((file) => {
          if (
            file.filename.search(fileType) > 0 ||
            file.filename.search('margin') > 0
          ) {
            if (file.filename.search('bite') > 0) return;
            if (file.filename.search('-') < 0) return;
            // ids.push(file.id);
            on3DDownload({ id: file.id, count: fileCount });
          }
        });
      // get3dUris(ids);
    }
  }, [files, show3D]);

  // useEffect(() => {
  //   if (uris) {
  //     for (const [key, value] of Object.entries(uris)) {
  //       // console.log(`${key}: ${value}`);
  //       on3DDownload(value, fileCount);
  //     }
  //   }
  // }, [uris]);

  // const get3dUris = async (ids) => {
  //   try {
  //     setLoading3D(true);
  //     let res = await getFileUri({ id: ids });

  //     if (res.statusText !== 'OK') {
  //       throw new Error(`HTTP error! status: ${res.status}`);
  //     } else {
  //       setUris(await res.data);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const onClick3D = () => {
    setShow2D(false);
    setShow3D(true);
  };

  if (!show2D && !is3DFile) return null;

  return (
    <div style={{ marginTop: '25px' }}>
      {/* {!file2D && (
        <CaseDetail2DPreviewBox>
          <div className={'loading'}>
            <Spinner />
          </div>
        </CaseDetail2DPreviewBox>
      )} */}
      {/* {isTabletOrDesktop && show2D && file2D && (
        <CaseDetail2DPreviewBox>
          <div className="paintBackground">
            <img className="image2D" height="100%" ref={img2D} alt="2D" />
          </div>
        </CaseDetail2DPreviewBox>
      )} */}

      {show2D && file2D && (
        <div
          style={{ background: '#f0f1f2', height: '519px', margin: '0 20px 0 20px' }}
        >
          {/* <div style={{ padding: '0 20px 0 20px' }}> */}
          <picture>
            <img
              style={{
                display: 'block',
                maxWidth: '100%',
                height: '519px',
                objectFit: 'cover',
                margin: '0 auto',
              }}
              ref={img2D}
              alt="2D"
            />
          </picture>
          {/* </div> */}
        </div>
      )}

      {show3D && (
        <CaseDetail3DBox id="CaseDetail3DBox" isTabletOrDesktop={isTabletOrDesktop}>
          <div style={{ width: '100%' }} id="fullscreen">
            <LoadingBox id="loading-overlay">
              <div id="loading-bar">
                <span id="progress"></span>
              </div>
            </LoadingBox>

            {!loading3D && file3D && fileName && (
              <BasicScene
                fileBuffer={file3D}
                fileName={fileName}
                fileCount={fileCount}
                fileType={fileType}
              />
            )}
          </div>
        </CaseDetail3DBox>
      )}
      <CaseDetailSelectBox>
        {is3DFile && (
          <HButton
            id="show3D"
            category="Tertiary"
            size="Medium"
            text={t('Show 3D').toUpperCase()}
            width="128px"
            margin="0 0 0 16px"
            onClick={onClick3D}
            disabled={show3D}
          />
        )}
      </CaseDetailSelectBox>
    </div>
  );
};

export default React.memo(CaseDetail3D);
