import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CaseDetail from '../../components/case/CaseDetail';
import { caseContent, listFiles, listComments } from '../../modules/case';
// import { listCases } from '../../modules/cases';
import { useRefreshOnAuthError, useAutoRefreshEvent } from '../../lib/common';
import { getEventsToken } from '../../lib/api/auth';
import { useTranslation } from 'react-i18next';
import MessageBox from '../../components/common/MessageBox';
import { sleep } from '../../lib/util';
import { finishCursorLoading } from '../../modules/cursorLoading';

const CaseDetailContainer = () => {
  const { error, content, user, userSettings, loading } = useSelector(
    ({ caseDetail, user, loading, auth }) => ({
      error: caseDetail.error,
      content: caseDetail.content,
      user: user.user,
      userSettings: user.settings,
      loading: loading['case/CASE_DETAIL'],
    }),
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const event = useAutoRefreshEvent();

  useEffect(() => {
    if (event) {
      const msg = JSON.parse(event);
      if (!window.actionIDs.has(msg.action_id)) {
        const caseId = content.id;
        if (msg.id === 'post_modified' && caseId === msg.post_id) {
          dispatch(caseContent(msg.post_id));
        } else if (msg.id === 'post_uploaded' && caseId === msg.post_id) {
          dispatch(caseContent(msg.post_id));
          dispatch(listFiles(msg.post_id));
        } else if (
          (msg.id === 'post_status_modified' || msg.id === 'post_assignee_changed') &&
          caseId === msg.post_id
        ) {
          dispatch(caseContent(msg.post_id));
        } else if (
          (msg.id === 'files_uploaded' || msg.id === 'file_deleted') &&
          caseId === msg.post_id
        ) {
          dispatch(listFiles(msg.post_id));
          dispatch(caseContent(msg.post_id));
        } else if (
          (msg.id === 'comment_added' ||
            msg.id === 'comment_modified' ||
            msg.id === 'comment_deleted') &&
          caseId === msg.post_id
        ) {
          dispatch(listComments(msg.post_id));
          dispatch(caseContent(msg.post_id));
        }
      }
    }
  }, [event]);

  useRefreshOnAuthError();

  const downloadFilesAll = async (postId) => {
    if (content.usertype != 'OT' && content.from_locked_device == true) {
      setLockMessageTitle(t('Info'));
      setLockMessageContent(
        t('Download mesh data is not allowed for export management device'),
      );
      setLockMessageVisible(true);
      dispatch(finishCursorLoading('caseDownLoading')); // reset
      return;
    }

    try {
      // await getEventsToken({ callFrom: 'download' });
      const res = await getEventsToken({ callFrom: 'downloadAll', postId });
      // await sleep(1000);
      const uidb64 = user.idb64;
      // const uri = `/heclore/v1/cases/${postId}/files/download/${uidb64}/`;
      const uri = `/heclore/v1/cases/${postId}/files/download/${uidb64}/?filename=${res.data.filename}`;
      dispatch(finishCursorLoading('caseDownLoading')); // reset
      window.location.href = uri;
    } catch (e) {
      console.log(e);
    }
  };

  const [lockMessageTitle, setLockMessageTitle] = useState('');
  const [lockMessageContent, setLockMessageContent] = useState('');
  const [lockMessageVisible, setLockMessageVisible] = useState(false);

  const onLockMessageBoxOk = async () => {
    setLockMessageVisible(false);
  };

  return (
    <div>
      <CaseDetail error={error} loading={loading} downloadFilesAll={downloadFilesAll} />
      <MessageBox
        visible={lockMessageVisible}
        type="ok"
        title={lockMessageTitle}
        content={lockMessageContent}
        onOk={onLockMessageBoxOk}
      />
    </div>
  );
};

export default CaseDetailContainer;
