import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { keyframes, css } from 'styled-components';
import { changeField, initializeForm } from '../../modules/connections';
import { useTranslation } from 'react-i18next';
import SVG from '../common/svg';

const fadeIn = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1
  }
  to {
    opacity: 0
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0px);
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-100px);
  }
`;

const Fullscreen = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;

  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${fadeIn};
  animation-fill-mode: forwards;

  ${(props) =>
    props.disappear &&
    css`
      animation-name: ${fadeOut};
    `}
`;

const ModalBlock = styled.div`
  width: 696px;
  /* height: 340px; */
  background: white;
  border-radius: 4px;
  border: solid 1px #eeeff0;

  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${slideDown};
  animation-fill-mode: forwards;

  ${(props) =>
    props.disappear &&
    css`
      animation-name: ${slideUp};
    `}
`;

const TitleBlock = styled.div`
  .titleBox {
    width: 696px;
    height: 56px;
    border-radius: 4px 4px 0 0;
    margin: 0 0 0 0;
    background: #eeeff0;
  }

  .titleText {
    width: 400px;
    height: 25px;
    margin: 15px 0 0 19px;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
    float: left;
  }

  .closeIcon {
    width: 24px;
    height: 24px;
    margin: 16px 19px 0 0;
    float: right;
  }
`;

const InputBlock = styled.div`
  margin-top: 24px;
  margin-left: 28px;
  margin-right: 28px;
  margin-bottom: 24px;

  .grid-container {
    display: grid;
    grid-template-columns: minmax(140px, auto) minmax(0, 540px) 108px;
    grid-template-rows: repeat(6, 40px);
    gap: 8px;
  }

  //name title
  .item:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  //name text
  .item:nth-child(2) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  //picture
  .item:nth-child(3) {
    grid-column: 3 / 4;
    grid-row: 1 / 4;
  }

  //email title
  .item:nth-child(4) {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  //email text
  .item:nth-child(5) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  //country title
  .item:nth-child(6) {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  //country text
  .item:nth-child(7) {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
  }

  //institution title
  .item:nth-child(8) {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
  }

  //institution text
  .item:nth-child(9) {
    grid-column: 2 / 4;
    grid-row: 4 / 5;
  }

  //phone title
  .item:nth-child(10) {
    grid-column: 1 / 2;
    grid-row: 5 / 6;
  }

  //phone text
  .item:nth-child(11) {
    grid-column: 2 / 4;
    grid-row: 5 / 6;
  }

  //address title
  .item:nth-child(12) {
    grid-column: 1 / 2;
    grid-row: 6 / 7;
  }

  //addres text
  .item:nth-child(13) {
    grid-column: 2 / 4;
    grid-row: 6 / 7;
  }

  .item {
    display: flex;
    align-items: center;
    color: #59616a;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }

  .title {
    color: #a5a9ae;
  }

  .image {
    align-items: flex-start;
  }

  .right-align {
    justify-content: flex-end;
  }
`;

const StyledLogo = styled.img`
  object-fit: contain;
  max-width: 108px;
  /* height: 40px; */
`;

const ConnectionInfo = ({ connection, visible, title, onCancel }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [animate, setAnimate] = useState(false);
  const [localVisible, setLocalVisible] = useState(visible);

  const { odm, app } = useSelector(({ app }) => ({
    odm: app.odm,
    app: app.app,
  }));

  useEffect(() => {
    if (localVisible && !visible) {
      setAnimate(true);
      setTimeout(() => setAnimate(false), 250);
    }
    setLocalVisible(visible);
  }, [localVisible, visible]);

  if (!animate && !localVisible) return null;

  return (
    <Fullscreen disappear={!visible}>
      <ModalBlock disappear={!visible}>
        <TitleBlock>
          <div className="titleBox">
            <div class="titleText">{title}</div>
            <div className={'closeIcon'} onClick={onCancel}>
              <SVG
                name="close"
                color="#878D93"
                colorOver={odm.main_color_hover}
                colorOut="#878D93"
                colorDown={odm.main_color}
              />
            </div>
          </div>
        </TitleBlock>

        <InputBlock>
          <div className="grid-container">
            <span className="item title right-align">{t('Name') + ' :'}</span>
            <span className="item">{connection.name}</span>

            <div className="item image">
              {connection.picture && (
                <StyledLogo alt="Logo" src={connection.picture}></StyledLogo>
              )}
            </div>

            <span className="item title right-align">{t('Email Address') + ' :'}</span>
            <span className="item">{connection.email}</span>

            <span className="item title right-align">{t('Country') + ' :'}</span>
            <span className="item">{connection.country}</span>

            <span className="item title right-align">
              {t('Institution Name') + ' :'}
            </span>
            <span className="item">{connection.institution}</span>

            <span className="item title right-align">{t('Phone') + ' :'}</span>
            <span className="item">{connection.phone}</span>

            <span className="item title right-align">{t('Address') + ' :'}</span>
            <span className="item">{connection.address}</span>
          </div>
        </InputBlock>
      </ModalBlock>
    </Fullscreen>
  );
};

export default ConnectionInfo;
