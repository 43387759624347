import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled, { keyframes, css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import CalendarTextBox from './CalendarTextBox';

const TextBlock = styled.div`
  width: 36px;
  height: 36px;
  float: left;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.2px;
  text-align: center;
  color: #a5a9ae;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  margin-left: 4px;
  border-radius: 20px;
  cursor: pointer;
`;

const dwName = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

const CalendarDateView = ({date, selectedDate, onSelectedDate}) => {
  const {t} = useTranslation();

  var monDay = [ 31,28,31,30,31,30,31,31,30,31,30,31 ];

  const totalDays = 42;
  const [dateInfo, setDateInfo] = useState([]);
  const [today, setToday] = useState(new Date());
  const [selectedInfo, setSelectedInfo] = useState([]);
  const [lastDate, setLastDate] = useState(null);
  const [lastMenu, setLastMenu] = useState("");

  //#region calculate day info and styles
  

  function dayOfWeek(day)
  {
    if ((date.getFullYear() % 4 === 0 && date.getFullYear() % 100 != 0) || (date.getFullYear() % 400 == 0))
    {
      monDay[1] = 29;
    }

    var total_day = 0;

    for (var i=1; i<date.getMonth(); i++)
    {
      total_day += monDay[i-1];
    }
    total_day += day - 1;
    return (total_day) % 7;
  }
  
  function calDays()
  {    
    var dInfo = [];
    var firstMonth = new Date(date);
    firstMonth.setDate(1);

    var dow = firstMonth.getDay() - 1;
    if (dow < 0) dow = 6;
    var index = 0;

    for (var i = dow; i > 0; i--)
    {
      var d = new Date(date);      
      d.setDate(firstMonth.getDate() - i);
      dInfo[index++] = d;
    }

    var lastDate = null;    
    for (var i=0; i<monDay[date.getMonth()]; i++)
    {
      lastDate = new Date(date);      
      lastDate.setDate(firstMonth.getDate() + i);
      dInfo[index++] = lastDate;
    }
    
    const cnt = totalDays - index + 1;
    
    for (var i=1; i<cnt; i++)
    {      
      var d = new Date(date);      
      d.setDate(lastDate.getDate() + i);      
      dInfo[index++] = d;
    }

    setDateInfo(dInfo);
  }
  //#endregion

  function onClicked(dInfo)
  {
    onSelectedDate([dInfo]);    
  }

  function getSelected(date)
  { 
    return selectedDate.some(x => x.getFullYear() === date.getFullYear() && x.getMonth() === date.getMonth() && x.getDate() === date.getDate());
  }

  useEffect(() => {    
    // if (lastMenu != selectedMenu)
    //   setSelectedDays();    
    
    if (lastDate === null || lastDate !== date)
    { 
      calDays();
      setLastDate(date);
    }
  });

  return (
    <>
      <div style={{marginLeft: '22px'}}>
        {/* display day of week text */}
        {dwName.map((item, index) => (
          <div key={index}>
            <TextBlock>
              {t(item)}
            </TextBlock>
          </div>
        ))}

        {/* display days */}
        {dateInfo.map((item, index) => (
          <div key={index}>
            <CalendarTextBox
              today={today}              
              month={date.getMonth()}
              date={item}
              isSelected={getSelected(item)}
              onClicked={onClicked}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default CalendarDateView;
