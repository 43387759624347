import client from './client';

export const setLang = ({ language }) =>
  client.post(
    '/v1/setLang/',
    { language },
    { headers: { 'X-API-KEY': client.apiKey_en } },
  );

export const appInfo = () =>
  client.get('/v1/app-info/', { headers: { 'X-API-KEY': client.apiKey_en } });

export const getIpInfo = () => client.get('https://ipapi.co/json/');
