import styled, { css } from 'styled-components';

const LinkButton = styled.button`
  width: ${(props) => props.width || '150px'};
  height: ${(props) => props.height || '36px'};
  margin: ${(props) => props.margin};

  outline: none;
  border-radius: 4px;
  border: solid 1px #e7e9ea;
  background: #ffffff;

  color: ${(props) => props.theme.main_color};
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.2px;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.main_color};
    background: #eeeff0;
  }
  &:active {
    color: ${(props) => props.theme.main_color};
    background: ${(props) => props.theme.main_color_focus};
    border: solid 1px ${(props) => props.theme.main_color};
  }
  &:disabled {
    color: #c3c6c9;
    background: #f5f6f6;
  }
`;

export default LinkButton;
