import { createAction, handleActions } from 'redux-actions';
import { produce } from 'immer';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import * as connsAPI from '../lib/api/connections';
import { takeLatest, takeLeading } from 'redux-saga/effects';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

/* Action Type */
const CHANGE_FIELD = 'connections/CHANGE_FIELD';
const INITIALIZE_FORM = 'connections/INITIALIZE_FORM';
const ADD_NEW_CASE = 'connections/ADD_NEW_CASE';
const SEARCH_FILTER = 'connections/SEARCH_FILTER';
const [REMOVE_CONNECTION, REMOVE_CONNECTION_SUCCESS, REMOVE_CONNECTION_FAILURE] =
  createRequestActionTypes('connections/REMOVE_CONNECTION');

const [REJECT_CONNECTION, REJECT_CONNECTION_SUCCESS, REJECT_CONNECTION_FAILURE] =
  createRequestActionTypes('connections/REJECT_CONNECTION');

const [LIST_CONNS, LIST_CONNS_SUCCESS, LIST_CONNS_FAILURE] = createRequestActionTypes(
  'connections/LIST_CONNECTIONS',
);

const [REQUEST_CONNECTION, REQUEST_CONNECTION_SUCCESS, REQUEST_CONNECTION_FAILURE] =
  createRequestActionTypes('connections/REQUEST_CONNECTION');

const [EDIT_CONNECTION, EDIT_CONNECTION_SUCCESS, EDIT_CONNECTION_FAILURE] =
  createRequestActionTypes('connections/EDIT_CONNECTION');

const [ACCEPT_CONNECTION, ACCEPT_CONNECTION_SUCCESS, ACCEPT_CONNECTION_FAILURE] =
  createRequestActionTypes('connections/ACCEPT_CONNECTION');

/* Action */
export const changeField = createAction(CHANGE_FIELD, ({ form, key, value }) => ({
  form,
  key,
  value,
}));

export const initializeForm = createAction(INITIALIZE_FORM, (form) => form);
export const listConnections = createAction(LIST_CONNS, (search) => search);
export const requestConnection = createAction(
  REQUEST_CONNECTION,
  ({ email, alias, message, id }) => ({
    email,
    alias,
    message,
    id,
  }),
);
export const editConnection = createAction(EDIT_CONNECTION, ({ id, alias }) => ({
  id,
  alias,
}));
export const addNewCase = createAction(ADD_NEW_CASE);
export const searchFilter = createAction(SEARCH_FILTER);
export const acceptConnection = createAction(ACCEPT_CONNECTION, ({ cidb64, token }) => ({
  cidb64,
  token,
}));

export const removeConnection = createAction(REMOVE_CONNECTION);
export const rejectConnection = createAction(REJECT_CONNECTION);

/* Saga */
const listConnsSaga = createRequestSaga(LIST_CONNS, connsAPI.listConnections);
const requestConnectionSaga = createRequestSaga(REQUEST_CONNECTION, connsAPI.requestConnection);
const editConnectionSaga = createRequestSaga(EDIT_CONNECTION, connsAPI.editConnection);
const acceptConnectionSaga = createRequestSaga(ACCEPT_CONNECTION, connsAPI.acceptConnection);
const removeConnectionSaga = createRequestSaga(REMOVE_CONNECTION, connsAPI.removeConnection);
const rejectConnectionSaga = createRequestSaga(REJECT_CONNECTION, connsAPI.rejectConnection);

// function listConnsFailureSaga({ payload: e }) {
//   const refreshCookie = cookies.get('refresh');
//   if (e.response.status === 401 && !refreshCookie) {
//     window.location.href = '/';
//   }
// }

export function* connectionsSaga() {
  yield takeLatest(LIST_CONNS, listConnsSaga);
  yield takeLatest(REQUEST_CONNECTION, requestConnectionSaga);
  yield takeLatest(EDIT_CONNECTION, editConnectionSaga);
  yield takeLeading(ACCEPT_CONNECTION, acceptConnectionSaga);
  yield takeLatest(REMOVE_CONNECTION, removeConnectionSaga);
  yield takeLatest(REJECT_CONNECTION, rejectConnectionSaga);
  // yield takeLatest(LIST_CONNS_FAILURE, listConnsFailureSaga);
}

/* Init */
const initialState = {
  connections: null,
  acceptconn: null,
  error: null,
  remove: null,
  reject: null,
  editconn: null,
  request: null,
  page: { page1: true },
  changefield: {
    email: '',
    alias: '',
    message: '',
  },
  lab: { lab: null },
  addNewCase: 0,
  searchFilter: '',
  removeConnection: 0,
};

const connections = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: { form, key, value } }) =>
      produce(state, (draft) => {
        draft[form][key] = value;
      }),
    [INITIALIZE_FORM]: (state, { payload: form }) => ({
      ...state,
      [form]: initialState[form],
      conn: null,
      error: null,
    }),
    [LIST_CONNS_SUCCESS]: (state, { payload: { connections, page } }) => ({
      ...state,
      connections,
      error: null,
    }),
    [LIST_CONNS_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [REQUEST_CONNECTION_SUCCESS]: (state, { payload: request }) => ({
      ...state,
      request,
      error: null,
    }),
    [REQUEST_CONNECTION_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [EDIT_CONNECTION_SUCCESS]: (state, { payload: editconn }) => ({
      ...state,
      editconn,
      error: null,
    }),
    [EDIT_CONNECTION_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [ADD_NEW_CASE]: (state, action) => ({
      ...state,
      addNewCase: action.payload,
    }),
    [SEARCH_FILTER]: (state, action) => ({
      ...state,
      searchFilter: action.payload,
    }),
    [ACCEPT_CONNECTION_SUCCESS]: (state, { payload: acceptconn }) => ({
      ...state,
      acceptconn,
      error: null,
    }),
    [ACCEPT_CONNECTION_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error: error,
    }),
    [REMOVE_CONNECTION_SUCCESS]: (state, { payload: remove }) => ({
      ...state,
      remove,
      error: null,
    }),
    [REMOVE_CONNECTION_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error: error,
    }),
    [REJECT_CONNECTION_SUCCESS]: (state, { payload: reject }) => ({
      ...state,
      reject,
      error: null,
    }),
    [REJECT_CONNECTION_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error: error,
    }),
  },
  initialState,
);

export default connections;
