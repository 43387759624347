import React from 'react';
import SignupContainer from '../containers/auth/SignupContainer';
import styled from 'styled-components';

const Body = styled('div')`
  min-width: 360px;
  min-height: 100vh;
  margin: auto;
  background: #eeeff0;
  /* overflow-x: hidden;
  overflow-y: auto; */
`;

const SignupPage = () => {
  return (
    <Body>
      <SignupContainer />
    </Body>
  );
};

export default SignupPage;
