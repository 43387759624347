import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch, useStore, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import client from './api/client';
import { getEventsToken, resetAccessToken, logout as api_logout } from './api/auth';
import { esObj } from './util';
import { resetError } from '../modules/auth';
import { logout } from '../modules/user';
import ReconnectingEventSource from './reconnecting-eventsource';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

// custom hook for showing message when relogin required
export const useRefreshOnAuthError = () => {
  const { t } = useTranslation();
  const { error } = useSelector(
    ({ auth }) => ({
      error: auth.authError,
    }),
    shallowEqual,
  );
  const store = useStore();
  const dispatch = useDispatch();

  useEffect(() => {
    if (error && error.response.status === 401) {
      // get latest authError from store
      const authError = store.getState().auth.authError;
      if (authError) {
        // reset authError to prevent the alert window from popping up twice
        dispatch(resetError());

        // refreshError if request path includes 'refresh'
        const refreshError = error.config.url.includes('auth/refresh/');
        if (!refreshError) {
          window.location.reload();
        } else {
          alert(t('Relogin required.'));
          localStorage.removeItem('user');
          // delete access token
          resetAccessToken();
          // same with /login?next=
          window.location.reload();
        }
      }
    }
  }, [error]);
};

// custom hook for creating event source for SSE
export const useAutoRefreshEvent = () => {
  const [event, setEvent] = useState(null);
  const { user_checking, autoRefreshEnabled } = useSelector(({ loading, user }) => ({
    user_checking: loading['user/CHECK'],
    autoRefreshEnabled: user.settings.use_auto_refresh,
  }));

  const onEventListner = useCallback((e) => {
    setEvent(e.data);
  }, []);

  useEffect(() => {
    if (autoRefreshEnabled) {
      if (!window.es && !user_checking && !esObj.setInProg) {
        esObj.setInProg = true;
        getEventsToken({ callFrom: null })
          .then((res) => {
            window.es = new ReconnectingEventSource(`/events/`, {
              // withCredentials: true,
              // headers: {
              //   Authorization: client.defaults.headers.common['Authorization'],
              // },
            });
            esObj.setInProg = false;
          })
          .catch((e) => {
            esObj.setInProg = false;
            console.log(e);
          });
      }
      if (window.es) {
        window.es.addEventListener('message', onEventListner);
        // console.log(`addEventListener: ${window.es._listeners.message.length}`);
      }

      return () => {
        if (window.es) {
          window.es.removeEventListener('message', onEventListner);
          // if (window.es._listeners.message)
          //   console.log(`removeEventListener: ${window.es._listeners.message.length}`);
          // else console.log('removeEventListener: 0');
        }
      };
    } else {
      // not use
      if (window.es) {
        window.es.close();
        delete window.es;
      }
    }
  }, [autoRefreshEnabled, user_checking, esObj.setInProg]);

  return event;
};

export const logoutUser = async (dispatch) => {
  if (window.es) {
    window.es.close();
    delete window.es;
  }
  // localStorage.removeItem('user');
  // localStorage.removeItem('settings');
  // localStorage.removeItem('caseFilters');
  removeLocalStorages();
  dispatch(logout());
  await api_logout();
};

export const removeLocalStorages = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('settings');
  localStorage.removeItem('caseFilters');
};

export const redirectLocation = (user_loc, next) => {
  const isLocal = window.location.hostname === 'localhost';

  removeLocalStorages();
  // delete access token
  resetAccessToken();

  // redirect to user's data location region
  if (isLocal) {
    alert(`To be redirected to ${user_loc} server`);
    window.location.reload();
  } else {
    const domain = window.location.hostname.match(/\w*\.\w*$/gi)[0];
    const newHost = `${user_loc.toLowerCase()}.${domain}`;

    cookies.set('redirectRegion', 'true', {
      path: '/',
      secure: true,
      domain: domain,
      sameSite: 'Strict',
    });
    // redirect the other region
    window.location.assign(`https://${newHost}${next}`);
  }
};
