import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledError = styled.div`
  display: flex;
  flex-direction: column;
  /* min-width: 416px; */
  padding: 10px 10px 10px 20px;
  justify-content: center;
  /* align-items: center; */
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  border: 1px #ff0000 solid;
  background-color: #fff;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};

  .errorListTitle {
    display: flex;
    color: #ff0000;
    font-size: 24px;
    font-family: 'Source Sans Pro';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  .errorListText {
    font-size: 15px;
    font-family: 'Source Sans Pro';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
`;

const ErrorList = ({ error, ...rest }) => {
  const { t } = useTranslation();
  // console.log(error);

  const errors = Array.isArray(error)
    ? error.map((list, index) => (
        <div key={index} className="errorListText">
          {list}
        </div>
      ))
    : error;

  return (
    error && (
      <StyledError {...rest}>
        <div className="errorListTitle">{t('There was a problem')}</div>
        <div style={{ height: '5px' }} />
        {errors}
      </StyledError>
    )
  );
};

export default React.memo(ErrorList);
