import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../lib/createRequestSaga';
import * as casesAPI from '../lib/api/cases';
import { call, put, takeLatest } from 'redux-saga/effects';

{
  /* Action Type */
}
const [LIST_CASES, LIST_CASES_SUCCESS, LIST_CASES_FAILURE] =
  createRequestActionTypes('cases/LIST_CASES');

const [USER_FILTER, USER_FILTER_SUCCESS, USER_FILTER_FAILURE] =
  createRequestActionTypes('cases/USER_FILTER');

// const [DELETE_CASES, DELETE_CASES_SUCCESS, DELETE_CASES_FAILURE] =
//   createRequestActionTypes('cases/DELETE_CASES');

const SET_FILTERS = 'cases/FILTERS';
const UPDATE_CASE = 'cases/UPDATE_CASE';

{
  /* Action */
}
export const listCases = createAction(LIST_CASES, ({ filters, page }) => ({
  filters,
  page,
}));

// export const deleteCases = createAction(DELETE_CASES, ({ ids }) => ({ ids }));

export const setFilters = createAction(
  SET_FILTERS,
  ({
    status,
    unreadCommentsOnly,
    createDateFrom,
    createDateTo,
    institution,
    person,
    userIds,
    searchString,
    orderBy,
  }) => ({
    status,
    unreadCommentsOnly,
    createDateFrom,
    createDateTo,
    institution,
    person,
    userIds,
    searchString,
    orderBy,
  }),
);
export const userFilter = createAction(USER_FILTER);

{
  /* Saga */
}
const listCasesSaga = createRequestSaga(LIST_CASES, casesAPI.listCases);
const userFilterSaga = createRequestSaga(USER_FILTER, casesAPI.userFilters);
// const deleteCasesSaga = createRequestSaga(DELETE_CASES, casesAPI.deleteCases);

export function* casesSaga() {
  yield takeLatest(LIST_CASES, listCasesSaga);
  yield takeLatest(USER_FILTER, userFilterSaga);
  // yield takeLatest(DELETE_CASES, deleteCasesSaga);
}

{
  /* Init */
}
const initialState = {
  cases: null,
  error: null,
  page: null,
  // caseStatus: 'Open',
  filters: {
    status: [],
    unreadCommentsOnly: false,
    createDateFrom: null,
    createDateTo: null,
    institution: 'All',
    person: 'All',
    userIds: '',
    searchString: '',
    orderBy: '',
  },
  userFilters: null,
};

const cases = handleActions(
  {
    [LIST_CASES_SUCCESS]: (state, { payload: cases, meta: response }) => ({
      ...state,
      // cases,
      cases: cases.posts,
      page: cases.page,
      del_disabled_all: cases.del_disabled_all,
      error: null,
      // lastPage: parseInt(response.headers['last-page'], 10),
    }),
    [LIST_CASES_FAILURE]: (state, { payload: error }) => ({ ...state, error }),

    [SET_FILTERS]: (state, { payload: filters }) => ({
      ...state,
      filters,
    }),
    [USER_FILTER_SUCCESS]: (state, { payload: userFilters }) => ({
      ...state,
      userFilters,
      error: null,
    }),
    [USER_FILTER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    // [DELETE_CASES_SUCCESS]: (state, { payload: cases }) => ({
    //   ...state,
    //   cases,
    //   error: null,
    // }),
    // [DELETE_CASES_FAILURE]: (state, { payload: error }) => ({
    //   ...state,
    //   error,
    // }),

    [UPDATE_CASE]: (state, { payload: content }) => ({
      ...state,
      cases: updateCases(state.cases, content),
    }),
  },
  initialState,
);

const updateCases = (cases, content) => {
  const item = cases.find((x) => x.id === content.id);
  if (item) {
    item.title = content.title;
    item.files_count = content.files_count;
    item.comment_count = content.comments_count;
    item.updated_date = content.updated_date;
    item.del_enabled = content.del_enabled;
    if (item.usertype === 'AU' || item.usertype === 'SG') {
      // to
      item.to.disp_name = content.to.disp_name;
      item.to.username = content.to.username;
      item.to.is_deleted = content.to.is_deleted;
    } else {
      // author
      item.author.disp_name = content.author.disp_name;
      item.author.username = content.author.username;
      item.author.is_deleted = content.author.is_deleted;
      item.author.picture = content.author.picture;
    }
    // status
    item.status.code = content.status.code;
    item.status.str = content.status.str;
  }
  // not found
  return cases;
};

export default cases;
