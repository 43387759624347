import { createAction, handleActions } from 'redux-actions';
import { takeLatest, call, put, getContext } from 'redux-saga/effects';
import * as authAPI from '../lib/api/auth';
import * as settingsAPI from '../lib/api/settings';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import { startLoading, finishLoading } from '../modules/loading';
import { setToken } from '../modules/auth';
import { initAll } from './dashboard';
import { odm_name } from '../lib/odm';

const TEMP_SET_USER = 'user/TEMP_SET_USER';
const [CHECK, CHECK_SUCCESS, CHECK_FAILURE] = createRequestActionTypes('user/CHECK');
const LOGOUT = 'user/LOGOUT';
const [MIGRATION_INFO, MIGRATION_INFO_SUCCESS, MIGRATION_INFO_FAILURE] =
  createRequestActionTypes('user/MIGRATION_INFO');

export const tempSetUser = createAction(TEMP_SET_USER, ({ user, settings }) => ({
  user,
  settings,
}));
export const check = createAction(CHECK, ({ callFrom }) => ({ callFrom }));
export const logout = createAction(LOGOUT);
export const getMigrationInfo = createAction(MIGRATION_INFO);

function* checkSaga(action) {
  yield put(startLoading(CHECK));
  try {
    let isAccessToken = true;
    if (!authAPI.hasAccessToken()) {
      isAccessToken = false;
      try {
        const response = yield call(authAPI.refresh);
        yield put(setToken(response.data));
        isAccessToken = true;
      } catch (e) {
        // console.log(error);
        throw e;
      }
    }
    if (isAccessToken) {
      const response = yield call(authAPI.check, action.payload);

      yield put({
        type: CHECK_SUCCESS,
        payload: response.data,
        meta: response,
      });
      const user = response.data;
      if (user.user && user.settings.data_loc_set === false) {
        const isDeviceLogin = /^\/adm.*/i.test(window.location.pathname);
        if (!isDeviceLogin && odm_name === 'HCLOUD') {
          const history = yield getContext('history');
          history.push('/profile');
        }
      }
    } else {
      throw new Error('unable to refresh');
    }
  } catch (e) {
    yield put({
      type: CHECK_FAILURE,
      payload: e,
      error: true,
    });
  }
  yield put(finishLoading(CHECK));
}

function checkSuccessSaga({ payload }) {
  try {
    localStorage.setItem('user', JSON.stringify(payload.user));
    localStorage.setItem('settings', JSON.stringify(payload.settings));
  } catch (e) {
    console.log('localStorage is not working');
  }
}

function checkFailureSaga() {
  try {
    localStorage.removeItem('user');
    localStorage.removeItem('settings');
    // yield put(push('/login'));
  } catch (e) {
    console.log('localStorage is not working');
  }
}

function* logoutSaga() {
  try {
    yield put(initAll());
    // yield call(authAPI.logout);
  } catch (e) {
    console.log(e);
  }
}

const migrationInfoSaga = createRequestSaga(MIGRATION_INFO, settingsAPI.migrationInfo);

export function* userSaga() {
  yield takeLatest(CHECK, checkSaga);
  yield takeLatest(CHECK_SUCCESS, checkSuccessSaga);
  yield takeLatest(CHECK_FAILURE, checkFailureSaga);
  yield takeLatest(LOGOUT, logoutSaga);
  yield takeLatest(MIGRATION_INFO, migrationInfoSaga);
}

const initialState = {
  user: null,
  settings: null,
  migrationInfo: null,
  checkError: null,
  migrationInfoError: null,
};

export default handleActions(
  {
    [TEMP_SET_USER]: (state, { payload }) => ({
      ...state,
      user: payload.user,
      settings: payload.settings,
    }),
    [CHECK_SUCCESS]: (state, { payload }) => ({
      ...state,
      checkError: null,
      user: payload.user,
      settings: payload.settings,
    }),
    [CHECK_FAILURE]: (state, { payload: error }) => ({
      ...state,
      user: null,
      settings: null,
      checkError: error,
    }),
    [LOGOUT]: (state) => ({ ...state, user: null, settings: null }),
    [MIGRATION_INFO_SUCCESS]: (state, { payload: migrationInfo }) => ({
      ...state,
      migrationInfo,
      migrationInfoError: null,
    }),
    [MIGRATION_INFO_FAILURE]: (state, { payload: error }) => ({
      ...state,
      migrationInfo: null,
      migrationInfoError: error,
    }),
  },
  initialState,
);
