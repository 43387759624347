import {
	EventDispatcher,
	MOUSE,
	Quaternion,
	Vector2,
	Vector3
} from 'three/build/three.module.js';

/**
 * @author Eberhard Graether / http://egraether.com/
 * @author Mark Lundin 	/ http://mark-lundin.com
 */

var TrackballControls = function (object, domElement) {

	if (domElement === undefined) console.warn('THREE.TrackballControls: The second parameter "domElement" is now mandatory.');
	if (domElement === document) console.error('THREE.TrackballControls: "document" should not be used as the target "domElement". Please use "renderer.domElement" instead.');

	var _this = this;
	var STATE = { NONE: -1, ROTATE: 0, ZOOM: 1, PAN: 2, TOUCH_ROTATE: 3, TOUCH_ZOOM_PAN: 4 };

	this.object = object;
	// this.domElement = (domElement !== undefined) ? domElement : document;
	this.domElement = domElement;

	// API

	this.enabled = true;

	this.screen = { left: 0, top: 0, width: 0, height: 0 };

	this.panSpeed = 1.5;

	this.noPan = false;
	// this.noRoll = false;

	this.staticMoving = false;
	this.dynamicDampingFactor = 0.2;

	this.minDistance = 0;
	this.maxDistance = Infinity;

	// internals
	this.target = new Vector3();

	var EPS = 0.000001;

	var lastPosition = new Vector3();

	var _state = STATE.NONE,
		_prevState = STATE.NONE,

		_eye = new Vector3(),

		_touchZoomDistanceStart = 0,
		_touchZoomDistanceEnd = 0,

		_panStart = new Vector2(),
		_panEnd = new Vector2();

	// for reset
	this.target0 = this.target.clone();
	this.position0 = this.object.position.clone();
	this.up0 = this.object.up.clone();

	// events
	var changeEvent = { type: 'change' };
	var startEvent = { type: 'start' };
	var endEvent = { type: 'end' };


	// methods
	this.handleResize = function () {

		if (this.domElement === document) {
			this.screen.left = 0;
			this.screen.top = 0;
			this.screen.width = window.innerWidth;
			this.screen.height = window.innerHeight;
		} else {
			var box = this.domElement.getBoundingClientRect();
			// adjustments come from similar code in the jquery offset() function
			var d = this.domElement.ownerDocument.documentElement;
			this.screen.left = box.left + window.pageXOffset - d.clientLeft;
			this.screen.top = box.top + window.pageYOffset - d.clientTop;
			this.screen.width = box.width;
			this.screen.height = box.height;
		}

	};

	this.handleEvent = function (event) {

		if (typeof this[event.type] == 'function') {
			this[event.type](event);
		}

	};

	var getMouseOnScreen = (function () {
		var vector = new Vector2();

		return function (pageX, pageY) {
			vector.set(
				(pageX - _this.screen.left) / _this.screen.width,
				(pageY - _this.screen.top) / _this.screen.height
			);
			return vector;
		};
	}());



	this.panCamera = (function () {

		var mouseChange = new Vector2(),
			objectUp = new Vector3(),
			pan = new Vector3();

		return function () {

			mouseChange.copy(_panEnd).sub(_panStart);

			if (mouseChange.lengthSq()) {

				mouseChange.multiplyScalar(_eye.length() * _this.panSpeed);

				pan.copy(_eye).cross(_this.object.up).setLength(mouseChange.x);
				pan.add(objectUp.copy(_this.object.up).setLength(mouseChange.y));

				_this.object.position.add(pan);
				_this.target.add(pan);

				if (_this.staticMoving) {

					_panStart.copy(_panEnd);

				} else {

					_panStart.add(mouseChange.subVectors(_panEnd, _panStart).multiplyScalar(_this.dynamicDampingFactor));

				}

			}
		}

	}());

	// this.checkDistances = function () {
	//
	// 	if ( !_this.noPan ) {
	//
	// 		if ( _eye.lengthSq() > _this.maxDistance * _this.maxDistance ) {
	//
	// 			_this.object.position.addVectors( _this.target, _eye.setLength( _this.maxDistance ) );
	//
	// 		}
	//
	// 		if ( _eye.lengthSq() < _this.minDistance * _this.minDistance ) {
	//
	// 			_this.object.position.addVectors( _this.target, _eye.setLength( _this.minDistance ) );
	//
	// 		}
	//
	// 	}
	//
	// };

	this.update = function () {

		_eye.subVectors(_this.object.position, _this.target);

		if (!_this.noPan) {
			_this.panCamera();
		}

		_this.object.position.addVectors(_this.target, _eye);

		// _this.checkDistances();

		_this.object.lookAt(_this.target);

		if (lastPosition.distanceToSquared(_this.object.position) > EPS) {

			_this.dispatchEvent(changeEvent);

			lastPosition.copy(_this.object.position);

		}

	};

	this.reset = function () {

		_state = STATE.NONE;
		_prevState = STATE.NONE;

		_this.target.copy(_this.target0);
		_this.object.position.copy(_this.position0);
		_this.object.up.copy(_this.up0);

		_eye.subVectors(_this.object.position, _this.target);

		_this.object.lookAt(_this.target);

		_this.dispatchEvent(changeEvent);

		lastPosition.copy(_this.object.position);

	};

	// listeners


	function mousedown(event) {

		if (_this.enabled === false) return;

		event.preventDefault();
		event.stopPropagation();

		if (_state === STATE.NONE) {

			_state = event.button;

		}

		if (_state === STATE.PAN && !_this.noPan) {

			_panStart.copy(getMouseOnScreen(event.pageX, event.pageY));
			_panEnd.copy(_panStart)

		}

		_this.domElement.ownerDocument.addEventListener('pointermove', mousemove, false);
		_this.domElement.ownerDocument.addEventListener('pointerup', mouseup, false);

		_this.dispatchEvent(startEvent);

	}

	function mousemove(event) {

		if (_this.enabled === false) return;

		event.preventDefault();
		event.stopPropagation();

		if (_state === STATE.PAN && !_this.noPan) {

			_panEnd.copy(getMouseOnScreen(event.pageX, event.pageY));

		}

	}

	function mouseup(event) {

		if (_this.enabled === false) return;

		event.preventDefault();
		event.stopPropagation();

		_state = STATE.NONE;

		_this.domElement.ownerDocument.removeEventListener('pointermove', mousemove);
		_this.domElement.ownerDocument.removeEventListener('pointerup', mouseup);
		_this.dispatchEvent(endEvent);

	}

	function touchstart(event) {

		if (_this.enabled === false) return;

		switch (event.touches.length) {
			case 2:
				_state = STATE.TOUCH_ZOOM_PAN;
				var dx = event.touches[0].pageX - event.touches[1].pageX;
				var dy = event.touches[0].pageY - event.touches[1].pageY;
				_touchZoomDistanceEnd = _touchZoomDistanceStart = Math.sqrt(dx * dx + dy * dy);

				var x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
				var y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
				_panStart.copy(getMouseOnScreen(x, y));
				_panEnd.copy(_panStart);
				break;

			default:
				_state = STATE.NONE;

		}
		_this.dispatchEvent(startEvent);


	}

	function touchmove(event) {

		if (_this.enabled === false) return;

		event.preventDefault();
		event.stopPropagation();

		switch (event.touches.length) {
			case 2:
				var dx = event.touches[0].pageX - event.touches[1].pageX;
				var dy = event.touches[0].pageY - event.touches[1].pageY;
				_touchZoomDistanceEnd = Math.sqrt(dx * dx + dy * dy);

				var x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
				var y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
				_panEnd.copy(getMouseOnScreen(x, y));
				break;

			default:
				_state = STATE.NONE;

		}

	}

	function touchend(event) {

		if (_this.enabled === false) return;

		switch (event.touches.length) {
			case 2:
				_touchZoomDistanceStart = _touchZoomDistanceEnd = 0;

				var x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
				var y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
				_panEnd.copy(getMouseOnScreen(x, y));
				_panStart.copy(_panEnd);
				break;
		}

		_state = STATE.NONE;
		_this.dispatchEvent(endEvent);

	}

	this.domElement.addEventListener('touchstart', touchstart, false);
	this.domElement.addEventListener('touchend', touchend, false);
	this.domElement.addEventListener('touchmove', touchmove, false);

	// this.domElement.addEventListener( 'contextmenu', function ( event ) { event.preventDefault(); }, false );
	this.domElement.addEventListener('pointerdown', mousedown, false);


	this.handleResize();

	// force an update at start
	this.update();

};

TrackballControls.prototype = Object.create(EventDispatcher.prototype);
TrackballControls.prototype.constructor = TrackballControls;

export { TrackballControls };
