import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import HButton from '../common/HButton';
import { useSelector } from 'react-redux';
import SVG from '../common/svg';
import { useTranslation } from 'react-i18next';

const InputBox_fadeIn = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

const InputBox_fadeOut = keyframes`
  from {
    opacity: 1
  }
  to {
    opacity: 0
  }
`;

const InputBox_slideDown = keyframes`
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0px);
  }
`;

const InputBox_slideUp = keyframes`
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-100px);
  }
`;

const InputBox_Fullscreen = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;

  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${InputBox_fadeIn};
  animation-fill-mode: forwards;

  ${(props) =>
    props.disappear &&
    css`
      animation-name: ${InputBox_fadeOut};
    `}
`;

const InputBox_ModalBlock = styled.div`
  width: 526px;
  height: 204px;
  background: white;
  border-radius: 4px;
  border: solid 1px #eeeff0;

  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${InputBox_slideDown};
  animation-fill-mode: forwards;

  ${(props) =>
    props.disappear &&
    css`
      animation-name: ${InputBox_slideUp};
    `}
`;

const InputBox_TitleBlock = styled.div`
  .titleBox {
    width: 526px;
    height: 56px;
    border-radius: 4px 4px 0 0;
    margin: 0 0 0 0;
    background: #eeeff0;
  }

  .titleText {
    width: 300px;
    height: 25px;
    margin: 15px 0 0 19px;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
    text-align: left;
    float: left;
  }

  .closeIcon {
    width: 24px;
    height: 24px;
    margin: 16px 19px 0 0;
    float: right;
  }
`;

const InputBox_ContentBlock = styled.div`
  .inputBox {
    width: calc(100% - 40px - 32px);
    height: 36px;
    margin: 24px 0px 24px 20px;
    padding-left: 16px;
    padding-right: 16px;
    outline: none;
    border-radius: 4px;
    border: solid 1px #e7e9ea;
    background-color: transparent;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
  }

  .inputBox:focus {
    outline: none !important;
    border:1px solid ${props => props.theme.main_color};
  }

`;

const InputBox_ButtonBlock = styled.div``;

const InputBox = ({ visible, type, title, content, onOk, onCancel }) => {
  const [animate, setAnimate] = useState(false);
  const [localVisible, setLocalVisible] = useState(visible);
  const [inputText, setInputText] = useState('');
  const [inputFocus, setInputFocus] = useState(false);
  const { t } = useTranslation();

  const { odm } = useSelector(({ app }) => ({
    odm: app.odm,
  }));

  useEffect(() => {
    setInputText(content);
    if (localVisible && !visible) {
      setAnimate(true);
      setTimeout(() => setAnimate(false), 250);
    }
    setLocalVisible(visible);
  }, [localVisible, visible]);

  /*
  const closeActive =
    process.env.PUBLIC_URL + '/images/common/icon-24-close-active.svg';
  const closeHover =
    process.env.PUBLIC_URL + '/images/common/icon-24-close-hover.svg';
  const closeSelected =
    process.env.PUBLIC_URL + '/images/common/icon-24-close-selected.svg';
*/
  const onChangeInput = (e) => {
    setInputText(e.target.value);
    setInputFocus(true);
  };

  const onKeyPress = (e) => {
    if (e.key == 'Enter') {
    }
  };

  if (!animate && !localVisible) return null;

  return (
    <InputBox_Fullscreen disappear={!visible}>
      <InputBox_ModalBlock disappear={!visible}>
        <InputBox_TitleBlock>
          <div className="titleBox">
            <div class="titleText">{title}</div>
            {/*
            <img
              className={'closeIcon'}
              src={closeActive}
              onMouseOver={(e) => (e.currentTarget.src = closeHover)}
              onMouseOut={(e) => (e.currentTarget.src = closeActive)}
              onMouseDown={(e) => (e.currentTarget.src = closeSelected)}
              onClick={onCancel}
            />
            */}
            <div className={'closeIcon'} onClick={onCancel} >
              <SVG name="close" color="#878D93" colorOver={odm.main_color_hover} colorOut="#878D93" colorDown={odm.main_color} />
            </div>
          </div>
        </InputBox_TitleBlock>
        <InputBox_ContentBlock>
          <input
            className="inputBox"
            name="alias"
            type="text"
            value={inputText}
            onChange={onChangeInput}
          />
        </InputBox_ContentBlock>
        <InputBox_ButtonBlock>
          <HButton
            category="Primary"
            size="Medium"
            text={t('OK').toUpperCase()}
            width="auto"
            margin="0 20px 0 0"
            float="right"
            onClick={() => onOk(inputText)}
          />
          {type !== 'ok' ? (
            <div>
              <HButton
                category="Secondary"
                size="Medium"
                text={t('Cancel').toUpperCase()}
                width="auto"
                margin="0 16px 0 0"
                float="right"
                onClick={onCancel}
              />
            </div>
          ) : (
            <div></div>
          )}
        </InputBox_ButtonBlock>
      </InputBox_ModalBlock>
    </InputBox_Fullscreen>
  );
};

export default InputBox;
