import axios from 'axios';

const client = axios.create();

client.defaults.baseURL = '/heclore/';

client.apiKey_en = 'Q3dfND15JnxHeVlFKWcoMUI='; // base64

/*
client.defaults.headers.common['Authorization'] = 'Bearer a1b2c3d4';

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);
*/

export default client;

// const helpdeskClient = axios.create();
// helpdeskClient.defaults.baseURL = process.env.REACT_APP_HELPDESK_URL
//   ? process.env.REACT_APP_HELPDESK_URL + '/helpdesk/WebObjects/Helpdesk.woa/'
//   : '/helpdesk/WebObjects/Helpdesk.woa/';

// helpdeskClient.defaults.withCredentials = true;
// helpdeskClient.defaults.crossDomain = true;

// export { helpdeskClient };
