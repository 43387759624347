import React, { useCallback, useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import Logo from './styled/Logo';
import Input from './styled/Input';
import ImageButton from './styled/ImageButton';
import Button from './styled/Button';
import { useTranslation } from 'react-i18next';
import ErrorList from './styled/ErrorList';
import DropdownList from './styled/DropdownList';
import countries from 'i18n-iso-countries';
import German from 'i18n-iso-countries/langs/de.json';
import English from 'i18n-iso-countries/langs/en.json';
import Spanish from 'i18n-iso-countries/langs/es.json';
import French from 'i18n-iso-countries/langs/fr.json';
import Japanese from 'i18n-iso-countries/langs/ja.json';
import Korean from 'i18n-iso-countries/langs/ko.json';
import Italian from 'i18n-iso-countries/langs/it.json';
import Polish from 'i18n-iso-countries/langs/pl.json';
import Portuguese from 'i18n-iso-countries/langs/pt.json';

import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import { initializeForm } from '../../modules/auth';
import ValidateDiv from '../../components/auth/styled/ValidateText';
import { getIpInfo } from '../../lib/api/app';
import MobileTop from './styled/MobileTop';
import { TabletOrDesktop } from '../../lib/responsive';
// console.log(countries.getAlpha2Code('south korea', 'en'));

const Container = styled.div`
  width: 100%;
  /* min-width: '360px'; */
  background: #eeeff0;
  .validateCss {
    margin-top: 4px;
    margin-bottom: 8px;
  }
  div {
    font-family: 'Source Sans Pro';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
`;

const ContentContainer = styled.div`
  /* display: flex; */
  /* width: 100%; */
  /* align-items: center;
  justify-content: center; */
`;

const Content = styled.div`
  max-width: ${(props) => props.maxWidth};
  margin: 0 auto;
  /* display: flex; */
  /* flex-direction: column; */
  /* width: 100%; */
  /* align-items: center;
  justify-content: center; */
  /* width: 448px; */
  /* margin-left: 496px; */
`;

const Title = styled.div`
  width: ${(props) => (props.mobile ? '288px' : '328px')};
  text-align: ${(props) => (props.mobile ? 'center' : 'left')};
  height: 32px;
  margin-left: 16px;
  margin-right: 16px;
  ${(props) =>
    props.error
      ? css`
          margin-top: 0px;
        `
      : props.mobile
      ? css`
          margin-top: 80px;
        `
      : css`
          margin-top: 102px;
        `}

  font-size: 25px;
  font-weight: 600;
  color: #59616a;
`;

const SubTitle = styled.div`
  width: ${(props) => (props.mobile ? '288px' : 'auto')};
  text-align: ${(props) => (props.mobile ? 'center' : 'left')};
  height: 100%;
  margin: 16px 0 24px 16px;
  font-size: 15px;
  line-height: 1.6;
  color: #969ba1;
`;

const Avatar = styled.div`
  display: flex;
  /* min-width: 424px; */
`;

const P = styled.p`
  display: inline-block;
  width: ${(props) => props.width};
  margin: 0 16px 16px;
  /* height: 48px; */
  margin-top: 16px;
  margin-left: 16px;
  font-size: 15px;
  line-height: 1.6;
  color: #59616a;
  display: flex;
  align-items: center;

  font-family: 'Source Sans Pro';
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
`;

const SignupForm = ({
  form,
  language,
  onChange,
  onSelect,
  onChangeImage,
  disabled,
  imgRef,
  coverImgRef,
  recaptchaRef,
  onMouseOver,
  onMouseOut,
  onDeleteClick,
  imgSrc,
  onSubmit,
  error,
  onBackButtonEvent,
  onFocusOut,
  recaptcha,
  setRecaptcha,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isTabletOrDesktop = TabletOrDesktop();
  const mobileButtonMaxWidth = '320px';

  let languageName = English;
  if (language === 'de') languageName = German;
  else if (language === 'en') languageName = English;
  else if (language === 'es') languageName = Spanish;
  else if (language === 'fr') languageName = French;
  else if (language === 'ja') languageName = Japanese;
  else if (language === 'ko') languageName = Korean;
  else if (language === 'it') languageName = Italian;
  else if (language === 'pl') languageName = Polish;
  else if (language === 'pt') languageName = Portuguese;
  countries.registerLocale(languageName);

  const [countryCode, setCountryCode] = useState();
  const countryRef = useRef(null);

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);

    const ipInfo = async () => {
      const res = await getIpInfo();
      if (res.data) setCountryCode(res.data.country_code);
    };
    ipInfo();

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
      dispatch(initializeForm('authError'));
    };
  }, []);

  useEffect(() => {
    if (countryCode) {
      const country_name = countries.getName(countryCode, language);
      countryRef.current.setValue(country_name);
      onSelect(countryCode, 'country');
    }
  }, [countryCode]);

  return (
    <Container>
      {isTabletOrDesktop && <Logo />}
      {!isTabletOrDesktop && <MobileTop></MobileTop>}
      <ContentContainer>
        <Content maxWidth={isTabletOrDesktop ? '448px' : mobileButtonMaxWidth}>
          <ErrorList error={error}></ErrorList>
          <Title style={{ fontWeight: '600' }} error={error} mobile={!isTabletOrDesktop}>
            {t('Create New Account')}
          </Title>
          <SubTitle mobile={!isTabletOrDesktop}>
            {t('Enter your details below and click “Create Account”.')}
          </SubTitle>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (!recaptcha || error) {
                recaptchaRef.current.execute();
                setRecaptcha(true);
              }
            }}
          >
            <Input
              width={isTabletOrDesktop ? '416px' : '288px'}
              type="email"
              name="username"
              margin="0"
              placeholder={t('Email Address') + '*'}
              value={form.username}
              onChange={onChange}
              onBlur={onFocusOut}
              tabIndex={1}
              required
            />
            <ValidateDiv name="usernameValidate"></ValidateDiv>
            <Input
              width={isTabletOrDesktop ? '416px' : '288px'}
              type="password"
              name="password"
              margin="0"
              placeholder={t('Password') + '*'}
              value={form.password}
              onChange={onChange}
              onBlur={onFocusOut}
              tabIndex={2}
              required
            />
            <ValidateDiv name="passwordValidate"></ValidateDiv>
            <Input
              width={isTabletOrDesktop ? '416px' : '288px'}
              type="password"
              name="passwordConfirm"
              margin="0"
              placeholder={t('Confirm Password') + '*'}
              value={form.passwordConfirm}
              onChange={onChange}
              onBlur={onFocusOut}
              tabIndex={3}
              required
            />
            <ValidateDiv name="password_confirmValidate"></ValidateDiv>
            <Input
              width={isTabletOrDesktop ? '416px' : '288px'}
              type="text"
              name="first_name"
              margin="0"
              placeholder={t('First Name') + '*'}
              value={form.first_name}
              onChange={onChange}
              onBlur={onFocusOut}
              tabIndex={4}
              required
            />
            <ValidateDiv name="first_nameValidate"></ValidateDiv>
            <Input
              width={isTabletOrDesktop ? '416px' : '288px'}
              type="text"
              name="last_name"
              margin="0"
              placeholder={t('Last Name') + '*'}
              value={form.last_name}
              onChange={onChange}
              onBlur={onFocusOut}
              tabIndex={5}
              required
            />
            <ValidateDiv name="last_nameValidate"></ValidateDiv>
            <DropdownList
              name="role"
              width={isTabletOrDesktop ? '448px' : mobileButtonMaxWidth}
              // width="448px"
              height="48px"
              placeholder={t('Institution Type') + '*'}
              margin="8px 0 0 0"
              img_margin="12px 12px 12px 0"
              search={true}
              items={['Dental Clinic', 'Dental Lab', 'Other']}
              onSelect={onSelect}
              zIndex={2}
              tabIndex={6}
              required
            />
            <ValidateDiv name="roleValidate"></ValidateDiv>
            <Input
              name="institution"
              width={isTabletOrDesktop ? '416px' : '288px'}
              margin="0"
              placeholder={t('Institution Name')}
              value={form.institution}
              onChange={onChange}
              maxLength={100}
              tabIndex={7}
            />
            <DropdownList
              name="country"
              width={isTabletOrDesktop ? '448px' : mobileButtonMaxWidth}
              // width="448px"
              height="48px"
              maxHeight="216px"
              placeholder={t('Country') + '*'}
              defaultValue={countries.getName(countryCode, language)}
              margin="8px 0 0 0"
              img_margin="12px 12px 12px 0"
              search={true}
              items={Object.fromEntries(
                Object.entries(countries.getNames(language, { select: 'official' })).sort(function (
                  [, a],
                  [, b],
                ) {
                  return a.localeCompare(b);
                }),
              )}
              // itmes={countries.getNames(language, { select: 'official' })}
              onSelect={onSelect}
              zIndex={1}
              tabIndex={8}
              required
              ref={countryRef}
            />
            <ValidateDiv name="countryValidate"></ValidateDiv>
            <Input
              type="tel"
              width={isTabletOrDesktop ? '416px' : '288px'}
              name="phone"
              margin="0"
              placeholder={t('Phone')}
              value={form.phone}
              onChange={onChange}
              maxLength={20}
              tabIndex={9}
            />
            <Input
              as="textarea"
              width={isTabletOrDesktop ? '416px' : '288px'}
              style={{ resize: 'none', overflow: 'hidden' }}
              name="address"
              height="66px"
              margin="0"
              padding="14px 15px 14px 15px"
              placeholder={t('Address')}
              value={form.address}
              onChange={onChange}
              tabIndex={10}
              maxLength={200}
            />
            <Avatar>
              <ImageButton
                social_auth={false}
                style={{ marginTop: 8, marginLeft: 8 }}
                src={imgSrc}
                tabIndex={11}
                disabled={disabled}
                imgRef={imgRef}
                coverImgRef={coverImgRef}
                onChangeImage={onChangeImage}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                onDeleteClick={onDeleteClick}
              />
              <P width={isTabletOrDesktop ? '344px' : '216px'}>
                {t(
                  'Upload an image so your connections can easily recognize your cases (Optional)',
                )}
              </P>
            </Avatar>
            <Button
              width={isTabletOrDesktop ? '448px' : '320px'}
              data-action="submit"
              margin={isTabletOrDesktop ? '24px 0 224px 0' : '24px 0 80px 0'}
              tabIndex={12}
            >
              {t('Create Account').toUpperCase()}
            </Button>
            <ReCAPTCHA
              style={{ zIndex: 100, position: 'relative' }}
              sitekey="6LfVh2IbAAAAAFOCUOSItCkoladQJrr7HkSv66kv"
              onChange={(val) => {
                onSubmit(val);
              }}
              ref={recaptchaRef}
              onExpired={(exp) => {
                console.log(exp);
              }}
              onErrored={(err) => {
                console.log(err);
              }}
              hl={language}
              size="invisible"
            />
          </form>
        </Content>
      </ContentContainer>
    </Container>
  );
};

export default React.memo(SignupForm);
