import React, { Component } from 'react';
import styled, { css } from 'styled-components';

const getSVG = (name, color, colorOver, colorOut, colorDown, opacity) => {
  if (name === 'reply') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color}
      >
        <g fill-rule="nonzero">
          <path
            d="M9.378 4.32l-6.782 6.633c-.553.619-.553 1.475-.048 2.043l6.879 6.736.09.092c.889.845 2.483.195 2.483-1.139v-2.657l.387.012c1.88.076 3.255.417 4.815 1.442.713.469 1.381 1.06 1.999 1.787.976 1.151 2.853.324 2.63-1.183l-.045-.283c-.773-4.596-3.672-8.07-9.634-9.566L12 8.199V5.315c0-1.38-1.706-2.028-2.622-.995zm-4.995 7.678L10 6.504v2.298c0 .593.419 1.096.99 1.217l.361.081c4.643 1.083 7.093 3.369 8.096 6.508l.03.099-.221-.19c-.31-.256-.628-.491-.956-.706-2.039-1.34-3.807-1.726-6.213-1.782l-.883-.017c-.666.02-1.204.573-1.204 1.249v2.236l-5.617-5.499z"
            transform="translate(-996 -1480) translate(984 1472) translate(12 8)"
          />
        </g>
      </svg>
    );
  } else if (name === 'forward') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color}
        onMouseOver={(e) => e.currentTarget.setAttribute('fill', colorOver)}
        onMouseOut={(e) => e.currentTarget.setAttribute('fill', colorOut)}
        onMouseDown={(e) => e.currentTarget.setAttribute('fill', colorDown)}
      >
        <g fill-rule="evenodd">
          <path
            d="M19 11c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L19 13H4c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L4 11h15z"
            transform="translate(-513 -204) translate(296 112) translate(19 92) translate(198)"
          />
          <path
            d="M19.293 11.293c.39-.39 1.024-.39 1.414 0 .36.36.388.928.083 1.32l-.083.094-6 6c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094 6-6z"
            transform="translate(-513 -204) translate(296 112) translate(19 92) translate(198)"
          />
          <path
            d="M13.293 5.293c.36-.36.928-.388 1.32-.083l.094.083 6 6c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083-6-6c-.39-.39-.39-1.024 0-1.414z"
            transform="translate(-513 -204) translate(296 112) translate(19 92) translate(198)"
          />
        </g>
      </svg>
    );
  } else if (name === 'attach') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color}
        onMouseOver={(e) => e.currentTarget.setAttribute('fill', colorOver)}
        onMouseOut={(e) => e.currentTarget.setAttribute('fill', colorOut)}
        onMouseDown={(e) => e.currentTarget.setAttribute('fill', colorDown)}
      >
        <g fill-rule="evenodd">
          <path
            d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"
            transform="translate(-758 -385) translate(726 205) translate(20 76) translate(12 104)"
          />
        </g>
      </svg>
    );
  } else if (name === 'delete') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color}
        onMouseOver={(e) => e.currentTarget.setAttribute('fill', colorOver)}
        onMouseOut={(e) => e.currentTarget.setAttribute('fill', colorOut)}
        onMouseDown={(e) => e.currentTarget.setAttribute('fill', colorDown)}
      >
        <g fill-rule="evenodd">
          <path
            fill-rule="nonzero"
            d="M18 8c.513 0 .936.386.993.883L19 9v9c0 1.597-1.25 2.904-2.824 2.995L16 21H8c-1.597 0-2.904-1.25-2.995-2.824L5 18V9c0-.552.448-1 1-1 .513 0 .936.386.993.883L7 9v9c0 .512.387.935.883.993L8 19h8c.512 0 .935-.387.993-.883L17 18V9c0-.552.448-1 1-1zM20 4c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L20 6H4c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L4 4h16z"
            transform="translate(-1174 -1345) translate(694 1219) translate(1 80) translate(0 34) translate(479 12)"
          />
          <path
            d="M12 6c-1.104 0-2-.896-2-2s.896-2 2-2 2 .896 2 2-.896 2-2 2"
            transform="translate(-1174 -1345) translate(694 1219) translate(1 80) translate(0 34) translate(479 12)"
          />
          <path
            fill-rule="nonzero"
            d="M10 8c.513 0 .936.386.993.883L11 9v7c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L9 16V9c0-.552.448-1 1-1zM14 8c.513 0 .936.386.993.883L15 9v7c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L13 16V9c0-.552.448-1 1-1z"
            transform="translate(-1174 -1345) translate(694 1219) translate(1 80) translate(0 34) translate(479 12)"
          />
        </g>
      </svg>
    );
  } else if (name === 'delete_input') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color}
        onMouseOver={(e) => e.currentTarget.setAttribute('fill', colorOver)}
        onMouseOut={(e) => e.currentTarget.setAttribute('fill', colorOut)}
        onMouseDown={(e) => e.currentTarget.setAttribute('fill', colorDown)}
      >
        <g fill-rule="evenodd">
          <path
            d="M15.707 14.293c.391.391.391 1.023 0 1.414-.195.195-.451.293-.707.293-.256 0-.512-.098-.707-.293L12 13.414l-2.293 2.293C9.512 15.902 9.256 16 9 16c-.256 0-.512-.098-.707-.293-.391-.391-.391-1.023 0-1.414L10.586 12 8.293 9.707c-.391-.391-.391-1.023 0-1.414s1.023-.391 1.414 0L12 10.586l2.293-2.293c.391-.391 1.023-.391 1.414 0s.391 1.023 0 1.414L13.414 12l2.293 2.293zM12 4c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8z"
            transform="translate(-728 -325) translate(572 313) translate(156 12)"
          />
        </g>
      </svg>
    );
  } else if (name === 'close') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill={color}
        onMouseOver={(e) => e.currentTarget.setAttribute('fill', colorOver)}
        onMouseOut={(e) => e.currentTarget.setAttribute('fill', colorOut)}
        onMouseDown={(e) => e.currentTarget.setAttribute('fill', colorDown)}
      >
        <g fill-rule="evenodd">
          <path
            d="M18.293 4.293c.39-.39 1.024-.39 1.414 0 .36.36.388.928.083 1.32l-.083.094-14 14c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094 14-14z"
            transform="translate(-98 -527) translate(98.5 527)"
          />
          <path
            d="M4.293 4.293c.36-.36.928-.388 1.32-.083l.094.083 14 14c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083-14-14c-.39-.39-.39-1.024 0-1.414z"
            transform="translate(-98 -527) translate(98.5 527)"
          />
        </g>
      </svg>
    );
  } else if (name === 'more') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color}
        onMouseOver={(e) => e.currentTarget.setAttribute('fill', colorOver)}
        onMouseOut={(e) => e.currentTarget.setAttribute('fill', colorOut)}
        onMouseDown={(e) => e.currentTarget.setAttribute('fill', colorDown)}
      >
        <g fill-rule="evenodd">
          <path
            d="M14 12c0 1.104-.896 2-2 2s-2-.896-2-2 .896-2 2-2 2 .896 2 2M14 19c0 1.104-.896 2-2 2s-2-.896-2-2 .896-2 2-2 2 .896 2 2M14 5c0 1.104-.896 2-2 2s-2-.896-2-2 .896-2 2-2 2 .896 2 2"
            transform="translate(-1356 -217) translate(296 193) translate(878 16) translate(182 8)"
          />{' '}
        </g>
      </svg>
    );
  } else if (name === 'editSmall') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill={color}
        onMouseOver={(e) => e.currentTarget.setAttribute('fill', colorOver)}
        onMouseOut={(e) => e.currentTarget.setAttribute('fill', colorOut)}
        onMouseDown={(e) => e.currentTarget.setAttribute('fill', colorDown)}
      >
        <g fill-rule="evenodd">
          <path d="M14.16.07a1.69,1.69,0,0,1,1.22.51,1.74,1.74,0,0,1,0,2.45l-8,8a.59.59,0,0,1-.17.1l-2.76.92a.42.42,0,0,1-.27,0h0a.44.44,0,0,1-.26-.56l.93-2.75A1,1,0,0,1,5,8.58l8-8A1.73,1.73,0,0,1,14.19.07M16,14V12a2,2,0,0,0-2-2H11a1,1,0,0,0-1,1,1,1,0,0,0,1,1h3v2H2V12H2a1,1,0,0,0,1-1,1,1,0,0,0-1-1H2a2,2,0,0,0-2,2v2a2,2,0,0,0,2,2H14A2,2,0,0,0,16,14Z" />{' '}
        </g>
      </svg>
    );
  } else if (name === 'edit') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color}
        onMouseOver={(e) => e.currentTarget.setAttribute('fill', colorOver)}
        onMouseOut={(e) => e.currentTarget.setAttribute('fill', colorOut)}
        onMouseDown={(e) => e.currentTarget.setAttribute('fill', colorDown)}
      >
        <g fill-rule="evenodd">
          <path
            class="a"
            d="M18,13a1,1,0,0,0-1,1v5H5V5h7a1,1,0,0,0,0-2H5.8A3,3,0,0,0,3,6.1V17.9A3,3,0,0,0,5.8,21H16.2A3,3,0,0,0,19,17.9V14a1,1,0,0,0-1-1M19.85,2.07a2,2,0,0,0-1.42.59L9.2,11.89a.76.76,0,0,0-.12.19L8,15.26a.51.51,0,0,0,.31.64h0a.55.55,0,0,0,.31,0l3.18-1.06a.76.76,0,0,0,.19-.12l9.23-9.23a2,2,0,0,0,0-2.84,2,2,0,0,0-1.4-.58"
          />
        </g>
      </svg>
    );
  } else if (name === 'maximize') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill={color}
        onMouseOver={(e) => e.currentTarget.setAttribute('fill', colorOver)}
        onMouseOut={(e) => e.currentTarget.setAttribute('fill', colorOut)}
        onMouseDown={(e) => e.currentTarget.setAttribute('fill', colorDown)}
      >
        <g fill-rule="evenodd">
          <g>
            <path
              d="M8 3c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L8 5H4c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L4 3h4z"
              transform="translate(-98 -815) translate(98.5 815)"
            />
            <path
              d="M4 3c.513 0 .936.386.993.883L5 4v4c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L3 8V4c0-.552.448-1 1-1z"
              transform="translate(-98 -815) translate(98.5 815)"
            />
            <path
              d="M4.293 4.293c.36-.36.928-.388 1.32-.083l.094.083 4 4c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083-4-4c-.39-.39-.39-1.024 0-1.414zM20 3c.513 0 .936.386.993.883L21 4v4c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L19 8V4c0-.552.448-1 1-1z"
              transform="translate(-98 -815) translate(98.5 815)"
            />
            <path
              d="M20 3c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L20 5h-4c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L16 3h4z"
              transform="translate(-98 -815) translate(98.5 815)"
            />
            <path
              d="M18.293 4.293c.39-.39 1.024-.39 1.414 0 .36.36.388.928.083 1.32l-.083.094-4 4c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094 4-4zM20 19c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L20 21h-4c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L16 19h4z"
              transform="translate(-98 -815) translate(98.5 815)"
            />
            <path
              d="M20 15c.513 0 .936.386.993.883L21 16v4c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L19 20v-4c0-.552.448-1 1-1z"
              transform="translate(-98 -815) translate(98.5 815)"
            />
            <path
              d="M14.293 14.293c.36-.36.928-.388 1.32-.083l.094.083 4 4c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083-4-4c-.39-.39-.39-1.024 0-1.414zM4 15c.513 0 .936.386.993.883L5 16v4c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L3 20v-4c0-.552.448-1 1-1z"
              transform="translate(-98 -815) translate(98.5 815)"
            />
            <path
              d="M8 19c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L8 21H4c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L4 19h4z"
              transform="translate(-98 -815) translate(98.5 815)"
            />
            <path
              d="M8.293 14.293c.39-.39 1.024-.39 1.414 0 .36.36.388.928.083 1.32l-.083.094-4 4c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094 4-4z"
              transform="translate(-98 -815) translate(98.5 815)"
            />
          </g>
        </g>
      </svg>
    );
  } else if (name == 'dashboard') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color}
        // onMouseOver={(e) => e.currentTarget.setAttribute('fill', colorOver)}
        // onMouseOut={(e) => e.currentTarget.setAttribute('fill', colorOut)}
        // onMouseDown={(e) => e.currentTarget.setAttribute('fill', colorDown)}
      >
        <g fill-rule="evenodd">
          <path
            d="M8 3H6C4.344 3 3 4.344 3 6v2c0 1.656 1.344 3 3 3h2c1.656 0 3-1.344 3-3V6c0-1.656-1.344-3-3-3zM6 5h2c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1H6c-.552 0-1-.448-1-1V6c0-.552.448-1 1-1zM18 3h-2c-1.656 0-3 1.344-3 3v2c0 1.656 1.344 3 3 3h2c1.656 0 3-1.344 3-3V6c0-1.656-1.344-3-3-3zm-2 2h2c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1h-2c-.552 0-1-.448-1-1V6c0-.552.448-1 1-1zM8 13H6c-1.656 0-3 1.344-3 3v2c0 1.656 1.344 3 3 3h2c1.656 0 3-1.344 3-3v-2c0-1.656-1.344-3-3-3zm-2 2h2c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1H6c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1zM18 13h-2c-1.656 0-3 1.344-3 3v2c0 1.656 1.344 3 3 3h2c1.656 0 3-1.344 3-3v-2c0-1.656-1.344-3-3-3zm-2 2h2c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1h-2c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1z"
            transform="translate(-20 -128) translate(0 120) translate(20 8)"
          />
        </g>
      </svg>
    );
  } else if (name == 'cases') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color}
        // onMouseOver={(e) => e.currentTarget.setAttribute('fill', colorOver)}
        // onMouseOut={(e) => e.currentTarget.setAttribute('fill', colorOut)}
        // onMouseDown={(e) => e.currentTarget.setAttribute('fill', colorDown)}
      >
        <g fill-rule="evenodd">
          <path
            d="M17 2H7C5.344 2 4 3.344 4 5v14c0 1.656 1.344 3 3 3h10c1.656 0 3-1.344 3-3V5c0-1.656-1.344-3-3-3zM7 4h10c.552 0 1 .448 1 1v14c0 .552-.448 1-1 1H7c-.552 0-1-.448-1-1V5c0-.552.448-1 1-1z"
            transform="translate(-20 -168) translate(0 160) translate(20 8)"
          />
          <path
            d="M15 8c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L15 10H9c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L9 8h6zM11 12c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L11 14H9c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L9 12h2z"
            transform="translate(-20 -168) translate(0 160) translate(20 8)"
          />
        </g>
      </svg>
    );
  } else if (name == 'connections') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color}
        // onMouseOver={(e) => e.currentTarget.setAttribute('fill', colorOver)}
        // onMouseOut={(e) => e.currentTarget.setAttribute('fill', colorOut)}
        // onMouseDown={(e) => e.currentTarget.setAttribute('fill', colorDown)}
      >
        <g fill-rule="evenodd">
          <path
            d="M13 15c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L13 17H5c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L5 15h8z"
            transform="translate(-20 -248) translate(0 240) translate(20 8)"
          />
          <path
            d="M6.293 12.293c.39-.39 1.024-.39 1.414 0 .36.36.388.928.083 1.32l-.083.094-3 3c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094 3-3z"
            transform="translate(-20 -248) translate(0 240) translate(20 8)"
          />
          <path
            d="M3.293 15.293c.36-.36.928-.388 1.32-.083l.094.083 3 3c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083-3-3c-.39-.39-.39-1.024 0-1.414zM19 7c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L19 9h-8c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L11 7h8z"
            transform="translate(-20 -248) translate(0 240) translate(20 8)"
          />
          <path
            d="M19.293 7.293c.39-.39 1.024-.39 1.414 0 .36.36.388.928.083 1.32l-.083.094-3 3c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094 3-3z"
            transform="translate(-20 -248) translate(0 240) translate(20 8)"
          />
          <path
            d="M16.293 4.293c.36-.36.928-.388 1.32-.083l.094.083 3 3c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083-3-3c-.39-.39-.39-1.024 0-1.414z"
            transform="translate(-20 -248) translate(0 240) translate(20 8)"
          />
        </g>
      </svg>
    );
  } else if (name === 'more') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color}
        onMouseOver={(e) => e.currentTarget.setAttribute('fill', colorOver)}
        onMouseOut={(e) => e.currentTarget.setAttribute('fill', colorOut)}
        onMouseDown={(e) => e.currentTarget.setAttribute('fill', colorDown)}
      >
        <g fill-rule="evenodd">
          <path
            d="M14 12c0 1.104-.896 2-2 2s-2-.896-2-2 .896-2 2-2 2 .896 2 2M14 19c0 1.104-.896 2-2 2s-2-.896-2-2 .896-2 2-2 2 .896 2 2M14 5c0 1.104-.896 2-2 2s-2-.896-2-2 .896-2 2-2 2 .896 2 2"
            transform="translate(-1356 -217) translate(296 193) translate(878 16) translate(182 8)"
          />{' '}
        </g>
      </svg>
    );
  } else if (name === 'maximize') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill={color}
        onMouseOver={(e) => e.currentTarget.setAttribute('fill', colorOver)}
        onMouseOut={(e) => e.currentTarget.setAttribute('fill', colorOut)}
        onMouseDown={(e) => e.currentTarget.setAttribute('fill', colorDown)}
      >
        <g fill-rule="evenodd">
          <g>
            <path
              d="M8 3c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L8 5H4c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L4 3h4z"
              transform="translate(-98 -815) translate(98.5 815)"
            />
            <path
              d="M4 3c.513 0 .936.386.993.883L5 4v4c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L3 8V4c0-.552.448-1 1-1z"
              transform="translate(-98 -815) translate(98.5 815)"
            />
            <path
              d="M4.293 4.293c.36-.36.928-.388 1.32-.083l.094.083 4 4c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083-4-4c-.39-.39-.39-1.024 0-1.414zM20 3c.513 0 .936.386.993.883L21 4v4c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L19 8V4c0-.552.448-1 1-1z"
              transform="translate(-98 -815) translate(98.5 815)"
            />
            <path
              d="M20 3c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L20 5h-4c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L16 3h4z"
              transform="translate(-98 -815) translate(98.5 815)"
            />
            <path
              d="M18.293 4.293c.39-.39 1.024-.39 1.414 0 .36.36.388.928.083 1.32l-.083.094-4 4c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094 4-4zM20 19c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L20 21h-4c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L16 19h4z"
              transform="translate(-98 -815) translate(98.5 815)"
            />
            <path
              d="M20 15c.513 0 .936.386.993.883L21 16v4c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L19 20v-4c0-.552.448-1 1-1z"
              transform="translate(-98 -815) translate(98.5 815)"
            />
            <path
              d="M14.293 14.293c.36-.36.928-.388 1.32-.083l.094.083 4 4c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083-4-4c-.39-.39-.39-1.024 0-1.414zM4 15c.513 0 .936.386.993.883L5 16v4c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L3 20v-4c0-.552.448-1 1-1z"
              transform="translate(-98 -815) translate(98.5 815)"
            />
            <path
              d="M8 19c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L8 21H4c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L4 19h4z"
              transform="translate(-98 -815) translate(98.5 815)"
            />
            <path
              d="M8.293 14.293c.39-.39 1.024-.39 1.414 0 .36.36.388.928.083 1.32l-.083.094-4 4c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094 4-4z"
              transform="translate(-98 -815) translate(98.5 815)"
            />
          </g>
        </g>
      </svg>
    );
  } else if (name === 'refresh') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill={color}
        onMouseOver={(e) => e.currentTarget.setAttribute('fill', colorOver)}
        onMouseOut={(e) => e.currentTarget.setAttribute('fill', colorOut)}
        onMouseDown={(e) => e.currentTarget.setAttribute('fill', colorDown)}
      >
        <g fill-rule="evenodd">
          <path
            class="a"
            d="M20.55,3.15a1,1,0,0,0-1.09.9l-.3,3.17a9,9,0,1,0,.17,9.3,1,1,0,1,0-1.73-1,7,7,0,1,1,.17-6.68l-3.65-.24a1,1,0,1,0-.14,2l5.86.4h.07a1,1,0,0,0,1-.9l.55-5.84A1,1,0,0,0,20.55,3.15Z"
          />
        </g>
      </svg>
    );
  } else if (name === 'filter') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill={color}
        onMouseOver={(e) => e.currentTarget.setAttribute('fill', colorOver)}
        onMouseOut={(e) => e.currentTarget.setAttribute('fill', colorOut)}
        onMouseDown={(e) => e.currentTarget.setAttribute('fill', colorDown)}
      >
        <g fill-rule="evenodd">
          <g>
            <path
              d="M13 17c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L13 19H3c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L3 17h10zM13 5c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L13 7H3c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L3 5h10zM21 17c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L21 19h-4c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L17 17h4z"
              transform="translate(-98 -767) translate(98.5 767)"
            />
            <path
              d="M17 15c.513 0 .936.386.993.883L18 16v4c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L16 20v-4c0-.552.448-1 1-1zM21 5c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L21 7h-4c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L17 5h4z"
              transform="translate(-98 -767) translate(98.5 767)"
            />
            <path
              d="M17 3c.513 0 .936.386.993.883L18 4v4c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L16 8V4c0-.552.448-1 1-1zM21 11c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L21 13H11c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L11 11h10zM7 11c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L7 13H3c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L3 11h4z"
              transform="translate(-98 -767) translate(98.5 767)"
            />
            <path
              d="M7 9c.513 0 .936.386.993.883L8 10v4c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L6 14v-4c0-.552.448-1 1-1z"
              transform="translate(-98 -767) translate(98.5 767)"
            />
          </g>
        </g>
      </svg>
    );
  } else if (name === 'arrow') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill={color}
        onMouseOver={(e) => e.currentTarget.setAttribute('fill', colorOver)}
        onMouseOut={(e) => e.currentTarget.setAttribute('fill', colorOut)}
        onMouseDown={(e) => e.currentTarget.setAttribute('fill', colorDown)}
      >
        <g fill-rule="evenodd">
          <path
            d="M12.793 5.793c.39-.39 1.024-.39 1.414 0 .36.36.388.928.083 1.32l-.083.094-5 5c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094 5-5z"
            transform="translate(-98 -191) translate(98.5 191)"
          />
          <path
            d="M7.793 10.793c.36-.36.928-.388 1.32-.083l.094.083 5 5c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083-5-5c-.39-.39-.39-1.024 0-1.414z"
            transform="translate(-98 -191) translate(98.5 191)"
          />
        </g>
      </svg>
    );
  } else if (name === 'arrow_first') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color}
        onMouseOver={(e) => e.currentTarget.setAttribute('fill', colorOver)}
        onMouseOut={(e) => e.currentTarget.setAttribute('fill', colorOut)}
        onMouseDown={(e) => e.currentTarget.setAttribute('fill', colorDown)}
      >
        <g fill-rule="nonzero">
          <g>
            <path
              class="c"
              d="M8.99,5.88v11.12c0,.55-.44,1-.99,1-.51,0-.94-.39-.99-.88V6c0-.55,.44-1,.99-1,.51,0,.94,.39,.99,.88Zm8.63,9.91l-4.29-4.29,4.29-4.29,.08-.09c.3-.39,.28-.96-.08-1.32-.39-.39-1.02-.39-1.41,0l-5,5-.08,.09s-.05,.09-.07,.14c-.03,.05-.05,.09-.07,.14-.03,.07-.03,.15-.04,.22,0,.05-.02,.09-.02,.14,0,.08,.02,.16,.05,.24,.01,.04,.01,.08,.03,.12,.05,.12,.12,.22,.21,.32l5,5,.09,.08c.39,.3,.96,.28,1.32-.08,.39-.39,.39-1.02,0-1.41Z"
            />
          </g>
        </g>
      </svg>
    );
  } else if (name === 'arrow_end') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color}
        onMouseOver={(e) => e.currentTarget.setAttribute('fill', colorOver)}
        onMouseOut={(e) => e.currentTarget.setAttribute('fill', colorOut)}
        onMouseDown={(e) => e.currentTarget.setAttribute('fill', colorDown)}
      >
        <g fill-rule="nonzero">
          <g>
            <path
              class="c"
              d="M14.93,11.83s-.05,.09-.07,.14c-.03,.05-.04,.1-.07,.14l-.08,.09-5,5c-.39,.39-1.02,.39-1.41,0-.36-.36-.39-.93-.08-1.32l.08-.09,4.29-4.29-4.29-4.29c-.39-.39-.39-1.02,0-1.41,.36-.36,.93-.39,1.32-.08l.09,.08,5,5c.09,.09,.16,.2,.21,.32,.02,.04,.02,.08,.03,.12,.02,.08,.04,.16,.05,.24,0,.05-.01,.09-.02,.14,0,.08-.02,.15-.04,.22Zm4.06-5.95c-.06-.5-.48-.88-.99-.88-.55,0-1,.45-1,1v11.12c.06,.5,.49,.88,1,.88,.55,0,1-.45,1-1V5.88Z"
            />
          </g>
        </g>
      </svg>
    );
  } else if (name === 'arrow_left') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color}
        onMouseOver={(e) => e.currentTarget.setAttribute('fill', colorOver)}
        onMouseOut={(e) => e.currentTarget.setAttribute('fill', colorOut)}
        onMouseDown={(e) => e.currentTarget.setAttribute('fill', colorDown)}
      >
        <g fill-rule="nonzero">
          <g>
            <path
              d="M12.793 5.793c.39-.39 1.024-.39 1.414 0 .36.36.388.928.083 1.32l-.083.094-5 5c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094 5-5z"
              transform="translate(-98 -191) translate(98.5 191)"
            />
            <path
              d="M7.793 10.793c.36-.36.928-.388 1.32-.083l.094.083 5 5c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083-5-5c-.39-.39-.39-1.024 0-1.414z"
              transform="translate(-98 -191) translate(98.5 191)"
            />
          </g>
        </g>
      </svg>
    );
  } else if (name === 'arrow_right') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color}
        onMouseOver={(e) => e.currentTarget.setAttribute('fill', colorOver)}
        onMouseOut={(e) => e.currentTarget.setAttribute('fill', colorOut)}
        onMouseDown={(e) => e.currentTarget.setAttribute('fill', colorDown)}
      >
        <g fill-rule="nonzero">
          <g>
            <g>
              <path
                d="M14.793 10.793c.39-.39 1.024-.39 1.414 0 .36.36.388.928.083 1.32l-.083.094-5 5c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094 5-5z"
                transform="translate(-658 -762) translate(256 750) translate(402 12)"
              />
              <path
                d="M9.793 5.793c.36-.36.928-.388 1.32-.083l.094.083 5 5c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083-5-5c-.39-.39-.39-1.024 0-1.414z"
                transform="translate(-658 -762) translate(256 750) translate(402 12)"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  } else if (name === 'collapse') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color}
        onMouseOver={(e) => e.currentTarget.setAttribute('fill', colorOver)}
        onMouseOut={(e) => e.currentTarget.setAttribute('fill', colorOut)}
        onMouseDown={(e) => e.currentTarget.setAttribute('fill', colorDown)}
      >
        <g fill-rule="nonzero">
          <g>
            <g>
              <path
                d="M20 11c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L20 13H5c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L5 11h15z"
                transform="translate(-772 -120) translate(560 96) translate(212 24)"
              />
              <path
                d="M9.293 5.293c.39-.39 1.024-.39 1.414 0 .36.36.388.928.083 1.32l-.083.094-6 6c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094 6-6z"
                transform="translate(-772 -120) translate(560 96) translate(212 24)"
              />
              <path
                d="M3.293 11.293c.36-.36.928-.388 1.32-.083l.094.083 6 6c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083-6-6c-.39-.39-.39-1.024 0-1.414zM20 5c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L20 7h-5c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L15 5h5zM20 17c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L20 19h-5c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L15 17h5z"
                transform="translate(-772 -120) translate(560 96) translate(212 24)"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  } else if (name === 'calendar_icon') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color}
        onMouseOver={(e) => e.currentTarget.setAttribute('fill', colorOver)}
        onMouseOut={(e) => e.currentTarget.setAttribute('fill', colorOut)}
        onMouseDown={(e) => e.currentTarget.setAttribute('fill', colorDown)}
      >
        <g fill-rule="nonzero">
          <path
            fill-rule="nonzero"
            d="M18 3H6C4.344 3 3 4.344 3 6v12c0 1.656 1.344 3 3 3h12c1.656 0 3-1.344 3-3V6c0-1.656-1.344-3-3-3zM6 5h12c.552 0 1 .448 1 1v12c0 .552-.448 1-1 1H6c-.552 0-1-.448-1-1V6c0-.552.448-1 1-1z"
            transform="translate(-98 -431) translate(98.5 431)"
          />
          <path
            d="M9 12c0 .552-.448 1-1 1s-1-.448-1-1 .448-1 1-1 1 .448 1 1M17 12c0 .552-.448 1-1 1s-1-.448-1-1 .448-1 1-1 1 .448 1 1M13 12c0 .552-.448 1-1 1s-1-.448-1-1 .448-1 1-1 1 .448 1 1M9 16c0 .552-.448 1-1 1s-1-.448-1-1 .448-1 1-1 1 .448 1 1M17 16c0 .552-.448 1-1 1s-1-.448-1-1 .448-1 1-1 1 .448 1 1M13 16c0 .552-.448 1-1 1s-1-.448-1-1 .448-1 1-1 1 .448 1 1"
            transform="translate(-98 -431) translate(98.5 431)"
          />
          <path
            fill-rule="nonzero"
            d="M20 7c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L20 9H4c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L4 7h16z"
            transform="translate(-98 -431) translate(98.5 431)"
          />
        </g>
      </svg>
    );
  } else if (name === 'Upper') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill={color}
      >
        <g fillRule="evenodd">
          <path
            d="M44.73,23.24C42.42,22.19,37.62,20,28,20s-14.42,2.19-16.73,3.24A4,4,0,0,0,9,26.85V31h.05a3.47,3.47,0,0,1,5.63-2.22,4,4,0,0,1,6-.7A4.46,4.46,0,0,1,28,27.71a4.46,4.46,0,0,1,7.28.37,4,4,0,0,1,6,.7A3.42,3.42,0,0,1,43.5,28,3.49,3.49,0,0,1,47,31h0V26.85a4,4,0,0,0-2.27-3.61"
            opacity="0.1"
          />
          <path
            d="M47,31h0a3.47,3.47,0,0,0-5.63-2.22,4,4,0,0,0-6-.7A4.46,4.46,0,0,0,28,27.71a4.46,4.46,0,0,0-7.28.37A4,4,0,0,0,18,27a4,4,0,0,0-3.32,1.78A3.47,3.47,0,0,0,9.05,31H9V26.85a4,4,0,0,1,2.27-3.61C13.58,22.19,18.38,20,28,20s14.42,2.19,16.73,3.24A4,4,0,0,1,47,26.85Zm-2,2a1,1,0,0,1-.38.78l-.5.4a1,1,0,0,1-1.24,0l-.5-.4A1,1,0,0,1,42,33V31.5a1.5,1.5,0,0,1,3,0ZM40,31.5V34a1,1,0,0,1-1,1H37a1,1,0,0,1-1-1V31a2,2,0,0,1,4,0ZM34,31v4a1,1,0,0,1-1,1H30a1,1,0,0,1-1-1V30.5a2.5,2.5,0,0,1,5,0Zm-7,4a1,1,0,0,1-1,1H23a1,1,0,0,1-1-1V30.5a2.5,2.5,0,0,1,5,0Zm-7-1a1,1,0,0,1-1,1H17a1,1,0,0,1-1-1V31a2,2,0,0,1,4,0Zm-6-1a1,1,0,0,1-.38.78l-.5.4a1,1,0,0,1-1.24,0l-.5-.4A1,1,0,0,1,11,33V31.5a1.5,1.5,0,0,1,3,0ZM45.56,21.42C43.31,20.4,38.06,18,28,18s-15.31,2.4-17.56,3.42A6,6,0,0,0,7,26.85V31a2,2,0,0,0,2,2v0a3,3,0,0,0,1.13,2.34l.5.4a3,3,0,0,0,3.74,0l.14-.11A3,3,0,0,0,17,37h2a2.94,2.94,0,0,0,1.47-.4A3,3,0,0,0,23,38h3a3,3,0,0,0,2-.78A3,3,0,0,0,30,38h3a3,3,0,0,0,2.53-1.4A2.94,2.94,0,0,0,37,37h2a3,3,0,0,0,2.49-1.33l.14.11a3,3,0,0,0,3.74,0l.5-.4A3,3,0,0,0,47,33v0a2,2,0,0,0,2-2V26.85A6,6,0,0,0,45.56,21.42Z"
            opacity={opacity}
          />
          <path
            d="M31.5,28A2.5,2.5,0,0,0,29,30.5V35a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V30.5A2.5,2.5,0,0,0,31.5,28m-7,0A2.5,2.5,0,0,0,22,30.5V35a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V30.5A2.5,2.5,0,0,0,24.5,28M18,29a2,2,0,0,0-2,2v3a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V31a2,2,0,0,0-2-2m-5.5,1A1.5,1.5,0,0,0,11,31.5V33a1,1,0,0,0,.38.78l.5.4a1,1,0,0,0,1.24,0l.5-.4A1,1,0,0,0,14,33V31.5A1.5,1.5,0,0,0,12.5,30m31,0A1.5,1.5,0,0,0,42,31.5V33a1,1,0,0,0,.38.78l.5.4a1,1,0,0,0,1.24,0l.5-.4A1,1,0,0,0,45,33V31.5A1.5,1.5,0,0,0,43.5,30M38,29a2,2,0,0,0-2,2v3a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V31a2,2,0,0,0-2-2"
            fill="#fff"
          />
        </g>
      </svg>
    );
  } else if (name === 'Lower') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill={color}
      >
        <g fillRule="evenodd">
          <path
            d="M43.5,28a3.4,3.4,0,0,1-1.79-.51,4,4,0,0,1-6.43,1.43,4.46,4.46,0,0,1-7.28.37,4.46,4.46,0,0,1-7.28-.37,4,4,0,0,1-6.43-1.43A3.4,3.4,0,0,1,12.5,28a3.5,3.5,0,0,1-3.45-3H9v4.15a4,4,0,0,0,2.27,3.61C13.58,33.81,18.38,36,28,36s14.42-2.19,16.73-3.24A4,4,0,0,0,47,29.15V25h0a3.5,3.5,0,0,1-3.45,3"
            opacity="0.1"
          />
          <path
            d="M47,29.15a4,4,0,0,1-2.27,3.61C42.42,33.81,37.62,36,28,36s-14.42-2.19-16.73-3.24A4,4,0,0,1,9,29.15V25h.05a3.5,3.5,0,0,0,3.45,3,3.4,3.4,0,0,0,1.79-.51,4,4,0,0,0,6.43,1.43,4.46,4.46,0,0,0,7.28.37,4.46,4.46,0,0,0,7.28-.37,4,4,0,0,0,6.43-1.43A3.4,3.4,0,0,0,43.5,28,3.5,3.5,0,0,0,47,25h0ZM11,22a1,1,0,0,1,.38-.78l.5-.4a1,1,0,0,1,1.24,0l.5.4A1,1,0,0,1,14,22V24.5a1.5,1.5,0,0,1-3,0Zm5,2.54V22a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1v4a2,2,0,0,1-4,0V24.5ZM22,26V23a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1v3.5a2.5,2.5,0,0,1-5,0V26Zm7-3a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1v3.5a2.5,2.5,0,0,1-5,0V23Zm7,0V22a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1v4a2,2,0,0,1-4,0Zm6-1v0a1,1,0,0,1,.38-.78l.5-.4a1,1,0,0,1,1.24,0l.5.4A1,1,0,0,1,45,22V24.5a1.5,1.5,0,0,1-3,0V22Zm5,1V22a3,3,0,0,0-1.13-2.34l-.5-.4a3,3,0,0,0-3.74,0l-.5.4c-.06,0-.1.1-.15.14A3,3,0,0,0,39,19H37a3,3,0,0,0-2.53,1.4A2.94,2.94,0,0,0,33,20H30a3,3,0,0,0-2,.78A3,3,0,0,0,26,20H23a2.94,2.94,0,0,0-1.47.4A3,3,0,0,0,19,19H17a3,3,0,0,0-2,.76s-.09-.1-.15-.14l-.5-.4a3,3,0,0,0-3.74,0l-.5.4A3,3,0,0,0,9,22v1a2,2,0,0,0-2,2v4.15a6,6,0,0,0,3.44,5.43C12.69,35.6,17.94,38,28,38s15.31-2.4,17.56-3.42A6,6,0,0,0,49,29.15V25A2,2,0,0,0,47,23Z"
            opacity={opacity}
          />
          <path
            d="M24.5,29A2.5,2.5,0,0,0,27,26.5V23a1,1,0,0,0-1-1H23a1,1,0,0,0-1,1v3.5A2.5,2.5,0,0,0,24.5,29m7,0A2.5,2.5,0,0,0,34,26.5V23a1,1,0,0,0-1-1H30a1,1,0,0,0-1,1v3.5A2.5,2.5,0,0,0,31.5,29m12-3A1.5,1.5,0,0,0,45,24.5V22a1,1,0,0,0-.38-.78l-.5-.4a1,1,0,0,0-1.24,0l-.5.4A1,1,0,0,0,42,22V24.5A1.5,1.5,0,0,0,43.5,26M38,28a2,2,0,0,0,2-2V22a1,1,0,0,0-1-1H37a1,1,0,0,0-1,1v4a2,2,0,0,0,2,2M18,28a2,2,0,0,0,2-2V22a1,1,0,0,0-1-1H17a1,1,0,0,0-1,1v4a2,2,0,0,0,2,2m-5.5-2A1.5,1.5,0,0,0,14,24.5V22a1,1,0,0,0-.38-.78l-.5-.4a1,1,0,0,0-1.24,0l-.5.4A1,1,0,0,0,11,22V24.5A1.5,1.5,0,0,0,12.5,26"
            fill="#fff"
          />
        </g>
      </svg>
    );
  } else if (name === 'UpperBody') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
        <g fillRule="evenodd">
          <path
            d="M8,24.25a3.14,3.14,0,0,1,1.19-2.63C12.48,20,18.32,20,28,20s15.52,0,18.81,1.62A3.14,3.14,0,0,1,48,24.25v3.67h0a14.87,14.87,0,0,0-5.64-1,21.91,21.91,0,0,0-7.17,1.29h0l-.66.25a12.64,12.64,0,0,0-2.48-1,13.62,13.62,0,0,0-3.81-.54,15.11,15.11,0,0,0-4.25.62h0a15,15,0,0,0-2.51.95l-.36-.11-.2-.06h0a22.35,22.35,0,0,0-6.75-1.06A21.35,21.35,0,0,0,8.24,28a.05.05,0,0,1,0,0l-.2.11H8Zm38.45,8.38a2.17,2.17,0,0,1-1.22,1.86c-.31.14-1.14.16-3.2-1.25l-.74-.53c-.31-.23-.57-.42-.77-.54A1.06,1.06,0,0,0,40,32a1,1,0,0,0-.86.49,1,1,0,0,0,.34,1.37l.18.12a2.74,2.74,0,0,1-1.61.52,1.57,1.57,0,0,1-1.36-1.21,15.34,15.34,0,0,1-.21-3.51c2.12-.68,6.24-1.62,10.11-.29C46.57,30.73,46.5,32.22,46.45,32.63ZM31,39H27.6L25,36.57V29.25a12.48,12.48,0,0,1,6-.06ZM19.45,32.63a2.17,2.17,0,0,1-1.22,1.86c-.31.14-1.14.16-3.2-1.25l-.74-.53c-.31-.23-.58-.42-.77-.54A1.07,1.07,0,0,0,13,32a1,1,0,0,0-.85.49,1,1,0,0,0,.34,1.37l.18.12a2.74,2.74,0,0,1-1.61.52A1.57,1.57,0,0,1,9.7,33.31a16.66,16.66,0,0,1-.21-3.57,19.72,19.72,0,0,1,10.1.19C19.56,31.09,19.5,32.26,19.45,32.63ZM6.54,30.05a1,1,0,0,0,.94.31,15.16,15.16,0,0,0,.31,3.54,3.51,3.51,0,0,0,3.11,2.63,5.84,5.84,0,0,0,3.55-1.27h0c1.43.91,3.14,1.71,4.59,1.06a4.18,4.18,0,0,0,2.38-3.42c.06-.46.11-1.51.15-2.47a1.64,1.64,0,0,0,.68-.18c.14-.07.4-.2.74-.34v6.76A1.73,1.73,0,0,0,23.56,38l2.74,2.58a1.77,1.77,0,0,0,1.2.47h3.75A1.75,1.75,0,0,0,33,39.25V29.88c.23.11.45.2.67.32a1.78,1.78,0,0,0,.81.21,14.28,14.28,0,0,0,.31,3.49,3.51,3.51,0,0,0,3.11,2.63,5.81,5.81,0,0,0,3.55-1.27h0c1.43.91,3.14,1.7,4.59,1.06a4.18,4.18,0,0,0,2.38-3.42c.06-.45.11-1.56.14-2.57a1,1,0,0,0,.92-.31A1.87,1.87,0,0,0,50,28.75v-4.5c0-1.64-.71-3.64-2.31-4.42C44,18,38,18,28,18S12,18,8.31,19.83C6.71,20.61,6,22.61,6,24.25v4.5A1.89,1.89,0,0,0,6.54,30.05Z"
            fill={color}
            opacity={opacity}
          />
          <path
            d="M36.48,29.81a15.18,15.18,0,0,0,.22,3.51,1.56,1.56,0,0,0,1.35,1.21A2.77,2.77,0,0,0,39.67,34l-.19-.12a1,1,0,0,1-.34-1.37A1,1,0,0,1,40,32a1.06,1.06,0,0,1,.51.14c.2.12.47.31.78.54s.47.35.74.53c2.06,1.41,2.88,1.39,3.2,1.25a2.14,2.14,0,0,0,1.21-1.85c.06-.42.12-1.91.15-3.12-3.86-1.33-8-.39-10.11.29M25,29.25v7.32L27.59,39H31V29.19a12.47,12.47,0,0,0-6,.06m-15.52.49a15.88,15.88,0,0,0,.22,3.57,1.56,1.56,0,0,0,1.35,1.22A2.79,2.79,0,0,0,12.67,34l-.19-.12a1,1,0,0,1-.34-1.37A1,1,0,0,1,13,32a1.06,1.06,0,0,1,.51.14c.2.12.46.31.77.54s.48.35.75.53c2.06,1.41,2.88,1.39,3.19,1.25a2.15,2.15,0,0,0,1.22-1.86c0-.37.11-1.54.14-2.7a19.72,19.72,0,0,0-10.1-.19"
            fill="#fff"
          />
          <path
            d="M8.2,28l0,0a21.21,21.21,0,0,1,5.91-.83,22.3,22.3,0,0,1,6.75,1.06h0l.2.07.36.11A15,15,0,0,1,24,27.46h0a15.58,15.58,0,0,1,4.25-.61,13.52,13.52,0,0,1,3.81.54,12.73,12.73,0,0,1,2.49,1l.65-.25h0a21.9,21.9,0,0,1,7.16-1.29,14.92,14.92,0,0,1,5.65,1h0l0-3.67a3.11,3.11,0,0,0-1.19-2.63C43.51,20,37.67,20,28,20S12.48,20,9.18,21.62A3.14,3.14,0,0,0,8,24.25v3.88H8A1.39,1.39,0,0,1,8.2,28"
            fill={color}
            opacity="0.1"
          />
        </g>
      </svg>
    );
  } else if (name === 'LowerBody') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill={color}
      >
        <g fillRule="evenodd">
          <path
            d="M48,31.75a3.11,3.11,0,0,1-1.19,2.63C43.52,36,37.68,36,28,36S12.48,36,9.19,34.38A3.14,3.14,0,0,1,8,31.75V28.08H8a14.87,14.87,0,0,0,5.64,1,21.91,21.91,0,0,0,7.17-1.29h0l.66-.25a12.64,12.64,0,0,0,2.48,1,13.62,13.62,0,0,0,3.81.54A15.11,15.11,0,0,0,32,28.54h0a15,15,0,0,0,2.51-.95l.36.11.2.06h0a22.35,22.35,0,0,0,6.75,1.06A21.35,21.35,0,0,0,47.76,28l0,0,.2-.11h0ZM9.55,23.37a2.17,2.17,0,0,1,1.22-1.86c.31-.14,1.14-.16,3.2,1.25l.74.53c.31.23.57.42.77.54A1.06,1.06,0,0,0,16,24a1,1,0,0,0,.86-.49,1,1,0,0,0-.34-1.37L16.33,22a2.74,2.74,0,0,1,1.61-.52,1.57,1.57,0,0,1,1.36,1.21,15.34,15.34,0,0,1,.21,3.51c-2.12.68-6.24,1.62-10.11.29C9.43,25.27,9.5,23.78,9.55,23.37ZM25,17h3.4L31,19.43v7.32a12.48,12.48,0,0,1-6,.06Zm11.55,6.37a2.17,2.17,0,0,1,1.22-1.86c.31-.14,1.14-.16,3.2,1.25l.74.53c.31.23.57.42.77.54A1.06,1.06,0,0,0,43,24a1,1,0,0,0,.86-.49,1,1,0,0,0-.34-1.37L43.33,22a2.74,2.74,0,0,1,1.61-.52,1.57,1.57,0,0,1,1.36,1.22,16.66,16.66,0,0,1,.21,3.57,19.72,19.72,0,0,1-10.1-.19C36.44,24.91,36.5,23.74,36.55,23.37ZM49.46,26a1,1,0,0,0-.94-.31,15.16,15.16,0,0,0-.31-3.54,3.51,3.51,0,0,0-3.11-2.63,5.84,5.84,0,0,0-3.55,1.27h0C40.11,19.83,38.4,19,37,19.68a4.17,4.17,0,0,0-2.38,3.42c-.06.46-.11,1.5-.14,2.47a1.64,1.64,0,0,0-.69.18l-.74.34V19.33a1.73,1.73,0,0,0-.56-1.28L29.7,15.47A1.77,1.77,0,0,0,28.5,15H24.75A1.75,1.75,0,0,0,23,16.75v9.37c-.23-.11-.45-.2-.67-.32a1.78,1.78,0,0,0-.81-.21,14.28,14.28,0,0,0-.31-3.49,3.51,3.51,0,0,0-3.11-2.63,5.81,5.81,0,0,0-3.55,1.27h0C13.11,19.83,11.4,19,10,19.68A4.17,4.17,0,0,0,7.57,23.1c-.06.45-.11,1.56-.14,2.57a1,1,0,0,0-.92.31A1.87,1.87,0,0,0,6,27.25v4.5c0,1.63.71,3.64,2.31,4.42C12,38,18,38,28,38s16,0,19.69-1.83c1.6-.78,2.31-2.79,2.31-4.42v-4.5A1.89,1.89,0,0,0,49.46,26Z"
            fill={color}
            opacity={opacity}
          />
          <path
            d="M19.53,26.19a15,15,0,0,0-.22-3.5A1.56,1.56,0,0,0,18,21.47a2.77,2.77,0,0,0-1.62.52l.19.12a1,1,0,0,1,.34,1.37A1,1,0,0,1,16,24a1.06,1.06,0,0,1-.51-.14c-.2-.12-.47-.31-.78-.54s-.47-.35-.74-.53c-2.06-1.41-2.88-1.39-3.2-1.25a2.14,2.14,0,0,0-1.21,1.86c-.06.41-.12,1.91-.15,3.11,3.86,1.33,8,.39,10.11-.29M31,26.75V19.43L28.42,17H25v9.81a12.48,12.48,0,0,0,6-.06m15.52-.49a15.93,15.93,0,0,0-.22-3.57A1.57,1.57,0,0,0,45,21.47a2.77,2.77,0,0,0-1.62.52l.18.12A1,1,0,0,1,43,24a1,1,0,0,1-.51-.14c-.2-.12-.47-.31-.78-.54s-.47-.35-.74-.53c-2.06-1.41-2.88-1.39-3.2-1.25a2.14,2.14,0,0,0-1.21,1.86c0,.37-.11,1.54-.15,2.7a19.76,19.76,0,0,0,10.11.19"
            fill="#fff"
          />
          <path
            d="M47.81,28l0,0a21.21,21.21,0,0,1-5.91.83,22.3,22.3,0,0,1-6.75-1.06h0l-.2-.07-.35-.11a15.38,15.38,0,0,1-2.51.95h0a15.44,15.44,0,0,1-4.24.61A13.52,13.52,0,0,1,24,28.62a12.73,12.73,0,0,1-2.49-1l-.66.25h0a21.9,21.9,0,0,1-7.16,1.29A14.73,14.73,0,0,1,8,28.1H8v3.67a3.12,3.12,0,0,0,1.2,2.63C12.5,36,18.34,36,28,36s15.52,0,18.81-1.62A3.11,3.11,0,0,0,48,31.75V27.87h0l-.2.11"
            fill={color}
            opacity="0.1"
          />
        </g>
      </svg>
    );
  } else if (name === 'UpperPreop') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill={color}
      >
        <g fillRule="evenodd">
          <path
            d="M42,15h-.15l.27-.22a1,1,0,0,0-1.24-1.56l-2.5,2a.73.73,0,0,0-.08.09.5.5,0,0,0-.08.07.25.25,0,0,0,0,.07.76.76,0,0,0-.09.18.64.64,0,0,0-.06.18A1.28,1.28,0,0,0,38,16a1.21,1.21,0,0,0,0,.19.64.64,0,0,0,.06.18.76.76,0,0,0,.09.18l0,.08.07.05a.43.43,0,0,0,.09.1l2.5,2a1,1,0,0,0,.62.22,1,1,0,0,0,.78-.37,1,1,0,0,0-.15-1.41L41.85,17H42a3,3,0,1,1-3,3,1,1,0,0,0-2,0,5,5,0,1,0,5-5"
            fill={color}
            opacity={opacity}
          />
          <path
            d="M45,33a1,1,0,0,1-.38.78l-.5.4a1,1,0,0,1-1.24,0l-.5-.4A1,1,0,0,1,42,33V31.5a1.5,1.5,0,0,1,3,0ZM40,31.5V34a1,1,0,0,1-1,1H37a1,1,0,0,1-1-1V31a2,2,0,0,1,4,0v.5ZM34,31v4a1,1,0,0,1-1,1H30a1,1,0,0,1-1-1V30.5a2.5,2.5,0,0,1,5,0Zm-7,4a1,1,0,0,1-1,1H23a1,1,0,0,1-1-1V30.5a2.5,2.5,0,0,1,5,0V35Zm-7-1a1,1,0,0,1-1,1H17a1,1,0,0,1-1-1V31a2,2,0,0,1,4,0Zm-6-1a1,1,0,0,1-.38.78l-.5.4a1,1,0,0,1-1.24,0l-.5-.4A1,1,0,0,1,11,33V31.5a1.5,1.5,0,0,1,3,0Zm34.55-8.46A8.22,8.22,0,0,1,47,26.27a3.69,3.69,0,0,1,0,.58V31h0a3.47,3.47,0,0,0-5.63-2.22,4,4,0,0,0-6-.7A4.46,4.46,0,0,0,28,27.71a4.46,4.46,0,0,0-7.28.37A4,4,0,0,0,18,27a4,4,0,0,0-3.32,1.78A3.47,3.47,0,0,0,9.05,31H9V26.85a4,4,0,0,1,2.27-3.61C13.58,22.19,18.38,20,28,20a53.25,53.25,0,0,1,6,.32c0-.1,0-.21,0-.32a7.54,7.54,0,0,1,.18-1.66A54,54,0,0,0,28,18c-10.06,0-15.31,2.4-17.56,3.42A6,6,0,0,0,7,26.85V31a2,2,0,0,0,2,2v0a3,3,0,0,0,1.13,2.34l.5.4a3,3,0,0,0,3.74,0l.14-.11A3,3,0,0,0,17,37h2a2.94,2.94,0,0,0,1.47-.4A3,3,0,0,0,23,38h3a3,3,0,0,0,2-.78A3,3,0,0,0,30,38h3a3,3,0,0,0,2.53-1.4A2.94,2.94,0,0,0,37,37h2a3,3,0,0,0,2.49-1.33l.14.11a3,3,0,0,0,3.74,0l.5-.4A3,3,0,0,0,47,33v0a2,2,0,0,0,2-2V26.85A6.21,6.21,0,0,0,48.55,24.58Z"
            fill={color}
            opacity={opacity}
          />
          <path
            d="M34,20.32A53.25,53.25,0,0,0,28,20c-9.62,0-14.42,2.19-16.73,3.24A4,4,0,0,0,9,26.85V31h.05a3.47,3.47,0,0,1,5.63-2.22,4,4,0,0,1,6-.7A4.46,4.46,0,0,1,28,27.71a4.46,4.46,0,0,1,7.28.37A4,4,0,0,1,38,27h.15A8,8,0,0,1,34,20.32m13,6.53a3.69,3.69,0,0,0,0-.58A8,8,0,0,1,42,28a8.09,8.09,0,0,1-1.56-.15,3.89,3.89,0,0,1,.88.93A3.47,3.47,0,0,1,47,31h0Z"
            fill={color}
            opacity="0.1"
          />
          <path
            d="M24.5,28A2.5,2.5,0,0,0,22,30.5V35a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V30.5A2.5,2.5,0,0,0,24.5,28m7,0A2.5,2.5,0,0,0,29,30.5V35a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V30.5A2.5,2.5,0,0,0,31.5,28M18,29a2,2,0,0,0-2,2v3a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V31a2,2,0,0,0-2-2m-5.5,1A1.5,1.5,0,0,0,11,31.5V33a1,1,0,0,0,.38.78l.5.4a1,1,0,0,0,1.24,0l.5-.4A1,1,0,0,0,14,33V31.5A1.5,1.5,0,0,0,12.5,30m31,0A1.5,1.5,0,0,0,42,31.5V33a1,1,0,0,0,.38.78l.5.4a1,1,0,0,0,1.24,0l.5-.4A1,1,0,0,0,45,33V31.5A1.5,1.5,0,0,0,43.5,30M38,29a2,2,0,0,0-2,2v3a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V31a2,2,0,0,0-2-2"
            fill="#fff"
          />
        </g>
      </svg>
    );
  } else if (name === 'LowerPreop') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill={color}
      >
        <g fillRule="evenodd">
          {/* class a */}
          <path
            d="M47,29.15a4,4,0,0,1-2.27,3.61C42.42,33.81,37.62,36,28,36s-14.42-2.19-16.73-3.24A4,4,0,0,1,9,29.15V25h.05a3.5,3.5,0,0,0,3.45,3,3.4,3.4,0,0,0,1.79-.51,4,4,0,0,0,6.43,1.43,4.46,4.46,0,0,0,7.28.37,4.46,4.46,0,0,0,7.28-.37,4,4,0,0,0,6.43-1.43A3.4,3.4,0,0,0,43.5,28,3.5,3.5,0,0,0,47,25h0ZM11,22a1,1,0,0,1,.38-.78l.5-.4a1,1,0,0,1,1.24,0l.5.4A1,1,0,0,1,14,22V24.5a1.5,1.5,0,0,1-3,0Zm5,2.54V22a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1v4a2,2,0,0,1-4,0V24.5ZM22,26V23a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1v3.5a2.5,2.5,0,0,1-5,0V26Zm7-3a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1v3.5a2.5,2.5,0,0,1-5,0V23Zm18.25,0A8.14,8.14,0,0,1,45,24.41v.09a1.5,1.5,0,0,1-2.91.49H42a8.24,8.24,0,0,1-2-.26V26a2,2,0,0,1-4,0V22.27a8,8,0,0,1-1.08-1.55A2.9,2.9,0,0,0,33,20H30a3,3,0,0,0-2,.78A3,3,0,0,0,26,20H23a2.94,2.94,0,0,0-1.47.4A3,3,0,0,0,19,19H17a3,3,0,0,0-2,.76s-.09-.1-.15-.14l-.5-.4a3,3,0,0,0-3.74,0l-.5.4A3,3,0,0,0,9,22v1a2,2,0,0,0-2,2v4.15a6,6,0,0,0,3.44,5.43C12.69,35.6,17.94,38,28,38s15.31-2.4,17.56-3.42A6,6,0,0,0,49,29.15V25A2,2,0,0,0,47.25,23Z"
            fill={color}
            opacity={opacity}
          />
          {/* class a */}
          <path
            d="M42,12h-.15l.27-.22a1,1,0,0,0-1.24-1.56l-2.5,2a.73.73,0,0,0-.08.09.5.5,0,0,0-.08.07.25.25,0,0,0,0,.07.76.76,0,0,0-.09.18.64.64,0,0,0-.06.18A1.28,1.28,0,0,0,38,13a1.21,1.21,0,0,0,0,.19.64.64,0,0,0,.06.18.76.76,0,0,0,.09.18l0,.08.07,0a.43.43,0,0,0,.09.1l2.5,2a1,1,0,0,0,.62.22,1,1,0,0,0,.78-.37,1,1,0,0,0-.15-1.41L41.85,14H42a3,3,0,1,1-3,3,1,1,0,0,0-2,0,5,5,0,1,0,5-5"
            fill={color}
            opacity={opacity}
          />
          {/* class b */}
          <path
            d="M24.5,29A2.5,2.5,0,0,0,27,26.5V23a1,1,0,0,0-1-1H23a1,1,0,0,0-1,1v3.5A2.5,2.5,0,0,0,24.5,29M18,28a2,2,0,0,0,2-2V22a1,1,0,0,0-1-1H17a1,1,0,0,0-1,1v4a2,2,0,0,0,2,2m13.5,1A2.5,2.5,0,0,0,34,26.5V23a1,1,0,0,0-1-1H30a1,1,0,0,0-1,1v3.5A2.5,2.5,0,0,0,31.5,29m-19-3A1.5,1.5,0,0,0,14,24.5V22a1,1,0,0,0-.38-.78l-.5-.4a1,1,0,0,0-1.24,0l-.5.4A1,1,0,0,0,11,22V24.5A1.5,1.5,0,0,0,12.5,26"
            fill="#fff"
          />
          {/* class b */}
          <path
            d="M36,22.28V26a2,2,0,0,0,4,0V24.74a8,8,0,0,1-4-2.46M43.5,26A1.5,1.5,0,0,0,45,24.5v-.09a8,8,0,0,1-2.91.59,1.51,1.51,0,0,0,1.41,1"
            fill="#fff"
          />
          {/* class c */}
          <path
            d="M43.5,28a3.4,3.4,0,0,1-1.79-.51,4,4,0,0,1-6.43,1.43,4.46,4.46,0,0,1-7.28.37,4.46,4.46,0,0,1-7.28-.37,4,4,0,0,1-6.43-1.43A3.4,3.4,0,0,1,12.5,28a3.5,3.5,0,0,1-3.45-3H9v4.15a4,4,0,0,0,2.27,3.61C13.58,33.81,18.38,36,28,36s14.42-2.19,16.73-3.24A4,4,0,0,0,47,29.15V25h0a3.5,3.5,0,0,1-3.45,3"
            fill={color}
            opacity="0.1"
          />
        </g>
      </svg>
    );
  } else if (name === 'Solid') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill={color}
      >
        <g fillRule="evenodd">
          <path
            d="M20.33,34.26A8.07,8.07,0,0,1,20,32c0-4.5,6.21-15,8-16V26.59l5.89-5.89C31.85,17,29.5,14,28,14c-2.64,0-10,12.33-10,18a9.9,9.9,0,0,0,.76,3.82l1.57-1.56Zm1.74,3.08L28,31.42V40a8,8,0,0,1-5.93-2.66M40.71,17.29a1,1,0,0,0-1.42,0l-24,24a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l3.95-4A10,10,0,0,0,38,32c0-1.84-1-5-2.49-8.1l5.2-5.19a1,1,0,0,0,0-1.42"
            opacity={opacity}
          />
        </g>
      </svg>
    );
  } else if (name === 'search') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color}
        onMouseOver={(e) => e.currentTarget.setAttribute('fill', colorOver)}
        onMouseOut={(e) => e.currentTarget.setAttribute('fill', colorOut)}
        onMouseDown={(e) => e.currentTarget.setAttribute('fill', colorDown)}
      >
        <g fill-rule="evenodd">
          <path
            d="M10 3c-3.865 0-7 3.135-7 7s3.135 7 7 7 7-3.135 7-7-3.135-7-7-7zm0 2c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5z"
            transform="translate(-682 -68) translate(670 60) translate(12 8)"
          />
          <path
            d="M13.793 13.793c.36-.36.928-.388 1.32-.083l.094.083 4.5 4.5c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083-4.5-4.5c-.39-.39-.39-1.024 0-1.414z"
            transform="translate(-682 -68) translate(670 60) translate(12 8)"
          />
        </g>
      </svg>
    );
  }
};

class SVG extends Component {
  render() {
    return (
      <>
        {getSVG(
          this.props.name,
          this.props.color,
          this.props.colorOver,
          this.props.colorOut,
          this.props.colorDown,
          this.props.opacity,
        )}
      </>
    );
  }
}

export default SVG;
