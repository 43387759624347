import React, { useEffect, useRef, useState, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { loadPly } from '../../lib/threejs/ply';
import { OBJLoader } from '../../lib/threejs/OBJLoader';
import { loadMarginPoints } from '../../lib/threejs/margin';
import { TrackballControls } from '../../lib/threejs/TrackballControls';
import { TransformControls } from '../../lib/threejs/TransformControls';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import SliderBox from './CaseDetail3DSlider';
import { setDownloadFileList } from '../../lib/api/case';
import SVG from '../common/svg';
import { ThemeContext } from 'styled-components';
import { fullScreen } from '../../modules/case';

const ResizeSensor = (element, callback) => {
  let zIndex = parseInt(getComputedStyle(element));
  if (isNaN(zIndex)) {
    zIndex = 0;
  }
  zIndex--;

  let expand = document.createElement('div');
  expand.style.position = 'absolute';
  expand.style.left = '0px';
  expand.style.top = '0px';
  expand.style.right = '0px';
  expand.style.bottom = '0px';
  expand.style.overflow = 'hidden';
  expand.style.zIndex = zIndex;
  expand.style.visibility = 'hidden';

  let expandChild = document.createElement('div');
  expandChild.style.position = 'absolute';
  expandChild.style.left = '0px';
  expandChild.style.top = '0px';
  expandChild.style.width = '10000000px';
  expandChild.style.height = '10000000px';
  expand.appendChild(expandChild);

  let shrink = document.createElement('div');
  shrink.style.position = 'absolute';
  shrink.style.left = '0px';
  shrink.style.top = '0px';
  shrink.style.right = '0px';
  shrink.style.bottom = '0px';
  shrink.style.overflow = 'hidden';
  shrink.style.zIndex = zIndex;
  shrink.style.visibility = 'hidden';

  let shrinkChild = document.createElement('div');
  shrinkChild.style.position = 'absolute';
  shrinkChild.style.left = '0px';
  shrinkChild.style.top = '0px';
  shrinkChild.style.width = '200%';
  shrinkChild.style.height = '200%';
  shrink.appendChild(shrinkChild);

  element.appendChild(expand);
  element.appendChild(shrink);

  function setScroll() {
    expand.scrollLeft = 10000000;
    expand.scrollTop = 10000000;

    shrink.scrollLeft = 10000000;
    shrink.scrollTop = 10000000;
  }
  setScroll();

  let size = element.getBoundingClientRect();

  let currentWidth = size.width;
  let currentHeight = size.height;

  let onScroll = function () {
    let size = element.getBoundingClientRect();

    let newWidth = size.width;
    let newHeight = size.height;

    if (newWidth !== currentWidth || newHeight !== currentHeight) {
      currentWidth = newWidth;
      currentHeight = newHeight;

      callback();
    }

    setScroll();
  };

  expand.addEventListener('scroll', onScroll);
  shrink.addEventListener('scroll', onScroll);
};

const BasicScene = ({ fileBuffer, fileName, fileCount, fileType }) => {
  const { fullScreenState, settings } = useSelector(
    ({ caseDetail, user }) => ({
      fullScreenState: caseDetail.fullScreen,
      settings: user.settings,
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();
  const ref = useRef(null);
  const themeContext = useContext(ThemeContext);

  const screen = document.getElementById('fullscreen');
  const [sliderVisible, setSliderVisible] = useState(false);

  const frustumSize = 50;
  const aspect = screen.clientWidth / 519;
  const [renderer, setRenderer] = useState(
    new THREE.WebGLRenderer({ antialias: true, alpha: true }),
  );
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(screen.clientWidth, 519);

  const [scene] = useState(new THREE.Scene());
  const [pivot] = useState(new THREE.Group());
  let camera = new THREE.PerspectiveCamera(15, aspect, 0.5, 2000);

  if (settings != null) {
    if (settings._3d_projection !== 'perspective') {
      camera = new THREE.OrthographicCamera(
        (frustumSize * aspect) / -2, //left
        (frustumSize * aspect) / 2, //right
        frustumSize / 2, //top
        frustumSize / -2, //bottom
        0.5,
        2000,
      );
    }
  }

  const [light] = useState(new THREE.DirectionalLight(0xffffff, 0.45));
  const [ambi_light] = useState(new THREE.AmbientLight(0xeda942, 0.6));
  const [arrObj, setArrObj] = useState([]);
  const [marginObj, setMarginObj] = useState([]);
  const [transControl] = useState(new TransformControls(camera, renderer.domElement));
  const [loading_order, setLoading_order] = useState([]);
  const [loading_order_margin, setLoading_order_margin] = useState([]);
  const [temp_colors, setTemp_colors] = useState([
    new Array(),
    new Array(),
    new Array(),
    new Array(),
    new Array(),
    new Array(),
  ]);
  const [geoLen, setGeoLen] = useState([]);
  const [colors, setColors] = useState([]);
  const cameraZ = 250;

  const onMaximize = () => {
    document.querySelector('#fullscreen').classList.add('FullScreen');
    setSliderVisible(true);
    document.querySelector('.slider').classList.remove('hide');
    renderer.domElement.classList.remove('cornerRound');
    dispatch(fullScreen(true));
  };
  const onClose = () => {
    document.querySelector('#fullscreen').classList.remove('FullScreen');
    setSliderVisible(false);
    document.querySelector('.slider').classList.add('hide');
    renderer.domElement.classList.add('cornerRound');
    dispatch(fullScreen(false));
  };

  useEffect(() => {
    camera.aspect = screen.clientWidth / screen.clientHeight;
    renderer.setSize(screen.clientWidth, screen.clientHeight);
    camera.updateProjectionMatrix();
  }, [fullScreenState]);

  useEffect(() => {
    if (fileBuffer) {
      console.debug('useEffect, First Loading');
      const refcopy = ref; //Resolve to cleanup issue

      scene.add(pivot);
      scene.background = new THREE.Color(0xf0f1f2);
      scene.fog = new THREE.Fog(0xffffff, 100, 2000);

      camera.position.set(0, 0, cameraZ);
      if (refcopy.current !== null) refcopy.current.appendChild(renderer.domElement);

      // renderer.domElement.setAttribute("style", "border-Radius: 4px;")
      renderer.domElement.classList.add('cornerRound');

      //Light
      light.position.set(0, 50, 200);
      scene.add(light);
      scene.add(ambi_light);

      //Zoom
      const orbit = new OrbitControls(camera, renderer.domElement);
      orbit.enablePan = false;
      orbit.enableRotate = false;
      orbit.minDistance = 70;
      orbit.maxDistance = 600;
      orbit.minZoom = 0.25;
      orbit.maxZoom = 3;

      //Pan
      const trackballControls = new TrackballControls(camera, renderer.domElement);
      trackballControls.noPan = false;
      trackballControls.noRotate = true;
      trackballControls.noZoom = true;
      trackballControls.panSpeed = 0.6;
      trackballControls.staticMoving = true;
      trackballControls.dynamicDampingFactor = 0.3;

      //Rotate
      transControl.setMode('rotate');
      scene.add(transControl);

      const animate = () => {
        requestAnimationFrame(animate);
        trackballControls.update();
        renderer.clear();
        renderer.render(scene, camera);

        // renderer.clearDepth(); // important!
        // renderer.setViewport(20, 20, screen.clientWidth, screen.clientHeight);
      };

      //Resize
      ResizeSensor(screen, function () {
        camera.aspect = screen.clientWidth / screen.clientHeight;
        renderer.setSize(screen.clientWidth, screen.clientHeight);
        camera.updateProjectionMatrix();
      });

      animate();

      return () => {
        console.debug('Clean up 3D Scene!!!');
        if (refcopy.current != null) {
          refcopy.current.removeChild(renderer.domElement);
        }
        setDownloadFileList({});
      };
    }
  }, []);

  //Load mesh for file extension
  useEffect(() => {
    if (fileBuffer) {
      if (fileName.search('margin') < 0) {
        setLoading_order((state) => [...state, fileName]);
      } else if (fileName.search('margin') > 0) {
        setLoading_order_margin((state) => [...state, fileName]);
      }
      console.debug('Loading 3D File!!!!!!!!!!!!!!!!!!');

      if (fileName.search('.stl') > 0) {
        const material = new THREE.MeshPhongMaterial({
          color: 0xffffff,
          specular: 0x111111,
          shininess: 60,
          // vertexColors: THREE.VertexColors,
          transparent: true,
          opacity: 1,
          side: THREE.DoubleSide,
          // depthTest: true,
          // depthWrite: false,
          // alphaTest: 0.5,
        });
        material.polygonOffset = true;
        material.polygonOffsetFactor = 5;

        // light.color.setHex(0xffffff);
        // light.intensity = 1;
        // light.position.set(0, 0, 30);
        // scene.remove(ambi_light);
        // material.color.setHex( 0xf2f2f2 );

        var sloader = new STLLoader();
        const geometry = sloader.parse(fileBuffer);
        const mesh = new THREE.Mesh(geometry, material);

        //Rotate heronclinic 3d mesh
        mesh.rotation.x = Math.PI / 2;
        mesh.rotation.y = Math.PI;
        // mesh.rotation.set(new THREE.Vector3( Math.PI / 2, Math.PI, 0));

        pivot.add(mesh);
        setArrObj((state) => [...state, mesh]);
      } else if (fileName.search('.ply') > 0) {
        const geometry = loadPly(fileBuffer);
        const material = new THREE.MeshPhongMaterial({
          color: 0xffffff,
          specular: 0x111111,
          shininess: 60,
          vertexColors: THREE.VertexColors,

          transparent: true,
          opacity: 1,
          side: THREE.DoubleSide,
          // depthTest: true,
          // depthWrite: false,
          // alphaTest: 0.5,
        });

        material.polygonOffset = true;
        material.polygonOffsetFactor = 5;

        ambi_light.color.setHex(0xffffff);

        const mesh = new THREE.Mesh(geometry, material);

        //Rotate heronclinic 3d mesh
        mesh.rotation.x = Math.PI / 2;
        mesh.rotation.y = Math.PI;

        pivot.add(mesh);
        setArrObj((state) => [...state, mesh]);
      } else if (fileName.search('.obj') > 0) {
        const material = new THREE.MeshPhongMaterial({
          color: 0xffffff,
          specular: 0x111111,
          shininess: 60,
          vertexColors: THREE.VertexColors,

          transparent: true,
          opacity: 1,
          side: THREE.DoubleSide,
          // depthTest: false,
          // depthWrite: false,
          // alphaTest: 0.5,
        });
        material.polygonOffset = true;
        material.polygonOffsetFactor = 5;

        ambi_light.color.setHex(0xffffff);

        var oloader = new OBJLoader();
        const mesh = oloader.parse(fileBuffer);
        setArrObj((state) => [...state, mesh.children[0]]);
        mesh.children[0].material = material;

        //Rotate heronclinic 3d mesh
        mesh.rotation.x = Math.PI / 2;
        mesh.rotation.y = Math.PI;

        pivot.add(mesh);
      } else if (fileName.search('margin') > 0) {
        const points = loadMarginPoints(fileBuffer);
        const geometry = new LineGeometry();
        geometry.setPositions(points);
        var resolution = new THREE.Vector2(screen.clientWidth, screen.clientHeight);
        const material = new LineMaterial({
          color: 0x00ff00,
          linewidth: 2, // in world units with size attenuation, pixels otherwise
          resolution: resolution,
          dashed: false,
          worldUnits: false,
          // alphaToCoverage: true,
        });

        material.polygonOffset = true;
        material.polygonOffsetFactor = -5;

        const mesh = new Line2(geometry, material);
        // mesh.computeLineDistances();
        // mesh.scale.set(1, 1, 1);
        setMarginObj((state) => [...state, mesh]);

        //Rotate heronclinic 3d mesh
        mesh.rotation.x = Math.PI / 2;
        mesh.rotation.y = Math.PI;

        pivot.add(mesh);
      }

      transControl.attach(pivot);
    }
  }, [dispatch, fileBuffer, pivot]);

  //Move an axis the center of an object
  useEffect(() => {
    var sumx = 0,
      sumy = 0,
      sumz = 0;
    var vertexscount = 0;
    // var abutment_cnt = 0;

    if (fileCount === arrObj.length + marginObj.length) {
      console.debug('3D file loading completed >>> Center transformation started.');
      const loadingOverlay = document.querySelector('#loading-overlay');
      const progressBar = document.querySelector('#progress');
      loadingOverlay.style.display = 'none';
      progressBar.style.width = 0;

      for (var i = 0; i < arrObj.length; i++) {
        if (arrObj[i].geometry.type === 'BufferGeometry') {
          for (var j = 0; j < arrObj[i].geometry.attributes.position.count; j += 1000) {
            sumx += arrObj[i].geometry.attributes.position.array[j * 3];
            sumy += arrObj[i].geometry.attributes.position.array[j * 3 + 1];
            sumz += arrObj[i].geometry.attributes.position.array[j * 3 + 2];
            vertexscount++;
          }
        }
      }

      sumx = sumx / vertexscount;
      sumy = sumy / vertexscount;
      sumz = sumz / vertexscount;

      for (var i = 0; i < marginObj.length; i++) {
        marginObj[i].geometry.translate(-sumx, -sumy, -sumz);
      }

      for (var i = 0; i < arrObj.length; i++) {
        arrObj[i].geometry.translate(-sumx, -sumy, -sumz);

        //Solid & Color
        if (fileType === 'ply' || fileType === 'obj') {
          geoLen.push(arrObj[i].geometry.attributes.color.count * 3);
          colors.push(arrObj[i].geometry.attributes.color.array);

          for (var j = 0; j < geoLen[i]; j++) {
            temp_colors[i][j] = arrObj[i].geometry.attributes.color.array[j];
          }
        }
      }
    }
  }, [arrObj, marginObj]);

  return (
    <div>
      {fileCount === arrObj.length + marginObj.length && (
        <SliderBox
          arrObj={arrObj}
          marginObj={marginObj}
          loading_order={loading_order}
          loading_order_margin={loading_order_margin}
          transControl={transControl}
          fileType={fileType}
          scene={scene}
          ambi_light={ambi_light}
          light={light}
          geoLen={geoLen}
          colors={colors}
          temp_colors={temp_colors}
        />
      )}
      {!sliderVisible && fileCount === arrObj.length + marginObj.length && (
        <div style={{ position: 'relative' }}>
          {/* <img
            style={{
              position: 'absolute',
              marginTop: '16px',
              marginRight: '16px',
              right: '0',
              userSelect: 'none',
            }}
            src={maximizeActive}
            onMouseOver={(e) => (e.currentTarget.src = maximizeHover)}
            onMouseOut={(e) => (e.currentTarget.src = maximizeActive)}
            onMouseDown={(e) => (e.currentTarget.src = maximizeSelected)}
            onClick={onMaximize}
            alt="maximizeIcon"
          /> */}
          <div
            style={{
              position: 'absolute',
              marginTop: '16px',
              marginRight: '16px',
              right: '0',
              userSelect: 'none',
            }}
            onClick={onMaximize}
          >
            <SVG
              name="maximize"
              color="#878D93"
              colorOver={themeContext.main_color_hover}
              colorOut="#878D93"
              colorDown={themeContext.main_color}
            />
          </div>
        </div>
      )}
      {sliderVisible && (
        <div style={{ position: 'relative' }}>
          {/* <img
            style={{
              position: 'absolute',
              marginTop: '20px',
              marginRight: '20px',
              right: '0',
              userSelect: 'none',
            }}
            src={closeActive}
            onMouseOver={(e) => (e.currentTarget.src = closeHover)}
            onMouseOut={(e) => (e.currentTarget.src = closeActive)}
            onMouseDown={(e) => (e.currentTarget.src = closeSelected)}
            onClick={onClose}
            alt="closeIcon"
          /> */}
          <div
            style={{
              position: 'absolute',
              marginTop: '20px',
              marginRight: '20px',
              right: '0',
              userSelect: 'none',
            }}
            onClick={onClose}
          >
            <SVG
              name="close"
              color="#878D93"
              colorOver={themeContext.main_color_hover}
              colorOut="#878D93"
              colorDown={themeContext.main_color}
            />
          </div>
        </div>
      )}

      <div ref={ref} />
    </div>
  );
};

export default React.memo(BasicScene);
