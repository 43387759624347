import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TabletOrDesktop } from '../../lib/responsive';

const CaseDetailTitleBox = styled.div`
  font-family: Source Sans Pro;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 600;
  font-size: ${(props) => props.fontSize};

  .caseDetailTitleBox {
    width: 100%;
    height: 48px;
    background: #eeeff0;
    /* text-align: center; */
    display: flex;
    justify-content: center;
  }
  .caseDetailCenterBox {
    ${(props) =>
      !props.isTabletOrDesktop &&
      css`
        width: 90%;
        display: flex;
        justify-content: space-around;
      `};
  }

  .caseDetailTitle {
    display: inline-block;
    width: auto;
    height: 19px;
    margin-top: 16px;
    color: ${(props) => props.odm_color};
    text-align: right;
  }

  .caseDetailTitleBlock {
    display: inline-block;
  }

  .caseDetailTitleCommentsNo {
    display: inline-block;
    margin-top: 16px;
    margin-left: ${(props) => (props.isTabletOrDesktop ? '48px' : null)};
  }

  .caseDetailNumberBox {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background: #787f86;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
  }

  .caseDetailTitleComments {
    display: inline-block;
    width: auto;
    height: 19px;
    margin-top: 14px;
    margin-left: 8px;
    background: transparent;
    color: #59616a;
  }

  .caseDetailTitleAttachments {
    display: inline-block;
    width: auto;
    height: 19px;
    margin-top: 14px;
    color: #59616a;
    text-align: left;
  }

  .caseDetailTitleAttachmentsNo {
    display: inline-block;
    margin-top: 16px;
    margin-right: 8px;
    margin-left: ${(props) => (props.isTabletOrDesktop ? '48px' : null)};
  }
`;

const CaseDetailTitle = ({ comments, files, onClickComments, onClickAttachments }) => {
  const { t } = useTranslation();
  const isTabletOrDesktop = TabletOrDesktop();
  const fontSize = isTabletOrDesktop ? '15px' : '13px';

  const { odm } = useSelector(({ app }) => ({
    odm: app.odm,
  }));

  const getCommentsCount = () => {
    if (comments && comments.comments) return comments.comments.length;
    else return '';
  };

  const getFilesCount = () => {
    if (files && files.files) return files.files.length;
    else return '';
  };

  return (
    <CaseDetailTitleBox
      odm_color={odm.main_color}
      isTabletOrDesktop={isTabletOrDesktop}
      fontSize={fontSize}
    >
      <div class="caseDetailTitleBox">
        <div class="caseDetailCenterBox">
          <div class="caseDetailTitle">{t('Case Details')}</div>

          <div className="caseDetailTitleBlock">
            <div class="caseDetailTitleCommentsNo">
              <div class="caseDetailNumberBox">
                <div>{getCommentsCount()}</div>
              </div>
            </div>
            <div class="caseDetailTitleComments" onClick={onClickComments}>
              {t('Comments')}
            </div>
          </div>

          <div className="caseDetailTitleBlock">
            <div class="caseDetailTitleAttachmentsNo">
              <div class="caseDetailNumberBox">
                <div>{getFilesCount()}</div>
              </div>
            </div>
            <div class="caseDetailTitleAttachments" onClick={onClickAttachments}>
              {t('Attachments')}
            </div>
          </div>
        </div>
      </div>
    </CaseDetailTitleBox>
  );
};

export default CaseDetailTitle;
