import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes, css } from 'styled-components';
import {
  initializeForm,
  // uploadFile,
  uploadFilesDone,
  listComments,
  caseContent,
  listFiles,
} from '../../modules/case';
import { addComment, uploadFile } from '../../lib/api/case';
import HButton from '../common/HButton';
import { useTranslation } from 'react-i18next';
import SVG from '../common/svg';
import MessageBox from '../common/MessageBox';
import { TabletOrDesktop } from '../../lib/responsive';

const fadeIn = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1
  }
  to {
    opacity: 0
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0px);
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-100px);
  }
`;

const Fullscreen = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;

  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${fadeIn};
  animation-fill-mode: forwards;

  ${(props) =>
    props.disappear &&
    css`
      animation-name: ${fadeOut};
    `}
`;

const ModalBlock = styled.div`
  width: ${(props) => (props.isTabletOrDesktop ? '694px' : '320px')};
  height: auto;
  background: white;
  border-radius: 4px;
  border: solid 1px #eeeff0;

  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${slideDown};
  animation-fill-mode: forwards;

  ${(props) =>
    props.disappear &&
    css`
      animation-name: ${slideUp};
    `}
`;

const TitleBlock = styled.div`
  .titleBox {
    width: ${(props) => (props.isTabletOrDesktop ? '694px' : '320px')};
    height: 56px;
    border-radius: 4px 4px 0 0;
    margin: 0 0 0 0;
    background: #eeeff0;
  }

  .titleText {
    /* width: 400px; */
    width: ${(props) => (props.isTabletOrDesktop ? '400px' : '200px')};
    height: 25px;
    margin: 15px 0 0 19px;
    font-family: 'Source Sans Pro';
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
    float: left;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .closeIcon {
    width: 24px;
    height: 24px;
    margin: 16px 19px 0 0;
    float: right;
  }
`;

const InputBlock = styled.div`
  .fileSizeText {
    margin-top: 8px;
    margin-bottom: 16px;
    margin-left: 12px;
    font-size: 15px;
    font-family: 'Source Sans Pro';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #969ba1;
  }

  .scrollbar {
    width: ${(props) => (props.isTabletOrDesktop ? '653px' : '296px')};
    max-height: 174px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      /* box-shadow: inset 0px 0px 5px white; */
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c0c3c7;
      border-radius: 4px;
      background-clip: padding-box;
      border: 1px solid transparent;
    }
    &::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }

    /* Firefox scrollbar */
    scrollbar-width: thin;
    scrollbar-color: #c0c3c7 transparent;
  }

  .inputBox {
    width: ${(props) => (props.isTabletOrDesktop ? '653px' : '294px')};
    height: 136px;
    margin: ${(props) => (props.isTabletOrDesktop ? '20px 0 0 20px' : '20px 0 0 12px')};
    outline: none;
    border-radius: 4px;
    border: solid 1px #e7e9ea;
    resize: none;
  }

  .messageBox {
    width: ${(props) => (props.isTabletOrDesktop ? '614px' : '261px')};
    height: 73px;
    margin: 10px 16px 5px 16px;
    outline: none;
    border: none;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
    resize: none;
  }

  .messageBox::-webkit-input-placeholder {
    color: #969ba1;
  }

  .messageBox:focus::placeholder {
    color: transparent;
  }

  .toolBox {
    width: ${(props) => (props.isTabletOrDesktop ? '653px' : '294px')};
    height: 40px;
    margin: 0 0 0 0;
    outline: none;
    border-top: solid 1px #e7e9ea;
  }

  .attachIcon {
    width: 24px;
    height: 24px;
    margin: 8px 0 0 12px;
    float: left;
  }

  .emojiIcon {
    width: 24px;
    height: 24px;
    margin: 8px 0 0 12px;
    float: left;
  }

  .attachment {
    width: ${(props) => (props.isTabletOrDesktop ? '653px' : '294px')};
    height: 40px;
    /* margin: 8px 0 0 0; */
    outline: none;
    background: #e7e9ea;
    border-radius: 4px;
    border: solid 1px #e7e9ea;

    display: grid;
    grid-template-columns: auto 24px 12px;
    grid-template-rows: 100%;
  }

  .attachmentText {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;

    margin: 0 0 0 20px;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #59616a;

    display: flex;
    justify-content: Left;
    align-items: center;
    word-wrap: break-word;
    word-break: break-all;
  }

  .attachmentTrash {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;

    width: 24px;
    height: 24px;
    margin: 8px 0 0 0;
  }
`;

const LoadingBox = styled.div`
  /* position: absolute; */
  display: flex;
  margin-top: 4px;
  margin-bottom: 8px;
  /* width: 100%; */
  width: 655px;
  background: transparent;
  justify-content: center;
  align-items: center;
  /* background-color: #ebeced; */
  border-radius: 4px;

  #loading-bar {
    background-color: #d3d3d3;
    width: 100%;
    height: 0.25em;
    border-radius: 0.25em;
    display: inline-flex;
  }

  #progress {
    background-color: #787f86;
    height: inherit;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;

    width: 0%;
  }
`;

const AddCommentDlg = ({
  CaseDetailFileList,
  setCaseDetailFileList,
  visible,
  caseId,
  status,
  onSaved,
  onCancel,
  onSaving,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [animate, setAnimate] = useState(false);
  const [localVisible, setLocalVisible] = useState(visible);
  const [commentText, setCommentText] = useState('');
  const [fileList, setFileList] = useState([]);
  const nextFileId = useRef(1);
  const [disableSave, setDisableSave] = useState(false);

  const [messageVisible, setMessageVisible] = useState(false);
  const [messageTitle, setMessageTitle] = useState('');
  const [messageType, setMessageType] = useState('ok');
  const [messageContent, setMessageContent] = useState('');

  const [progressInfos, setProgressInfos] = useState({ val: [] });
  // const [message, setMessage] = useState([]);
  const progressInfosRef = useRef(null);

  const isTabletOrDesktop = TabletOrDesktop();

  const { odm } = useSelector(({ app }) => ({
    odm: app.odm,
  }));

  const onConfirmBoxOk = () => {
    setMessageVisible(false);
  };

  const onConfirmBoxCancel = () => {
    setMessageVisible(false);
  };

  useEffect(() => {
    if (CaseDetailFileList) {
      CaseDetailFileList.map((file) =>
        setFileList((fileList) =>
          fileList.concat({
            id: (nextFileId.current += 1),
            percentage: 0,
            file: file,
          }),
        ),
      );
    }
  }, [CaseDetailFileList]);

  useEffect(() => {
    if (localVisible && !visible) {
      setAnimate(true);
      setTimeout(() => setAnimate(false), 250);
    }
    setLocalVisible(visible);

    setCommentText('');
    if (!CaseDetailFileList) setFileList([]);
    setDisableSave(false);
  }, [localVisible, visible]);

  useEffect(() => {
    onSaving(disableSave);
  }, [disableSave]);

  const onTextChanged = (e) => {
    setCommentText(e.target.value);
  };

  const onAttach = () => {
    if (!disableSave) hiddenFileInput.current.click();
  };

  const hiddenFileInput = React.useRef(null);

  const onFileSelected = (e) => {
    const files = e.target.files;
    var check_file_type = [
      'tiff',
      'pjp',
      'jfif',
      'bmp',
      'gif',
      'svg',
      'png',
      'xbm',
      'dib',
      'jxl',
      'jpeg',
      'svgz',
      'jpg',
      'webp',
      'ico',
      'tif',
      'pjpeg',
      'avif',
      'pdf',
      'xml',
      'obj',
      'ply',
      'stl',
      'qcproject',
      'xyz',
    ];

    Object.entries(files).forEach(([key, file]) => {
      let fileLength = file.name.length;
      let fileDot = file.name.lastIndexOf('.');
      let fileType = file.name.substring(fileDot + 1, fileLength).toLowerCase();
      if (check_file_type.indexOf(fileType) !== -1) {
        if (file.size <= 200 * 1024 * 1024) {
          //file size 200MB under
          setFileList((fileList) =>
            fileList.concat({
              id: (nextFileId.current += 1),
              percentage: 0,
              file: file,
            }),
          );
        }
      }
    });
  };

  useEffect(() => {
    progressInfosRef.current = {
      val: fileList,
    };
    setProgressInfos({ val: fileList });
    setCaseDetailFileList(undefined);
  }, [fileList]);

  const onDelete = (item) => {
    if (!disableSave) setFileList(fileList.filter((file) => file.id !== item.id));
  };

  // Upload
  const onClickSave = async (e) => {
    e.preventDefault();

    {
      /* Add Comment */
    }
    if (commentText !== '') {
      try {
        setDisableSave(true);
        const res = await addComment({ id: caseId, comment: commentText });
        dispatch(listComments(caseId));
        dispatch(caseContent(caseId));
        dispatch(initializeForm('comment'));
        if (fileList.length === 0) {
          onSaved();
        }
      } catch (e) {
        console.log(e);
      }
    }

    {
      /* upload file */
    }

    let uploadError = false;
    if (fileList.length > 0) {
      await fileList
        .reduce((previous, current, idx) => {
          return previous.then(async () => {
            let _progressInfos = [...progressInfosRef.current.val];

            const formData = new FormData();
            formData.append('file', current.file);
            if (!caseId) formData.append('post_uid', '');

            if (!uploadError) {
              await uploadFile(caseId, formData, (event) => {
                if (Object.keys(_progressInfos).length) {
                  _progressInfos[idx].percentage = Math.round(
                    (100 * event.loaded) / event.total,
                  );
                  setProgressInfos({ val: _progressInfos });
                }
              })
                .then((response) => {
                  // console.log("Once uploaded!!");
                  // console.log(response);
                })
                .catch((e) => {
                  _progressInfos[idx].percentage = 0;
                  setProgressInfos({ val: _progressInfos });
                  uploadError = true;
                  // console.log(e);
                  if (e.response) {
                    setMessageTitle(t('Error') + ' ' + e.response.status);
                    if (
                      e.response.status === 400 &&
                      e.response.data.detail.includes('size limit')
                    )
                      setMessageContent(t('The total size limit has been exceeded.'));
                    else setMessageContent(t('File upload failed.'));
                    setMessageVisible(true);
                  }
                });
            }
          });
        }, Promise.resolve())
        .then(() => {
          // console.log('Upload is finished.');
          dispatch(uploadFilesDone(caseId));
          dispatch(listFiles(caseId));
          dispatch(caseContent(caseId));
          if (!uploadError) onSaved();
        });
    }
  };

  const getSizeString = (file) => {
    let num = file.size + 'B';

    if (file.size >= 1024 && file.size < 1024 * 1024) {
      num = (file.size / 1024).toFixed(0) + 'kB';
    } else if (file.size >= 1024 * 1024 && file.size < 1024 * 1024 * 1024) {
      num = (file.size / (1024 * 1024)).toFixed(0) + 'MB';
    } else if (
      file.size >= 1024 * 1024 * 1024 &&
      file.size < 1024 * 1024 * 1024 * 1024
    ) {
      num = (file.size / (1024 * 1024 * 1024)).toFixed(1) + 'GB';
    }

    return num;
  };

  if (!animate && !localVisible) return null;

  return (
    <Fullscreen disappear={!visible}>
      <ModalBlock disappear={!visible} isTabletOrDesktop={isTabletOrDesktop}>
        <TitleBlock isTabletOrDesktop={isTabletOrDesktop}>
          <div className="titleBox">
            <div class="titleText">{t('Add your comment')}</div>
            <div className={'closeIcon'} onClick={onCancel}>
              <SVG
                className={'closeIcon'}
                name="close"
                color="#878D93"
                colorOver={odm.main_color_hover}
                colorOut="#878D93"
                colorDown={odm.main_color}
              />
            </div>
          </div>
        </TitleBlock>

        <InputBlock isTabletOrDesktop={isTabletOrDesktop}>
          <div className={'inputBox'}>
            {/* Message */}
            <textarea
              name="message"
              className={'messageBox'}
              placeholder={t('Message')}
              value={commentText}
              onChange={onTextChanged}
            />
            {/* AttachmentIcon */}
            <div className={'toolBox'}>
              <input
                ref={hiddenFileInput}
                type="file"
                multiple
                style={{ display: 'none' }}
                onChange={onFileSelected}
                accept="image/*,.pdf,.xml,.obj,.ply,.stl,.qcProject,.xyz"
              />
              {status.code !== 'CLSD' && (
                <div className={'attachIcon'} onClick={onAttach} title={t('Attach')}>
                  <SVG
                    name="attach"
                    color={disableSave ? '#C3C6C9' : '#878D93'}
                    colorOver={disableSave ? '#C3C6C9' : odm.main_color_hover}
                    colorOut={disableSave ? '#C3C6C9' : '#878D93'}
                    colorDown={disableSave ? '#C3C6C9' : odm.main_color}
                  />
                </div>
              )}
            </div>

            <div className="fileSizeText">
              {t('Maximum upload file size') + ': 200 MB'}
            </div>

            {/* AttachmentItemList */}
            <div className={'scrollbar'}>
              {progressInfos &&
                progressInfos.val.length > 0 &&
                progressInfos.val.map((progressInfo, index) => (
                  <div key={index}>
                    <div className={'attachment'}>
                      <div className={'attachmentText'}>
                        {progressInfo.file.name} ({getSizeString(progressInfo.file)})
                      </div>
                      <div
                        className={'attachmentTrash'}
                        onClick={() => onDelete(progressInfo)}
                      >
                        <SVG
                          name="delete"
                          color={disableSave ? '#C3C6C9' : '#878D93'}
                          colorOver={disableSave ? '#C3C6C9' : odm.main_color_hover}
                          colorOut={disableSave ? '#C3C6C9' : '#878D93'}
                          colorDown={disableSave ? '#C3C6C9' : odm.main_color}
                        />
                      </div>
                    </div>
                    <LoadingBox id="loading-overlay">
                      <div id="loading-bar">
                        <span
                          id="progress"
                          style={{ width: progressInfo.percentage + '%' }}
                        ></span>
                      </div>
                    </LoadingBox>
                  </div>
                ))}
            </div>
            {/* Save&Cancel Button */}
            <HButton
              type="submit"
              category="Primary"
              size="Medium"
              text={t('Save Comment').toUpperCase()}
              width="auto"
              margin="24px 0 20px 0"
              float="right"
              disabled={disableSave}
              onClick={onClickSave}
            />
            <HButton
              category="Secondary"
              size="Medium"
              text={t('Cancel').toUpperCase()}
              width="auto"
              margin="24px 16px 20px 0"
              float="right"
              onClick={onCancel}
            />
          </div>
        </InputBlock>
        <MessageBox
          visible={messageVisible}
          type={messageType}
          title={messageTitle}
          content={messageContent}
          onOk={onConfirmBoxOk}
          onCancel={onConfirmBoxCancel}
        />
        {/* </div> */}
      </ModalBlock>
    </Fullscreen>
  );
};

export default React.memo(AddCommentDlg);
