export const randomDataBase64url = (length) => {
  let bytes = new Uint8Array(length);
  crypto.getRandomValues(bytes);
  const data = toHexString(bytes);
  return base64urlencodeNoPadding(data);
};

export const base64urlencodeNoPadding = (buffer) => {
  let base64 = hexToBase64(buffer);
  base64 = base64.replace(/\+/g, '-');
  base64 = base64.replace(/\//g, '_');
  base64 = base64.replace(/=/g, '');
  return base64;
};

function toHexString(byteArray) {
  return Array.prototype.map
    .call(byteArray, function (byte) {
      return ('0' + (byte & 0xff).toString(16)).slice(-2);
    })
    .join('');
}

function hexToBase64(hexstring) {
  return btoa(
    hexstring
      .match(/\w{2}/g)
      .map(function (a) {
        return String.fromCharCode(parseInt(a, 16));
      })
      .join(''),
  );
}
