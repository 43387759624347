import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import HButton from '../common/HButton';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SVG from '../common/svg';
import { validate } from '../../lib/api/auth';
import ValidateDiv from '../../components/auth/styled/ValidateText';

const fadeIn = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1
  }
  to {
    opacity: 0
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0px);
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-100px);
  }
`;

const Fullscreen = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;

  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${fadeIn};
  animation-fill-mode: forwards;

  ${(props) =>
    props.disappear &&
    css`
      animation-name: ${fadeOut};
    `}
`;

const ModalBlock = styled.div`
  width: 526px;
  height: auto;
  background: white;
  border-radius: 4px;
  border: solid 1px #eeeff0;

  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${slideDown};
  animation-fill-mode: forwards;

  ${(props) =>
    props.disappear &&
    css`
      animation-name: ${slideUp};
    `}
`;

const TitleBlock = styled.div`
  .titleBox {
    width: 526px;
    height: 56px;
    border-radius: 4px 4px 0 0;
    margin: 0 0 0 0;
    background: #eeeff0;
  }

  .titleText {
    width: 400px;
    height: 25px;
    margin: 15px 0 0 19px;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
    float: left;
  }

  .closeIcon {
    width: 24px;
    height: 24px;
    margin: 16px 19px 0 0;
    float: right;
  }
`;

const MessageBlock = styled.div`
  .errorMSG {
    width: 460px;
    height: 20px;
    margin: 0 28px 20px 28px;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    color: #59616a;
    display: block;
  }
`;

const InputBlock = styled.div`
  .alias {
    width: 454px;
    height: 36px;
    margin: 24px 0 20px 20px;
    padding-left: 16px;
    padding-right: 16px;
    outline: none;
    border-radius: 4px;
    border: solid 1px #e7e9ea;
    background-color: transparent;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
  }

  .alias::-webkit-input-placeholder {
    color: #969ba1;
  }

  .alias:focus::placeholder {
    color: transparent;
  }

  .message {
    width: 454px;
    height: 54px;
    margin: 8px 0 0 20px;
    padding: 10px 16px 10px 16px;
    outline: none;
    border-radius: 4px;
    border: solid 1px #e7e9ea;
    color: #969ba1;
    background-color: transparent;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
    resize: none;
  }

  .message::-webkit-input-placeholder {
    color: #969ba1;
  }

  .message:focus::placeholder {
    color: transparent;
  }
`;

const ButtonBlock = styled.div`
  .email {
    width: 260px;
    height: 36px;
    margin: 0 0 0 20px;
    padding-left: 16px;
    padding-right: 16px;
    outline: none;
    border-radius: 4px;
    border: solid 1px #e7e9ea;
    background-color: transparent;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
    float: left;
  }

  .email:focus {
    outline: none !important;
    border: 1px solid ${(props) => props.theme.main_color};
  }

  .email::-webkit-input-placeholder {
    color: #969ba1;
  }

  .buttonsBox {
    height: 45px;
    margin: 0 0 0 0;
  }
`;

const EditMembers = styled.div`
  .titleBox {
    height: 18px;
    margin: 20px 24px 16px 20px;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #59616a;
  }
`;

const EditGroupMembers = ({ visible, title, error, onSendInvitation, onCancel }) => {
  const { t } = useTranslation();

  const { odm } = useSelector(({ app }) => ({
    odm: app.odm,
  }));

  const [animate, setAnimate] = useState(false);
  const [localVisible, setLocalVisible] = useState(visible);
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const { app } = useSelector(({ app }) => ({
    app: app.app,
  }));

  useEffect(() => {
    if (localVisible && !visible) {
      setAnimate(true);
      setTimeout(() => setAnimate(false), 250);
    }
    setLocalVisible(visible);
  }, [localVisible, visible]);

  useEffect(() => {
    if (visible) setEmail('');
  }, [visible]);

  /*
  const closeActive =
    process.env.PUBLIC_URL + '/images/common/icon-24-close-active.svg';
  const closeHover =
    process.env.PUBLIC_URL + '/images/common/icon-24-close-hover.svg';
  const closeDisabled =
    process.env.PUBLIC_URL + '/images/common/icon-24-close-disabled.svg';
  const closeSelected =
    process.env.PUBLIC_URL + '/images/common/icon-24-close-selected.svg';
  */

  const onInputChanged = (e) => {
    const { value, name } = e.target;
    setEmail(value);

    if (name === 'email') validate_field(value, 'groupUser', name);
  };

  const validate_field = async (value, formName, field) => {
    const formData = new FormData();
    formData.append('email', '');
    if (field) formData.set(field, value);

    try {
      let res = await validate({ formData, form: formName, field });

      if (res.statusText !== 'OK') {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      const node = document.getElementsByName(field + 'Validate')[0];
      if (JSON.stringify(res.data) === '{}') {
        setValidEmail(true);

        if (node.childNodes.length > 0) node.removeChild(node.childNodes[0]);
        node.classList.remove('validateCss');
      } else {
        if (res.data.error_message) {
          setValidEmail(false);

          let text = '';
          res.data.error_message.forEach((el) => {
            // text += '<div>' + t(el) + '</div>';
            if (el === 'user not in the same region') {
              // const other_loc = app ? (app.server_loc === 'US' ? 'EU' : 'non-EU') : '';
              // 'This email account is located in the EU region: you cannot connect to transfer data.'
              let msg = t(
                'This email account is located in a different region: you cannot connect to transfer data.',
              );
              // msg = msg.replace('EU', other_loc);
              text += '<div>' + msg + '</div>';
            } else text += '<div>' + t(el) + '</div>';
          });

          let small = document.createElement('small');
          small.innerHTML = "<font color='red'>" + `${text}` + '</font>';

          if (node.childNodes.length === 0) node.appendChild(small);
          else node.replaceChild(small, node.childNodes[0]);
          node.classList.add('validateCss');
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (!animate && !localVisible) return null;
  {
    //    error = "error occurred~!!";
  }
  return (
    <Fullscreen disappear={!visible}>
      <ModalBlock disappear={!visible}>
        <TitleBlock>
          <div className="titleBox">
            <div class="titleText">{title}</div>
            {/*
            <img
              className={'closeIcon'}
              src={closeActive}
              onMouseOver={(e) => (e.currentTarget.src = closeHover)}
              onMouseOut={(e) => (e.currentTarget.src = closeActive)}
              onMouseDown={(e) => (e.currentTarget.src = closeSelected)}
              onClick={onCancel}
            />
            */}
            <div className={'closeIcon'} onClick={onCancel}>
              <SVG
                name="close"
                color="#878D93"
                colorOver={odm.main_color_hover}
                colorOut="#878D93"
                colorDown={odm.main_color}
              />
            </div>
          </div>
        </TitleBlock>

        {/* <EditMembers>
                  <div className='titleBox'>EDIT MEMBERS</div>
                  {groups && groups.sharegroup && (
                      <div>
                          <MemberItem
                              _member={""}
                          />
                      </div>
                  )}
              </EditMembers> */}

        <EditMembers>
          <div className="titleBox">{t('Invite new member').toUpperCase()}</div>
        </EditMembers>

        <ButtonBlock>
          <div className="buttonsBox">
            <input
              name="email"
              type="email"
              className="email"
              placeholder={t('Email Address')}
              onChange={onInputChanged}
              value={email}
            />

            <HButton
              category="Primary"
              size="Medium"
              text={t('Send Invite').toUpperCase()}
              width="auto"
              margin="0 20px 0 0"
              float="right"
              height="40px"
              onClick={(e) => onSendInvitation(email)}
              disabled={!validEmail}
            />
          </div>
          <ValidateDiv
            name="emailValidate"
            paddingLeft={'35px'}
            paddingBottom={'10px'}
          ></ValidateDiv>
        </ButtonBlock>
      </ModalBlock>
    </Fullscreen>
  );
};

export default EditGroupMembers;
