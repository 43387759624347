import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { changeField, listProfile, initializeForm } from '../../modules/auth';
import { check } from '../../modules/user';
import { validate, setUserProfile } from '../../lib/api/auth';
import { saveSettings } from '../../lib/api/settings';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditProfile from '../../components/auth/EditProfile';
// import { userProfileSuccess, setUserProfileSuccess } from '../../modules/auth';
import { ThemeContext } from 'styled-components';
import { useRefreshOnAuthError, redirectLocation } from '../../lib/common';
import qs from 'qs';
import countries from 'i18n-iso-countries';
import { GotoCases } from '../../lib/responsive';

const EditProfileContainer = ({ history, location }) => {
  GotoCases();
  const { t, i18n } = useTranslation();
  const language = i18n.language.split('-')[0];
  const [disabled, setDisabled] = useState(false);
  const imgRef = useRef(null);
  const coverImgRef = useRef(null);
  const dispatch = useDispatch();
  const { user, userSettings, userChecking, form, loading, odm } = useSelector(
    ({ user, auth, loading, app }) => ({
      user: user.user,
      userSettings: user.settings,
      userChecking: loading['user/CHECK'],
      form: auth.signup,
      // auth: auth.auth,
      // language: auth.language.language,
      loading: loading['auth/LIST_PROFILE'],
      odm: app.odm,
    }),
    shallowEqual,
  );
  const themeContext = useContext(ThemeContext);
  const [migLoading, setMigLoading] = useState(false);

  const baseImage =
    process.env.PUBLIC_URL + '/' + themeContext.images + '/signup/avatar-64-add.svg';
  const [imgSrc, setImgSrc] = useState(baseImage);
  const [avataImage, setAvataImage] = useState(false);

  const [messageTitle, setMessageTitle] = useState('');
  const [messageType, setMessageType] = useState('ok');
  const [messageVisible, setMessageVisible] = useState(false);
  const [messageContent, setMessageContent] = useState('');
  const [messageConfirmCheck, setMessageConfirmCheck] = useState({ mode: 'save' });
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const next = params.next ? params.next : '/';
  // const isUserLoc = !(auth && !auth.user_loc);
  const isHCloud = odm.name === 'HCLOUD';
  const isUserLoc = !isHCloud || (isHCloud && userSettings.data_loc_set !== false);

  const onChange = (e) => {
    const { value, name } = e.target;
    dispatch(
      changeField({
        form: 'signup',
        key: name,
        value,
      }),
    );
  };

  const onFocusOut = (e) => {
    const { value, name } = e.target;
    validate_signup(value, 'signup', name);
  };

  const validate_signup = async (value, formName, field) => {
    const formData = new FormData();
    formData.append('username', '');
    formData.append('password', '');
    formData.append('password_confirm', '');
    formData.append('first_name', '');
    formData.append('last_name', '');
    formData.append('role', '');
    formData.append('institution', '');
    formData.append('country', '');
    formData.append('phone', '');
    formData.append('address', '');
    formData.append('picture', '');

    if (field) formData.set(field, value);

    try {
      let res = await validate({ formData, form: formName, field });

      if (res.statusText !== 'OK') {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      const node = document.getElementsByName(field + 'Validate')[0];
      if (JSON.stringify(res.data) === '{}') {
        if (node.childNodes.length > 0) node.removeChild(node.childNodes[0]);
        node.classList.remove('validateCss');
      } else {
        if (res.data.error_message) {
          let text = '';
          res.data.error_message.forEach((el) => {
            text += '<div key={index}>' + t(el) + '</div>';
          });

          let small = document.createElement('small');
          small.innerHTML = "<font color='red'>" + `${text}` + '</font>';

          if (node.childNodes.length === 0) node.appendChild(small);
          else node.replaceChild(small, node.childNodes[0]);
          node.classList.add('validateCss');
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSelect = (value, name) => {
    dispatch(
      changeField({
        form: 'signup',
        key: name,
        value,
      }),
    );
  };

  const onChangeImage = (e) => {
    const { name } = e.target;
    if (e.target.files.length) {
      //selected file
      var imgTarget = e.target.files[0];

      dispatch(
        changeField({
          form: 'signup',
          key: name,
          value: imgTarget,
        }),
      );

      var fileReader = new FileReader();
      fileReader.readAsDataURL(imgTarget);
      fileReader.onload = function (e) {
        setImgSrc(e.target.result);
        setAvataImage(true);

        if (imgRef) {
          imgRef.current.addEventListener('mouseover', onMouseOver);
          imgRef.current.addEventListener('mouseout', onMouseOut);
        }
        if (coverImgRef) {
          coverImgRef.current.addEventListener('mouseover', onMouseOver);
          coverImgRef.current.addEventListener('mouseout', onMouseOut);
        }
      };

      e.target.value = ''; //onchagne event when same file
    }
  };

  const onMouseOver = useCallback((e) => {
    if (imgRef) {
      imgRef.current.style.opacity = '0.3';
      coverImgRef.current.style.display = 'flex';
      setDisabled(true);
    }
  }, []);
  const onMouseOut = useCallback((e) => {
    if (imgRef) {
      imgRef.current.style.opacity = '1';
      coverImgRef.current.style.display = 'none';
      setDisabled(false);
    }
  }, []);

  const onDeleteClick = (e) => {
    e.preventDefault();
    setImgSrc(baseImage);
    setAvataImage(false);

    if (imgRef && coverImgRef) {
      onMouseOut();
      imgRef.current.removeEventListener('mouseover', onMouseOver);
      imgRef.current.removeEventListener('mouseout', onMouseOut);
      coverImgRef.current.removeEventListener('mouseover', onMouseOver);
      coverImgRef.current.removeEventListener('mouseout', onMouseOut);
    }

    dispatch(
      changeField({
        form: 'signup',
        key: 'picture',
        value: '',
      }),
    );
  };

  const submit = () => {
    if (isUserLoc) {
      onEditProfile();
    } else {
      setMessageContent(
        t(
          'Data storage region is assigned depending on the country you select. This option cannot be modified after you click OK. Do you want to continue?',
        ),
      );
      setMessageTitle(t('Info'));
      setMessageType('okcancel');
      setMessageVisible(true);
      setMessageConfirmCheck({ mode: 'dataInfoAsk' });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    submit();
  };

  const onEditProfile = async () => {
    const formData = new FormData();
    const {
      username,
      password,
      passwordConfirm,
      first_name,
      last_name,
      role,
      institution,
      country,
      phone,
      address,
      picture,
    } = form;

    if (user.social_auth) {
      if ([role, country].includes('')) {
        alert(t('Please enter all required items.'));
        return;
      }
    } else {
      if ([first_name, last_name, role, country].includes('')) {
        alert(t('Please enter all required items.'));
        return;
      }
    }

    if (!user.social_auth) {
      if (first_name) formData.append('first_name', first_name);
      if (last_name) formData.append('last_name', last_name);
      if (picture) formData.append('picture', picture);
      else {
        if (!avataImage) formData.append('picture', '');
      }
    }

    if (role) formData.append('role', role);
    if (institution) formData.append('institution', institution);
    if (country) formData.append('country', country);
    if (phone) formData.append('phone', phone);
    if (address) formData.append('address', address);

    // for (var pair of formData.entries()) { console.log(pair[0] + ', ' + pair[1]); }

    // dispatch(set_userProfile({ formData }));
    setMigLoading(true);
    try {
      const res = await setUserProfile({ formData });
      // if (!userSettings.data_loc_set) await saveSettings({ data_loc_set: true });
      if (isUserLoc) {
        setMessageContent(t('Profile has been changed successfully'));
        setMessageTitle(t('Info'));
        setMessageType('ok');
        setMessageVisible(true);
        setMessageConfirmCheck({ mode: 'save' });
      } else {
        // reset store.auth
        // dispatch(initializeForm('login'));
        // if (app.server_loc === res.data.user_loc) {
        if (res.data.server_loc === res.data.user_loc) {
          // history.push(next);
          window.location.assign(next);
        } else redirectLocation(res.data.user_loc, next);
      }
    } catch (e) {
      console.log(e);
    }
    setMigLoading(false);
  };

  const onConfirmBoxOk = () => {
    setMessageVisible(false);
    if (messageConfirmCheck.mode === 'save') {
      dispatch(check({ callFrom: user.is_device_manager ? 'adm' : null }));
    } else if (messageConfirmCheck.mode === 'dataInfoAsk') {
      onEditProfile();
    } else if (messageConfirmCheck.mode === 'migration') {
      let selectedCountry = countries.getName(form.country, language);
      setMessageContent(
        t(
          'You have selected the United States as your country. Your data will be assigned to the selected region. This option cannot be modified after you click OK. Do you want to continue?',
        ).replace('United States', selectedCountry),
      );
      setMessageTitle(t('Info'));
      setMessageType('okcancel');
      setMessageVisible(true);
      setMessageConfirmCheck({ mode: 'migrationStart' });
    } else if (messageConfirmCheck.mode === 'migrationStart') {
      onEditProfile(); //start migration
    }
    // setUserProfileSuccess(false);
  };

  const onCancel = () => {
    setMessageVisible(false);
    // setUserProfileSuccess(false);
  };

  useEffect(() => {
    if (!userChecking) {
      // console.log('userChecking');
      dispatch(listProfile());
      // if (!userSettings.data_loc_set) {
      //   setMessageContent(
      //     t(
      //       'Please update your profile, you need to select the country where you reside. This information is required to assign proper data storage location.',
      //     ),
      //   );
      //   setMessageTitle(t('Info'));
      //   setMessageType('ok');
      //   setMessageVisible(true);
      //   setMessageConfirmCheck({ mode: 'dataInfo' });
      // }
      // if (userProfileSuccess === true) {
      //   setMessageContent(t('Profile has been changed successfully.'));
      //   setMessageVisible(true);
      //   setMessageTitle(t('Info'));
      // }
    }
  }, [dispatch, userChecking]);

  useEffect(() => {
    if (!loading && form) {
      if (!isUserLoc) {
        setMessageContent(
          t(
            'From Jan 8th 2023, the data storage region assigned to your account will depend on your location. Please select the country where you are located.',
          ),
        );
        setMessageTitle(t('Info'));
        setMessageType('okcancel');
        setMessageVisible(true);
        setMessageConfirmCheck({ mode: 'migration' });
      }
    }
  }, [loading]);

  useEffect(() => {
    if (user.picture) {
      setImgSrc(user.picture);
      setAvataImage(true);
    }
  }, [user.picture]);

  // useEffect(() => {
  //   if (user) {
  //     history.push('/');
  //     try {
  //       localStorage.setItem('user', JSON.stringify(user));
  //     } catch (e) {
  //       console.log('localStorage is not working');
  //     }
  //   }
  // }, [history, user]);

  useRefreshOnAuthError();

  return (
    <EditProfile
      migLoading={migLoading}
      loading={loading}
      user={user}
      form={form}
      language={language}
      onChange={onChange}
      onSelect={onSelect}
      onChangeImage={onChangeImage}
      imgSrc={imgSrc}
      onSubmit={onSubmit}
      disabled={disabled}
      imgRef={imgRef}
      coverImgRef={coverImgRef}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onDeleteClick={onDeleteClick}
      messageConfirmCheck={messageConfirmCheck.mode}
      messageVisible={messageVisible}
      messageTitle={messageTitle}
      messageType={messageType}
      messageContent={messageContent}
      onConfirmBoxOk={onConfirmBoxOk}
      onCancel={onCancel}
      onFocusOut={onFocusOut}
      isUserLoc={isUserLoc}
      isHCloud={isHCloud}
    />
  );
};

export default withRouter(React.memo(EditProfileContainer));
