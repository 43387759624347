import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import Logo from './styled/Logo';
import Input from './styled/Input';
import Button from './styled/Button';
import ErrorList from './styled/ErrorList';
import { getODM } from '../../lib/odm';
import { useDispatch } from 'react-redux';
import { initializeForm } from '../../modules/auth';
import MobileTop from './styled/MobileTop';
import { TabletOrDesktop } from '../../lib/responsive';

const Container = styled.div`
  display: flex;
  min-height: 798px;
  height: 100%;
  background: #eeeff0;
  justify-content: center;

  .FormContainer {
    margin-top: ${(props) => (props.mobile ? (props.error ? '0px' : '80px') : '225px')};
  }
`;

const Text = styled.div`
  display: flex;
  margin-left: ${(props) => (props.mobile ? 'auto' : '16px')};
  margin-right: ${(props) => (props.mobile ? 'auto' : '0px')};
  margin-top: ${(props) => props.marginTop};
  width: ${(props) => (props.mobile ? '288px' : '328px')};
  /* text-align: center; */
  height: ${(props) => props.height};
  background: transparent;
  flex-direction: row;

  font-family: 'Source Sans Pro';
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight || '600'};
  font-stretch: normal;
  font-style: normal;
  line-height: ${(props) => props.lineHeight || 'normal'};
  letter-spacing: ${(props) => props.letterSpacing || 'normal'};
  text-align: ${(props) => (props.mobile ? 'center' : 'left')};
  justify-content: ${(props) => (props.mobile ? 'center' : 'left')};
  color: ${(props) => props.color || '#ff6d22'};
`;

const PasswordAssistanceForm = ({
  form,
  onChange,
  onSubmit,
  error,
  onBackButtonEvent,
  isLoadingRef,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isTabletOrDesktop = TabletOrDesktop();

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
      dispatch(initializeForm('authError'));
    };
  }, []);

  return (
    <div>
      {isTabletOrDesktop && <Logo />}
      {!isTabletOrDesktop && <MobileTop></MobileTop>}
      <Container error={error} mobile={!isTabletOrDesktop}>
        <div className="FormContainer">
          <ErrorList error={error} width={isTabletOrDesktop ? 'auto' : '288px'} />
          <Text
            mobile={!isTabletOrDesktop}
            width="328px"
            // height="32px"
            color="#59616a"
            fontSize="25px"
          >
            {t('Password Assistance')}
          </Text>
          <Text
            mobile={!isTabletOrDesktop}
            width="416px"
            color="#969ba1"
            fontSize="15px"
            lineHeight="1.6"
            marginTop="16px"
            fontWeight="normal"
          >
            {t(
              `Enter the email address you used when creating the account and click Send.`,
            )}
          </Text>
          <form onSubmit={onSubmit}>
            <div>
              <Input
                width={isTabletOrDesktop ? '416px' : '288px'}
                type="email"
                margin="24px 0 0 0"
                name="username"
                placeholder={t('Email Address')}
                value={form.username}
                onChange={onChange}
                required
                autoFocus
              />
            </div>
            <div>
              <Button
                width={isTabletOrDesktop ? '448px' : '320px'}
                margin="16px 0 0 0"
                disabled={isLoadingRef.current}
              >
                {t('Reset Password').toUpperCase()}
              </Button>
            </div>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default React.memo(PasswordAssistanceForm);
