import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Card from '../../components/dashboard/DashboardCaseCard';
import styled from 'styled-components';

const CaseStatusLayout = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 16px 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    'Open Open Open InProgress InProgress InProgress'
    'Space Space Space Space Space Space'
    'Completed Completed Reopened Reopened Closed Closed';

  .Space {
    grid-area: Space;
  }
  .Open {
    grid-area: Open;
  }
  .InProgress {
    grid-area: InProgress;
  }
  .Completed {
    grid-area: Completed;
  }
  .Reopened {
    grid-area: Reopened;
  }
  .Closed {
    grid-area: Closed;
  }
`;

const CasesStatus = ({ status, clicked }) => {
  const collapsed = useSelector((state) => state.leftpanel.collapsed);
  const { t } = useTranslation();

  const cardStatusColor = {
    Open: '#2274ec',
    //Submitted: '#ff6d22',
    // Reopened: '#e63b3b',
    Reopened: '#2274ec',
    InProgress: '#a78120',
    Completed: '#50b019',
    Closed: '#a0a0a0',

    //'WaitingApproval': '#8655e1'
  };

  return (
    <CaseStatusLayout>
      <div className="Open">
        <Card
          status={status}
          id="Open"
          name={t('Open')}
          clicked={clicked}
          textColor={cardStatusColor['Open']}
        />
      </div>
      <div className="InProgress">
        <Card
          status={status}
          id="InProgress"
          name={t('In Progress')}
          clicked={clicked}
          textColor={cardStatusColor['InProgress']}
        />
      </div>
      <div className="Completed">
        <Card
          status={status}
          id="Completed"
          name={t('Completed')}
          clicked={clicked}
          textColor={cardStatusColor['Completed']}
        />
      </div>
      <div className="Reopened">
        <Card
          status={status}
          id="Reopened"
          name={t('Reopened')}
          clicked={clicked}
          textColor={cardStatusColor['Reopened']}
        />
      </div>
      <div className="Closed">
        <Card
          status={status}
          id="Closed"
          name={t('Closed')}
          clicked={clicked}
          textColor={cardStatusColor['Closed']}
        />
      </div>
    </CaseStatusLayout>
  );

  return (
    false && (
      <>
        <div className="row" style={{}}>
          <div className="column">
            <Card
              status={status}
              id="Open"
              name={t('Open')}
              clicked={clicked}
              textColor={cardStatusColor['Open']}
              width={collapsed === false ? 402 : 474}
            />
          </div>

          <div
            className="column"
            style={{ marginLeft: collapsed === true ? '20px' : '19px' }}
          >
            <Card
              status={status}
              id="InProgress"
              name={t('In Progress')}
              clicked={clicked}
              textColor={cardStatusColor['InProgress']}
              width={collapsed === false ? 402 : 474}
            />
          </div>
          {/* <div
          className="column"
          style={{ marginLeft: collapsed === true ? '20px' : '19px' }}
        >
          <Card
            status={status}
            id="Rescan"
            name={t('Rescan')}
            clicked={clicked}
            textColor={cardStatusColor['Rescan']}
            width={collapsed === false ? 262 : 309}
          />
        </div> */}
        </div>

        <div className="row" style={{ marginTop: '16px' }}>
          <div className="column">
            <Card
              status={status}
              id="Completed"
              name={t('Completed')}
              clicked={clicked}
              textColor={cardStatusColor['Completed']}
              width={collapsed === false ? 262 : 309}
            />
          </div>

          <div
            className="column"
            style={{ marginLeft: collapsed === true ? '20px' : '19px' }}
          >
            <Card
              status={status}
              id="Reopened"
              name={t('Reopened')}
              clicked={clicked}
              textColor={cardStatusColor['Reopened']}
              width={collapsed === false ? 262 : 309}
            />
          </div>
          <div
            className="column"
            style={{ marginLeft: collapsed === true ? '20px' : '19px' }}
          >
            <Card
              status={status}
              id="Closed"
              name={t('Closed')}
              clicked={clicked}
              textColor={cardStatusColor['Closed']}
              width={collapsed === false ? 262 : 309}
            />
          </div>
        </div>
      </>
    )
  );
};

export default CasesStatus;
