import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

const CardStyle = styled.div`
  width: auto;
  height: 126px;
  margin-right: 19px;
  border-radius: 4px;
  border: solid 1px #e7e9ea;
  background-color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  position: relative;

  &:hover {
    background-color: #f5f6f6;
  }

  .cardCountStyle {
    width: 180px;
    height: 44px;
    margin-top: 16px;
    margin-left: 20px;
    font-family: 'Source Sans Pro';
    font-size: 35px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
    display: block;
    cursor: pointer;

    text-decoration: none;
  }

  .cardStatusStyle {
    width: 180px;
    height: 19px;
    margin-top: 33px;
    margin-left: 19px;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.13px;
    color: ${(props) => props.textColor};
    float: left;
    cursor: pointer;

    text-decoration: none;
  }
`;

const DashboardCaseCard = ({ status, id, name, clicked, textColor, width }) => {
  const leftpanel = useSelector((state) => state.leftpanel);
  const { t } = useTranslation();

  var cnt = '';
  if (status) {
    //console.log("x: " + status);
    // if (id === 'New') cnt = status.status_counts.new;
    if (id === 'Open') cnt = status.status_counts.open;
    else if (id === 'InProgress') cnt = status.status_counts.inprog;
    // else if (id === 'Rescan') cnt = status.status_counts.rescan;
    else if (id === 'Completed') cnt = status.status_counts.completed;
    else if (id === 'Reopened') cnt = status.status_counts.reopened;
    else if (id === 'Closed') cnt = status.status_counts.closed;
    else cnt = 'N/A';
  }

  return (
    <>
      <Link
        to="/cases"
        onClick={(e) => clicked(id)}
        style={{ textDecoration: 'none' }}
      >
        <CardStyle>
          <div className="cardCountStyle">{cnt}</div>
          <div className="cardStatusStyle" style={{ color: textColor }}>
            {' '}
            {t(name)}{' '}
          </div>
          <img
            src={
              process.env.PUBLIC_URL +
              '/images/common/icon-24-arrow-forward-active.svg'
            }
            alt=""
            style={{
              display: 'flex',
              position: 'absolute',
              right: 20,
              marginTop: '28px',
            }}
            //style={{ marginLeft: leftpanel.collapsed === true ? '36px' : '28px', cursor: 'pointer' }}
            //onClick={ }
          />
        </CardStyle>
        {/* <div
          className="cardStyle"
          style={{ width: width - 2, position: 'relative' }}
        >
          <div className="cardCountStyle">{cnt}</div>

          <div style={{ width: width, marginTop: '33px', marginLeft: '19px' }}>
            <label
              className="cardStatusStyle"
              style={{ color: textColor, width: width - 168 }}
            >
              {t(name)}
            </label>
            <img
              src={
                process.env.PUBLIC_URL +
                '/images/common/icon-24-arrow-forward-active.svg'
              }
              alt=""
              style={{ display: 'flex', position: 'absolute', right: 20 }}
            //style={{ marginLeft: leftpanel.collapsed === true ? '36px' : '28px', cursor: 'pointer' }}
            //onClick={ }
            />
          </div>
        </div> */}
      </Link>
    </>
  );
};

export default DashboardCaseCard;
