import { createAction, handleActions } from 'redux-actions';
import { produce } from 'immer';
import { takeLatest, put, delay, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import * as authAPI from '../lib/api/auth';
import * as csAPI from '../lib/api/cs';
import { check } from './user';

const ACCESS_EXP_MIN = 60;

const CHANGE_FIELD = 'auth/CHANGE_FIELD';
const INITIALIZE_FORM = 'auth/INITIALIZE_FORM';
const SET_TOKEN = 'auth/SET_TOKEN';
const RESET_ERROR = 'auth/RESET_ERROR';
const REMOVE_ACCESS = 'auth/REMOVE_ACCESS';

const [SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAILURE] = createRequestActionTypes('auth/SIGNUP');
const [LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE] = createRequestActionTypes('auth/LOGIN');
const [REFRESH, REFRESH_SUCCESS, REFRESH_FAILURE] = createRequestActionTypes('auth/REFRESH');
const [ACTIVATE, ACTIVATE_SUCCESS, ACTIVATE_FAILURE] = createRequestActionTypes('auth/ACTIVATE');

const [RESETPASSWORD, RESETPASSWORD_SUCCESS, RESETPASSWORD_FAILURE] =
  createRequestActionTypes('auth/RESETPASSWORD');
const [CHECKPASSTOKEN, CHECKPASSTOKEN_SUCCESS, CHECKPASSTOKEN_FAILURE] =
  createRequestActionTypes('auth/CHECKPASSTOKEN');
const [RESETPASSWORDCONFIRM, RESETPASSWORDCONFIRM_SUCCESS, RESETPASSWORDCONFIRM_FAILURE] =
  createRequestActionTypes('auth/RESETPASSWORDCONFIRM');
const [SOCIALLOGINOTP, SOCIALLOGINOTP_SUCCESS, SOCIALLOGINOTP_FAILURE] =
  createRequestActionTypes('auth/SOCIALLOGINOTP');
const [LIST_PROFILE, LIST_PROFILE_SUCCESS, LIST_PROFILE_FAILURE] =
  createRequestActionTypes('auth/LIST_PROFILE');
// const [SET_USERPROFILE, SET_USERPROFILE_SUCCESS, SET_USERPROFILE_FAILURE] =
//   createRequestActionTypes('auth/SET_USERPROFILE');
const [CHANGEPASSWORD, CHANGEPASSWORD_SUCCESS, CHANGEPASSWORD_FAILURE] =
  createRequestActionTypes('auth/CHANGEPASSWORD');
const [CSLOGIN, CSLOGIN_SUCCESS, CSLOGIN_FAILURE] = createRequestActionTypes('auth/CSLOGIN');

export const changeField = createAction(CHANGE_FIELD, ({ form, key, value }) => ({
  form,
  key,
  value,
}));
export const initializeForm = createAction(INITIALIZE_FORM, (form) => form);
export const resetError = createAction(RESET_ERROR);
const removeAccess = createAction(REMOVE_ACCESS);
export const signup = createAction(SIGNUP, ({ formData }) => ({ formData }));
export const login = createAction(LOGIN, ({ username, password, callFrom }) => ({
  username,
  password,
  callFrom,
}));
export const refreshAccessToken = createAction(REFRESH);
export const setToken = createAction(SET_TOKEN, ({ access }) => ({ access }));
export const resetPassword = createAction(RESETPASSWORD, ({ email }) => ({
  email,
}));
export const checkResetPasswordToken = createAction(CHECKPASSTOKEN, ({ token }) => ({ token }));
export const resetPasswordConfirm = createAction(RESETPASSWORDCONFIRM, ({ token, password }) => ({
  token,
  password,
}));
export const socialLoginOtp = createAction(SOCIALLOGINOTP, ({ code, codeVerifier }) => ({
  code,
  codeVerifier,
}));
export const listProfile = createAction(LIST_PROFILE);
// export const set_userProfile = createAction(
//   SET_USERPROFILE,
//   ({ formData }) => ({
//     formData,
//   }),
// );
export const changePassword = createAction(CHANGEPASSWORD, ({ formData }) => ({
  formData,
}));
export const activate = createAction(ACTIVATE, ({ uidb64, token }) => ({
  uidb64,
  token,
}));
export const csLogin = createAction(CSLOGIN, (uid) => uid);

const signupSaga = createRequestSaga(SIGNUP, authAPI.signup);
const loginSaga = createRequestSaga(LOGIN, authAPI.login);
const refreshSaga = createRequestSaga(REFRESH, authAPI.refresh);
const resetPasswordSaga = createRequestSaga(RESETPASSWORD, authAPI.resetPassword);
const checkResetPassTokenSaga = createRequestSaga(CHECKPASSTOKEN, authAPI.checkResetPassToken);
const resetPasswordConfirmSaga = createRequestSaga(
  RESETPASSWORDCONFIRM,
  authAPI.resetPasswordConfirm,
);
const socialLoginOtpSaga = createRequestSaga(SOCIALLOGINOTP, authAPI.socialLoginOtp);
const listProfileSaga = createRequestSaga(LIST_PROFILE, authAPI.getUserProfile);
// const set_userProfileSaga = createRequestSaga(
//   SET_USERPROFILE,
//   authAPI.setUserProfile,
// );
const changePasswordSaga = createRequestSaga(CHANGEPASSWORD, authAPI.changePassword);
const activateSaga = createRequestSaga(ACTIVATE, authAPI.activate);
const csLoginSaga = createRequestSaga(CSLOGIN, csAPI.tempLogin);

const getUser = (state) => state.user.user;

function* setTokenSaga(action) {
  authAPI.setToken(action.payload);
  // yield put(initializeForm('login'));
  yield put(removeAccess());

  // refresh access token after ACCESS_EXP_MIN -1 minutes
  yield delay((ACCESS_EXP_MIN - 1) * 60 * 1000);
  const user = yield select(getUser);
  // refresh only when user logged in
  if (user) yield put(refreshAccessToken());
}
function* signupSuccessSaga({ payload }) {
  yield put(push('/signupEmail'));
}
function* loginSuccessSaga({ payload }) {
  yield put(setToken(payload));
}
function* loginFailureSaga({ payload }) {
  if (yield payload.response.data.uid) {
    yield put(push(`/resend/${payload.response.data.uid}`));
  }
}
function* refreshSuccessSaga({ payload }) {
  yield put(setToken(payload));
}
function* socialLoginOtpSuccessSaga({ payload }) {
  yield put(setToken(payload));
}

// export let userProfileSuccess = false;
// export const setUserProfileSuccess = (val) => {
//   userProfileSuccess = val;
// };
// function* set_userProfileSuccessSaga({ payload }) {
//   yield put(check());
//   yield (userProfileSuccess = true);
// }

function* checkResetPassTokenFailureSaga({ payload }) {
  yield put(push('/tokenExpired'));
}

function* resetPasswordConfirmSuccessSaga({ payload }) {
  yield put(initializeForm('signup'));
  yield put(push('/createNewPassword'));
}

export let changePasswordSuccess = false;
export const setchangePasswordSuccess = (val) => {
  changePasswordSuccess = val;
};
function* changePasswordSuccessSaga({ payload, meta }) {
  yield put(initializeForm('signup'));
  yield (changePasswordSuccess = true);
  // yield put(push('/'));
}
function* csLoginSuccessSaga({ payload }) {
  yield put(setToken(payload));
}

export function* authSaga() {
  yield takeLatest(SIGNUP, signupSaga);
  yield takeLatest(SIGNUP_SUCCESS, signupSuccessSaga);
  yield takeLatest(LOGIN, loginSaga);
  yield takeLatest(LOGIN_SUCCESS, loginSuccessSaga);
  yield takeLatest(LOGIN_FAILURE, loginFailureSaga);
  yield takeLatest(SET_TOKEN, setTokenSaga);
  yield takeLatest(REFRESH, refreshSaga);
  yield takeLatest(REFRESH_SUCCESS, refreshSuccessSaga);
  yield takeLatest(RESETPASSWORD, resetPasswordSaga);
  yield takeLatest(RESETPASSWORDCONFIRM, resetPasswordConfirmSaga);
  yield takeLatest(RESETPASSWORDCONFIRM_SUCCESS, resetPasswordConfirmSuccessSaga);
  yield takeLatest(CHECKPASSTOKEN, checkResetPassTokenSaga);
  yield takeLatest(CHECKPASSTOKEN_FAILURE, checkResetPassTokenFailureSaga);
  yield takeLatest(SOCIALLOGINOTP, socialLoginOtpSaga);
  yield takeLatest(SOCIALLOGINOTP_SUCCESS, socialLoginOtpSuccessSaga);
  yield takeLatest(LIST_PROFILE, listProfileSaga);
  // yield takeLatest(SET_USERPROFILE, set_userProfileSaga);
  // yield takeLatest(SET_USERPROFILE_SUCCESS, set_userProfileSuccessSaga);
  yield takeLatest(CHANGEPASSWORD, changePasswordSaga);
  yield takeLatest(CHANGEPASSWORD_SUCCESS, changePasswordSuccessSaga);
  yield takeLatest(ACTIVATE, activateSaga);
  yield takeLatest(CSLOGIN, csLoginSaga);
  yield takeLatest(CSLOGIN_SUCCESS, csLoginSuccessSaga);
}

const initialState = {
  signup: {
    username: '',
    old_password: '',
    password: '',
    passwordConfirm: '',
    first_name: '',
    last_name: '',
    role: '',
    institution: '',
    country: '',
    phone: '',
    address: '',
    picture: '',
  },
  login: {
    username: '',
    password: '',
  },
  auth: null,
  authError: null,
  language: { language: 'en' },
};

const auth = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: { form, key, value } }) =>
      produce(state, (draft) => {
        draft[form][key] = value;
      }),
    [INITIALIZE_FORM]: (state, { payload: form }) => ({
      ...state,
      [form]: initialState[form],
      auth: null,
      authError: null,
    }),
    [RESET_ERROR]: (state) => ({
      ...state,
      authError: null,
    }),
    [REMOVE_ACCESS]: (state) => {
      if (state.auth && state.auth.access) delete state.auth.access;
      return state;
    },
    [SIGNUP_SUCCESS]: (state, { payload: auth }) => ({
      ...state,
      authError: null,
      auth,
    }),
    [SIGNUP_FAILURE]: (state, { payload: error }) => ({
      ...state,
      authError: error,
    }),
    [LOGIN_SUCCESS]: (state, { payload: auth }) => ({
      ...state,
      authError: null,
      auth,
    }),
    [LOGIN_FAILURE]: (state, { payload: error }) => ({
      ...state,
      authError: error,
    }),
    [REFRESH_SUCCESS]: (state, { payload: auth }) => ({
      ...state,
      authError: null,
      auth,
    }),
    [REFRESH_FAILURE]: (state, { payload: error }) => ({
      ...state,
      authError: error,
    }),
    [RESETPASSWORD_SUCCESS]: (state, { payload: auth }) => ({
      ...state,
      authError: null,
      auth,
    }),
    [RESETPASSWORD_FAILURE]: (state, { payload: error }) => ({
      ...state,
      authError: error,
    }),
    [CHECKPASSTOKEN_SUCCESS]: (state, { payload: auth }) => ({
      ...state,
      authError: null,
      auth,
    }),
    [CHECKPASSTOKEN_FAILURE]: (state, { payload: error }) => ({
      ...state,
      authError: error,
    }),
    [RESETPASSWORDCONFIRM_SUCCESS]: (state, { payload: auth }) => ({
      ...state,
      authError: null,
      auth,
    }),
    [RESETPASSWORDCONFIRM_FAILURE]: (state, { payload: error }) => ({
      ...state,
      authError: error,
    }),
    [SOCIALLOGINOTP_SUCCESS]: (state, { payload: auth }) => ({
      ...state,
      authError: null,
      auth,
    }),
    [SOCIALLOGINOTP_FAILURE]: (state, { payload: error }) => ({
      ...state,
      authError: error,
    }),
    [LIST_PROFILE_SUCCESS]: (state, { payload: auth }) => ({
      ...state,
      signup: auth,
    }),
    [LIST_PROFILE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    // [SET_USERPROFILE_SUCCESS]: (state, { payload: auth }) => ({
    //   ...state,
    //   auth,
    // }),
    // [SET_USERPROFILE_FAILURE]: (state, { payload: error }) => ({
    //   ...state,
    //   error,
    // }),
    [CHANGEPASSWORD_SUCCESS]: (state, { payload: auth }) => ({
      ...state,
      auth,
    }),
    [CHANGEPASSWORD_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [ACTIVATE_SUCCESS]: (state, { payload: auth }) => ({
      ...state,
      auth,
    }),
    [ACTIVATE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [CSLOGIN_SUCCESS]: (state, { payload: auth }) => ({
      ...state,
      auth,
    }),
    [CSLOGIN_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
  },
  initialState,
);

export default auth;
