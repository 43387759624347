import React, { useState, useEffect } from 'react';
import LeftPanel from '../containers/leftpanel/LeftPanelContainer';
import CaseListContainer from '../containers/cases/CaseListContainer';
import CaseDetailContainer from '../containers/case/CaseDetailContainer';
import PaginationContainer from '../containers/cases/PaginationContainer';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styled from 'styled-components';
import { TabletOrDesktop } from '../lib/responsive';
import { initializeForm } from '../modules/case';

const Desktop = styled.div`
  .CaseListNormal {
    width: 100%;
    height: 100%;
    min-width: 1100px !important;
    margin: auto;
    display: grid;
    grid-template-columns: 256px 450px auto;
    grid-template-rows: 100vh auto;
    gap: 0px;
  }

  .CaseListCollapsed {
    width: 100%;
    height: 100%;
    min-width: 1100px !important;
    margin: auto;
    display: grid;
    grid-template-columns: 64px 523px auto;
    grid-template-rows: 100vh auto;
    gap: 0px;
  }

  .CaseListCollapsed2 {
    width: 100%;
    height: 100%;
    min-width: 1100px !important;
    margin: auto;
    display: grid;
    grid-template-columns: 64px 450px auto;
    grid-template-rows: 100vh auto;
    gap: 0px;
  }

  .CaseListLeftPanel {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .CaseListCases {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .CaseListDetail {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .CaseListSpace {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    background: #eeeff0;
  }

  .CaseScrollBox {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

const MobileContainer = styled.div`
  overflow: ${(props) => (props.hideScroll ? 'hidden' : null)};
  min-width: 360px;
  min-height: 100vh;
  height: 100vh;
  margin: 0;
`;

const Tablet = styled.div`
  .CaseList {
    width: 100%;
    height: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    gap: 0px;
  }

  .CaseListCases {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }
`;

const CaseListPage = ({ history, match, location }) => {
  const { content, loading, fullScreen } = useSelector(
    ({ caseDetail, loading }) => ({
      fullScreen: caseDetail.fullScreen,
      content: caseDetail.content,
      loading: loading['cases/LIST_CASES'],
    }),
    shallowEqual,
  );

  const leftpanel = useSelector((state) => state.leftpanel);
  const isTabletOrDesktop = TabletOrDesktop();
  const dispatch = useDispatch();
  const [collapse, setCollapse] = useState(false);
  const [ScrollY, setScrollY] = useState(0);
  const [hideScroll, setHideScroll] = useState(false);

  if (fullScreen) {
    window.scrollTo({
      top: 0,
    });
  }

  useEffect(() => {
    if (fullScreen && ScrollY === 0) setHideScroll(true);
    else setHideScroll(false);
  }, [ScrollY, fullScreen]);

  const handleFollow = () => {
    setScrollY(window.pageYOffset);
  };

  useEffect(() => {
    const watch = () => {
      window.addEventListener('scroll', handleFollow);
    };
    watch();
    return () => {
      window.removeEventListener('scroll', handleFollow);
    };
  });

  useEffect(() => {
    if (!isTabletOrDesktop) {
      if (content && !loading) {
        dispatch(initializeForm('content'));
      }
    }
  }, [isTabletOrDesktop, loading]);

  useEffect(() => {
    window.addEventListener('resize', OnResize);
    return () => {
      window.removeEventListener('resize', OnResize);
    };
  }, []);

  // useEffect(() => {
  //   alert('CaseListPage: ' + isTabletOrDesktop);
  // }, [isTabletOrDesktop]);

  const OnResize = () => {};

  const getMainClass = () => {
    if (leftpanel.collapsed && leftpanel.collapsedAuto) return 'CaseListCollapsed2';
    else if (leftpanel.collapsed) return 'CaseListCollapsed';
    else if (leftpanel.collapsedAuto) return 'CaseListCollapsed2';
    else return 'CaseListNormal';
  };

  return (
    <>
      {isTabletOrDesktop && (
        <Desktop>
          <div className={getMainClass()}>
            <div className={'CaseListLeftPanel'}>
              <LeftPanel />
            </div>
            <div>
              <div className={'CaseListCases'}>
                <CaseListContainer />
              </div>
              <div>
                <PaginationContainer />
              </div>
            </div>
            <div className={'CaseScrollBox'}>
              <div className={'CaseListDetail'}>
                <CaseDetailContainer />
              </div>
            </div>
            <div className={'CaseListSpace'} />
          </div>
        </Desktop>
      )}
      {!isTabletOrDesktop && (
        <MobileContainer hideScroll={hideScroll}>
          <LeftPanel />
          <div>
            {content && (
              <div className={'CaseScrollBox'}>
                <div className={'CaseListDetail'}>
                  <CaseDetailContainer />
                </div>
              </div>
            )}
            {!content && (
              <>
                <div className={'CaseListCases'}>
                  <CaseListContainer />
                </div>
                <div>
                  <PaginationContainer />
                </div>
              </>
            )}
          </div>
        </MobileContainer>
      )}
    </>
  );
};

export default CaseListPage;
