import React from 'react';
import './DashboardPage.css';
import LeftPanel from '../containers/leftpanel/LeftPanelContainer';
import DashboardContainer from '../containers/dashboard/DashboardContainer';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { GotoCases } from '../lib/responsive';

const MainBlock = styled.div`
  .Normal {
    width: 100%;
    height: 100%;
    margin: auto;
    display: grid;
    min-width: 1100px !important;
    background: #eeeff0;
    grid-template-columns: 256px auto;
    grid-template-rows: 100vh auto;
    gap: 0px;
  }

  .Collapsed {
    width: 100%;
    height: 100%;
    margin: auto;
    display: grid;
    min-width: 1100px !important;
    background: #eeeff0;
    grid-template-columns: 64px auto;
    grid-template-rows: 100vh auto;
    gap: 0px;
  }

  .GridLeftPanel {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .GridDashboard {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    height: auto;
    background-color: #eeeff0;
  }

  .ScrollBox {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

const DashboardPage = () => {
  const leftpanel = useSelector((state) => state.leftpanel);
  GotoCases();

  return (
    <MainBlock>
      <div className={leftpanel.collapsed || leftpanel.collapsedAuto ? 'Collapsed' : 'Normal'}>
        <div className="GridLeftPanel">
          <LeftPanel />
        </div>

        <div className="ScrollBox">
          <div className="GridDashboard">
            <DashboardContainer collapsed={leftpanel.collapsed || leftpanel.collapsedAuto} />
          </div>
        </div>
      </div>
    </MainBlock>
  );
};

export default DashboardPage;
